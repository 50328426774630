import { generateQueryOfEndPoint } from '../utils/functions'
import client, { IFilter, PAGE_SIZE } from './client'
import { IPermisions } from './permissions'

export interface IUserRole {
  id: number
  name: string
  permissions?: IPermisions[]
}
export const getRoles = async ({
  search = '',
  sortBy = 'id',
  sortDirection = 'desc',
  pageSize = PAGE_SIZE,
  currentPage = 1,
}: IFilter) => {
  const sort = `${sortDirection === 'desc' ? '-' : ''}${sortBy}`
  const endPoint = generateQueryOfEndPoint('/api/roles', {
    sort,
    'filter[search]': search,
    limit: pageSize,
    page: currentPage,
  })
  return client.get(`${endPoint}include=permissions`)
}
export const createRole = async (payload: {
  name: string
  permissions?: string[]
}) => {
  return client.post('/api/roles', payload)
}

export const editRole = (
  id: number,
  payload: {
    name: string
    permissions?: string[]
  },
) => {
  return client.put(`/api/roles/${id}`, payload)
}
export const deleteRole = (id: number) => {
  return client.delete(`api/roles/${id}`)
}
