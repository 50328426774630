export const PAYMENT_TYPE_CHECK = 'check'
export const PAYMENT_TYPE_CC = 'credit_card'
export const PAYMENT_TYPE_CASH = 'cash'
export const PAYMENT_TYPE_BANK = 'bank_transfer'
export const PAYMENT_TYPES_LABELS: Record<string, string> = {
  [PAYMENT_TYPE_CHECK]: 'Check',
  [PAYMENT_TYPE_CC]: 'Credit Card',
  [PAYMENT_TYPE_BANK]: 'Bank Payment',
  [PAYMENT_TYPE_CASH]: 'Cash',
}
export const PAYMENT_TYPES = [
  {
    label: PAYMENT_TYPES_LABELS[PAYMENT_TYPE_CHECK],
    value: PAYMENT_TYPE_CHECK,
    icon: 'check',
  },
  {
    label: PAYMENT_TYPES_LABELS[PAYMENT_TYPE_CASH],
    value: PAYMENT_TYPE_CASH,
    icon: 'money',
  },
  {
    label: PAYMENT_TYPES_LABELS[PAYMENT_TYPE_BANK],
    value: PAYMENT_TYPE_BANK,
    icon: 'bank',
  },
]
