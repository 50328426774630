import cx from 'classnames'
import { format as formatDefault, isBefore } from 'date-fns'
import { ChangeEvent, useEffect, useState } from 'react'
import * as DProps from 'react-datepicker'

import {
  dateTimeFormat,
  formatDateNoTz,
  formatStrDateBlur,
} from '../../utils/dateTime'
//import { Calendar, Close } from 'icons/outline'
import { Popover } from '../index'
import Picker from './Picker'

export interface Props {
  showPresets?: boolean
  showTime?: boolean
  allowClear?: boolean
  size?: 'small' | 'default' | 'large'
  block?: boolean
  className?: string
  format?: string
  value?: Date | string | null
  placeholder?: string
  onChange?: (dateStr: string | null, date: Date | null) => void
  popupContainer?: () => HTMLElement
  pickerProps?: Partial<DProps.ReactDatePickerProps<any, any>>
  fieldName?: string | null
  closeOnSelect?: boolean
  disableEdit?: boolean
}

const sizes = {
  small: 'h-6 py-0 px-[0.4375rem]',
  default: 'h-10 py-1 px-3',
  large: 'h-12 px-4 py-1.5',
}

export function DatePicker({
  showPresets,
  allowClear,
  size = 'default',
  block,
  className,
  format,
  value,
  placeholder,
  onChange,
  popupContainer,
  pickerProps,
  fieldName,
  closeOnSelect = false,
  disableEdit = false,
}: Props) {
  const showTime = false // temporary disable showtime
  const defaultFormat =
    format ||
    `${dateTimeFormat['MM-dd-yyyy']}${
      showTime ? ' ' + dateTimeFormat['HH:mm'] : ''
    }`

  const [date, setDate] = useState<Date | null>(null)
  const [inputDate, setInputDate] = useState('')
  const handleDateChange = (d: Date | null) => {
    if (!d) {
      return handleClear()
    }
    setDate(d)
    onChange?.(formatDefault(d, defaultFormat), d)
  }

  const handleClear = () => {
    setDate(null)
    onChange?.(null, null)
    setInputDate('')
  }

  const handleChangeInputDate = (e: ChangeEvent<HTMLInputElement>) => {
    if (disableEdit) {
      return
    }
    setInputDate(e.target.value)
  }

  const handleBlurInputDate = () => {
    if (disableEdit) {
      return
    }
    const result = formatStrDateBlur(inputDate)
    const prevValue = (value || '').toString()
    if (prevValue !== inputDate) {
      const validDate = getValidInputDate(result)
      setInputDate(formatDefault(validDate, defaultFormat))
      handleDateChange(!!result ? new Date(validDate) : null)
    }
  }
  const getValidInputDate = (d: string) => {
    const minDate = pickerProps?.minDate || null
    const maxDate = pickerProps?.maxDate || null
    const defaultDate = new Date(d)
    if (minDate && isBefore(defaultDate, minDate)) {
      return minDate
    }
    if (maxDate && isBefore(maxDate, defaultDate)) {
      return maxDate
    }
    return defaultDate
  }
  useEffect(() => {
    if (!!value && !date) {
      setDate(new Date(formatDateNoTz(value, dateTimeFormat['MM/dd/yyyy']))) // has problem with format MM-dd-yyyy on safari
      setInputDate(formatDateNoTz(value))
    }
  }, [(value || '').toString()])

  return (
    <div
      className={cx(
        'relative border border-light-stroke rounded-lg w-full text-body',
        'border-separation-900 hover:border-primary-900 hover:shadow-input focus:shadow-input focus:border-primary-900 outline-0',
        'flex items-center cursor-text bg-white',
        sizes[size],
        block && 'block',
        className,
      )}
    >
      <input
        value={inputDate}
        placeholder={placeholder}
        className={cx(
          'appearance-none outline-0 w-full mr-1.5',
          'placeholder:text-black-400 placeholder:font-normal',
        )}
        name={fieldName || ''}
        type='text'
        onChange={handleChangeInputDate}
        onBlur={handleBlurInputDate}
        readOnly={disableEdit}
      />
      {allowClear && !!date && (
        <div className='w-3 h-full shrink-0 inline-flex justify-center items-center z-[1]'>
          <span
            className='font-icon-close text-black-600 cursor-pointer'
            onClick={handleClear}
          />
        </div>
      )}

      <Popover
        popupContainer={popupContainer}
        placement='bottom-start'
        className={cx(
          !disableEdit && 'w-8 justify-center',
          disableEdit && 'absolute left-0 right-0 opacity-0 cursor-pointer',
          'h-full shrink-0 inline-flex items-center',
        )}
        content={onClose => (
          <Picker
            {...pickerProps}
            showTime={showTime}
            showPresets={showPresets}
            selected={date}
            onChange={(d: Date | null) => {
              handleDateChange(d)
              setInputDate(d ? formatDateNoTz(d) : '')
              if (closeOnSelect) {
                onClose()
              }
            }}
            onClose={onClose}
          />
        )}
      >
        <span className='font-icon-calendar text-black-600 cursor-pointer' />
      </Popover>
    </div>
  )
}
