import { generateQueryOfEndPoint } from '../utils/functions'
import client, { IFilter, PAGE_SIZE } from './client'

export interface ITag {
  id: number
  name: string
  created_at: Date | string
  files_count?: number
}

export interface ITagPayload {
  name: string
}

export const getTags = async ({
  search = '',
  sortBy = 'id',
  sortDirection = 'desc',
  pageSize = PAGE_SIZE,
  currentPage = 1,
}: IFilter) => {
  const sort = `${sortDirection === 'desc' ? '-' : ''}${sortBy}`
  const endPoint = generateQueryOfEndPoint('/api/tags', {
    sort,
    'filter[search]': search,
    limit: pageSize,
    page: currentPage,
  })
  return client.get(`${endPoint}&include=filesCount`)
}

export const createTag = async (payload: ITagPayload) => {
  return client.post('/api/tags', payload)
}

export const editTag = async (tagId: number, payload: ITagPayload) => {
  return client.put(`/api/tags/${tagId}`, payload)
}

export const deleteTag = async (tagId: number) => {
  return client.delete(`/api/tags/${tagId}`)
}
