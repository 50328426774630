export const HightLightText = ({
  text = '',
  searchWord,
  highlightClassName = 'bg-green-600',
}: {
  text?: string | null
  searchWord: string
  highlightClassName?: string
}) => {
  if (!text || text === '') {
    return null
  }
  if (searchWord === '') {
    return <span>{text}</span>
  }
  const regex = new RegExp(`(${searchWord})`, 'gi')
  const parts = text.split(regex)
  return (
    <span>
      {parts
        .filter(part => part)
        .map((part, i) =>
          regex.test(part) ? (
            <mark
              key={i}
              className={highlightClassName}
              dangerouslySetInnerHTML={{ __html: part }}
            />
          ) : (
            <span key={i} dangerouslySetInnerHTML={{ __html: part }} />
          ),
        )}
    </span>
  )
}
