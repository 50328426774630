import { OptionValue } from '../utils/form'
import { generateQueryOfEndPoint } from '../utils/functions'
import client, { IFilter, PAGE_SIZE } from './client'

export interface ITransactionCard {
  brand: string
  country: string
  exp_month: number
  exp_year: number
  fingerprint: string
  funding: string
  installments: string | null
  last4: string
  mandate: string | null
  network: string
  three_d_secure: string | null
  wallet: string | null
  checks: {
    address_line1_check: string | null
    address_postal_code_check: string | null
    cvc_check: string
  }
}
export interface ITransaction {
  id: number
  transaction_id: string
  created_at: Date | string
  paid_date: Date | string | null
  paid_method: string | null
  handler: string
  amount: number
  receipt_url: string | null
  resource_id: number
  resource_type: string
  payment_method_details: {
    type?: string | null
    card?: ITransactionCard | null
    check_number?: string | null
  } | null
}

export interface ITransactionPayload {
  resource_type: string
  resource_id: number
  paid_method: string
  paid_date: Date | string
  payment_method_details?: {
    check_number: string
  } | null
  amount: number
}

export interface IFilterTransaction extends IFilter {
  filterResource?: OptionValue | null
}

export const getTransactions = async ({
  search = '',
  sortBy = 'id',
  sortDirection = 'desc',
  pageSize = PAGE_SIZE,
  currentPage = 1,
  filterResource = null,
}: IFilterTransaction) => {
  const sort = `${sortDirection === 'desc' ? '-' : ''}${sortBy}`
  const endPoint = generateQueryOfEndPoint('/api/transactions', {
    sort,
    'filter[search]': search,
    limit: pageSize,
    page: currentPage,
    'filter[resource]':
      filterResource?.value && filterResource?.label
        ? `${filterResource.label},${filterResource.value}`
        : null,
  })
  return client.get(endPoint)
}

export const createTransaction = async (payload: ITransactionPayload) => {
  return client.post('/api/transactions', payload)
}

export const detailTransaction = async (transactionId: number) => {
  return client.get<{ data: ITransaction }>(`api/transactions/${transactionId}`)
}
