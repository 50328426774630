import ImageLogoSystem from '../assets/images/logo_system.png'
import { Avatar } from '../components'

export const AvatarSystem = () => (
  <Avatar
    hashId='user-system'
    src={ImageLogoSystem}
    className='bg-avatar-gradient !p-[0.375rem] border-none shadow-avatar'
  />
)
