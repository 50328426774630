import { useEffect, useState } from 'react'

import { Select, SelectProps } from '../../../components'
import { IOpportunity } from '../../../services/opportunities'
import { IS_MONTHLY } from '../../../utils/constant'
import { CONTRACT_CYCLE_TYPE } from '../../../utils/contracts'

interface Props extends Omit<SelectProps, 'options'> {
  selected?: string | null
  onSelect?: (text: string | null) => void
  onUpdateComplete?: (data: IOpportunity) => void
}
export const ContractCycleSelect = ({
  selected = IS_MONTHLY,
  onSelect,
  onUpdateComplete,
  ...props
}: Props) => {
  const [currentSelected, setSelected] = useState<string | null>(selected)

  const handleSelect = (value: string | null) => {
    setSelected(value)
    onSelect?.(value)
  }

  useEffect(() => {
    if (selected !== currentSelected) {
      setSelected(selected)
    }
  }, [selected])

  return (
    <div className='relative'>
      <Select
        {...props}
        value={currentSelected}
        onSelect={onSelect ? (value: string) => handleSelect(value) : undefined}
        onDeselect={onSelect ? () => handleSelect(null) : undefined}
      >
        {CONTRACT_CYCLE_TYPE.map(s => (
          <Select.Option value={s} key={s}>
            {s}
          </Select.Option>
        ))}
      </Select>
    </div>
  )
}
