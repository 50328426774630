import { ReactNode } from 'react'
import { createPortal } from 'react-dom'

const DEFAULT_POPUP_CONTAINER = document.body

export const getPopupContainer = (selector: string | (() => HTMLElement)) => {
  let node: HTMLElement | null = null
  if (typeof selector === 'string') {
    node = document.querySelector(selector)
  } else {
    node = selector()
  }
  return node || DEFAULT_POPUP_CONTAINER
}

interface Props {
  children: ReactNode
  popupContainer?: string | (() => HTMLElement)
}

export const Portal = ({ children, popupContainer }: Props) => {
  return createPortal(
    children,
    popupContainer
      ? getPopupContainer(popupContainer)
      : DEFAULT_POPUP_CONTAINER,
  )
}
