import { filter as filterArray } from 'lodash'

import { AsyncSelect } from '../../../components/Forms/AsyncSelect'
import { Props as SelectProps } from '../../../components/Forms/Select'
import { PAGE_SIZE_DROPDOWN } from '../../../services/client'
import {
  getInvoices,
  IFilterInvoices,
  IInvoice,
} from '../../../services/invoices'
import { OptionValue } from '../../../utils/form'
import {
  mapResourceToOptionValue,
  RESOURCE_INVOICE,
} from '../../../utils/resources'

interface Props extends Omit<SelectProps, 'options' | 'onSelect'> {
  selected?: OptionValue | null
  onSelect?: (value: OptionValue | null, valueFull?: IInvoice | null) => void
  className?: string
  excludesValues?: number[]
  filterInvoice?: IFilterInvoices | null
}
export const InvoiceSelect = ({
  selected = null,
  className = '',
  onSelect,
  excludesValues = [],
  filterInvoice = null,
  ...props
}: Props) => {
  const handleGetInvoices = async ({ search = '', page = 1 } = {}) => {
    try {
      const { data } = await getInvoices({
        search,
        currentPage: page,
        pageSize: PAGE_SIZE_DROPDOWN,
        filterArchived: 'exclude',
        ...filterInvoice,        
      })
      return { data: data.data, totalPage: data.meta.last_page }
    } catch {
      return { data: [], totalPage: 1 }
    }
  }

  const handleSelect = (
    item: OptionValue | null,
    itemFull?: IInvoice | null,
  ) => {
    const value = !!itemFull
      ? mapResourceToOptionValue(itemFull, RESOURCE_INVOICE)
      : null
    onSelect?.(value, itemFull)
  }
  const mapOptions = (invoices: IInvoice[]) => {
    return filterArray(invoices, item => !excludesValues.includes(item.id)).map(
      item => mapResourceToOptionValue(item, RESOURCE_INVOICE),
    )
  }

  const triggerValue = filterInvoice?.filterAccount?.value

  return (
    <AsyncSelect
      placeholder='All Invoices'
      labelInValue
      allowClear
      dropdownClassName='w-full'
      dropdownMatchSelectWidth={350}
      {...props}
      value={
        selected?.value
          ? { value: selected.value + '', label: selected.label }
          : null
      }
      onChange={handleSelect}
      className={className}
      fetcher={handleGetInvoices}
      options={mapOptions}
      triggerFilter={triggerValue}
    />
  )
}
