import { SVGProps } from 'react'

export const WarningCircle = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width='16'
      height='15'
      viewBox='0 0 16 15'
      xmlns='http://www.w3.org/2000/svg'
      fill='currentColor'
      {...props}
    >
      <path
        d='M6 1.5L8 0.5L9.5 1L15.5 11L15 13L13.5 14H3L1 13L0.5 11L6 1.5Z'
        fill='currentColor'
      />
      <path
        d='M7.99981 10.2783C7.5525 10.2783 7.17755 10.6532 7.17755 11.1005C7.17755 11.5478 7.5525 11.9228 7.99981 11.9228C8.43067 11.9228 8.82206 11.5478 8.80233 11.1203C8.82206 10.6499 8.4504 10.2783 7.99981 10.2783Z'
        fill='white'
      />
      <path
        d='M15.6107 13.1924C16.127 12.301 16.1303 11.2387 15.6172 10.3506L10.4666 1.43074C9.95679 0.532826 9.03585 0 8.00309 0C6.97033 0 6.0494 0.536115 5.53959 1.42745L0.382367 10.3572C-0.130724 11.2551 -0.127435 12.3241 0.392235 13.2154C0.905326 14.0969 1.82297 14.6264 2.84915 14.6264H13.1373C14.1668 14.6264 15.091 14.0903 15.6107 13.1924ZM14.4924 12.5477C14.2062 13.0411 13.6997 13.3338 13.134 13.3338H2.84586C2.28673 13.3338 1.7835 13.0477 1.50393 12.5642C1.22107 12.0741 1.21779 11.4886 1.50064 10.9953L6.65787 2.06881C6.93744 1.57874 7.43737 1.28931 8.00309 1.28931C8.56552 1.28931 9.06874 1.58203 9.34831 2.0721L14.5022 10.9986C14.7785 11.4788 14.7752 12.0576 14.4924 12.5477Z'
        fill='currentColor'
      />
      <path
        d='M7.79592 4.506C7.40452 4.61783 7.16113 4.97305 7.16113 5.40391C7.18087 5.66375 7.19731 5.92687 7.21705 6.18671C7.27296 7.17671 7.32887 8.14698 7.38479 9.13698C7.40452 9.47246 7.66436 9.71585 7.99984 9.71585C8.33532 9.71585 8.59845 9.45602 8.61489 9.11725C8.61489 8.91333 8.61489 8.72585 8.63463 8.51864C8.67081 7.88385 8.71027 7.24907 8.74645 6.61428C8.76619 6.20315 8.80237 5.79202 8.8221 5.38089C8.8221 5.23288 8.80237 5.10132 8.74645 4.96976C8.57871 4.60138 8.18732 4.41391 7.79592 4.506Z'
        fill='white'
      />
    </svg>
  )
}
