import cx from 'classnames'
import { ReactNode, useState } from 'react'

import { Tooltip } from '../components'
import { useAsync } from '../hooks'
import { addFavorite, removeFavorite } from '../services/favorite'

interface Props {
  resourceId?: number
  resourceType?: string
  currentStatus: boolean
  className?: string
  activeClassName?: string
  viewOnly?: boolean
  onToggleFavorite?: (status: boolean) => void
  children?: ReactNode
}
export const ButtonFavorite = ({
  resourceId,
  currentStatus,
  onToggleFavorite,
  resourceType,
  className,
  activeClassName = '!text-orange-900',
  viewOnly = false,
  children,
}: Props) => {
  const [status, setStatus] = useState(currentStatus)
  const favoriteSync = useAsync({ showNotifOnError: true })

  const toggleFavorite = async () => {
    if (!resourceId || !resourceType) {
      return
    }
    await favoriteSync.execute(
      status
        ? removeFavorite({ id: resourceId, resource: resourceType })
        : addFavorite({ id: resourceId, resource: resourceType }),
    )
    const newStatus = !status
    setStatus(newStatus)
    onToggleFavorite?.(newStatus)
  }

  if (viewOnly) {
    return (
      <span
        className={cx(
          className,
          status
            ? `font-icon-star_filled ${activeClassName}`
            : 'font-icon-star',
        )}
      />
    )
  }
  return (
    <Tooltip
      content={
        <span className='capitalize'>
          {status ? 'Remove from farvorite' : 'Add to farvorite'}
        </span>
      }
      placement='bottom'
      className='leading-none'
    >
      {children ? (
        <div
          onClick={toggleFavorite}
          className={cx('cursor-pointer', className)}
        >
          {children}
        </div>
      ) : (
        <span
          className={cx(
            'cursor-pointer',
            className,
            status
              ? `font-icon-star_filled ${activeClassName}`
              : 'font-icon-star',
          )}
          onClick={toggleFavorite}
        />
      )}
    </Tooltip>
  )
}
