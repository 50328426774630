import cx from 'classnames'
import { AnimatePresence } from 'framer-motion'
import { KeyboardEvent, memo, useEffect, useRef, useState } from 'react'

import { Avatar } from '../../../components/Avatar'
import { AuthUser } from '../../../services/auth'
import { INote } from '../../../services/notes'
import { formatDateTime } from '../../../utils/dateTime'
import {
  removeMarkupMetions,
  renderPasswordNote,
  replaceNoteBodyByRegex,
  replaceTextPassword,
} from '../../../utils/notes'
import {
  copyLinkResource,
  RESOURCE_NOTE,
  RESOURCE_PATHNAME,
} from '../../../utils/resources'
import { AvatarSystem } from '../../AvatarSystem'
import { CustomMentionInput } from '../../Form/MentionsInput'
import { HightLightText } from '../../HightLightText'
import { ModalDeleteNote } from '../../Modals/Notes/Delete'
import { BODY_ONLY_IMG, useNotesCtx } from '../Context'
import { NoteMedias } from './Medias'

type TNoteItem = {
  note: INote
  canUpdate?: boolean
  canDelete?: boolean
  isPinned?: boolean
}

export const NoteItem = memo(
  ({ note, canUpdate, canDelete, isPinned = false }: TNoteItem) => {
    const {
      handleDeleteNote,
      handleEditNote,
      handleTogglePin,
      resultNotes,
      keyword,
      scrollId,
      detailNoteId,
      currentUser,
    } = useNotesCtx()
    const [isEdit, setEdit] = useState(false)
    const [isDelete, setDelete] = useState(false)
    const [textEdit, setTextEdit] = useState(note.body)

    const isHasKeyword = resultNotes.some(item => item.id === note.id)

    const handleSave = async () => {
      if (textEdit !== note.body) {
        await handleEditNote(note.id, textEdit)
        setEdit(false)
      }
    }
    const handleCancel = () => {
      setEdit(false)
      setTextEdit(note.body)
    }
    const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.key === 'Enter' && !e.shiftKey) {
        handleSave()
      }
      if (e.key === 'Escape') {
        handleCancel()
      }
    }

    const handleCopyUrlNote = () => {
      const pathName = `${RESOURCE_PATHNAME[note.resource_type]}/${
        note.resource_id
      }/${RESOURCE_PATHNAME[RESOURCE_NOTE]}`
      copyLinkResource(pathName, note.id)
    }
    /*
    is_system=true : is a system note that is sent automatically by BE, not through an action of any user
    is_system=false && user === null : is a system note that is sent by the action of a user (admin) such as change status , send quote....
  */
    const isSystemUserName = !note.user_id
    const isSystemDefault = note.is_system
    const isNoteOnlyImg =
      !!note?.files && note.files?.length > 0 && note.body === BODY_ONLY_IMG
    const isNotePinned = Boolean(note.meta?.[0]?.pinned)

    return (
      <>
        <div
          className='flex gap-3'
          id={`note-${note.id}`}
          key={note.resource_id}
        >
          {isSystemUserName ? (
            <AvatarSystem />
          ) : (
            <Avatar
              hashId={`user-${note.user_id}`}
              src={note.user.avatar_preview_url}
            />
          )}
          <div className='flex-1 max-w-xl pb-4'>
            {isEdit ? (
              <div>
                <CustomMentionInput
                  placeholder='Write your note...'
                  rows={4}
                  value={textEdit}
                  className='textarea border border-light-stroke rounded-lg w-full hover:border-primary focus:border-primary outline-0'
                  // @ts-ignore
                  onKeyDown={handleKeyDown}
                  onChange={value => {
                    setTextEdit(value)
                  }}
                  style={{
                    input: {
                      overflow: 'auto',
                      resize: 'none',
                      padding: 8,
                    },
                    highlighter: {
                      overflow: 'hidden',
                      maxHeight: 112,
                      height: 'auto',
                      padding: 8,
                    },
                  }}
                />
                <div className='text-xs mt-1 text-black-400'>
                  Press Enter to{' '}
                  <span
                    className='text-primary-900 font-medium cursor-pointer'
                    onClick={handleSave}
                  >
                    Save
                  </span>
                  , Esc to{' '}
                  <span
                    className='text-primary-900 font-medium cursor-pointer'
                    onClick={handleCancel}
                  >
                    Cancel
                  </span>
                </div>
              </div>
            ) : (
              <>
                {isPinned && (
                  <div className='flex gap-2 items-center pl-3 mb-2'>
                    <div className='w-6 h-5 bg-blue-900 rounded-xl flex items-center justify-center'>
                      <span className='font-icon-pin text-white' />
                    </div>
                    <span className='font-medium text-blue-900'>Pinned</span>
                  </div>
                )}
                <div className='text-black-400 pl-3 mb-2'>
                  {isSystemUserName ? 'System' : `${note.user.name}`}
                </div>
                <div
                  className={cx(
                    'bg-separation-400 rounded-2xl p-3 mb-1 border-[0.125rem] border-transparent',
                    (scrollId === note.id || detailNoteId === note.id) &&
                      '!border-primary-900',
                    isPinned && 'bg-blue-500',
                  )}
                >
                  <NoteBody
                    note={note.body}
                    className={
                      isSystemDefault || isSystemUserName
                        ? 'text-black-600 system-note'
                        : 'text-black-600 whitespace-pre-wrap'
                    }
                    keyword={keyword}
                    isHasKeyword={isHasKeyword}
                    currentUser={currentUser}
                  />
                  <NoteMedias files={note.files || []} />
                </div>
                <div className='pl-3 flex gap-2 items-center'>
                  {!isSystemDefault && (canDelete || canUpdate) && (
                    <>
                      {canUpdate && !isNoteOnlyImg && !isPinned && (
                        <>
                          <div
                            className='text-black-400 font-medium cursor-pointer'
                            onClick={() => setEdit(true)}
                          >
                            Edit
                          </div>
                          <div className='w-1 h-1 rounded-full bg-separation-800' />
                        </>
                      )}
                      {canUpdate && (
                        <>
                          <div
                            className='text-black-400 font-medium cursor-pointer'
                            onClick={() => handleTogglePin(note, !isNotePinned)}
                          >
                            {isNotePinned ? 'Unpin' : 'Pin'}
                          </div>
                          <div className='w-1 h-1 rounded-full bg-separation-800' />
                        </>
                      )}
                      {canDelete && !isPinned && (
                        <>
                          <div
                            className='text-black-400 font-medium cursor-pointer text-'
                            onClick={() => setDelete(true)}
                          >
                            Delete
                          </div>
                          <div className='w-1 h-1 rounded-full bg-separation-800' />
                        </>
                      )}
                    </>
                  )}
                  <div
                    className='text-black-400 font-medium cursor-pointer'
                    onClick={handleCopyUrlNote}
                  >
                    Copy URL
                  </div>
                  <div className='w-1 h-1 rounded-full bg-separation-800' />
                  <div className='text-black-400'>
                    {formatDateTime(note.updated_at)}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <AnimatePresence initial={false}>
          {isDelete && (
            <ModalDeleteNote
              noteId={note.id}
              onClose={() => setDelete(false)}
              onSuccess={() => handleDeleteNote(note.id)}
            />
          )}
        </AnimatePresence>
      </>
    )
  },
)

interface PropsBody {
  note: string
  className?: string
  currentUser?: AuthUser | null
  isHasKeyword?: boolean
  keyword?: string
}
export const NoteBody = ({
  isHasKeyword,
  note,
  className,
  currentUser,
  keyword,
}: PropsBody) => {
  const refBody = useRef<HTMLDivElement | null>(null)

  const togglePass = (el: Element) => {
    const isShow = el.classList.contains('show')
    el.classList.toggle('show')
    const attrValue = el.getAttribute('value') || ''
    el.innerHTML = renderPasswordNote(attrValue, !isShow)
  }
  useEffect(() => {
    if (refBody?.current) {
      const elementPassword = refBody?.current.querySelectorAll('.password')
      if (elementPassword) {
        elementPassword.forEach(item => {
          item.addEventListener('click', () => togglePass(item))
        })
      }
    }
  }, [isHasKeyword])

  return (
    <div className='note-body' ref={refBody}>
      {isHasKeyword && keyword ? (
        <div className={className}>
          <HightLightText
            text={removeMarkupMetions(replaceTextPassword(note, true))}
            searchWord={keyword}
            highlightClassName='bg-orange-900'
          />
        </div>
      ) : (
        <div
          className={className}
          dangerouslySetInnerHTML={{
            __html: replaceNoteBodyByRegex(note || '', currentUser?.id || null),
          }}
        />
      )}
    </div>
  )
}
