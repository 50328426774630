import cx from 'classnames'
import { motion } from 'framer-motion'
import { ReactNode } from 'react'

import { Overlay, Portal } from '../index'

interface Props {
  width?: number
  className?: string
  placement?: 'left' | 'right'
  mask?: boolean
  maskCloseable?: boolean
  onClose: () => void
  children: ReactNode
}

const OVERLAY_ID = 'overlay'

export const Drawer = ({
  children,
  width = 250,
  className,
  placement = 'left',
  mask,
  maskCloseable,
  onClose,
}: Props) => {
  const handleClose: React.MouseEventHandler = event => {
    if (maskCloseable === false) {
      return
    }
    if ((event.target as HTMLDivElement).id !== OVERLAY_ID) {
      return
    }
    const selection = window.getSelection()
    if (selection?.toString() !== '') {
      return
    }
    onClose()
  }

  const renderDrawer = () => (
    <motion.div
      style={{ width }}
      className={cx(
        'bg-white fixed top-0 h-full border-light-stroke z-drawer',
        placement === 'left' ? 'border-r' : 'border-l',
        className,
      )}
      transition={{ duration: 0.2, type: 'tween' }}
      initial={{ [placement]: -width, opacity: 0 }}
      animate={{ [placement]: 0, opacity: 1 }}
      exit={{ [placement]: -width, opacity: 0 }}
    >
      <>
        <span
          className={cx(
            'font-icon-close absolute text-black-600 top-5 bg-white rounded-lg w-8 h-9 text-body leading-9 align-middle cursor-pointer text-center',
            placement === 'right'
              ? 'left-[-2rem] rounded-r-none'
              : 'right-[-2rem] rounded-l-none',
          )}
          onClick={onClose}
        />
        {children}
      </>
    </motion.div>
  )

  return (
    <Portal>
      {mask !== false ? (
        <Overlay
          lockScroll
          id={OVERLAY_ID}
          className='!z-drawer'
          onClickCapture={maskCloseable !== false ? handleClose : undefined}
        >
          {renderDrawer()}
        </Overlay>
      ) : (
        renderDrawer()
      )}
    </Portal>
  )
}
