import { SVGProps } from 'react'

export const Word = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className='text-[#2B56B1]'
      {...props}
    >
      <path
        d='M0 2.28571C0 1.02335 1.02335 0 2.28571 0H13.7143C14.9767 0 16 1.02335 16 2.28571V13.7143C16 14.9767 14.9767 16 13.7143 16H2.28571C1.02335 16 0 14.9767 0 13.7143V2.28571Z'
        fill='currentColor'
      />
      <path
        d='M13.1429 4.57143L11.2662 11.4286H9.14343L7.99487 6.91575L6.8053 11.4286H4.68252L2.85714 4.57143H4.7338L5.76955 9.56288L7.05142 4.57143H8.97935L10.2099 9.56288L11.2559 4.57143H13.1429Z'
        fill='white'
      />
    </svg>
  )
}
