import orderBy from 'lodash/orderBy'
import { useEffect, useState } from 'react'
import shallow from 'zustand/shallow'

import { Select, SelectProps } from '../../../components'
import { IProject } from '../../../services/projects'
import useStore, { Store } from '../../../store'
import { OptionValue } from '../../../utils/form'

interface Props extends Omit<SelectProps, 'options'> {
  selected?: OptionValue | null
  onSelect?: (option: OptionValue | null) => void
  onUpdateComplete?: (data: IProject) => void
}

const mapState = (state: Store) => ({
  expense_categories: state.settings?.expense?.expense_categories,
})

export const ExpenseCategoriesSelect = ({
  selected = null,
  onSelect,
  onUpdateComplete,
  ...props
}: Props) => {
  const { expense_categories } = useStore(mapState, shallow)
  const [currentSelected, setSelected] = useState<OptionValue | null>(selected)

  const handleSelect = (value: OptionValue | null) => {
    setSelected(value)
    onSelect?.(value)
  }
  if (!expense_categories) {
    return null
  }

  const mapCategories = orderBy(expense_categories, ['label'], ['asc']).map(
    ({ id, label }) => ({
      value: id,
      label,
    }),
  )

  useEffect(() => {
    if (
      selected !== currentSelected ||
      selected?.label !== currentSelected?.label ||
      selected?.value !== currentSelected?.value
    ) {
      setSelected(selected)
    }
  }, [selected])
  return (
    <div className='relative'>
      <Select
        {...props}
        value={currentSelected}
        options={mapCategories}
        labelInValue
        animation={undefined}
        onSelect={
          onSelect ? (value: OptionValue) => handleSelect(value) : undefined
        }
        onDeselect={onSelect ? () => handleSelect(null) : undefined}
      />
    </div>
  )
}
