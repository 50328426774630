import { PATHNAME } from '../configs/routes'
import { initFilter } from '../services/client'
import { IExpense, IFilterExpense } from '../services/expenses'
import { compareFilterPagination } from './functions'
import {
  RESOURCE_CONTRACT,
  RESOURCE_OPPORTUNITY,
  RESOURCE_PROJECT,
  RESOURCE_SERVICE,
  RESOURCE_TYPE_LABEL,
} from './resources'
import { renderTextForServiceSelect } from './services'

export const EXPENSE_STATUS = ['Invoiced', 'Paid', 'Pending']

export const EXPENSE_RESOURCE = {
  PROJECT: {
    value: 1,
    label: RESOURCE_TYPE_LABEL[RESOURCE_PROJECT],
  },
  CONTRACT: {
    value: 2,
    label: 'Maintenance',
  },
  SERVICE: {
    value: 4,
    label: RESOURCE_TYPE_LABEL[RESOURCE_SERVICE],
  },
  OPPORTUNITY: {
    value: 5,
    label: RESOURCE_TYPE_LABEL[RESOURCE_OPPORTUNITY],
  },
}

export const initFilterExpense: IFilterExpense = {
  ...initFilter,
  search: '',
  filterAccount: null,
  filterResource: null,
  filterResourceType: null,
}
export const getExpenseResourceLink = (expense: IExpense) => {
  if (expense?.resource_type === EXPENSE_RESOURCE.PROJECT.label) {
    return `/${PATHNAME.projects}/${expense.resource_id}`
  }
  if (expense?.resource_type === EXPENSE_RESOURCE.OPPORTUNITY.label) {
    return `/${PATHNAME.opportunities}/${expense.resource_id}`
  }
  return '/'
}
export const getExpenseResourceName = (expense: IExpense) => {
  const resourceType = expense?.resource_type || ''
  if (!expense.resource) {
    return ''
  }
  switch (resourceType) {
    case RESOURCE_OPPORTUNITY:
    case RESOURCE_PROJECT: {
      return 'name' in expense?.resource ? expense?.resource?.name : ''
    }
    case RESOURCE_CONTRACT: {
      return 'contract_number' in expense.resource
        ? expense?.resource?.contract_number
        : ''
    }
    case RESOURCE_SERVICE: {
      return 'reason_for_visit' in expense?.resource
        ? renderTextForServiceSelect(expense?.resource?.reason_for_visit)
        : expense.resource.id
    }
    default: {
      return ''
    }
  }
}

export const getExpenseResourceTypeByValue = (value?: number | null) => {
  if (value === 0 || !value) {
    return null
  }
  switch (value) {
    case 1:
      return RESOURCE_PROJECT
    case 2:
      return RESOURCE_CONTRACT
    case 4:
      return RESOURCE_SERVICE
    case 5:
      return RESOURCE_OPPORTUNITY
  }
}

export const getExpenseResourceLabelByType = (resourceType: string) => {
  switch (resourceType) {
    case RESOURCE_OPPORTUNITY: {
      return RESOURCE_TYPE_LABEL[RESOURCE_OPPORTUNITY]
    }
    case RESOURCE_SERVICE: {
      return RESOURCE_TYPE_LABEL[RESOURCE_SERVICE]
    }
    case RESOURCE_PROJECT: {
      return RESOURCE_TYPE_LABEL[RESOURCE_PROJECT]
    }
    case RESOURCE_CONTRACT: {
      return RESOURCE_TYPE_LABEL[RESOURCE_CONTRACT]
    }
    default: {
      return ''
    }
  }
}

export const getExpenseOptionResourceByType = (resourceType: string) => {
  switch (resourceType) {
    case RESOURCE_OPPORTUNITY: {
      return EXPENSE_RESOURCE.OPPORTUNITY
    }
    case RESOURCE_CONTRACT: {
      return EXPENSE_RESOURCE.CONTRACT
    }
    case RESOURCE_SERVICE: {
      return EXPENSE_RESOURCE.SERVICE
    }
    case RESOURCE_PROJECT:
    default: {
      return EXPENSE_RESOURCE.PROJECT
    }
  }
}

export const isHasFilterExpense = (filter: IFilterExpense) =>
  !!filter?.filterAccount || !!filter?.filterResource || compareFilterPagination(filter, initFilterExpense)
