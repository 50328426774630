import { useState } from 'react'

import { AsyncSelect } from '../../../components/Forms/AsyncSelect'
import { Props as SelectProps } from '../../../components/Forms/Select'
import { getTaxVendors, ITaxVendor } from '../../../services/taxVendors'
import { OptionValueTax } from '../../../utils/form'
import { AccountAvatar } from '../../AccountName'

interface Props extends Omit<SelectProps, 'options'> {
  selected?: OptionValueTax[] | OptionValueTax | null
  onSelect?: (option: OptionValueTax[] | OptionValueTax | null) => void
  className?: string
  fetchOnFirst?: boolean
}
export const TaxVendorSelect = ({
  selected = null,
  className = '',
  onSelect,
  ...props
}: Props) => {
  const [listData, setListData] = useState<ITaxVendor[]>([])
  const [statePage, setStatePage] = useState(0)
  const handleGetTaxVendors = async ({ search = '', page = 1 } = {}) => {
    try {
      const { data } = await getTaxVendors({ search, currentPage: page })
      if (page !== statePage) {
        const newData = listData.concat(data.data)
        setListData(newData)
        setStatePage(page)
      }
      return { data: data.data, totalPage: data.meta.last_page }
    } catch {
      return { data: [], totalPage: 1 }
    }
  }

  const handleSelect = (item: any, itemFull: any) => {
    let value: OptionValueTax | OptionValueTax[] | null = null
    if (!!item) {
      if (props?.mode === 'multiple') {
        item.forEach((i: OptionValueTax) => {
          if (!!i?.value) {
            const iValue = i.value ? +i.value : null
            const detail = listData.find(l => l.id === iValue)
            if (detail) {
              const valueDetail = {
                value: detail.id,
                sales_tax: detail.sales_tax,
                label: detail.name,
              }
              const newValue = value as OptionValueTax[] | null
              value = newValue ? [...newValue, valueDetail] : [valueDetail]
            }
          }
        })
      } else {
        const detail = listData.find(i => i.id === +item.value)
        if (detail) {
          value = {
            value: item.value,
            sales_tax: detail.sales_tax,
            label: detail.name,
          }
        }
      }
    }
    onSelect?.(value)
  }

  const mapOptions = (data: ITaxVendor[]) => {
    return data.map(item => ({
      value: item.id,
      label: (
        <div className='inline-flex items-center gap-2'>
          <div className='text-body'>{item.name}</div>
          {item.account && (
            <div className='flex-1'>
              <AccountAvatar account={item.account} />
            </div>
          )}
        </div>
      ),
      displayLabel: item.name,
    }))
  }
  return (
    <AsyncSelect
      placeholder='All Tax Vendors'
      {...props}
      labelInValue
      allowClear
      value={props.mode !== 'multiple' ? selected : !selected ? [] : selected}
      onChange={handleSelect}
      className={className}
      fetcher={handleGetTaxVendors}
      dropdownClassName='w-full'
      options={mapOptions}
      optionLabelProp='displayLabel'
    />
  )
}
