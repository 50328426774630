import { AsyncSelect } from '../../../components/Forms/AsyncSelect'
import { Props as SelectProps } from '../../../components/Forms/Select'
import { getAssetGroups, IAssetGroup } from '../../../services/assets'
import { OptionValue } from '../../../utils/form'
import { pluralText } from '../../../utils/functions'

interface Props extends Omit<SelectProps, 'options' | 'onSelect'> {
  selected?: OptionValue | null
  onSelect?: (value: OptionValue | null, valueFull?: IAssetGroup | null) => void
  className?: string
  excludesValues?: number[]
}
export const AssetGroupSelect = ({
  selected = null,
  className = '',
  onSelect,
  excludesValues = [],
  ...props
}: Props) => {
  const handleGetAssets = async ({ search = '', page = 1 } = {}) => {
    try {
      const { data } = await getAssetGroups({ search, currentPage: page })
      return { data: data.data, totalPage: data.meta.last_page }
    } catch {
      return { data: [], totalPage: 1 }
    }
  }

  const handleSelect = (item: any, itemFull: IAssetGroup) => {
    const value = !!item
      ? {
          value: +item.value,
          label: itemFull.name,
        }
      : null
    onSelect?.(value, itemFull)
  }
  return (
    <AsyncSelect
      placeholder='All Asset Group'
      labelInValue
      allowClear
      dropdownClassName='!w-[25rem]'
      placement='bottomRight'
      {...props}
      value={
        selected?.value
          ? { value: selected.value + '', label: selected.label }
          : null
      }
      onChange={handleSelect}
      className={className}
      fetcher={handleGetAssets}
      animation={undefined}
    >
      {(data: IAssetGroup[]) => {
        return data.map(item =>
          !excludesValues.includes(item.id) ? (
            <AsyncSelect.Option key={item.id}>
              <div className='text-body'>
                <div className='text-black-800 mb-1'>{item.name}</div>
                <div className='text-black-400'>
                  {item.assets_count || 0}{' '}
                  {pluralText('item', item.assets_count || 0)}
                </div>
              </div>
            </AsyncSelect.Option>
          ) : null,
        )
      }}
    </AsyncSelect>
  )
}
