import { Tooltip } from '../../../components'
import { IService } from '../../../services/services'
import { isBlockedService, isExpiredService } from '../../../utils/services'

interface Props {
  service: IService
}
export const ServiceIconStatus = ({ service }: Props) => {
  const isExpired = isExpiredService(service)
  const isBlocked = isBlockedService(service)
  if (isBlocked) {
    return (
      <Tooltip
        content={<span>This service blocked</span>}
        placement='auto'
        popupClassName='z-[9999]'
      >
        <span className='rounded min-w-[1.375rem] min-h-[1.375rem] inline-flex items-center justify-center cursor-pointer bg-separation-800'>
          <span className='font-icon-lock text-black-800 leading-none text-[0.875rem]' />
        </span>
      </Tooltip>
    )
  }
  if (isExpired) {
    return (
      <Tooltip
        content={<span>This service passed its valid date</span>}
        placement='auto'
        popupClassName='z-[9999]'
      >
        <span className='rounded min-w-[1.375rem] min-h-[1.375rem] inline-flex items-center justify-center cursor-pointer bg-red-900'>
          <span className='font-icon-notification text-white leading-none text-[0.875rem]' />
        </span>
      </Tooltip>
    )
  }
  return null
}
