import { differenceInCalendarDays } from 'date-fns'

import { initFilter } from '../services/client'
import { IFilterServices, IService } from './../services/services'
import { compareFilterPagination, parserHtmlToText, splitParagraph } from './functions'
import { TLeftSideMenu } from './menus'

export const SERVICE_IS_BLOCKED = 'Blocked'
export const SERVICE_IS_COMPLETED = 'Completed'
export const SERVICE_IS_CREATED = 'Created'
export const SERVICE_IS_INVOICED = 'Invoiced'
export const SERVICE_IS_SCHEDULED = 'Scheduled'

export const SERVICE_STATUS = [
  SERVICE_IS_BLOCKED,
  SERVICE_IS_COMPLETED,
  SERVICE_IS_CREATED,
  SERVICE_IS_INVOICED,
  SERVICE_IS_SCHEDULED,
]

export const initFilterServices: IFilterServices = {
  ...initFilter,
  sortBy: 'id',
  search: '',
  filterAccount: null,
  filterAssignee: null,
  filterSpecialStatus: null,
}

export const isExpiredService = (service: IService) => {
  const validDate = service?.datetime_ended
  if (!validDate) {
    return true
  }
  const diffValidDate = differenceInCalendarDays(
    new Date(validDate),
    new Date(),
  )
  return diffValidDate < 0
}

export const isBlockedService = (service: IService) =>
  service?.status === SERVICE_IS_BLOCKED

export const isHasFilterService = (filter: IFilterServices) =>
  !!filter?.filterAccount ||
  !!filter?.filterAssignee ||
  compareFilterPagination(filter, {
    ...initFilter,
    sortBy: 'id'
  })

export const serviceSideMenu: TLeftSideMenu[] = [
  { icon: 'info', name: 'Genral', id: 'general' },
  { icon: 'user', name: 'Contact', id: 'contacts' },
  { icon: 'calendar', name: 'Important Dates', id: 'dates' },
  { icon: 'link', name: 'Relating Resources', id: 'relating' },
  { icon: 'attachment', name: 'File / Gallery', id: 'media' },
  { icon: 'location', name: 'Site Address', id: 'address' },
]

export const renderTextForServiceSelect = (
  text?: string | null,
  total = 10,
) => {
  return splitParagraph(parserHtmlToText(text || '') || '', total)
}
