import cx from 'classnames'
import { AnimatePresence } from 'framer-motion'
import { debounce } from 'lodash'
import { useCallback, useEffect, useRef, useState } from 'react'

import ImgNotFound from '../../assets/images/NotFoundBox.png'
import { Spinner } from '../../components/Spinner'
import { usePermission } from '../../hooks/usePermission'
import { IAccount } from '../../services/accounts'
import { pluralText } from '../../utils/functions'
import { PERMISSIONS } from '../../utils/permission'
import toast from '../../utils/toast'
import InfiniteScrollLoader from '../infiniteScrollLoader'
import { ModalDeleteUncategorized } from '../Modals/Uncategorized/Delete'
import { ModalMoveUncategorized } from '../Modals/Uncategorized/Move'
import UncategorizedContextProvider, { useUncategorizedCtx } from './Context'
import { UncategorizedFooter } from './Footer'
import { NoteHeader } from './Header'
import { MessageItem } from './Message'

type Props = {
  account?: IAccount | null
  isSubPage?: boolean
}

export const Uncategorized = ({ account = null, isSubPage = true }: Props) => {
  return (
    <UncategorizedContextProvider account={account} isSubPage={isSubPage}>
      <NoteWrapper />
    </UncategorizedContextProvider>
  )
}

const NoteWrapper = () => {
  const refScrollBar = useRef<HTMLDivElement | null>(null)
  const refContent = useRef<HTMLDivElement | null>(null)
  const [loadingMore, setLoadingMore] = useState(false)

  const {
    listUncategorized,
    handleInitUncategorized,
    fetchNextPageData,
    handleDeleteUncategorized,
    currentPage,
    lastPage,
    lastPageIsFetched,
    scrollId,
    fetching,
    selectedIds,
    moveIds,
    deleteIds,
    account,
    dispatch,
    isSubPage,
    filterAccount,
  } = useUncategorizedCtx()
  const { canUpdate, canDelete } = usePermission({
    name: PERMISSIONS.WHATSAPP_MESSAGE,
  })

  const handleLazyLoad = useCallback(async () => {
    if (
      refContent.current &&
      currentPage <= lastPage &&
      !lastPageIsFetched &&
      !loadingMore
    ) {
      const { bottom } = refContent.current.getBoundingClientRect()
      if (bottom <= 2000) {
        setLoadingMore(true)
        await fetchNextPageData()
        setLoadingMore(false)
      }
    }
  }, [currentPage, lastPage, lastPageIsFetched, loadingMore])

  useEffect(() => {
    const handleLazyLoadDebounced = debounce(handleLazyLoad, 100)

    window.addEventListener('scroll', handleLazyLoadDebounced)
    return () => {
      window.removeEventListener('scroll', handleLazyLoadDebounced)
    }
  }, [handleLazyLoad])

  const renderNotFound = () => {
    if (fetching) {
      return <Spinner />
    }
    return (
      <div className='flex-1 w-full flex justify-center items-center px-4 py-8 bg-white'>
        <div className='max-w-[20rem]'>
          <img src={ImgNotFound} className='mb-4' alt='empty data' />
          <p className='mb-0 text-center font-medium'>
            Hooray! You have no uncategorized notes
          </p>
        </div>
      </div>
    )
  }

  const handleScrollOnEnd = () => {
    setTimeout(() => {
      if (refScrollBar?.current) {
        refScrollBar.current.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
        })
      }
    }, 100)
  }

  const handleScroll = (id: number) => {
    const elem = document.getElementById(`note-${id}`)
    if (elem) {
      elem.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    }
  }

  const handleSuccessDelete = (ids: number[]) => {
    handleDeleteUncategorized(ids)
    toast.success(
      {
        title: `${pluralText('Item', ids.length)} deleted`,
      },
      {
        position: 'bottom-center',
      },
    )
  }

  const handleSuccessMove = (ids: number[]) => {
    handleDeleteUncategorized(ids)
    toast.success(
      {
        title: `${pluralText('Item', ids.length)} moved`,
      },
      {
        position: 'bottom-center',
      },
    )
  }
  const initData = async () => {
    await handleInitUncategorized()
    isSubPage && handleScrollOnEnd()
  }

  useEffect(() => {
    if (scrollId) {
      handleScroll(scrollId)
    }
  }, [scrollId])

  useEffect(() => {
    initData()
  }, [])

  const listFiltered = filterAccount?.value
    ? listUncategorized.filter(item => item.account_id === filterAccount.value)
    : listUncategorized

  const isEmptyList = listFiltered.length === 0
  return (
    <>
      <div className='flex flex-col justify-between h-full flex-1 relative'>
        {(listFiltered.length > 0 || !isSubPage) && <NoteHeader />}
        <div
          className={cx(
            'flex-1 pt-4',
            selectedIds.length > 0 && 'pb-16',
            isSubPage && 'custom-scrollbar h-full overflow-y-auto',
          )}
          ref={refContent}
        >
          <div className={cx(isEmptyList && 'flex flex-col h-full')}>
            {isEmptyList ? (
              renderNotFound()
            ) : (
              <div
                className={cx(
                  'max-w-2xl mx-auto',
                  !isSubPage && 'flex flex-col gap-2',
                )}
              >
                {listFiltered &&
                  listFiltered?.map(item => (
                    <MessageItem
                      item={item}
                      canDelete={canDelete}
                      canUpdate={canUpdate}
                      key={item.id}
                    />
                  ))}
                {loadingMore && <InfiniteScrollLoader />}
              </div>
            )}
          </div>
          <div ref={refScrollBar} />
        </div>
        <UncategorizedFooter />
      </div>
      <AnimatePresence initial={false}>
        {moveIds.length > 0 && (
          <ModalMoveUncategorized
            onClose={() => dispatch({ moveIds: [] })}
            moveIds={moveIds}
            account={account}
            onSuccess={() => handleSuccessMove(moveIds)}
          />
        )}
      </AnimatePresence>
      <AnimatePresence initial={false}>
        {deleteIds.length > 0 && (
          <ModalDeleteUncategorized
            deleteIds={deleteIds}
            onClose={() => dispatch({ deleteIds: [] })}
            onSuccess={() => handleSuccessDelete(deleteIds)}
          />
        )}
      </AnimatePresence>
    </>
  )
}
