import './bootstrap'

import { ErrorBoundary, Provider as RollbarProvider } from '@rollbar/react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { createRoot } from 'react-dom/client'
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import App from './App'
import { ErrorFallback } from './components/ErrorFallback'
import history from './configs/history'

const rollbarConfig = {
  accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
  environment: process.env.REACT_APP_ROLLBAR_ENVIRONMENT,
  enabled: process.env.NODE_ENV === 'production',
}

const domRoot = document.getElementById('root')
if (domRoot) {
  createRoot(domRoot).render(
    <RollbarProvider config={rollbarConfig}>
      <ErrorBoundary fallbackUI={ErrorFallback}>
        <HistoryRouter history={history}>
          <DndProvider
            backend={HTML5Backend}
            options={{
              enableMouseEvents: true,
            }}
          >
            <App />
          </DndProvider>
          <ToastContainer
            newestOnTop
            hideProgressBar
            pauseOnFocusLoss={false}
            icon={false}
            closeButton={false}
          />
        </HistoryRouter>
      </ErrorBoundary>
    </RollbarProvider>,
  )
}
