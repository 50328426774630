import cx from 'classnames'
import { motion } from 'framer-motion'
import { FC, ReactNode, useEffect, useState } from 'react'

export interface Props {
  children?: ReactNode
  defaultActive?: boolean
  active?: boolean
  onChange?: (active: boolean) => void
  className?: string
  panel: ReactNode
  wraperClassName?: string
  panelClassName?: string
  contentClassName?: string
  iconWrapperClassName?: string
  iconClassName?: string
  prefix?: ReactNode
  suffix?: ReactNode
  onlyClickIcon?: boolean
  renderPanel?: (content: ReactNode) => ReactNode
}

export const Collapse: FC<Props> = ({
  defaultActive = false,
  active: propsActive,
  onChange,
  className,
  panel,
  panelClassName,
  contentClassName,
  wraperClassName,
  iconWrapperClassName,
  iconClassName,
  prefix,
  suffix,
  children,
  renderPanel,
  onlyClickIcon = false,
}) => {
  const [active, setActive] = useState<boolean>(
    () => propsActive || defaultActive,
  )

  const toggleCollapse = () => {
    setActive(prev => {
      onChange?.(!prev)
      return !prev
    })
  }

  const contentPanel = () => (
    <>
      <motion.div
        className={cx(
          'flex justify-center items-center',
          iconWrapperClassName || '',
          onlyClickIcon && 'cursor-pointer',
        )}
        transition={{ type: 'tween', duration: 0.2 }}
        animate={{ rotate: active ? 90 : 0 }}
        onClick={() => onlyClickIcon && toggleCollapse()}
      >
        <span
          className={cx(
            'font-icon-dropdown',
            iconClassName || '',
            active ? 'text-black-400' : 'text-black-800',
          )}
        />
      </motion.div>
      {panel}
    </>
  )

  useEffect(() => {
    if (Boolean(propsActive) !== active && !defaultActive) {
      setActive(Boolean(propsActive))
    }
  }, [propsActive])

  return (
    <div className={cx('w-full', className)}>
      <div className={cx('relative', wraperClassName)}>
        {prefix}
        {renderPanel ? (
          renderPanel(contentPanel())
        ) : (
          <div
            className={cx(
              'flex items-center py-3 px-4 cursor-pointer select-none text-sm',
              panelClassName,
            )}
            onClick={() => !onlyClickIcon && toggleCollapse()}
          >
            {contentPanel()}
          </div>
        )}
        {suffix}
      </div>
      <motion.div
        initial={false}
        animate={{ height: active ? 'auto' : 0 }}
        transition={{ type: 'tween', duration: 0.2 }}
        className={cx('w-full text-sm overflow-hidden')}
      >
        <div className={cx('p-4 pt-1 ', contentClassName)}>{children}</div>
      </motion.div>
    </div>
  )
}
