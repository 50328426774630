import { AxiosResponse } from 'axios'
import { Suspense, useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import shallow from 'zustand/shallow'

import { Spinner } from '../../components/Spinner'
import { useAsync } from '../../hooks'
import { AuthUser } from '../../services/auth'
import { instance } from '../../services/client'
import { getCompanySettings, getSettings } from '../../services/settings'
import { FooterPublicPage } from '../../shared/Footer/FooterPublicPage'
import { HeaderPublicPage } from '../../shared/Header/HeaderPublicPage'
import useStore, { Store } from '../../store'

const mapState = (state: Store) => ({
  auth: state.auth.currentUser,
  accounting: state.settings.accounting,
  setAuthUser: state.auth.setAuthUser,
  setSettings: state.settings.setSettings,
})

function PublicPageLayout() {
  const { setAuthUser, setSettings, auth } = useStore(mapState, shallow)
  const [isLoading, setLoading] = useState(true)
  const instanceAsync = useAsync<{ data: AxiosResponse<AuthUser> }>({
    status: 'pending',
  })

  const getMoreData = async () => {
    setLoading(true)
    const [settingsResponse, companyResponse] = await Promise.allSettled([
      getSettings(),
      getCompanySettings(),
    ])

    const settings =
      settingsResponse.status === 'fulfilled'
        ? settingsResponse.value.data.data
        : {}
    const company =
      companyResponse.status === 'fulfilled'
        ? companyResponse.value.data.data
        : {}

    setSettings({
      ...settings,
      quote: settings.quote,
      company,
    })
    setLoading(false)
  }

  const init = async () => {
    if (!auth) {
      instanceAsync
        .execute(instance.get('/api/user'))
        .then(async data => {
          setAuthUser(data.data.data)
          await getMoreData()
        })
        .finally(() => {
          instanceAsync.setState({ status: 'idle' })
          setLoading(false)
        })
    } else {
      instanceAsync.setState({ status: 'idle' })
      setLoading(false)
    }
  }

  useEffect(() => {
    init()
  }, [])

  if (instanceAsync.isLoading || isLoading) {
    return <Spinner className='w-screen h-screen overflow-hidden' />
  }

  return (
    <div className='bg-white min-h-screen'>
      <div className='pb-4 pt-[3.25rem] flex flex-col justify-between min-h-screen'>
        <HeaderPublicPage />
        <div className='flex-1 relative flex flex-col'>
          <Suspense fallback={<div />}>
            <Outlet />
          </Suspense>
        </div>
        <FooterPublicPage />
      </div>
    </div>
  )
}

export default PublicPageLayout
