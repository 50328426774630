import { Link } from 'react-router-dom'
import shallow from 'zustand/shallow'

import {
  bussinessNavigation,
  INavigation,
  managementNavigation,
  otherNavigation,
  salePipeLineNavigation,
} from '../../configs/navigations'
import { PATHNAME } from '../../configs/routes'
import useStore, { Store } from '../../store'
import { checkMenuPermisison, PERMISSIONS } from '../../utils/permission'

interface Props {
  onClose?: () => void
}

const mapStore = (store: Store) => ({
  permissions: store.auth.currentUser?.can,
  calendars: store.calendars.currentCalendar,
})
export const ContentMainMenu = ({ onClose }: Props) => {
  const { permissions, calendars } = useStore(mapStore, shallow)
  const renderMenuItem = (item: INavigation) => {
    return (
      <Link
        to={item.navLink}
        className='flex gap-1 items-center rounded-lg bg-white px-3 py-[0.875rem] text-black-400'
        key={`${item.key}-${item.label}`}
        onClick={() => onClose?.()}
      >
        <span className='w-5 flex items-center text-[1rem]'>{item.Icon}</span>
        <span className='text-black-800 leading-none'>{item.label}</span>
      </Link>
    )
  }
  const salePipelineFilter = salePipeLineNavigation.filter(item =>
    checkMenuPermisison(PERMISSIONS[item.key], permissions),
  )
  const businessFilter = bussinessNavigation.filter(item =>
    checkMenuPermisison(PERMISSIONS[item.key], permissions),
  )
  const managementFilter = managementNavigation.filter(item =>
    checkMenuPermisison(PERMISSIONS[item.key], permissions),
  )
  const otherFilter = otherNavigation.filter(item =>
    checkMenuPermisison(PERMISSIONS[item.key], permissions),
  )
  const menuCalendars: INavigation[] = calendars
    .filter(c => !!c.iframe)
    .map(item => ({
      key: 'CALENDAR',
      label: item.name,
      navLink: `/${PATHNAME.calendars}/${item.id}`,
      Icon: <span className='font-icon-calendar' />,
    }))
  const otherGroupMenu = [...otherFilter, ...menuCalendars]
  return (
    <div className='h-[calc(100vh_-_7rem)] sm:h-auto pb-12 md:pb-0'>
      <div className='my-4 px-5 text-xl sm:text-base font-medium'>Menu</div>
      <span
        className='font-icon-close sm:hidden block absolute top-4 right-4 text-black-800'
        onClick={onClose}
      />
      <div className='grid grid-cols-1 sm:grid-cols-2 gap-6 sm:gap-x-6 text-body px-5 pb-5 custom-scrollbar h-full overflow-y-auto'>
        <div>
          {salePipelineFilter.length > 0 && (
            <div className='mb-6'>
              <div className='font-semibold uppercase text-black-400 mb-2'>
                SALE PIPELINE & DASHBOARD
              </div>
              <div className='flex flex-col gap-2 bg-separation-200 p-3 rounded-lg'>
                {salePipelineFilter.map(item => renderMenuItem(item))}
              </div>
            </div>
          )}
          {businessFilter.length > 0 && (
            <div>
              <div className='font-semibold uppercase text-black-400 mb-2'>
                BUSINESS & ACCOUNTING
              </div>
              <div className='flex flex-col gap-2 bg-separation-200 p-3 rounded-lg'>
                {businessFilter.map(item => renderMenuItem(item))}
              </div>
            </div>
          )}
        </div>
        <div>
          {managementFilter.length > 0 && (
            <div className='mb-6'>
              <div className='font-semibold uppercase text-black-400 mb-2'>
                WORK MANAGEMENT
              </div>
              <div className='flex flex-col gap-2 bg-separation-200 p-3 rounded-lg'>
                {managementFilter.map(item => renderMenuItem(item))}
              </div>
            </div>
          )}
          {otherGroupMenu.length > 0 && (
            <div>
              <div className='font-semibold uppercase text-black-400 mb-2'>
                OTHERS
              </div>
              <div className='flex flex-col gap-2 bg-separation-200 p-3 rounded-lg'>
                {otherGroupMenu.map(item => renderMenuItem(item))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
