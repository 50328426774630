import cx from 'classnames'

import ImgThumbnail from '../../assets/images/img_thumbnail.svg'
import { Avatar } from '../../components'

export interface Props {
  thumbnail?: string | null
  className?: string
  type?: 'avatar' | 'thumbnail'
  hashId?: string | null
}
export const ResourceThumbnail = ({
  thumbnail,
  className = '',
  type = 'thumbnail',
  hashId,
}: Props) => {
  if (type === 'avatar' && hashId) {
    return <Avatar src={thumbnail} className={className} hashId={hashId} />
  }
  return thumbnail ? (
    <Avatar src={thumbnail} type={type} className={className} />
  ) : (
    <div className={cx('w-8 h-8', className)}>
      <img
        src={ImgThumbnail}
        className='w-full h-full object-cover'
        alt='thumbnail'
      />
    </div>
  )
}
