import { filter } from 'lodash'

import { AsyncSelect } from '../../../components/Forms/AsyncSelect'
import { Props as SelectProps } from '../../../components/Forms/Select'
import { PAGE_SIZE_DROPDOWN } from '../../../services/client'
import {
  getContracts,
  IContract,
  IFilterContracts,
} from '../../../services/contracts'
import { OptionValue } from '../../../utils/form'

interface Props extends Omit<SelectProps, 'options' | 'onSelect'> {
  selected?: OptionValue | null
  onSelect?: (option: OptionValue | null, valueFull?: IContract | null) => void
  className?: string
  excludesValues?: number[]
  filterContract?: IFilterContracts | null
  fetchOnFirst?: boolean
}
export const ContractSelect = ({
  selected = null,
  className = '',
  onSelect,
  excludesValues = [],
  filterContract = null,
  fetchOnFirst = true,
  ...props
}: Props) => {
  const handleGetContracts = async ({ search = '', page = 1 } = {}) => {
    try {
      const { data } = await getContracts({
        search,
        currentPage: page,
        pageSize: PAGE_SIZE_DROPDOWN,
        ...filterContract,
      })
      return { data: data.data, totalPage: data.meta.last_page }
    } catch {
      return { data: [], totalPage: 1 }
    }
  }

  const mapOptions = (contracts: IContract[]) => {
    return filter(contracts, item => !excludesValues.includes(item.id)).map(
      ({ id, contract_number }) => ({
        value: id,
        label: contract_number,
      }),
    )
  }
  const handleSelect = (item: any, itemFull: IContract) => {
    const value = !!item
      ? {
          value: +item.value,
          label: item.label,
        }
      : null
    onSelect?.(value, itemFull)
  }

  const triggerValue = filterContract?.filterAccount?.value

  return (
    <AsyncSelect
      placeholder='All Maintenance Contract'
      labelInValue
      allowClear
      {...props}
      value={selected}
      onChange={handleSelect}
      className={className}
      fetcher={handleGetContracts}
      options={mapOptions}
      dropdownClassName='w-full'
      triggerFilter={triggerValue}
      fetchOnFirst={fetchOnFirst}
    />
  )
}
