import { SVGProps } from 'react'

export const Image = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className='text-[#FF5C00]'
      {...props}
    >
      <path
        d='M0 2.28571C0 1.02335 1.02335 0 2.28571 0H13.7143C14.9767 0 16 1.02335 16 2.28571V13.7143C16 14.9767 14.9767 16 13.7143 16H2.28571C1.02335 16 0 14.9767 0 13.7143V2.28571Z'
        fill='currentColor'
      />
      <path
        d='M13.4879 13.7143C13.9955 13.7143 14.2511 13.1018 13.8941 12.741L10.1947 9.00199C9.96355 8.76836 9.58344 8.7774 9.36366 9.02175L6.57263 12.1247L4.85401 10.2108C4.61973 9.9499 4.20791 9.95959 3.98617 10.2312L1.90432 12.7815C1.59965 13.1547 1.8652 13.7143 2.34698 13.7143H13.4879Z'
        fill='white'
      />
    </svg>
  )
}
