import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { SetupIntent } from '@stripe/stripe-js'
import { useState } from 'react'

import { Button } from '../../components'
import toast from '../../utils/toast'

export const AddCardForm = ({
  onSuccess,
}: {
  onSuccess?: (paymentMethod: SetupIntent) => void
}) => {
  const [loading, setLoading] = useState(false)
  const elements = useElements()
  const stripeApi = useStripe()

  const handleSubmit = async () => {
    if (!stripeApi || !elements) {
      return
    }
    setLoading(true)
    try {
      const { setupIntent, error } = await stripeApi.confirmSetup({
        elements,
        redirect: 'if_required',
      })
      if (error) {
        toast.error({
          title: 'Payment method creation failed',
          description: error.message,
        })
      } else {
        onSuccess?.(setupIntent)
      }
    } catch (err: any) {
      const { message, errors } = err
      toast.error({ title: message, description: errors })
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <PaymentElement className='mb-4' />
      <Button className='w-full h-12' onClick={handleSubmit} disabled={loading}>
        Add Card
      </Button>
    </>
  )
}
