import { IAccount } from '../../services/accounts'
import { IAsset } from '../../services/assets'
import { IContact } from '../../services/contacts'
import { IContract, IPreventative } from '../../services/contracts'
import { IExpense } from '../../services/expenses'
import { IInvoice } from '../../services/invoices'
import { IMonitoring } from '../../services/monitoring'
import { INote } from '../../services/notes'
import { IOpportunity } from '../../services/opportunities'
import { IPlanSet } from '../../services/planSets'
import { IProduct } from '../../services/products'
import { IProject } from '../../services/projects'
import { IQuote } from '../../services/quotes'
import { TResource } from '../../services/resource'
import { IService } from '../../services/services'
import { ICompanySettings } from '../../services/settings'
import { IUser } from '../../services/users'
import {
  RESOURCE_ACCOUNT,
  RESOURCE_ASSET,
  RESOURCE_COMPANY_SETTING,
  RESOURCE_CONTACT,
  RESOURCE_CONTRACT,
  RESOURCE_EXPENSE,
  RESOURCE_INVOICE,
  RESOURCE_MONITORING,
  RESOURCE_NOTE,
  RESOURCE_OPPORTUNITY,
  RESOURCE_PLANSET,
  RESOURCE_PREVENTATIVE,
  RESOURCE_PRODUCT,
  RESOURCE_PROJECT,
  RESOURCE_QUOTE,
  RESOURCE_SERVICE,
  RESOURCE_USER,
} from '../../utils/resources'
import { renderTextForServiceSelect } from '../../utils/services'
import { AccountName } from '../AccountName'
import { ResourceNameThumbnail } from './ResourceNameThumbnail'

interface Props {
  resource: TResource
  resourceType: string
  hasThumbnail?: boolean
}
export const ResourceName = ({
  resource,
  resourceType,
  hasThumbnail = true,
}: Props) => {
  if (resourceType === RESOURCE_ACCOUNT) {
    const account = resource as IAccount
    return <AccountName account={account} showAvatar />
  }
  if (resourceType === RESOURCE_PROJECT) {
    const project = resource as IProject
    return <div>{project.name}</div>
  }
  if (resourceType === RESOURCE_ASSET) {
    const asset = resource as IAsset
    if (!hasThumbnail) {
      return <div>{asset.name}</div>
    }
    return (
      <ResourceNameThumbnail
        name={asset.name}
        thumbnail={asset.avatar_preview_url}
      />
    )
  }
  if (resourceType === RESOURCE_OPPORTUNITY) {
    const opportunity = resource as IOpportunity
    return <div>{opportunity.name}</div>
  }
  if (resourceType === RESOURCE_QUOTE) {
    const quote = resource as IQuote
    return <div>{quote.regarding || quote.quote_number}</div>
  }
  if (resourceType === RESOURCE_EXPENSE) {
    const expense = resource as IExpense
    return <div>{expense.name || expense.id}</div>
  }
  if (resourceType === RESOURCE_MONITORING) {
    const monitoring = resource as IMonitoring
    return <div>{monitoring.name}</div>
  }
  if (resourceType === RESOURCE_SERVICE) {
    const service = resource as IService
    return (
      <div>
        {service.reason_for_visit
          ? renderTextForServiceSelect(service.reason_for_visit)
          : service.id}
      </div>
    )
  }
  if (resourceType === RESOURCE_PRODUCT) {
    const product = resource as IProduct
    if (!hasThumbnail) {
      return <div>{product.name}</div>
    }
    return (
      <ResourceNameThumbnail
        name={product.name}
        thumbnail={product.avatar_preview_url}
      />
    )
  }
  if (resourceType === RESOURCE_INVOICE) {
    const invoice = resource as IInvoice
    return <div>{invoice?.invoice_number || invoice.id}</div>
  }
  if (resourceType === RESOURCE_CONTRACT) {
    const contract = resource as IContract
    return <div>{contract.contract_number}</div>
  }
  if (resourceType === RESOURCE_PREVENTATIVE) {
    const preventative = resource as IPreventative
    return <div>{preventative.id}</div>
  }
  if (resourceType === RESOURCE_PLANSET) {
    const planSet = resource as IPlanSet
    return <div>{planSet.name}</div>
  }
  if (resourceType === RESOURCE_USER) {
    const user = resource as IUser
    if (!hasThumbnail) {
      return <div>{user.name}</div>
    }
    return (
      <ResourceNameThumbnail
        name={user.name}
        thumbnail={user.avatar_preview_url}
        type='avatar'
        hashId={`user-${user.id}`}
      />
    )
  }
  if (resourceType === RESOURCE_CONTACT) {
    const contact = resource as IContact
    if (!hasThumbnail) {
      return <div>{contact.name || contact.first_name}</div>
    }
    return (
      <ResourceNameThumbnail
        name={contact.name || contact.first_name}
        thumbnail={contact.avatar_preview_url}
        type='avatar'
        hashId={`contact-${contact.id}`}
      />
    )
  }
  if (resourceType === RESOURCE_COMPANY_SETTING) {
    const company = resource as ICompanySettings
    return <div>{company.name}</div>
  }
  if (resourceType === RESOURCE_NOTE) {
    const note = resource as INote
    return <div>{note.id}</div>
  }
  return null
}
