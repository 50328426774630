import cx from 'classnames'
import { AnimatePresence } from 'framer-motion'
import { useEffect, useRef, useState } from 'react'
import { DropzoneProps, useDropzone } from 'react-dropzone'

import ImgDropFiles from '../../assets/images/img_drop_files.svg'
import { IFilePayload } from '../../services/files'
import { byteToSize, MAX_FILE_SIZE } from '../../utils/files'
import { ModalUploadFile } from '../Modals/Files/Upload'

interface Props {
  resourceId?: number | null
  resourceType?: string | null
  onUploadComplete?: () => void
  onDropEnd?: (files: File[]) => void
  onlyView?: boolean
  optionDropzone?: DropzoneProps | null
  payloadUploadFile?: IFilePayload | null
}

export const DropzoneFull = ({
  resourceId,
  resourceType,
  onUploadComplete,
  onDropEnd,
  onlyView = false,
  optionDropzone = {},
  payloadUploadFile = {},
}: Props) => {
  const [listFiles, setListFiles] = useState<File[]>([])
  const [isDragging, setDragging] = useState(false) // cant use isDragActive , cause init component , useDropzone is hide
  const [startingUpload, setStartingUpload] = useState(false)
  const sectionRef = useRef<HTMLElement | null>(null)
  const onDragEnter = (e: DragEvent) => {
    let dragOnSite = false
    if (e.dataTransfer?.items) {
      dragOnSite = Array.from(e.dataTransfer.items).some(
        item => item.kind === 'string',
      )
    }
    const hasOpenManualUpload = document.getElementById('modal-upload-file')
    const hasModalUpload = Boolean(hasOpenManualUpload) || startingUpload
    if (!isDragging && !dragOnSite && !hasModalUpload) {
      setDragging(true)
    }
  }
  const onDragLeave = () => {
    if (isDragging) {
      setDragging(false)
    }
  }

  const onDrop = (acceptedFiles: File[]) => {
    const newFiles = listFiles
      ? [...listFiles, ...acceptedFiles]
      : acceptedFiles
    !onlyView && setStartingUpload(true)
    onDropEnd?.(newFiles)
    setListFiles(newFiles)
    setDragging(false)
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    useFsAccessApi: false,
    ...optionDropzone,
  })

  useEffect(() => {
    window.addEventListener('dragenter', onDragEnter)
    sectionRef?.current?.addEventListener('dragleave', onDragLeave)
    return () => {
      window.removeEventListener('dragenter', onDragEnter)
      sectionRef?.current?.removeEventListener('dragleave', onDragLeave)
    }
  }, [sectionRef, isDragging, startingUpload])

  const payload = resourceId && resourceType ? {
    resource_id: resourceId,
    resource_type: resourceType,
    ...payloadUploadFile
  } : payloadUploadFile
  return (
    <>
      <section
        className={cx(
          'fixed inset-0 -z-10',
          isDragging &&
            '!z-[9999] bg-popup-overlay border-4 border-primary-700 flex justify-center items-center',
        )}
        ref={sectionRef}
      >
        {isDragging && (
          <div className='flex items-center justify-center flex-col'>
            <img src={ImgDropFiles} alt='icon dnd file' />
            <div className='text-xl text-white-900 font-medium mb-2 mt-8'>
              Drag & drop your file here to upload
            </div>
            <div className='text-white-600 text-base'>
              We’re supporting maximum {byteToSize(MAX_FILE_SIZE)} for each one
            </div>
          </div>
        )}
        <div
          {...getRootProps()}
          className='text-center py-6 px-4 w-full absolute inset-0'
        >
          <input {...getInputProps()} />
        </div>
      </section>
      <AnimatePresence initial={false}>
        {startingUpload && listFiles.length > 0 && (
          <ModalUploadFile
            onClose={() => {
              setStartingUpload(false)
              setListFiles([])
            }}
            files={listFiles}
            payloads={payload}
            onSuccess={() => {
              onUploadComplete?.()
              setListFiles([])
            }}
          />
        )}
      </AnimatePresence>
    </>
  )
}
