import { debounce } from 'lodash'
import { ChangeEvent, useState } from 'react'

import { Button, Checkbox } from '../../components'
import { SearchInput } from '../../components/Forms/SearchInput'
import { parserHtmlToText } from '../../utils/functions'
import { PASSWORD_NOTE_REGEX } from '../../utils/notes'
import { useNotesCtx } from './Context'

export const NoteHeader = () => {
  const { resultNotes, showFilter, dispatch, filterByAccount, resourceId } = useNotesCtx()
  const total = resultNotes.length

  const handleToggleShowAll = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch({
      filterByAccount: e.target.checked ? resourceId : null,
    })
  }
  return (
    <div className='flex items-center w-full border-b border-b-separation-800 shadow-card py-2'>
      <NoteSearch />
      {total > 0 ? (
        <NoteNavigateResult />
      ) : (
        showFilter && (
          <>
            <div className='h-8 w-px bg-separation-800' />
            <div className='px-6'>
              <Checkbox
                checked={Boolean(filterByAccount)}
                onChange={handleToggleShowAll}
              >
                Show All Account Notes
              </Checkbox>
            </div>
          </>
        )
      )}
    </div>
  )
}

const NoteNavigateResult = () => {
  const { resultNotes, dispatch } = useNotesCtx()
  const [currentItem, setCurrentItem] = useState(0)
  const total = resultNotes.length

  const handleClearSearch = () => {
    dispatch({
      resultNotes: [],
      keyword: '',
      scrollId: null,
    })
  }

  const handleNavigate = (newIndex: number) => {
    setCurrentItem(newIndex)
    dispatch({
      scrollId: resultNotes[newIndex].id,
    })
  }
  return (
    <div className='flex gap-2 items-center pr-2'>
      <div
        className='text-primary-900 font-medium cursor-pointer'
        onClick={handleClearSearch}
      >
        Clear
      </div>
      <div className='h-8 w-px bg-separation-800' />
      <div className='text-black-400'>{`${currentItem + 1}/${total}`}</div>
      {total > 1 && (
        <>
          <Button
            variant='ternary'
            className='w-6 !h-6 rounded-md'
            innerClassName='!p-0'
            disabled={currentItem === total - 1}
            onClick={() => handleNavigate(currentItem + 1)}
          >
            <span className='font-icon-arrow_down' />
          </Button>
          <Button
            variant='ternary'
            className='w-6 !h-6 rounded-md'
            innerClassName='!p-0'
            disabled={currentItem === 0}
            onClick={() => handleNavigate(currentItem - 1)}
          >
            <span className='font-icon-arrow_up text-[0.375rem]' />
          </Button>
        </>
      )}
    </div>
  )
}

const NoteSearch = () => {
  const { dispatch, listNotes, keyword, detailNoteId } = useNotesCtx()

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = (e.target as HTMLInputElement).value
    const resultNotes =
      value !== ''
        ? listNotes.filter(item =>
            parserHtmlToText(item.body.replace(PASSWORD_NOTE_REGEX, ''))
              .toLowerCase()
              .includes(value.toLowerCase()),
          )
        : []
    const resultFirstId = resultNotes[0]?.id
    dispatch({
      keyword: value,
      resultNotes,
      scrollId: resultFirstId,
      detailNoteId: resultFirstId ? null : detailNoteId,
    })
  }

  return (
    <div className='flex-1'>
      <SearchInput
        className='!border-none !shadow-none'
        prefixIcon={<span className='font-icon-search' />}
        prefixClassName='!bg-transparent !border-none'
        suffix={null}
        placeholder='Search note'
        onChange={debounce(handleChange, 200)}
        search={keyword}
      />
    </div>
  )
}
