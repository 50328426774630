import create from 'zustand'

import { IConfig } from '../services/config'
import authSlice, { AuthSlice } from './authSlice'
import calendarSlice, { CalendarSlice } from './calendarSlice'
import configsSlice, { ConfigsSlice } from './configSlice'
// import iconsSlice, { IconsSlice } from './iconSlice'
import menuSlice, { MenuSlice } from './menuSlice'
import permissionSlice, { PermissionsSlice } from './permissionSlice'
import rolesSlice, { RolesSlice } from './rolesSlice'
import settingsSlice, { SettingsSlice } from './settingsSlice'

export interface Store {
  auth: AuthSlice
  roles: RolesSlice
  permissions: PermissionsSlice
  settings: SettingsSlice
  menus: MenuSlice
  calendars: CalendarSlice
  // icons: IconsSlice
  configs: ConfigsSlice
}

export interface VanillaStore {
  configs: IConfig | null
}

type CreatorSet = (
  partial: Store | Partial<Store> | ((state: Store) => Store | Partial<Store>),
  replace?: boolean | undefined,
) => void

const store = (set: CreatorSet) => ({
  auth: authSlice(set),
  roles: rolesSlice(set),
  permissions: permissionSlice(set),
  settings: settingsSlice(set),
  menus: menuSlice(set),
  calendars: calendarSlice(set),
  // icons: iconsSlice(set),
  configs: configsSlice(set),
})

const useStore = create<Store>(store)

export default useStore
