import { IAccount } from './accounts'
import { IAsset } from './assets'
import client from './client'
import { IContact } from './contacts'
import { IContract, IPreventative } from './contracts'
import { IExpense } from './expenses'
import { IFile } from './files'
import { IInvoice } from './invoices'
import { IMonitoring } from './monitoring'
import { INote } from './notes'
import { IOpportunity } from './opportunities'
import { IPlanSet } from './planSets'
import { IProduct } from './products'
import { IProject } from './projects'
import { IQuote } from './quotes'
import { IService } from './services'
import { ICompanySettings } from './settings'
import { TimesheetEntry } from './timesheet'
import { IUser } from './users'

export type TResourcePayload = {
  resource_id: number
  resource_type: string
}
export type TResource =
  | IAccount
  | IAsset
  | ICompanySettings
  | IContact
  | IContract
  | IExpense
  | IFile
  | IInvoice
  | IMonitoring
  | INote
  | IOpportunity
  | IPlanSet
  | IProduct
  | IProject
  | IQuote
  | IService
  | IUser
  | IPreventative
  | TimesheetEntry

export interface ISearchResourcePayload {
  search: string
  resources?: string[] | null
}

export type TSearchResourceResult = TResource & {
  result_type: string
}
export type TKeySearchResourceResponse = Record<string, TSearchResourceResult[]>

export type ISearchResourceResponse = {
  data: TKeySearchResourceResponse
  grouped: TKeySearchResourceResponse
  results: TSearchResourceResult[]
}

export const searchResources = ({
  search,
  resources,
}: ISearchResourcePayload) => {
  const parseResources =
    resources && resources.length > 0 ? resources.join(',') : null
  return client.get<ISearchResourceResponse>(
    `/api/search?s=${search}${
      parseResources ? `&resource=${parseResources}` : ''
    }`,
  )
}

export const getValidateInboundEmail = (inbound_email_address: string) => {
  return client.post('/api/accounts?include=inboundEmailAddress&validate=1', {
    inbound_email_address,
  })
}
