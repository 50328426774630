import { AsyncSelect } from '../../../components/Forms/AsyncSelect'
import { Props as SelectProps } from '../../../components/Forms/Select'
import { PAGE_SIZE_DROPDOWN } from '../../../services/client'
import { getProducts, IProduct } from '../../../services/products'
import { OptionValue } from '../../../utils/form'
import { renderLabelOptionProduct } from '../../../utils/products'
import { ResourceThumbnail } from '../../Resources/ResourceThumbnail'

interface Props extends Omit<SelectProps, 'options' | 'onSelect'> {
  selected?: OptionValue | null
  onSelect?: (value: OptionValue | null, valueFull?: IProduct | null) => void
  className?: string
  excludesValues?: number[]
}

export const ProductSelect = ({
  selected = null,
  className = '',
  onSelect,
  excludesValues = [],
  ...props
}: Props) => {
  const handleGetProducts = async ({ search = '', page = 1 } = {}) => {
    try {
      const { data } = await getProducts({
        search,
        currentPage: page,
        pageSize: PAGE_SIZE_DROPDOWN,
      })
      return { data: data.data, totalPage: data.meta.last_page }
    } catch {
      return { data: [], totalPage: 1 }
    }
  }

  const handleSelect = (item: any, itemFull: IProduct) => {
    const value = !!item
      ? {
          value: +item.value,
          label: renderLabelOptionProduct(itemFull),
        }
      : null
    onSelect?.(value, itemFull)
  }

  const mapOptions = (data: IProduct[]) => {
    return data
      .filter(item => !excludesValues.includes(item.id))
      .map(item => ({
        value: item.id,
        label: (
          <div className='flex items-center gap-2'>
            <ResourceThumbnail thumbnail={item.avatar_preview_url} />
            <div className='text-body flex flex-col flex-1 w-full'>
              <span>{renderLabelOptionProduct(item)}</span>
            </div>
          </div>
        ),
        displayLabel: renderLabelOptionProduct(item),
      }))
  }

  return (
    <AsyncSelect
      placeholder='All Products'
      labelInValue
      allowClear
      dropdownClassName='!w-[25rem]'
      placement='bottomRight'
      {...props}
      value={
        selected?.value
          ? { value: selected.value + '', label: selected.label }
          : null
      }
      onChange={handleSelect}
      className={className}
      fetcher={handleGetProducts}
      animation={undefined}
      options={mapOptions}
    />
  )
}
