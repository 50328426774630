import { AxiosResponse } from 'axios'
import { Suspense, useEffect } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import shallow from 'zustand/shallow'

import ImgLoginLayout from '../../assets/images/img_login.png'
import { Spinner } from '../../components/Spinner'
import { useAsync } from '../../hooks'
import { AuthUser } from '../../services/auth'
import { instance } from '../../services/client'
import { FooterAuth } from '../../shared/Footer/FooterAuth'
import useStore, { Store } from '../../store'

const mapState = (state: Store) => ({
  auth: state.auth.currentUser,
  setAuthUser: state.auth.setAuthUser,
})

function LoginLayout() {
  const { setAuthUser, auth } = useStore(mapState, shallow)
  const instanceAsync = useAsync<{ data: AxiosResponse<AuthUser> }>({
    status: 'pending',
  })
  const init = async () => {
    if (!auth) {
      instanceAsync
        .execute(instance.get('/api/user'))
        .then(data => setAuthUser(data.data.data))
        .catch(() => console.error('Unauthenticated'))
        .finally(() => instanceAsync.setState({ status: 'idle' }))
    } else {
      instanceAsync.setState({ status: 'idle' })
    }
  }

  useEffect(() => {
    init()
  }, [])

  if (auth) {
    return <Navigate to='/' />
  }

  if (instanceAsync.isLoading) {
    return <Spinner className='w-screen h-screen overflow-hidden' />
  }

  return (
    <>
      <div className='w-screen min-h-screen flex overflow-x-hidden justify-center items-stretch'>
        <div className='w-full p-4 lg:p-10 flex-col justify-between flex lg:w-5/12'>
          <div className='flex-1 flex items-center'>
            <div className='flex-1 flex justify-center w-full'>
              <Suspense fallback={<div />}>
                <Outlet />
              </Suspense>
            </div>
          </div>
          <FooterAuth />
        </div>
        <div className='w-7/12 relative h-screen hidden lg:block'>
          <img
            src={ImgLoginLayout}
            className='absolute inset-0 object-cover w-full h-full'
            alt='banner_login_layout'
          />
        </div>
      </div>
    </>
  )
}

export default LoginLayout
