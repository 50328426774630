import cx from 'classnames'
import { ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { toast as toastify, ToastOptions, TypeOptions } from 'react-toastify'
import { ToastContentProps } from 'react-toastify/dist/types'

import {
  ErrorCircle,
  InfoCircleFilled,
  SuccesCircle,
  WarningCircle,
} from '../icons'

type Message = {
  icon?: ReactNode
  title: string | ReactNode
  description?: string | { [key: string]: string[] }
  action?: ReactNode
  closeable?: boolean
  path?: string
}

const defaultConfig: ToastOptions = {
  autoClose: 5000,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
  progress: undefined,
  position: 'top-right',
}

const renderMessage = (message: Message) => (props: ToastContentProps) => {
  const { title, description: desc, action, icon, closeable, path } = message
  const TagName = ({
    children,
    className,
  }: {
    children: ReactNode
    className: string
  }) => {
    if (path) {
      return (
        <Link to={path} className={className}>
          {children}
        </Link>
      )
    }
    return <div className={className}>{children}</div>
  }
  return (
    <TagName className={cx('flex gap-2', !desc && 'items-center')}>
      {icon && (
        <div className='toast-icon w-[1.125rem] h-[1.125rem] shrink-0 inline-flex justify-center items-center'>
          {icon}
        </div>
      )}
      <div className={cx('toast-body mr-2 grow', !desc && 'self-center')}>
        <div
          className={cx(
            'title text-[0.875rem] leading-normal font-semibold text-black-800',
          )}
        >
          {title}
        </div>
        {desc && (
          <div className='description text-13 text-black-400'>
            {typeof desc === 'string'
              ? desc
              : Object.keys(desc).map(k => <div key={k}>{desc[k][0]}</div>)}
          </div>
        )}
        {action && <div className='action mt-2'>{action}</div>}
      </div>
      {(closeable || closeable === undefined) && !path && (
        <span
          className='font-icon-close text-black-400 text-body'
          onClick={props.closeToast}
        />
      )}
    </TagName>
  )
}

const getToastIcon = (type: TypeOptions, icon?: ReactNode) => {
  if (icon) {
    return icon
  }
  if (type === 'warning') {
    return (
      <span>
        <WarningCircle className='text-orange-900' />
      </span>
    )
  }
  if (type === 'success') {
    return (
      <span>
        <SuccesCircle className='text-green-900' />
      </span>
    )
  }
  if (type === 'info') {
    return (
      <span>
        <InfoCircleFilled className='text-blue-900' />
      </span>
    )
  }
  if (type === 'error') {
    return (
      <span>
        <ErrorCircle className='text-red-900' />
      </span>
    )
  }
  return undefined
}

const _toast = (message: Message, config?: ToastOptions) => {
  const { type = 'info', className } = config || {}
  const icon = getToastIcon(type, message.icon)
  const configProps = {
    ...defaultConfig,
    ...config,
  }
  return toastify(renderMessage({ ...message, icon }), {
    ...configProps,
    closeOnClick: configProps.closeOnClick && !message.path, // disable close on click when have path
    className: cx('custom-toast', `toast-${type}`, className?.toString()),
  })
}

const genToast = (type: TypeOptions) => {
  return (message: Message, config?: ToastOptions) => {
    return _toast(message, { ...config, type })
  }
}

const toast = {
  info: genToast('info'),
  success: genToast('success'),
  warning: genToast('warning'),
  error: genToast('error'),
  dismiss: toastify.dismiss,
  update: toastify.update,
  message: renderMessage,
}

export default toast
