import cx from 'classnames'
import { subDays } from 'date-fns'
import { FieldData } from 'rc-field-form/es/interface'

import { DatePicker, FormField, Input } from '../../../../../components'
import { mapAddress } from '../../../../../utils/address'
import { formatDateNoTz } from '../../../../../utils/dateTime'
import { AddressFormFields } from '../../../../AddressFormFields'
import { FieldItem } from '../../../../Form/FieldItem'
import { UsStatesSelect } from '../../../../Select'
import { FormProfile } from '../FormHelper'

interface Props {
  onChangeAddress: (fields: FieldData[]) => void
  onChangeValues: (value: Partial<FormProfile>) => void
  formValues: FormProfile
}
export const ExtraForm = ({ onChangeAddress, ...props }: Props) => {
  const { formValues } = props
  const isEditMode = Boolean(formValues.isEditMode)
  return (
    <div className='mb-4'>
      <div className='text-black-400 text-xs mb-4 font-medium'>
        EXTRA INFORMATION
      </div>
      <div className='text-body'>
        <div className='pl-4 py-3 border-b border-b-separation-800'>
          <FieldItem
            label={
              <span className='flex gap-1 items-center'>
                <span className='font-icon-calendar w-4 text-left' />
                DOB
              </span>
            }
            className='mb-0 flex gap-2 items-center justify-between'
            labelClassName='!mt-0'
            childrenClassName='w-full'
          >
            {isEditMode ? (
              <FormField name='dob' className='mb-0 w-full'>
                <DatePicker
                  allowClear
                  pickerProps={{
                    maxDate: subDays(new Date(), 1),
                    openToDate: new Date('1990/01/01'),
                  }}
                />
              </FormField>
            ) : (
              <div className='text-right flex-1'>
                {formValues.dob ? formatDateNoTz(formValues.dob) : null}
              </div>
            )}
          </FieldItem>
        </div>
        <div className='pl-4 py-3 border-b border-b-separation-800'>
          <FieldItem
            label={
              <span className='flex gap-1 items-center'>
                <span className='font-icon-calendar w-4 text-left' />
                Driver License Number
              </span>
            }
            className='mb-0 flex gap-2 items-center justify-between'
            labelClassName='!mt-0'
            childrenClassName='w-full'
          >
            {isEditMode ? (
              <FormField
                name='driver_license_number'
                className='sm:basis-1/2 mb-0 w-full'
              >
                <Input />
              </FormField>
            ) : (
              <div className='text-right flex-1'>
                {formValues.driver_license_number}
              </div>
            )}
          </FieldItem>
        </div>
        <div className='pl-4 py-3 border-b border-b-separation-800'>
          <FieldItem
            label={<span className='pl-5'>Driver License State</span>}
            className='mb-0 flex gap-2 items-center justify-between'
            labelClassName='!mt-0 !text-left'
            childrenClassName='w-full'
          >
            {isEditMode ? (
              <FormField name='driver_license_state' className='mb-0 w-full'>
                <UsStatesSelect />
              </FormField>
            ) : (
              <div className='text-right flex-1'>
                {formValues.driver_license_state}
              </div>
            )}
          </FieldItem>
        </div>
        <div className='pl-4 py-3'>
          <FieldItem
            label={
              <span className='flex gap-1 items-center'>
                <span className='font-icon-location w-4 text-left' />
                Address
              </span>
            }
            className={cx(
              'mb-0 flex gap-2 justify-between',
              isEditMode ? 'items-start' : 'items-center',
            )}
            labelClassName='!mt-0'
            childrenClassName='w-full'
          >
            {isEditMode ? (
              <AddressFormFields onSelect={onChangeAddress} />
            ) : (
              <div className='text-right flex-1'>
                {mapAddress({
                  street_address: formValues.address,
                  street_address_2: formValues.address_2,
                  state: formValues.state,
                  zip: formValues.zip,
                  country: formValues.country,
                })}
              </div>
            )}
          </FieldItem>
        </div>
      </div>
    </div>
  )
}
