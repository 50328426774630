import { generateQueryOfEndPoint } from '../utils/functions'
import client, { IFilter, PAGE_SIZE, ResponseMeta } from './client'
import { IUser } from './users'

export interface IFilterNotification extends IFilter {
  mark_as_read?: string | null
}
export interface INotification {
  id: string
  level: string
  created_at: Date | string
  read_at: Date | string | null
  target_resource_id: number
  target_resource_type: string
  message: string
  by_user?: IUser | null
}
interface ReponseMetaNotification extends ResponseMeta {
  unread_count: number
}
export interface INotificationResponse {
  data: INotification[]
  meta: ReponseMetaNotification
}

export interface INotificationPayload {
  mark_as_read?: number | null
}

export const getNotifications = async ({
  sortBy = 'created_at',
  sortDirection = 'asc',
  pageSize = PAGE_SIZE,
  currentPage = 1,
  mark_as_read = null,
}: IFilterNotification) => {
  const sort = `${sortDirection === 'desc' ? '-' : ''}${sortBy}`
  const endPoint = generateQueryOfEndPoint('/api/notifications', {
    sort,
    limit: pageSize,
    page: currentPage,
    mark_as_read,
  })
  return client.get<INotificationResponse>(endPoint)
}

export const markReadNotification = async (id: string) => {
  return client.get(`api/notifications/${id}?mark_as_read=1`)
}

export const deleteAllNotifications = async () => {
  return client.delete('/api/notifications')
}
