import { orderBy } from 'lodash'
import { useEffect, useState } from 'react'
import shallow from 'zustand/shallow'

import { Select, SelectProps } from '../../../components'
import { IProject } from '../../../services/projects'
import useStore, { Store } from '../../../store'
import { OptionValue } from '../../../utils/form'

interface Props extends Omit<SelectProps, 'options'> {
  selected?: OptionValue | null
  onSelect?: (value: OptionValue | null) => void
  onUpdateComplete?: (data: IProject) => void
}

const mapState = (state: Store) => ({
  warranty_terms: state.settings?.quote?.warranty_terms,
})

export const WarrantyTermsSelect = ({
  selected = null,
  onSelect,
  onUpdateComplete,
  ...props
}: Props) => {
  const { warranty_terms } = useStore(mapState, shallow)
  const [currentSelected, setSelected] = useState<OptionValue | null>(selected)

  const handleSelect = (value: OptionValue | null) => {
    //handle change with select only
    setSelected(value)
    onSelect?.(value)
  }

  const mapConditions = orderBy(warranty_terms, ['label'], ['asc']).map(
    ({ id, label }) => ({
      value: id,
      label,
    }),
  )

  useEffect(() => {
    if (
      selected !== currentSelected ||
      selected?.label !== currentSelected?.label ||
      selected?.value !== currentSelected?.value
    ) {
      setSelected(selected)
    }
  }, [selected])

  if (!warranty_terms) {
    return null
  }

  return (
    <div className='relative'>
      <Select
        {...props}
        allowClear
        value={currentSelected}
        labelInValue
        onSelect={
          onSelect
            ? (value: OptionValue | null) => handleSelect(value)
            : undefined
        }
        onDeselect={onSelect ? () => handleSelect(null) : undefined}
        options={mapConditions}
      />
    </div>
  )
}
