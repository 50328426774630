import { SVGProps } from 'react'

export const Audio = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className='text-[#FF8D00]'
      {...props}
    >
      <path
        d='M0 2.28571C0 1.02335 1.02335 0 2.28571 0H13.7143C14.9767 0 16 1.02335 16 2.28571V13.7143C16 14.9767 14.9767 16 13.7143 16H2.28571C1.02335 16 0 14.9767 0 13.7143V2.28571Z'
        fill='currentColor'
      />
      <path
        d='M9.58795 12.5714V8.52919H11.3684V7.99985C11.3782 7.5558 11.2961 7.11452 11.1272 6.70375C10.9582 6.29298 10.7061 5.92161 10.3867 5.613C10.0778 5.29404 9.70637 5.04224 9.2957 4.87333C8.88504 4.70441 8.44395 4.62203 8 4.63132C7.55605 4.62203 7.11496 4.70441 6.7043 4.87333C6.29363 5.04224 5.92222 5.29404 5.61327 5.613C5.29432 5.92196 5.04253 6.2934 4.87363 6.70408C4.70473 7.11477 4.62234 7.55588 4.63163 7.99985V8.52919H6.41206V12.5714H4.39103C4.26482 12.572 4.13981 12.5469 4.02358 12.4977C3.90736 12.4485 3.80236 12.3762 3.71495 12.2851C3.6239 12.1977 3.55157 12.0927 3.50235 11.9765C3.45313 11.8602 3.42805 11.7352 3.42864 11.609V7.99985C3.42515 7.38603 3.54885 6.77816 3.79195 6.21453C4.2566 5.12444 5.12474 4.25626 6.21477 3.79159C6.77946 3.55203 7.38659 3.42857 8 3.42857C8.61341 3.42857 9.22054 3.55203 9.78524 3.79159C10.8753 4.25626 11.7434 5.12444 12.2081 6.21453C12.4511 6.77816 12.5748 7.38603 12.5714 7.99985V11.609C12.5676 11.8631 12.465 12.1057 12.2854 12.2854C12.1057 12.4651 11.863 12.5677 11.609 12.5714H9.58795Z'
        fill='white'
      />
    </svg>
  )
}
