import { OptionValue } from '../utils/form'
import { generateQueryOfEndPoint } from '../utils/functions'
import { IAccount } from './accounts'
import { IAsset } from './assets'
import client, { IFilter, PAGE_SIZE, PAGE_SIZE_RECENT } from './client'
import { IContact } from './contacts'
import { IContract } from './contracts'
import { IFile } from './files'
import { IInvoice } from './invoices'
import { IMonitoring } from './monitoring'
import { INote } from './notes'
import { IProject } from './projects'
import { ITag } from './tags'
import { IUser } from './users'
import { ITask } from './tasks'

export interface IService {
  id: number
  account_id: number
  account: IAccount
  project_id?: number | null
  project?: IProject | null
  monitoringService?: IMonitoring | null
  assets?: IAsset[] | null
  maintenance_contract_id?: number | null
  maintenanceContract?: IContract | null
  invoices?: IInvoice[] | null
  status?: string | null
  updated_at?: Date | string
  created_at: Date | string
  files?: IFile[] | null
  notes?: INote[] | null
  is_watched?: boolean
  assignees?: IUser[] | null
  watchers?: IUser[] | null
  watchers_count?: number | null
  street_address?: string | null
  street_address_2?: string | null
  city?: string | null
  state?: string | null
  zip?: string | null
  country?: string | null
  requested_by?: string | null
  requested_on?: Date | string | null
  scheduled_for?: Date | string | null
  datetime_started?: Date | string | null
  datetime_ended?: Date | string | null
  reason_for_visit?: string | null
  work_performed?: string | null
  blockers?: string | null
  return_visit_needed?: number | null
  return_visit_reason?: string | null
  contact?: IContact | null
  contacts?: IContact[] | null
  tags?: ITag[] | null
  inbound_email_address?: string | null
  vendorAccount?: IAccount | null
  vendor_account_id?: number | null
  under_warranty?: boolean
  description?: string | null
  summary?: string | null
  tasks?: ITask[] | null
}

export interface IServicePayload
  extends Omit<
    IService,
    | 'id'
    | 'account'
    | 'vendorAccount'
    | 'assignees'
    | 'watchers'
    | 'account_id'
    | 'project'
    | 'invoices'
    | 'monitoringService'
    | 'maintenance_contract'
    | 'contact'
    | 'contacts'
    | 'assets'
    | 'created_at'
    | 'tags'
  > {
  account_id?: number | null
  watchers?: number[] | null
  assignees?: number[] | null
  contact?: number | null
  contacts?: number[] | null
  assets?: number[] | null
  invoices?: number[] | null
  tags?: number[] | null
}

export interface ISendServicePayload {
  contacts?: number[] | null
  users?: number[] | null
  emails?: string[] | null
}
export interface IFilterServices extends IFilter {
  filterAccount?: OptionValue | null
  filterProject?: OptionValue | null
  filterContract?: OptionValue | null
  filterAssignee?: OptionValue | null
  filterSpecialStatus?: string | null
  filterStatusDirection?: 'include' | 'exclude' | null
}

export const getServices = async ({
  search = '',
  sortBy = 'id',
  sortDirection = 'desc',
  pageSize = PAGE_SIZE,
  currentPage = 1,
  filterAccount = null,
  filterProject = null,
  filterContract = null,
  filterSpecialStatus = null,
  filterStatusDirection = 'include',
}: IFilterServices) => {
  const sort = `${sortDirection === 'desc' ? '-' : ''}${sortBy}`
  const endPoint = generateQueryOfEndPoint('/api/serviceTickets', {
    sort,
    'filter[search]': search,
    limit: pageSize,
    page: currentPage,
    'filter[account_id]': filterAccount?.value || null,
    'filter[project_id]': filterProject?.value || null,
    'filter[maintenance_contract_id]': filterContract?.value || null,
    [`filter[${
      filterStatusDirection === 'exclude' && filterSpecialStatus ? '-' : ''
    }status]`]: filterSpecialStatus,
  })
  return client.get(`${endPoint}include=assignees,account,invoices`)
}

export const getRecentServices = async (props: IFilterServices) => {
  return getServices({
    ...props,
    sortBy: 'recent',
    sortDirection: 'asc',
    pageSize: PAGE_SIZE_RECENT,
    currentPage: 1,
  })
}

export const createService = async (payload: IServicePayload) => {
  return client.post('/api/serviceTickets', payload)
}

export const editService = (serviceId: number, payload: IServicePayload) => {
  return client.put<{ data: IService }>(
    `/api/serviceTickets/${serviceId}`,
    payload,
  )
}
export const deleteService = async (serviceId: number) => {
  return client.delete(`/api/serviceTickets/${serviceId}`)
}

export const detailServiceShort = async (serviceId: number) => {
  return client.get<{ data: IService }>(
    `api/serviceTickets/${serviceId}?include=account,watchers,assignees,notes,project,contact,contacts,assets,maintenanceContract,monitoringService,invoices,tags,inboundEmailAddress,vendorAccount,tasks`,
  )
}

export const detailService = async (serviceId: number) => {
  return client.get<{ data: IService }>(
    `api/serviceTickets/${serviceId}?include=account,files,project,contact,contacts,assets,maintenanceContract,monitoringService,tags,inboundEmailAddress,vendorAccount,tasks`,
  )
}

export const sendServiceToContact = async (
  serviceId: number,
  payload: ISendServicePayload,
) => {
  return client.post(`/api/serviceTickets/${serviceId}/send`, payload)
}
