import cx from 'classnames'

import { Tooltip } from '../../../components'
import { IQuote } from '../../../services/quotes'
import {
  isExpiredQuote,
  isFollowingUpQuote,
  isWarningQuote,
} from '../../../utils/quotes'

interface Props {
  quote: IQuote
}
export const QuoteIconStatus = ({ quote }: Props) => {
  const isExpired = isExpiredQuote(quote)
  const isWarning = isWarningQuote(quote)
  if (isExpired || isWarning) {
    return (
      <Tooltip
        content={
          isWarning ? (
            <span>This quote hasn’t been updated from the last 7 days</span>
          ) : (
            <span>This quote passed its valid date</span>
          )
        }
        placement='auto'
        popupClassName='z-[9999]'
      >
        <span
          className={cx(
            'rounded min-w-[1.375rem] min-h-[1.375rem] inline-flex items-center justify-center cursor-pointer',
            isExpired ? 'bg-red-800' : 'bg-orange-900',
          )}
        >
          <span className='font-icon-notification text-white leading-none text-[0.875rem]' />
        </span>
      </Tooltip>
    )
  }
  return null
}

export const QuoteFollowUpStatus = ({ quote }: Props) => {
  if (!quote.follow_up_date) {
    return null
  }
  const isFollowingUp = isFollowingUpQuote(quote)
  return (
    <Tooltip
      content={
        isFollowingUp ? (
          <span>This quote is being followed-up</span>
        ) : (
          <span>The followed-up timer is expired</span>
        )
      }
      placement='right'
      popupClassName='z-[9999]'
    >
      <span
        className={cx(
          'rounded min-w-[1.375rem] min-h-[1.375rem] inline-flex items-center justify-center cursor-pointer',
          isFollowingUp ? 'bg-purple-900' : 'bg-red-800',
        )}
      >
        <span className='font-icon-flag text-white leading-none text-[0.75rem]' />
      </span>
    </Tooltip>
  )
}
