import { OptionValue } from '../utils/form'
import { generateQueryOfEndPoint } from '../utils/functions'
import client, { IFilter, PAGE_SIZE } from './client'

export interface ISelectedSyncItem {
  resource?: OptionValue | null
  sync_mode: string
  id?: number | null
}

export interface IQuickbookLog {
  id: number
  error?: string | null
  description: string
  created_at: string
  data: ISelectedSyncItem
  resource_type: string
}
export interface IFilterLog extends IFilter {
  rangeDate?: string | null
}
export const connectQuickbooks = () => {
  return client.get<{ data: { oauth_url: string } }>('api/quickbooks/redirect')
}

export const getQuickbookCallback = async (param: string) => {
  return client.get<{ success: boolean }>(`/api/quickbooks/callback?${param}`)
}

export const getSyncLogs = async ({
  search = '',
  sortBy = 'id',
  sortDirection = 'desc',
  pageSize = PAGE_SIZE,
  currentPage = 1,
  rangeDate = null,
}: IFilterLog) => {
  const sort = `${sortDirection === 'desc' ? '-' : ''}${sortBy}`
  const endPoint = generateQueryOfEndPoint('/api/accounting/syncLogs', {
    sort,
    'filter[search]': search,
    'filter[date_range]': rangeDate,
    limit: pageSize,
    page: currentPage,
  })
  return client.get(endPoint)
}

export const detailSyncLogs = async (id: number) => {
  return client.get(`api/accounting/syncLogs/${id}`)
}

export const getMapAccounting = async (resourceType = 'account') => {
  return client.get(`/api/accounting/map?resource_type=${resourceType}`)
}

export interface IMapAccountingPayload {
  resource_id?: number | null
  accounting_id?: number | null
  sync_mode?: string | null
}

export const mapAccountings = (
  resource_type: string,
  payload: IMapAccountingPayload[],
) => {
  return client.put<{ data: { success: boolean } }>('/api/accounting/map', {
    resource_type,
    map: payload,
  })
}

export const performSyncResource = async (resource_type: string) => {
  return client.post('/api/accounting/sync', { resource_type })
}
