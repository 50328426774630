import { FormInstance } from 'rc-field-form'
import { FieldData } from 'rc-field-form/es/interface'
import { useState } from 'react'
import { getDetails } from 'use-places-autocomplete'

import defaultCountries from '../assets/countries.json'
import { FormField, Input } from '../components'
import { parseInfoAddresFromPlaceResult } from '../utils/address'
import { AutoCompleteField } from './Form/AddressCompleteField'
import { CountriesSelect, UsStatesSelect } from './Select'

interface Props {
  type?: string
  form?: FormInstance
  isRequiredAddress?: boolean
  onSelect?: (field: FieldData[]) => void
}

type AutocompletePrediction = google.maps.places.AutocompletePrediction

export const AddressFormFields = ({
  type = '',
  form,
  onSelect,
  isRequiredAddress,
}: Props) => {
  const [country, setCountry] = useState('US')

  const handleSelect = async (_: string, s: AutocompletePrediction) => {
    const details = (await getDetails({
      placeId: s.place_id,
      fields: ['address_components'],
    })) as google.maps.places.PlaceResult
    if (details) {
      const { streetAddress, state, city, zipCode } =
        parseInfoAddresFromPlaceResult(details)
      const value = [
        { name: `${type}address`, value: streetAddress },
        { name: `${type}city`, value: city },
        { name: `${type}state`, value: state },
        { name: `${type}zip`, value: zipCode },
      ]
      form?.setFields(value)
      onSelect?.(value)
    }
  }
  const handleSelectCountry = (value: string) => {
    const countryValue = defaultCountries.find(item => item.name === value)
    setCountry(countryValue?.code || 'US')
  }
  return (
    <>
      <AutoCompleteField
        country={country}
        placeType='address'
        name={`${type}address`}
        placeholder='Street address (e.g 683 Sunny Glen Lane,...)'
        onSelect={handleSelect}
        required={isRequiredAddress}
        requiredRule={[
          { required: true, message: 'Please input your address' },
        ]}
      />
      <FormField name={`${type}address_2`} className='!mb-2'>
        <Input placeholder='Apartment, suite or space number (e.g Apt. 1/ Suite A)' />
      </FormField>
      <div className='inline-flex w-full gap-2  flex-wrap sm:flex-nowrap'>
        <FormField
          name={`${type}city`}
          className='!mb-2 sm:basis-1/2 w-full'
          rules={
            isRequiredAddress
              ? [{ required: true, message: 'Please select city' }]
              : []
          }
          requiredMask={isRequiredAddress}
        >
          <Input placeholder='City' />
        </FormField>
        <FormField
          name={`${type}zip`}
          className='!mb-2 sm:basis-1/2 w-full'
          rules={
            isRequiredAddress
              ? [{ required: true, message: 'Please select zip code' }]
              : []
          }
          requiredMask={isRequiredAddress}
        >
          <Input placeholder='Zip' />
        </FormField>
      </div>
      <div className='inline-flex w-full gap-2 flex-wrap sm:flex-nowrap'>
        <FormField
          name={`${type}state`}
          className='!mb-2 sm:basis-1/2 w-full'
          rules={
            isRequiredAddress
              ? [{ required: true, message: 'Please select state' }]
              : []
          }
          requiredMask={isRequiredAddress}
        >
          {country === 'US' ? (
            <UsStatesSelect
              placeholder='State'
              labelIsName
              animation={undefined}
            />
          ) : (
            <Input placeholder='State' />
          )}
        </FormField>
        <FormField
          name={`${type}country`}
          className='!mb-2 sm:basis-1/2 w-full'
          rules={
            isRequiredAddress
              ? [{ required: true, message: 'Please select country' }]
              : []
          }
          requiredMask={isRequiredAddress}
        >
          <CountriesSelect
            onSelect={handleSelectCountry}
            placeholder='Country'
            animation={undefined}
          />
        </FormField>
      </div>
    </>
  )
}
