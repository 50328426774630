import { useLoadScript } from '@react-google-maps/api'
import { AxiosResponse } from 'axios'
import { Suspense, useEffect, useState } from 'react'
import { Navigate, Outlet, useNavigate } from 'react-router-dom'
import shallow from 'zustand/shallow'

import { Spinner } from '../../components/Spinner'
import { useAsync } from '../../hooks'
import { AuthUser } from '../../services/auth'
import { instance } from '../../services/client'
import { getConfigs } from '../../services/config'
import { getCompanySettings, getSettings } from '../../services/settings'
import useStore, { Store } from '../../store'
import {
  isHasPermission,
  PERMISSIONS,
  PERMISSIONS_VIEW,
} from '../../utils/permission'
import { Header } from './Header'
import CustomerPortalContextProvider from '../../pages/CustomerPortal/Context'

const mapState = (state: Store) => ({
  auth: state.auth.currentUser,
  accounting: state.settings.accounting,
  setAuthUser: state.auth.setAuthUser,
  setSettings: state.settings.setSettings,
  setConfigs: state.configs.setConfigs,
})

const libraries = ['places']

const CustomerLayout = () => {
  useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_PLACE_KEY!, // @ts-ignore
    libraries,
  })
  const navigate = useNavigate()
  const { setAuthUser, auth, setSettings, setConfigs } = useStore(
    mapState,
    shallow,
  )
  const [isLoading, setLoading] = useState(true)
  const instanceAsync = useAsync<{ data: AxiosResponse<AuthUser> }>({
    status: 'pending',
  })

  const getMoreData = async (dataAuth: AuthUser | null) => {
    const canViewSetting = isHasPermission(
      `${PERMISSIONS_VIEW} ${PERMISSIONS.SETTING}`,
      dataAuth?.can || [],
    )
    const canViewCompanySetting = isHasPermission(
      `${PERMISSIONS_VIEW} ${PERMISSIONS.COMPANY}`,
      dataAuth?.can || [],
    )

    const canViewCustomerPage = isHasPermission(
      `${PERMISSIONS_VIEW} ${PERMISSIONS.CUSTOMER_PORTAL}`,
      dataAuth?.can || [],
    )

    if (!canViewCustomerPage) {
      return navigate('/')
    }
    const [settingsPromise, companyPromise, configsPromise] =
      await Promise.allSettled([
        canViewSetting ? getSettings() : null,
        canViewCompanySetting ? getCompanySettings() : null,
        getConfigs(),
      ])

    const settings =
      settingsPromise.status === 'fulfilled' ? settingsPromise.value : null
    const company =
      companyPromise.status === 'fulfilled' ? companyPromise.value : null
    const configs =
      configsPromise.status === 'fulfilled' ? configsPromise.value : null
    const {
      conditions = [],
      warranty_terms = [],
      expense_categories = [],
      ...project
    } = settings?.data.data.project
    const quote = {
      ...(settings?.data?.data?.quote || {}),
      conditions,
      warranty_terms,
    }
    const expense = {
      expense_categories,
    }
    setSettings({
      ...(settings?.data.data || {}),
      quote,
      project,
      expense,
      company: company ? company.data.data : null,
    })
    if (configs && configs.data && configs.data.data) {
      setConfigs(configs.data.data)
    }
    setLoading(false)
  }

  const init = async () => {
    if (!auth) {
      instanceAsync
        .execute(instance.get('/api/user'))
        .then(async data => {
          setAuthUser(data.data.data)
          await getMoreData(data.data.data)
        })
        .catch(() => {
          localStorage.setItem('APP/LAST_PATH', window.location.href)
          console.error('Unauthenticated')
          setLoading(false)
        })
    } else {
      setLoading(false)
    }
  }

  useEffect(() => {
    init()
  }, [])

  if (isLoading) {
    return <Spinner className='w-screen h-screen overflow-hidden' />
  }

  if (!auth) {
    return <Navigate to='/login' />
  }

  return (
    <CustomerPortalContextProvider>
      <div className='w-full h-screen flex flex-col'>
        <div className='app__top-menu flex-0 h-[3.25rem] fixed top-0 left-0 right-0 z-[2999]'>
          <Header />
        </div>
        <div className='flex-1 pt-[3.25rem]'>
          <Suspense fallback={<div />}>
            <Outlet />
          </Suspense>
        </div>
      </div>
    </CustomerPortalContextProvider>
  )
}

export default CustomerLayout
