export const AccessControl = [
  { iconName: 'server_physical', keyword: 'server' },
  { iconName: 'card', keyword: 'card reader' },
  { iconName: 'door_handle', keyword: 'electric door strike' },
  { iconName: 'exit', keyword: 'request to exit button' },
  { iconName: 'exit', keyword: 'request to exit button sensor' },
  { iconName: 'door', keyword: 'door contact' },
  { iconName: 'software', keyword: 'ACS software' },
  { iconName: 'access_cp', keyword: 'ACS controller' },
  { iconName: 'workstation', keyword: 'client workstation' },
  { iconName: 'power', keyword: 'ACS power supply' },
  { iconName: 'panic_button_2', keyword: 'panic button' },
  { iconName: 'electric_box', keyword: 'enclosure' },
  { iconName: 'intercom_m', keyword: 'intercom M' },
  { iconName: 'intercom_s', keyword: 'intercom S' },
  { iconName: 'chip_2', keyword: 'ip controller' },
  { iconName: 'badge', keyword: 'badge, badge printer' },
  { iconName: 'finger_print', keyword: 'fingerprint, biometric reader' },
  { iconName: 'panic_button', keyword: 'door prop alarm' },
  { iconName: 'walk', keyword: 'pedestrian entry' },
  { iconName: 'metal_detector', keyword: 'pedestrian metal detector' },
  { iconName: 'gate', keyword: 'vehicle gate opener' },
  { iconName: 'electric_box_2', keyword: 'vehicle gate receiver' },
  { iconName: 'wifi_2', keyword: 'wireless receiver' },
  { iconName: 'magnet', keyword: 'magnetic, maglock, magnetic lock' },
  { iconName: 'pushbar_m', keyword: 'mechanical push bar' },
  { iconName: 'pushbar_e', keyword: 'electrified push bar' },
  { iconName: 'kiosk', keyword: 'visitor kiosk, receptionist' },
  { iconName: 'turnstile', keyword: 'turnstiles' },
  { iconName: 'overhead_door', keyword: 'overhead door' },
  { iconName: 'access_cp', keyword: 'keypad card reader' },
  { iconName: 'motion', keyword: 'move, run, motion, detector, sensor' },
  { iconName: 'panic_button', keyword: 'door release button' },
  { iconName: 'autodoor', keyword: 'automatic door' },
  { iconName: 'window', keyword: 'window contact' },
  { iconName: 'key', keyword: 'key, lockset' },
  { iconName: 'detect_location', keyword: 'positioning system' },
  { iconName: 'keypad', keyword: 'AC keypad, numpad' },
  {
    iconName: 'wireless_key_re',
    keyword: 'wireless door release receiver',
  },
  {
    iconName: 'monitor_temperature',
    keyword: 'temperature monitoring console',
  },
  {
    iconName: 'wireless_key_tr',
    keyword: 'wireless door release transmitter',
  },
  { iconName: 'lock', keyword: 'lock, security' },
]

export const BuildManagement = [
  {
    iconName: 'thief',
    keyword: 'thief, occupancy sensor, robber, burglar, stealer, detector',
  },
  { iconName: 'light_switch', keyword: 'light switch' },
  { iconName: 'power_outlet', keyword: 'power outlet' },
  {
    iconName: 'heat_humidity',
    keyword: 'heat, temperature, humidity, detector, sensor',
  },
  { iconName: 'humidity', keyword: 'humidity, water, detector, sensor' },
  { iconName: 'meter', keyword: 'utility meter' },
  { iconName: 'thermostat', keyword: 'thermostat, detector, sensor' },
  { iconName: 'bulb', keyword: 'light fixture' },
  { iconName: 'barcode', keyword: 'bar code scanner' },
  { iconName: 'server_rtls', keyword: 'RTLS server' },
  { iconName: 'location', keyword: 'RTLS locator' },
  { iconName: 'elevator', keyword: 'elevator' },
]

export const Security = [
  { iconName: 'wifi_2', keyword: 'wireless antennas, wifi, wireless receiver' },
  { iconName: 'cctv_2', keyword: 'camera, cctv' },
  { iconName: 'camera', keyword: 'camera' },
  { iconName: 'scanner', keyword: 'scanner' },
  { iconName: 'decoder', keyword: 'code, IT, binary' },
  { iconName: 'monitor', keyword: 'video monitor, screen' },
  { iconName: 'shield', keyword: 'shield, protect' },
  { iconName: 'facedetect', keyword: 'face recognizece detect' },
]

export const Networking = [
  { iconName: 'switch_network', keyword: 'network switch' },
  { iconName: 'global', keyword: 'network gateway' },
  { iconName: 'firewall', keyword: 'network firewall' },
  { iconName: 'server_physical', keyword: 'server' },
  { iconName: 'battery', keyword: 'ups power unit, power supply' },
  { iconName: 'network_switch', keyword: 'patch panel' },
  { iconName: 'switch_outlet', keyword: 'network outlet' },
  { iconName: 'rack', keyword: 'equipment rack' },
  { iconName: 'electric_box', keyword: 'Enclosure' },
  { iconName: 'network_switch', keyword: 'network surge protector' },
  { iconName: 'switch', keyword: 'PoE injector' },
  { iconName: 'cable_optic', keyword: 'fiber optic encoder/decoder' },
  { iconName: 'wifi', keyword: 'wireless access point' },
  { iconName: 'switch_2', keyword: 'switch 2' },
  { iconName: 'switch_3', keyword: 'switch 3' },
  { iconName: 'mobile', keyword: 'phone, smartphone, cellular, device' },
]

export const AudioVisual = [
  { iconName: 'server_physical', keyword: 'server' },
  { iconName: 'workstation', keyword: 'client workstation' },
  { iconName: 'microphone', keyword: 'mic, microphone' },
  { iconName: 'monitor', keyword: 'video monitor' },
  { iconName: 'electric_box', keyword: 'enclosure' },
  { iconName: 'network_switch', keyword: 'multimedia outlet' },
  { iconName: 'tv', keyword: 'television, TV' },
  { iconName: 'projector', keyword: 'projector' },
  { iconName: 'projector_screen', keyword: 'projection screen' },
  { iconName: 'speaker', keyword: 'speaker' },
  { iconName: 'subwoofer', keyword: 'subwoofer' },
  { iconName: 'music', keyword: 'music, sound, audio source' },
  { iconName: 'videoplayer', keyword: 'video server, video source' },
  { iconName: 'amplifier_1', keyword: 'pre-amplifier' },
  { iconName: 'amplifier_2', keyword: 'audio amplifier' },
  {
    iconName: 'video_distribution',
    keyword: 'video distribution device, video distributor',
  },
  { iconName: 'rack', keyword: 'equipment rack' },
  { iconName: 'cable_av', keyword: 'microphone outlet' },
  { iconName: 'bulb', keyword: 'lighting processor, light bulb' },
  { iconName: 'software_av', keyword: 'AV Software' },
  { iconName: 'calendar', keyword: 'room scheduler' },
  { iconName: 'stage_light', keyword: 'stage light' },
  {
    iconName: 'online_meeting',
    keyword: 'video conferencing, online meeting',
  },
  { iconName: 'video_wall', keyword: 'video wall' },
  { iconName: 'lighting_control', keyword: 'lighting control' },
  { iconName: 'volume_control', keyword: 'volume control' },
  { iconName: 'touch_control', keyword: 'touch control' },
  { iconName: 'sound_flat', keyword: 'sound flat, meeting' },
  {
    iconName: 'control_panel',
    keyword: 'AV controller, control panel, setting, adjustment',
  },
  { iconName: 'media_encoder', keyword: 'media encoder' },
  { iconName: 'media_decoder', keyword: 'media decoder' },
  { iconName: 'multimedia', keyword: 'multimedia, audio, photo' },
  { iconName: 'cine_camera', keyword: 'camera, movie camera' },
  { iconName: 'nvr', keyword: 'nvr, network video recorder' },
  { iconName: 'dvr', keyword: 'dvr, digital video recorder' },
]

export const Server = [
  { iconName: 'server_physical', keyword: 'server' },
  { iconName: 'server_cloud', keyword: 'cloud' },
  { iconName: 'server_hyrid', keyword: 'cloud server' },
  { iconName: 'pc', keyword: 'PC, computer' },
  { iconName: 'network_switch', keyword: 'hard disk' },
  { iconName: 'modem', keyword: 'router' },
  { iconName: 'firewall', keyword: 'firewall' },
  { iconName: 'power', keyword: 'shock, flash, thunder, power supply' },
  { iconName: 'battery', keyword: 'ups power unit, power supply' },
  { iconName: 'idf', keyword: 'idf, intrusion defense firewall' },
  { iconName: 'iot', keyword: 'iot, internet of things' },
  { iconName: 'ups', keyword: 'ups, uninterruptible power supply' },
  { iconName: 'mdf', keyword: 'mdf, main distribution frame' },
  { iconName: 'encoder', keyword: 'digital' },
  { iconName: 'wifi', keyword: 'wifi, wireless receiver' },
]

export const FireAlarm = [
  { iconName: 'fire_control', keyword: 'FA Control Panel' },
  { iconName: 'fire_box', keyword: 'fire enclosure' },
  { iconName: 'power', keyword: 'FA power supply' },
  { iconName: 'gas', keyword: 'duct, smoke, detector, sensor' },
  { iconName: 'water_flow', keyword: 'water flow, detector, sensor' },
  { iconName: 'exitinguisher', keyword: 'fire extinguisher' },
  { iconName: 'co', keyword: 'CO detector' },
  { iconName: 'heat', keyword: 'heat, temperature, detector, sensor' },
  { iconName: 'tamper_switch', keyword: 'tamper switch' },
  { iconName: 'speaker', keyword: 'speaker' },
  { iconName: 'panel', keyword: 'FA annunciator' },
  { iconName: 'fire', keyword: 'heat, fire, flame, detector, sensor' },
  { iconName: 'fire_fighter_phone', keyword: 'firefighter telephone' },
  { iconName: 'chip', keyword: 'FA expander' },
  { iconName: 'fire_horn', keyword: 'FA Horn' },
  { iconName: 'fa_relay', keyword: 'FA relay' },
  { iconName: 'fa_communicator', keyword: 'FA communicator' },
  { iconName: 'fa_pull_station', keyword: 'FA pull station' },
  { iconName: 'fa_strobe', keyword: 'FA Strobe' },
  { iconName: 'gas_detector', keyword: 'gas detector' },
  { iconName: 'panic_button', keyword: 'FA strobe horn' },
  { iconName: 'move', keyword: 'wave, sound, detector, sensor' },
]

export const IntrusionDetection = [
  { iconName: 'glass', keyword: 'glass, glass break, detector, sensor' },
  { iconName: 'window', keyword: 'window contact' },
  { iconName: 'door', keyword: 'door contact' },
  { iconName: 'gun', keyword: 'gunshot, detection, sensor' },
  { iconName: 'wifi_2', keyword: 'antenna' },
  { iconName: 'electric_box', keyword: 'enclosure' },
  { iconName: 'alarm', keyword: 'siren' },
  { iconName: 'panic_button_2', keyword: 'panic button' },
  { iconName: 'light_beam', keyword: 'beam sensor' },
  { iconName: 'motion', keyword: 'move, run, motion, detector, sensor' },
  { iconName: 'chip', keyword: 'zone expander' },
  { iconName: 'tamper_switch', keyword: 'tamper switch' },
  { iconName: 'alarm_communicator', keyword: 'alarm communicator' },
  { iconName: 'co', keyword: 'CO detector' },
  { iconName: 'gas', keyword: 'smoke detector' },
  { iconName: 'chip_2', keyword: 'IDS Panel' },
  { iconName: 'keypad', keyword: 'IDS keypad' },
  { iconName: 'alarm_strobe', keyword: 'Alarm Strobe' },
  { iconName: 'chip_3', keyword: 'RF receiver' },
]

export const Communications = [
  {
    iconName: 'emergency_call',
    keyword: 'emergency call box, emergency call, phone',
  },
  { iconName: 'amplifier_2', keyword: 'PA system amplifier' },
  { iconName: 'speaker', keyword: 'PA speaker' },
  { iconName: 'broadcast', keyword: 'Telecom System' },
  { iconName: 'wifi_2', keyword: 'antenna' },
]

export const VideoSurveillance = [
  { iconName: 'software_vms', keyword: 'VMS Software' },
  { iconName: 'server_physical', keyword: 'server' },
  { iconName: 'cctv_vf', keyword: 'varifocal lens camera' },
  { iconName: 'cctv_ptz', keyword: 'PTZ camera' },
  { iconName: 'cctv_fy', keyword: 'fisheye camera' },
  { iconName: 'cctv_ep', keyword: 'explosion-proof camera' },
  { iconName: 'cctv', keyword: 'specialty camera' },
  { iconName: 'cctv_fix', keyword: 'fixed lens camera' },
  { iconName: 'cctv_fix', keyword: 'ip camera' },
  { iconName: 'cctv_therminal', keyword: 'thermal camera' },
  { iconName: 'cctv_mul', keyword: 'multisensor camera' },
  { iconName: 'cctv_x3l', keyword: 'license plate recognition camera' },
  { iconName: 'workstation', keyword: 'client workstation' },
  { iconName: 'stage_light', keyword: 'IR illuminator' },
  { iconName: 'battery', keyword: 'ups power unit, power supply' },
  { iconName: 'microphone', keyword: 'mic, microphone' },
  { iconName: 'monitor', keyword: 'video monitor' },
  { iconName: 'media_encoder', keyword: 'media encoder' },
  { iconName: 'media_decoder', keyword: 'media decoder' },
  { iconName: 'switch', keyword: 'PoE injector' },
  { iconName: 'video_wall', keyword: 'video wall' },
  { iconName: 'modem', keyword: 'network switch' },
  { iconName: 'global', keyword: 'network gateway' },
  { iconName: 'firewall', keyword: 'firewall' },
  { iconName: 'network_switch', keyword: 'patch panel' },
  { iconName: 'rack', keyword: 'equipment rack' },
  { iconName: 'electric_box', keyword: 'enclosure' },
  { iconName: 'network_switch', keyword: 'network surge protector' },
  { iconName: 'barcode', keyword: 'bar code scanner' },
  { iconName: 'workstation', keyword: 'pc workstation' },
]

export const Health = [
  { iconName: 'first_aid', keyword: 'first aid' },
  { iconName: 'heart', keyword: 'defibrillator, heart' },
  { iconName: 'life_saver', keyword: 'life saver' },
]

export const Cable = [
  { iconName: 'cable', keyword: 'cable' },
  { iconName: 'cable_2', keyword: 'cable, power supply' },
]

export const ParentType = [
  { iconName: 'autodoor', keyword: 'Access Control Component' },
  { iconName: 'music', keyword: 'Audio/Visual' },
  { iconName: 'power_outlet', keyword: 'Building Management' },
  { iconName: 'cable', keyword: 'Cable' },
  { iconName: 'emergency_call', keyword: 'Communications' },
  { iconName: 'fire', keyword: 'Fire Alarm' },
  { iconName: 'heart', keyword: 'Health' },
  { iconName: 'alarm', keyword: 'Intrusion Detection' },
  { iconName: 'global', keyword: 'Networking' },
  { iconName: 'shield', keyword: 'Security' },
  { iconName: 'server_physical', keyword: 'Server' },
  { iconName: 'cctv', keyword: 'Video Surveillance' },
  { iconName: 'house', keyword: 'Housings & Mounts' },
]
export const HousingMount = [
  { iconName: 'camera_mount', keyword: 'Camera Mounts' },
  { iconName: 'dome', keyword: 'Dome Covers' },
  { iconName: 'extension_column', keyword: 'Extension Columns' },
  { iconName: 'mount_accessory', keyword: 'Mounting Accessories' },
  { iconName: 'pendant_cap', keyword: 'Pendant Caps' },
  { iconName: 'monitor_mouse', keyword: 'Monitor Mounts' },
  { iconName: 'junction_boxes', keyword: 'Junction Boxes' },
]

export default [
  ...AccessControl,
  ...AudioVisual,
  ...BuildManagement,
  ...Communications,
  ...FireAlarm,
  ...Health,
  ...IntrusionDetection,
  ...Networking,
  ...Security,
  ...Server,
  ...VideoSurveillance,
  ...HousingMount,
]
