import { SVGProps } from 'react'

export const SuccesCircle = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width='14'
      height='14'
      viewBox='0 0 14 14'
      xmlns='http://www.w3.org/2000/svg'
      fill='currentColor'
      {...props}
    >
      <path
        d='M7 0C3.1402 0 0 3.1402 0 7C0 10.8598 3.1402 14 7 14C10.8598 14 14 10.8598 14 7C14 3.1402 10.8598 0 7 0Z'
        fill='currentColor'
      />
      <path
        d='M9.6249 4.52511L6.02503 8.12494L4.37515 6.47502C4.12668 6.22654 3.72373 6.2265 3.47521 6.47498C3.22669 6.7235 3.22669 7.1264 3.47521 7.37492L5.57504 9.47484C5.69438 9.59418 5.85623 9.66125 6.02499 9.66125C6.02503 9.66125 6.02499 9.66125 6.02503 9.66125C6.1938 9.66125 6.35565 9.59418 6.47499 9.47488L10.5248 5.4251C10.7734 5.17658 10.7734 4.77368 10.5248 4.52516C10.2763 4.27664 9.87342 4.27659 9.6249 4.52511Z'
        fill='white'
      />
    </svg>
  )
}
