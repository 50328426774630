import cx from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import { orderBy } from 'lodash'
import { ChangeEvent, useEffect, useState } from 'react'
import { usePopper } from 'react-popper'
import { Link } from 'react-router-dom'
import shallow from 'zustand/shallow'

import {
  Collapse,
  Input,
  InputProps,
  Overlay,
  Popover,
  Portal,
} from '../../../components'
import { PATHNAME } from '../../../configs/routes'
import { IDetailSettings } from '../../../services/settings'
import useStore, { Store } from '../../../store'
import { OptionValue } from '../../../utils/form'
import { filterCategoryHierarchy } from '../../../utils/functions'
import {
  getListSettingHierarchy,
} from '../../../utils/settings'
import { AssetIconType } from '../../Asset/AssetIconType'

interface Props extends Omit<InputProps, 'onSelect'> {
  selected?: OptionValue | null
  onSelect?: (item: OptionValue | null) => void
  className?: string
  showSingleSelect?: boolean
  selectType?: string
}

interface PropsPanel {
  item: IDetailSettings
  index?: number | null
  level: number
  keyword: string
  onSelect: (item: IDetailSettings) => void
}

const mapState = (state: Store) => ({
  productCategories: state.settings?.product?.product_types,
  assetSubtypes: state.settings?.asset?.asset_sub_types,
})

const getPathUrl = (type: string) => {
  if (type === 'product') {
    return `/${PATHNAME.products}/?category`
  }
  return `/${PATHNAME.assets}/?type`
}

export const AssetSubTypesSelect = ({
  selected = null,
  onSelect,
  showSingleSelect = true,
  selectType = 'asset',
  ...props
}: Props) => {
  const { productCategories, assetSubtypes } = useStore(mapState, shallow)
  const listDataSelect =
    selectType === 'product' ? productCategories : assetSubtypes

  const [open, setOpen] = useState(false)
  const [focus, setFocus] = useState(false)
  const [keyword, setKeyword] = useState('')
  const [data, setData] = useState<IDetailSettings[]>(listDataSelect || [])
  const [refElement, setRefElement] = useState<HTMLElement | null>(null)
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null)
  const { styles, attributes } = usePopper(refElement, popperElement, {
    placement: 'bottom',
  })
  const [currentSelected, setSelected] = useState<OptionValue | null>(null)
  const [strSelected, setStrSelected] = useState<IDetailSettings[]>([])

  const handleStrSelected = (id?: number | null, label?: string | null) => {
    if (id && label) {
      const str = getListSettingHierarchy(selectType, id, label)
      console.log('sss', str)
      setStrSelected(str)
    } else {
      setStrSelected([])
    }
  }
  const handleSelect = (item: IDetailSettings | null) => {
    const newItem = item ? { value: item.id, label: item.label } : null
    handleStrSelected(item?.id, item?.label)
    setSelected(newItem)
    setKeyword('')
    onSelect?.(newItem)
  }
  if (!listDataSelect) {
    return null
  }

  const handleToggleStatus = (status: boolean) => {
    setOpen(status)
    setFocus(status)
  }

  const handleClearSearch = () => {
    setKeyword('')
    handleSelect(null)
    setData(listDataSelect)
  }

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setKeyword(e.target.value)
    currentSelected && setSelected(null)
    const listData = selectType === 'product' ? productCategories : assetSubtypes
    const result = filterCategoryHierarchy(listData || [], e.target.value, 'label')
    setData(result)
  }

  const mapStrSelected = (str: IDetailSettings[]) =>
    str.map(item => item.label).join(' / ')

  useEffect(() => {
    if (
      selected !== currentSelected ||
      selected?.label !== currentSelected?.label ||
      selected?.value !== currentSelected?.value
    ) {
      setSelected(selected)
      handleStrSelected(selected?.value, selected?.label)
    }
  }, [selected])

  const widthPopover = refElement?.offsetWidth
  const isExpand = Boolean(keyword !== '')
  return (
    <>
      <div
        ref={setRefElement}
        className='w-full flex-1 border-solid relative overflow-x-hidden'
      >
        {!focus && strSelected.length > 0 && !showSingleSelect && (
          <div
            className={cx(
              'flex flex-col justify-center left-3 right-8 top-1/2 -translate-y-1/2 z-10 w-fit max-w-[calc(100%_-_4.5rem)] overflow-hidden absolute h-fit',
              'border border-transparent rounded-lg text-body outline-0 overflow-hidden',
            )}
          >
            <div className='inline-block whitespace-nowrap overflow-hidden relative gap-1 w-fit capitalize'>
              {strSelected.map((item, index) => (
                <Link
                  to={`${getPathUrl(selectType)}=${item.id}`}
                  key={item.id}
                >
                  {`${item.label}${
                    index < strSelected.length - 1 ? ' / ' : ''
                  }`}
                </Link>
              ))}
            </div>
          </div>
        )}
        <Input
          onFocus={() => handleToggleStatus(true)}
          onChange={handleSearch}
          onBlur={e => {
            props.onBlur?.(e)
            setFocus(false)
          }}
          prefixClassName='!left-0 !top-0 md:w-[3.25rem] border-none md:border-solid  md:!bg-separation-100 md:border-separation-800 !bg-transparent'
          value={
            !showSingleSelect && !focus
              ? mapStrSelected(strSelected)
              : currentSelected?.label || keyword
          }
          suffix={
            keyword !== '' || (currentSelected && !!currentSelected?.label) ? (
              <span
                className='font-icon-close cursor-pointer text-13'
                onClick={handleClearSearch}
              ></span>
            ) : (
              <span
                className='font-icon-arrow_down text-[1.25rem] translate-x-2 relative cursor-pointer'
                onClick={() => handleToggleStatus(true)}
              />
            )
          }
          className='capitalize'
          {...props}
        />
      </div>
      <AnimatePresence initial={false}>
        {open && (
          <Portal>
            <Overlay className='!bg-transparent' />
            <div
              ref={setPopperElement}
              className='p-2 z-popover overflow-hidden'
              style={{ ...styles.popper }}
              {...attributes.popper}
            >
              <motion.div
                initial={{ y: '-100%', opacity: 1 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: '-100%', opacity: 0 }}
                transition={{ duration: 0.3 }}
              >
                <Popover.Content
                  onClose={() => handleToggleStatus(false)}
                  className={cx(
                    'min-w-full !p-0 shadow-dropdown max-h-[25rem] h-auto custom-scrollbar overflow-auto',
                  )}
                  style={{
                    width: widthPopover,
                  }}
                >
                  <div>
                    {data.length > 0 ? (
                      orderBy(data, ['label', 'asc']).map((item, index) => (
                        <Collapse
                          className={index && index > 0 ? 'mt-2' : ''}
                          contentClassName='!p-0'
                          panelClassName='!justify-start gap-3 !py-2'
                          panel={
                            <span
                              className='text-body leading-none flex-1'
                              onClick={() => handleSelect(item)}
                            >
                              <AssetIconType
                                assetDetail={item}
                                type={item.section}
                              />
                            </span>
                          }
                          active={isExpand}
                          onlyClickIcon
                          key={item.id}
                        >
                          <div className={true ? 'pl-6' : ''}>
                            {orderBy(item.children, ['label', 'asc']).map(
                              (child, idx) => (
                                <CollapseItem
                                  item={child}
                                  index={idx}
                                  level={2}
                                  key={child.id}
                                  onSelect={handleSelect}
                                  keyword={keyword}
                                />
                              ),
                            )}
                          </div>
                        </Collapse>
                      ))
                    ) : (
                      <div className='h-[4.375rem] flex justify-center items-center text-sm opacity-60'>
                        No Data
                      </div>
                    )}
                  </div>
                </Popover.Content>
              </motion.div>
            </div>
          </Portal>
        )}
      </AnimatePresence>
    </>
  )
}

const CollapseItem = ({
  item,
  index,
  level,
  onSelect,
  keyword,
}: PropsPanel) => {
  return (
    <div className='text-body items-center justify-start'>
      {!!item?.children && item.children?.length > 0 ? (
        <div>
          <Collapse
            className={index && index > 0 && level === 1 ? 'mt-2' : ''}
            panelClassName='!justify-start gap-3 !py-2'
            contentClassName='!p-0'
            panel={
              <span
                className='text-body leading-none flex-1'
                onClick={() => onSelect(item)}
              >
                {/* {item.label} */}
                <AssetIconType assetDetail={item} type={item.section} />
              </span>
            }
            active={keyword !== ''}
            onlyClickIcon
          >
            <div className={level > 1 ? 'pl-6' : ''}>
              {orderBy(item.children, ['label', 'asc']).map((child, idx) => (
                <CollapseItem
                  item={child}
                  index={idx}
                  level={level + 1}
                  key={child.id}
                  onSelect={onSelect}
                  keyword={keyword}
                />
              ))}
            </div>
          </Collapse>
        </div>
      ) : (
        <div
          className='pl-4 py-2 cursor-pointer'
          onClick={() => onSelect(item)}
        >
          <AssetIconType assetDetail={item} type={item.section} />
          {/* {item.label} */}
        </div>
      )}
    </div>
  )
}
