import { generateQueryOfEndPoint } from '../utils/functions'
import { IAccount } from './accounts'
import client, { IFilter, PAGE_SIZE } from './client'

export interface ITaxVendor {
  id: number
  account_id: number
  account: IAccount
  sales_tax: string
  name: string
  created_at: Date | string
}

export interface ITaxVendorPayload {
  name: string
  account_id: number
  sales_tax: string
}
export const getTaxVendors = async ({
  search = '',
  sortBy = 'id',
  sortDirection = 'desc',
  pageSize = PAGE_SIZE,
  currentPage = 1,
}: IFilter) => {
  const sort = `${sortDirection === 'desc' ? '-' : ''}${sortBy}`
  const endPoint = generateQueryOfEndPoint('/api/taxVendors', {
    sort,
    'filter[search]': search,
    limit: pageSize,
    page: currentPage,
  })
  return client.get(`${endPoint}&include=account`)
}

export const createTaxVendor = async (payload: ITaxVendorPayload) => {
  return client.post('/api/taxVendors', payload)
}

export const editTaxVendor = (
  taxVendorId: number,
  payload: Partial<ITaxVendorPayload>,
) => {
  return client.put<{ data: ITaxVendor }>(
    `/api/taxVendors/${taxVendorId}`,
    payload,
  )
}
export const deleteTaxVendor = async (taxVendorId: number) => {
  return client.delete(`/api/taxVendors/${taxVendorId}`)
}

export const detailTaxVendor = async (taxVendorId: number) => {
  return client.get<{ data: ITaxVendor }>(`api/taxVendors/${taxVendorId}`)
}
