import cx from 'classnames'
import { FieldData } from 'rc-field-form/es/interface'
import { useState } from 'react'
import shallow from 'zustand/shallow'

import {
  Button,
  DatePicker,
  Form,
  FormField,
  Input,
  Modal,
  ModalProps,
  TextArea,
} from '../../../../components'
import { PATHNAME } from '../../../../configs/routes'
import { useAsync } from '../../../../hooks'
import { editInvoice, IInvoice } from '../../../../services/invoices'
import {
  createTransaction,
  ITransactionPayload,
} from '../../../../services/transaction'
import useStore, { Store } from '../../../../store'
import { formatDateApi } from '../../../../utils/dateTime'
import { INVOICE_STATUS_PAID } from '../../../../utils/invoices'
import { PAYMENT_TYPE_CHECK, PAYMENT_TYPES } from '../../../../utils/payment'
import { RESOURCE_INVOICE } from '../../../../utils/resources'
import toast from '../../../../utils/toast'
import { ButtonCheckbox } from '../../../Form/ButtonCheckbox'
import { FieldItem } from '../../../Form/FieldItem'

interface Props extends Omit<ModalProps, 'children'> {
  onSuccess: (v: IInvoice) => void
  onClose: () => void
  invoice: IInvoice
  invoiceType: string
}

interface FormMarkPaid {
  paid_date: Date | string
  paid_method?: string | null
  check_number?: string | null
  notes?: string | null
  amount: number | null
}

const mapStore = (store: Store) => ({
  invoice_statuses: store.settings.invoice?.invoice_statuses,
})
export const ModalMarkInvoiceAsPaid = ({
  onClose,
  onSuccess,
  invoice,
  invoiceType,
  ...props
}: Props) => {
  const [form] = Form.useForm()
  const initFormValues = {
    paid_date: new Date(),
    paid_method: PAYMENT_TYPE_CHECK,
    check_number: null,
    notes: null,
    amount: invoice?.total_amount || null,
  }
  const { invoice_statuses } = useStore(mapStore, shallow)
  const [formValues, setFormValues] = useState<FormMarkPaid>(initFormValues)
  const { execute, isLoading } = useAsync({
    showNotifOnError: true,
  })

  const handleClose = () => {
    if (isLoading) {
      return
    }
    onClose()
  }

  const handleFieldChange = (field: FieldData[]) => {
    const name = (field[0].name as string[])[0] as keyof FormMarkPaid
    setFormValues({ ...formValues, [name]: field[0].value })
  }

  const handleChangeValue = (
    key: keyof FormMarkPaid,
    value: FormMarkPaid[keyof FormMarkPaid],
  ) => {
    setFormValues({ ...formValues, [key]: value })
    form.setFieldsValue({
      [key]: value,
    })
  }

  const generateForm = (values: FormMarkPaid): ITransactionPayload => {
    let data: ITransactionPayload = {
      resource_id: invoice.id,
      resource_type: RESOURCE_INVOICE,
      amount: values.amount || 0,
      paid_method: values.paid_method || '',
      paid_date: formatDateApi(values.paid_date),
    }
    if (values.paid_method === PAYMENT_TYPE_CHECK) {
      data = {
        ...data,
        payment_method_details: {
          check_number: values?.check_number || '',
        },
      }
    }
    return data
  }

  const handleSubmit = async (value: FormMarkPaid) => {
    if (isLoading) {
      return
    }
    const data = generateForm(value)
    const response = await execute(createTransaction(data))
    if (response.data.data) {
      const detailStatus = invoice_statuses?.find(
        item => item.label === invoiceType,
      )
      const newInvoice = await execute(
        editInvoice(invoice.id, {
          status: invoiceType,
          status_id: detailStatus?.id || null,
        }),
      )
      toast.success({
        title: `Invoice is marked as “${invoiceType}”`,
        path: `/${PATHNAME.invoices}/${invoice.id}`,
      })
      handleClose()
      onSuccess?.(newInvoice.data.data)
    }
  }

  return (
    <Modal {...props} className='max-w-[40rem] w-full' onClose={handleClose}>
      <div className='flex gap-3 relative'>
        <span
          className='cursor-pointer font-icon-close text-black-400 absolute -right-2 -top-2 z-10 w-10 h-10 flex justify-center items-center'
          onClick={onClose}
        />
        <div className='w-10 h-10 bg-primary-300 rounded-full flex items-center justify-center'>
          <span className='font-icon-check text-black-800 text-[15px]' />
        </div>
        <div className='flex-1'>
          <div className='text-base font-medium text-black-800 mb-6'>
            Mark invoice as “{invoiceType}”
          </div>
          <Form
            form={form}
            onFinish={() => handleSubmit(formValues)}
            onFieldsChange={handleFieldChange}
            initialValues={initFormValues}
          >
            <FieldItem label='Paid Date' viewType='row' required>
              <FormField
                name='paid_date'
                className='!mb-0'
                rules={[
                  {
                    required: true,
                    message: 'Please input paid date',
                  },
                ]}
              >
                <DatePicker
                  className='w-60'
                  allowClear
                  fieldName='install_date'
                  closeOnSelect
                />
              </FormField>
            </FieldItem>
            <FieldItem label='Paid Method' viewType='row' required>
              <div className='flex flex-wrap items-center gap-2'>
                {PAYMENT_TYPES.map(item => (
                  <ButtonCheckbox
                    key={item.value}
                    selected={formValues.paid_method === item.value}
                    onClick={() => handleChangeValue('paid_method', item.value)}
                  >
                    <div className='inline-flex items-center gap-2'>
                      <span
                        className={cx(
                          `font-icon-${item.icon}`,
                          'text-black-400 text-[0.6875rem]',
                        )}
                      />
                      {item.label}
                    </div>
                  </ButtonCheckbox>
                ))}
              </div>
            </FieldItem>
            {formValues.paid_method === PAYMENT_TYPE_CHECK && (
              <FieldItem label='Check Number' viewType='row' required>
                <FormField
                  name='check_number'
                  className='!mb-0'
                  rules={[
                    {
                      required: true,
                      message: 'Please input check number',
                    },
                  ]}
                >
                  <Input />
                </FormField>
              </FieldItem>
            )}
            <FieldItem label='Amount' viewType='row' required>
              <FormField
                name='amount'
                className='!mb-0'
                rules={[
                  {
                    required: true,
                    message: 'Please input amount',
                  },
                ]}
              >
                <Input
                  prefixIcon={<span className='text-body'>$</span>}
                  disabled={invoiceType === INVOICE_STATUS_PAID}
                  onChange={e => {
                    const value =
                      e.target.value && e.target.value !== ''
                        ? +e.target.value
                        : null
                    handleChangeValue('amount', value)
                    // const currentAmount = invoice?.total_amount || 0
                    // if(value && value >= currentAmount){
                    //   handleChangeValue('amount', currentAmount)
                    // }else{
                    //   handleChangeValue('amount', value)
                    // }
                  }}
                />
              </FormField>
            </FieldItem>
            <FieldItem label='Note' viewType='row'>
              <FormField name='note' className='!mb-0'>
                <TextArea rows={4} className='!py-2' />
              </FormField>
            </FieldItem>
            <div className='flex justify-end gap-2'>
              <Button
                variant='ternary'
                onClick={handleClose}
                disabled={isLoading}
                size='large'
                asLink
              >
                Cancel
              </Button>
              <Button
                variant='primary'
                size='large'
                disabled={isLoading}
                innerClassName='px-8'
              >
                Save
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </Modal>
  )
}
