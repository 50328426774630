import { OptionValue } from '../utils/form'
import { generateQueryOfEndPoint } from '../utils/functions'
import { IAsset } from './assets'
import client, { IFilter, PAGE_SIZE, PAGE_SIZE_RECENT } from './client'
import { IFile } from './files'
import { INote } from './notes'
import { IReponseItemSetting } from './settings'
import { ITag } from './tags'

export type TAccountType =
  | 'Customer'
  | 'Vendor'
  | 'Lead'
  | 'Subcontractor'
  | 'Indirect Customer'
export type TPayRateType = {
  name: string | null
  rate: number | null
}
export type TAccountPhone = {
  name: string
  phone: string
}

export type TAccountKpiType = {
  last_month: number
  last_3_months: number
  last_6_months: number
  last_12_months: number
  last_calender_month: number
  last_3_calender_months: number
  last_6_calender_months: number
  last_12_calender_months: number
}

export type TAccountRatePayload = {
  setting_label_id: number
  label_name: string
  value: string
}

export interface IAccount {
  id: number
  city: string
  company_name: string
  country: string
  email: string
  full_name: string
  phone_no: string
  phone_no_2: string
  state: string
  street_address: string
  street_address_2: string
  type: TAccountType
  zip: string
  accountRates?: IReponseItemSetting[] | null
  notes?: INote[] | null
  files?: IFile[] | null
  assets?: IAsset[] | null
  parent_account_id?: number | null
  parentAccount?: Omit<IAccount, 'parentAccount'> | null
  avatar?: string | null
  avatar_url?: string | null
  avatar_preview_url?: string | null
  created_at: Date | string
  product_links?: string[] | null
  sub_type?: string | null
  vertical?: string[] | null
  is_favorited?: boolean | null
  child_accounts_count: number
  inbound_email_address?: string | null
  tags?: ITag[] | null
  shipping_street_address?: string | null
  shipping_street_address_2?: string | null
  shipping_city?: string | null
  shipping_state?: string | null
  shipping_zip?: string | null
  shipping_country?: string | null
  billing_street_address?: string | null
  billing_street_address_2?: string | null
  billing_city?: string | null
  billing_state?: string | null
  billing_zip?: string | null
  billing_country?: string | null
  is_shipping_same_as_billing?: boolean
  site_street_address?: string | null
}

export interface IAccountPayload {
  company_name?: string | null
  full_name: string
  email: string
  type: TAccountType
  phone_no: string
  phone_no_2: string
  parent_account_id?: number | null
  sub_type?: string | null
  vertical?: string[] | null
  tags?: number[] | null
  existing_avatar_id?: number | null
  accountRates?: TAccountRatePayload[] | null
  inbound_email_address?: string | null
  shipping_street_address?: string | null
  shipping_street_address_2?: string | null
  shipping_city?: string | null
  shipping_state?: string | null
  shipping_zip?: string | null
  shipping_country?: string | null
  billing_street_address?: string | null
  billing_street_address_2?: string | null
  billing_city?: string | null
  billing_state?: string | null
  billing_zip?: string | null
  billing_country?: string | null
  is_shipping_same_as_billing?: boolean
  site_street_address?: string | null
}

export interface IAccountKpi {
  sales: TAccountKpiType
  projected: TAccountKpiType
}
export interface IFilterAccounts extends IFilter {
  filterAccountType?: string | null
  filterIsChild?: boolean | null
  filterIsParent?: boolean | null
  filterParentAccount?: OptionValue | null
  filterSubType?: string | null // this is Vendor type
  kpi?: number | null
}

export const getAccounts = async ({
  search = '',
  sortBy = 'full_name',
  sortDirection = 'asc',
  pageSize = PAGE_SIZE,
  currentPage = 1,
  filterAccountType = 'All',
  filterIsChild = false,
  filterIsParent = false,
  filterParentAccount = null,
  filterSubType = null,
  kpi = null,
}: IFilterAccounts) => {
  const sort = `${sortDirection === 'desc' ? '-' : ''}${sortBy}`
  const endPoint = generateQueryOfEndPoint('/api/accounts', {
    sort,
    'filter[search]': search,
    limit: pageSize,
    page: currentPage,
    'filter[type]':
      filterAccountType && filterAccountType !== 'All' ? filterAccountType : '',
    'filter[is_child_account]': filterIsChild ? 1 : '',
    'filter[is_parent_account]': filterIsParent ? 1 : '',
    'filter[parent_account_id]': filterParentAccount?.value || null,
    'filter[sub_type]': filterSubType,
    kpi,
  })
  return client.get(`${endPoint}include=parentAccount,inboundEmailAddress`)
}

export const getRecentAccounts = async (props: IFilterAccounts) => {
  return getAccounts({
    currentPage: 1,
    ...props,
    sortBy: 'recent',
    pageSize: PAGE_SIZE_RECENT,
  })
}

export const getAccount = async (id: number) => {
  return client.get<{ data: IAccount }>(`api/accounts/${id}`)
}

export const createAccount = async (payload: IAccountPayload) => {
  return client.post('/api/accounts', payload)
}

export const addAccountAvatar = async (
  id: number,
  { avatar }: { avatar: File | null },
) => {
  if (!avatar) {
    return
  }
  const formData = new FormData()
  formData.append('avatar', avatar)
  formData.append('_method', 'PUT')
  return client.post(`/api/accounts/${id}`, formData)
}

export const editAccount = (
  userId: number,
  payload: Partial<IAccountPayload>,
) => {
  return client.put(`/api/accounts/${userId}`, payload)
}
export const deleteAccount = async (id: number) => {
  return client.delete(`/api/accounts/${id}`)
}

export const detailAccountShort = async (id: string) => {
  return client.get<{ data: IAccount }>(
    `api/accounts/${id}?include=accountRates,notes,parentAccount,favorites,inboundEmailAddress,tags`,
  )
}

export const detailAccount = async (id: string) => {
  return client.get<{ data: IAccount }>(
    `api/accounts/${id}?include=accountRates,parentAccount,favorites,tags,files,inboundEmailAddress`,
  )
}

export const getAccountKpi = async (accountId: number) => {
  return client.get<{ data: IAccountKpi }>(`/api/accounts/${accountId}/kpi`)
}

export const getAccountSalePipeline = async (accountId: number) => {
  return client.get<{ data: IAccountKpi }>(
    `/api/accounts/${accountId}?salesPipelineReport=1`,
  )
}
