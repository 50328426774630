import { differenceInCalendarDays } from 'date-fns'
import { Link } from 'react-router-dom'
import { Settings } from 'react-slick'

import { PATHNAME } from '../configs/routes'
import { IAccount } from '../services/accounts'
import { IAsset } from '../services/assets'
import { IContact } from '../services/contacts'
import { IContract } from '../services/contracts'
import { IInvoice } from '../services/invoices'
import { IMonitoring } from '../services/monitoring'
import { IOpportunity } from '../services/opportunities'
import { IPlanSet } from '../services/planSets'
import { IProject } from '../services/projects'
import { IQuote } from '../services/quotes'
import { TResource } from '../services/resource'
import { IService } from '../services/services'
import { IUser } from '../services/users'
import useStore from '../store'
import { renderAccountName } from './accounts'
import { copyToClipBoard, pathResource } from './functions'
import { renderTextForServiceSelect } from './services'
import toast from './toast'

export const RESOURCE_ACCOUNT = 'account'
export const RESOURCE_PROJECT = 'project'
export const RESOURCE_SERVICE = 'service_ticket'
export const RESOURCE_QUOTE = 'quote'
export const RESOURCE_INVOICE = 'invoice'
export const RESOURCE_EXPENSE = 'expense'
export const RESOURCE_ASSET = 'asset'
export const RESOURCE_ASSET_GROUP = 'asset_group'
export const RESOURCE_OPPORTUNITY = 'opportunity'
export const RESOURCE_USER = 'user'
export const RESOURCE_PRODUCT = 'product'
export const RESOURCE_CONTRACT = 'maintenance_contract'
export const RESOURCE_CONTACT = 'contact'
export const RESOURCE_PREVENTATIVE = 'preventative_maintenance_log'
export const RESOURCE_PREVENTATIVE_TASK =
  'preventative_maintenance_task_template'
export const RESOURCE_COMPANY_SETTING = 'company_setting'
export const RESOURCE_MONITORING = 'monitoring_service'
export const RESOURCE_PLANSET = 'plan_set'
export const RESOURCE_ESTIMATION = 'estimation'
export const RESOURCE_LEAD = 'lead'
export const RESOURCE_MEDIA = 'media'
export const RESOURCE_SETTING = 'setting'
export const RESOURCE_NOTE = 'note'
export const RESOURCE_TIMESHEET_ENTRY = 'timesheet_entry'
export const RESOURCE_UNCATEGORIZED = 'whatsapp_chat_message'
export const RESOURCE_PERMIT = 'permit'
export const RESOURCE_TASK = 'task'

export const RESOURCE_TYPE_LABEL: Record<string, string> = {
  [RESOURCE_ACCOUNT]: 'Account',
  [RESOURCE_PROJECT]: 'Project',
  [RESOURCE_SERVICE]: 'Service Ticket',
  [RESOURCE_QUOTE]: 'Quote',
  [RESOURCE_INVOICE]: 'Invoice',
  [RESOURCE_OPPORTUNITY]: 'Opportunity',
  [RESOURCE_EXPENSE]: 'Expense',
  [RESOURCE_ASSET]: 'Asset',
  [RESOURCE_PRODUCT]: 'Product',
  [RESOURCE_CONTRACT]: 'Maintenance Contract',
  [RESOURCE_CONTACT]: 'Contact',
  [RESOURCE_PREVENTATIVE]: 'Preventative Maintenance',
  [RESOURCE_COMPANY_SETTING]: 'Company Settings',
  [RESOURCE_MONITORING]: 'Monitoring',
  [RESOURCE_USER]: 'User',
  [RESOURCE_PLANSET]: 'Plan Set',
  [RESOURCE_ESTIMATION]: 'Estimation',
  [RESOURCE_LEAD]: 'Lead',
  [RESOURCE_MEDIA]: 'File',
  [RESOURCE_SETTING]: 'Setting',
  [RESOURCE_NOTE]: 'Note',
  [RESOURCE_UNCATEGORIZED]: 'Uncategorized',
}

export const RESOURCE_PATHNAME: Record<string, string> = {
  [RESOURCE_ACCOUNT]: PATHNAME.accounts,
  [RESOURCE_PROJECT]: PATHNAME.projects,
  [RESOURCE_SERVICE]: PATHNAME.services,
  [RESOURCE_QUOTE]: PATHNAME.quotes,
  [RESOURCE_INVOICE]: PATHNAME.invoices,
  [RESOURCE_OPPORTUNITY]: PATHNAME.opportunities,
  [RESOURCE_EXPENSE]: PATHNAME.expenses,
  [RESOURCE_ASSET]: PATHNAME.assets,
  [RESOURCE_PRODUCT]: PATHNAME.products,
  [RESOURCE_CONTRACT]: PATHNAME.contracts,
  [RESOURCE_CONTACT]: PATHNAME.contacts,
  [RESOURCE_PREVENTATIVE]: PATHNAME.preventatives,
  [RESOURCE_MONITORING]: PATHNAME.monitorings,
  [RESOURCE_USER]: PATHNAME.users,
  [RESOURCE_PLANSET]: PATHNAME['plan-set'],
  [RESOURCE_MEDIA]: PATHNAME.files,
  [RESOURCE_SETTING]: PATHNAME.settings,
  [RESOURCE_NOTE]: PATHNAME.notes,
  [RESOURCE_UNCATEGORIZED]: PATHNAME.uncategorized,
  [RESOURCE_TIMESHEET_ENTRY]: PATHNAME.timesheets,
}

// type Test = {
//   key: typeof RESOURCE_TYPE_LABEL[keyof typeof RESOURCE_TYPE_LABEL]
// }

export const mapResourceToOptionValue = (
  resource: TResource | null,
  resourceType: string,
) => {
  if (!resource) {
    return null
  }
  switch (resourceType) {
    case RESOURCE_ASSET: {
      const asset = resource as IAsset
      return {
        value: asset.id,
        label: asset.name,
      }
    }
    case RESOURCE_ACCOUNT: {
      const account = resource as IAccount
      return {
        value: account.id || null,
        label: renderAccountName(account),
      }
    }

    case RESOURCE_CONTACT: {
      const contact = resource as IContact
      return {
        value: contact.id || null,
        label: contact.name || '',
      }
    }
    case RESOURCE_USER: {
      const user = resource as IUser
      return {
        value: user.id || null,
        label: user.name,
      }
    }
    case RESOURCE_OPPORTUNITY: {
      const opportunity = resource as IOpportunity
      return {
        value: opportunity.id,
        label: opportunity.name,
      }
    }
    case RESOURCE_MONITORING: {
      const monitoring = resource as IMonitoring
      return {
        value: monitoring.id,
        label: monitoring.name,
      }
    }
    case RESOURCE_PROJECT: {
      const project = resource as IProject
      return {
        value: project.id,
        label: project.name,
      }
    }
    case RESOURCE_QUOTE: {
      const quote = resource as IQuote
      return {
        value: quote.id,
        label: quote.regarding ? quote.regarding : quote.quote_number + '',
      }
    }
    case RESOURCE_INVOICE: {
      const invoice = resource as IInvoice
      return {
        value: invoice.id,
        label: `${renderAccountName(invoice.account)} | ${
          invoice.invoice_number
        }`,
      }
    }
    case RESOURCE_CONTRACT: {
      const contract = resource as IContract
      return {
        value: contract.id,
        label: contract.contract_number ? contract.contract_number + '' : null,
      }
    }
    case RESOURCE_SERVICE: {
      const service = resource as IService
      return {
        value: service.id || null,
        label: service?.reason_for_visit
          ? renderTextForServiceSelect(service?.reason_for_visit)
          : service.id + '',
      }
    }
    case RESOURCE_PLANSET: {
      const planSet = resource as IPlanSet
      return {
        value: planSet.id,
        label: planSet.name,
      }
    }
    default: {
      return null
    }
  }
}

export const listResourcesSearch = [
  { value: 'all', label: 'All Resources' },
  { value: RESOURCE_NOTE, label: 'Note', icon: 'text' },
  { value: RESOURCE_ACCOUNT, label: 'Account', icon: 'happy_face' },
  { value: RESOURCE_OPPORTUNITY, label: 'Opportunity', icon: 'sparkle' },
  { value: RESOURCE_ASSET, label: 'Asset', icon: 'device' },
  { value: RESOURCE_PLANSET, label: 'Plan set', icon: 'map' },
  // { value: RESOURCE_ESTIMATION, label: 'Estimation', icon: 'clock' },
  { value: RESOURCE_PRODUCT, label: 'Product', icon: 'box' },
  { value: RESOURCE_EXPENSE, label: 'Expenses', icon: 'moneybag' },
  { value: RESOURCE_PROJECT, label: 'Project', icon: 'rocket' },
  { value: RESOURCE_MEDIA, label: 'File', icon: 'attachment' },
  { value: RESOURCE_QUOTE, label: 'Quote', icon: 'document' },
  { value: RESOURCE_INVOICE, label: 'Invoice', icon: 'money' },
  { value: RESOURCE_SERVICE, label: 'Service Ticket', icon: 'ticket' },
  // { value: RESOURCE_LEAD, label: 'Lead', icon: 'flag' },
  { value: 'setting_label', label: 'Setting', icon: 'setting' },
  { value: RESOURCE_CONTRACT, label: 'Maintenance Contract', icon: 'wrench' },
  { value: RESOURCE_USER, label: 'User', icon: 'user' },
  {
    value: RESOURCE_MONITORING,
    label: 'Monitoring',
    icon: 'view',
    iconClass: 'text-[0.625rem]',
  },
]

export const renderValueResource = (value?: string | number | null) =>
  value || '----'

export const mergeInboundEmail = (email?: string | null) => {
  const { configs } = useStore.getState()
  const inboundDomain = configs?.currentConfigs?.inbound_email_domain
    ? `@${configs?.currentConfigs?.inbound_email_domain}`
    : DOMAIN_INBOUND_EMAIL
  if (!email || email.trim() === '') {
    return ''
  }
  return email + inboundDomain
}

export const splitInbounEmail = (email?: string | null) => {
  if (!email || email.trim() === '') {
    return ''
  }
  const splitEmail = email.split('@')
  if (splitEmail.length === 0) {
    return ''
  }
  return splitEmail[0]
}
export const DOMAIN_INBOUND_EMAIL =
  '@' + process.env.REACT_APP_DOMAIN_INBOUND_EMAIL

export const renderResourceNameOnDetail = (
  label: string,
  account?: IAccount | null,
  linkToAccount = true,
) => {
  if (!account) {
    return label
  }
  if (linkToAccount) {
    return (
      <>
        <Link to={`/${PATHNAME.accounts}/${account.id}`}>
          {renderAccountName(account)}:{' '}
        </Link>
        <span>{label}</span>
      </>
    )
  }
  return `${account.full_name}: ${label}`
}

export const formatResourceID = (id: number, resourceType: string) => {
  switch (resourceType) {
    case RESOURCE_QUOTE: {
      return `Q-${id}`
    }
    case RESOURCE_SERVICE: {
      return `ST-${id}`
    }
    default: {
      return ''
    }
  }
}

export const copyLinkResource = (
  pathName: string,
  pathId?: number,
  message?: string,
) => {
  copyToClipBoard(pathResource(pathName, pathId))
  toast.success({
    title: message || 'Link copied',
  })
}

export const listSliderRecentLarge: Settings['responsive'] = [
  {
    breakpoint: 1920,
    settings: {
      slidesToShow: 4,
    },
  },
  {
    breakpoint: 1024,
    settings: {
      slidesToShow: 3,
    },
  },
  {
    breakpoint: 767,
    settings: {
      slidesToShow: 2,
    },
  },
  {
    breakpoint: 480,
    settings: {
      slidesToShow: 1,
    },
  },
]

export const listSliderRecentDefault: Settings['responsive'] = [
  {
    breakpoint: 1920,
    settings: {
      slidesToShow: 6,
    },
  },
  {
    breakpoint: 1280,
    settings: {
      slidesToShow: 4,
    },
  },
  {
    breakpoint: 1024,
    settings: {
      slidesToShow: 3,
    },
  },
  {
    breakpoint: 767,
    settings: {
      slidesToShow: 2,
    },
  },
  {
    breakpoint: 480,
    settings: {
      slidesToShow: 1,
    },
  },
]

export const isNewResource = (created_at: string | Date) => {
  const diffNewUpdateDate = differenceInCalendarDays(
    new Date(),
    new Date(created_at),
  )
  return diffNewUpdateDate <= 7
}
