import { omit } from 'lodash'
import {
  createContext,
  Dispatch,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from 'react'

import { useAsync } from '../../hooks'
import { useLoadFilters } from '../../hooks/useLoadFilters'
import { ResponseMeta } from '../../services/client'
import { addFavorite, removeFavorite } from '../../services/favorite'
import {
  getFiles,
  getFolders,
  IFile,
  IFilterFile,
  IFolder,
} from '../../services/files'
import { initFilterFile } from '../../utils/files'
import {
  filterCategoryHierarchy,
  findCategoryHierarchyById,
  flatHierarchyOfCategoryId,
} from '../../utils/functions'
import { RESOURCE_MEDIA } from '../../utils/resources'

export enum TABLE_TYPE {
  TABLE_FOLDER = 'folder',
  TABLE_FILE = 'file',
}

export const TABLE_FOLDER_SORT_TYPE = ['name', 'created_at']
interface State {
  listFiles: IFile[]
  detailFile: IFile | null
  deleteData: (IFile | IFolder)[] | null
  renameData: IFile | IFolder | null
  manageAccessFile: IFile | null
  isFirstLoad: boolean
  filterFiles: IFilterFile
  columnHide: string[]
  isSubPage: boolean
  viewType: 'grid' | 'list'
  selectedResourceType: string | null
  selectedResourceId: number | null
  selectedGlobal: boolean
  selectedFiles: IFile[]
  selectedFolders: IFolder[]
  isFetchingData: boolean
  isSelectFolderFromList: boolean
  openManageTag: boolean
  openDetailFromUrl: boolean
  openMoveFile: boolean
  refetchRecent: boolean
  listFolders: IFolder[]
  currentOpenFolder: IFolder | null
  listSubFolders: IFolder[]
  pagination?: ResponseMeta | null
  showFilterAllAccount: boolean
  isShowSideBar: boolean
  currentLevelFolders: IFolder[]
}

type THookFilter = IFilterFile & {
  viewType?: State['viewType']
  isShowSideBar?: boolean
}

type TContext = State & {
  dispatch: Dispatch<Partial<State>>
  onUpdateFile: (id: number, value: Partial<IFile>) => void
  onSelectFilters: (
    filter?: IFilterFile,
    onlyFetchFilter?: IFilterFile,
  ) => Promise<void>
  onFetchFiles: (filter?: IFilterFile) => Promise<void>
  onFetchFolders: () => Promise<void>
  onClearFilter: () => void
  onFavoriteFile: (id: number, currentFav: boolean) => void
  onOpenFolder: (folder: IFolder | null) => void
  setLocalFilters: (filter: THookFilter) => void
  resetLocalFilters: () => void
  loadingFetch: boolean
}

const initState: State = {
  isSubPage: false,
  listFiles: [],
  detailFile: null,
  deleteData: null,
  renameData: null,
  manageAccessFile: null,
  isFirstLoad: true,
  filterFiles: initFilterFile,
  columnHide: [],
  viewType: 'list',
  selectedResourceType: null,
  selectedResourceId: null,
  selectedGlobal: false,
  selectedFiles: [],
  selectedFolders: [],
  isFetchingData: true,
  isSelectFolderFromList: false,
  openManageTag: false,
  refetchRecent: false,
  openDetailFromUrl: false,
  openMoveFile: false,
  showFilterAllAccount: false,
  listFolders: [],
  listSubFolders: [],
  currentOpenFolder: null,
  isShowSideBar: true,
  currentLevelFolders: [],
}

export const FILE_SORTER = [
  { label: 'File name', value: 'name' },
  { label: 'File size', value: 'size' },
  { label: 'Upload date', value: 'created_at' },
]

const FileManagementContext = createContext<TContext | undefined>(undefined)

const FileManagementContextProvider = (props: {
  children?: ReactNode
  selectedResourceType?: string | null
  selectedResourceId?: number | null
  selectedGlobal?: boolean
  columnHide?: string[]
  isSubPage?: boolean
  showFilterAllAccount?: boolean
}) => {
  const favoriteSync = useAsync()
  const { localFilters, setLocalFilters, resetLocalFilters } =
    useLoadFilters<THookFilter>(RESOURCE_MEDIA, { disable: props.isSubPage })
  const viewTypeLocal = localFilters?.viewType || 'list'
  const otherLocalFilters = omit(localFilters, ['viewType', 'isShowSideBar'])
  const listAsync = useAsync({ status: 'pending', showNotifOnError: true })
  const [state, dispatch] = useReducer(
    (s: State, a: Partial<State>) => ({ ...s, ...a }),
    {
      ...initState,
      selectedResourceId: props.selectedResourceId || null,
      selectedResourceType: props.selectedResourceType || null,
      selectedGlobal: Boolean(props.selectedGlobal),
      filterFiles: {
        ...initFilterFile,
        ...otherLocalFilters,
        filterResourceId: props.selectedResourceId || null,
        filterResourceType: props.selectedResourceType || null,
        filterGlobal: Boolean(props.selectedGlobal),
      },
      columnHide: props?.columnHide || [],
      isSubPage: !!props.isSubPage,
      showFilterAllAccount: Boolean(props.showFilterAllAccount),
      viewType: viewTypeLocal,
      isShowSideBar: Boolean(localFilters?.isShowSideBar),
    },
  )

  const onUpdateFile = (fileId: number, value: Partial<IFile>) => {
    const { listFiles } = state
    const detailIndex = listFiles.findIndex(item => item.id === fileId)
    if (detailIndex >= 0) {
      const newFiles = [
        ...listFiles.slice(0, detailIndex),
        { ...listFiles[detailIndex], ...value },
        ...listFiles.slice(detailIndex + 1),
      ]
      dispatch({
        listFiles: newFiles,
      })
    }
  }

  const onSelectFilters = async (
    filter?: IFilterFile,
    filterOnlyFetch?: IFilterFile,
  ) => {
    setLocalFilters({
      ...filter,
    })
    await onFetchFiles({
      ...filter,
      ...filterOnlyFetch,
    })
  }
  const onFetchFiles = async (filter?: IFilterFile) => {
    const { filterFiles } = state
    const newFilter = {
      ...filterFiles,
      ...filter,
    }
    dispatch({
      filterFiles: newFilter,
    })
    const response = await listAsync.execute(getFiles({ ...newFilter }))
    dispatch({
      listFiles: response.data.data,
      isFirstLoad: false,
    })
  }

  const onClearFilter = () => {
    const { selectedResourceId, selectedResourceType } = state
    resetLocalFilters()
    onFetchFiles({
      ...initFilterFile,
      filterAllFileByAccountId: null,
      filterResourceId: selectedResourceId,
      filterResourceType: selectedResourceType,
    })
  }

  const onFavoriteFile = async (id: number, currentFav: boolean) => {
    const detailIndex = state.listFiles.findIndex(item => item.id === id)
    if (detailIndex >= 0) {
      await favoriteSync.execute(
        currentFav
          ? removeFavorite({ id, resource: 'media' })
          : addFavorite({ id, resource: 'media' }),
      )
      onUpdateFile(id, { is_favorited: !currentFav })
    }
  }

  const onFetchFolders = async () => {
    const result = await listAsync.execute(
      getFolders({
        filterResourceId: props.selectedResourceId,
        filterResourceType: props.selectedResourceType,
      }),
    )
    if (result.data.data) {
      const newListFolders = result.data.data as IFolder[]
      const newDataOpenFolder = state.currentOpenFolder
        ? findCategoryHierarchyById(newListFolders, state.currentOpenFolder.id)
        : null
      dispatch({
        listFolders: newListFolders,
        currentOpenFolder: newDataOpenFolder,
      })
    }
  }

  const currentLevelFolders = useMemo(() => {
    const filterRootCat = state.currentOpenFolder
      ? filterCategoryHierarchy(
          state.listFolders,
          undefined,
          undefined,
          state.currentOpenFolder?.id,
        )
      : []
    return state.currentOpenFolder && filterRootCat.length > 0
      ? flatHierarchyOfCategoryId(filterRootCat, state.currentOpenFolder.id)
      : []
  }, [state.listFolders, state.currentOpenFolder])

  useEffect(() => {
    const selectedFoldersParam = new URLSearchParams(
      window.location.search,
    ).get('folder')
    if (selectedFoldersParam && state.listFolders.length > 0) {
      const folderIds = selectedFoldersParam
        .split(',')
        .map(id => parseInt(id, 10))
      const selectedFolderId = folderIds[folderIds.length - 1]
      const selectedFolder = findCategoryHierarchyById(
        state.listFolders,
        selectedFolderId,
      )
      if (selectedFolder) {
        onOpenFolder(selectedFolder)
      }
    }
  }, [state.listFolders])
  useEffect(() => {
    dispatch({ currentLevelFolders })
    if (currentLevelFolders.length > 0) {
      const folderIds = currentLevelFolders.map(f => f.id).join(',')
      const newUrl = `${location.pathname}?folder=${folderIds}`
      history.replaceState({}, '', newUrl)
    }
  }, [currentLevelFolders, history, location.pathname])

  const onOpenFolder = async (folder: IFolder | null | undefined) => {
    const { selectedResourceId, selectedResourceType, currentOpenFolder } =
      state

    const isSelected = folder?.id === currentOpenFolder?.id
    const newCurrentFolder = folder
      ? findCategoryHierarchyById(state.listFolders, folder.id)
      : null
    const filterFolderKey = props.isSubPage
      ? 'filterResourceFolderId'
      : 'filterFolderId'

    await onFetchFiles({
      ...initFilterFile,
      filterResourceId: selectedResourceId,
      filterResourceType: selectedResourceType,
      [filterFolderKey]: isSelected ? null : folder?.id,
      filterAllFileByAccountId: null,
    })

    dispatch({
      selectedFiles: [],
      selectedFolders: [],
      currentOpenFolder: isSelected ? null : newCurrentFolder,
    })
  }

  return (
    <FileManagementContext.Provider
      value={{
        ...state,
        dispatch,
        onUpdateFile,
        onSelectFilters,
        onFetchFiles,
        loadingFetch: listAsync.isLoading,
        pagination: listAsync?.data?.data?.meta || null,
        onClearFilter,
        onFavoriteFile,
        onFetchFolders,
        onOpenFolder,
        resetLocalFilters,
        setLocalFilters,
      }}
    >
      {props.children}
    </FileManagementContext.Provider>
  )
}

const useFileManagementCtx = () => {
  const ctx = useContext(FileManagementContext)
  if (ctx === undefined) {
    throw Error('Invalid context call')
  }
  return ctx
}

export { FileManagementContextProvider as default, useFileManagementCtx }
