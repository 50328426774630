import { ModalProps } from '../../../../components'
import { ModalDelete } from '../../../../components/Modal/Delete'
import { useAsync } from '../../../../hooks'
import { deleteWhatsappMessage } from '../../../../services/whatsApp'

interface Props extends Omit<ModalProps, 'children'> {
  onSuccess?: () => void
  onClose: () => void
  deleteIds: number[]
}

export const ModalDeleteUncategorized = ({
  onClose,
  onSuccess,
  deleteIds,
  ...props
}: Props) => {
  const { execute, isLoading } = useAsync({
    showNotifOnError: true,
  })

  const handleDelete = async () => {
    for(const id of deleteIds){
      await execute(deleteWhatsappMessage(id))
    }
    onSuccess?.()
    onClose()    
  }

  return (
    <ModalDelete
      title={`Delete ${deleteIds.length > 1 ? 'these items' : 'this item'}`}
      onClose={onClose}
      onCancel={onClose}
      onSubmit={handleDelete}
      loading={isLoading}
      {...props}
    />
  )
}
