import { useState } from 'react'

import { SelectProps } from '../../../components'
import { AsyncSelect } from '../../../components/Forms/AsyncSelect'
import { getTags, ITag } from '../../../services/tags'
import { OptionValueTag } from '../../../utils/form'

interface Props
  extends Omit<SelectProps, 'options' | 'onSelect' | 'onChange' | 'fetcher'> {
  tags?: OptionValueTag[] | null
  maxTag?: number
  maxTagLength?: number
  onChangeTags?: (tags: OptionValueTag[] | null) => void
}
export const TagsSelect = ({
  tags,
  onChangeTags,
  maxTag = 5,
  maxTagLength = 40,
  ...props
}: Props) => {
  const [selectedTags, setSelectedTags] = useState<OptionValueTag[] | null>(
    tags || null,
  )

  const handleChange = (v: OptionValueTag[]) => {
    const currentLength = tags ? tags.length : 0
    if (v.length > maxTag && v.length > currentLength) {
      return
    }
    const last = v?.at(-1)?.value || ''
    if (last?.length > maxTagLength) {
      return
    }
    setSelectedTags(v)
    onChangeTags?.(v)
  }

  const handleGetTags = async ({ search = '', page = 1 } = {}) => {
    try {
      const { data } = await getTags({ search, currentPage: page })
      return { data: data.data, totalPage: data.meta.last_page }
    } catch {
      return { data: [], totalPage: 1 }
    }
  }

  return (
    <AsyncSelect
      showArrow={false}
      animation={undefined}
      notFoundContent={null}
      mode='tags'
      labelInValue
      onChange={handleChange}
      value={selectedTags || undefined}
      className='select-tags'
      fetcher={handleGetTags}
      fetchOnFirst={false}
      {...props}
    >
      {(data: ITag[]) => {
        return data.map(item => (
          <AsyncSelect.Option key={item.id} value={item.name}>
            {item.name}
          </AsyncSelect.Option>
        ))
      }}
    </AsyncSelect>
  )
}
