import cx from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import { CSSProperties, Fragment, ReactNode, useState } from 'react'
import { PopperProps, usePopper } from 'react-popper'

import { Overlay, Portal } from '../index'
import { PopoverContent } from './PopoverContent'

interface Props {
  visible?: boolean
  style?: CSSProperties
  content: ((onClose: () => void) => ReactNode) | ReactNode
  className?: string
  popupClassName?: string
  popupContainer?: () => HTMLElement
  onVisibleChange?: (visible: boolean) => void
  placement?: PopperProps<any>['placement']
  zIndex?: number
  children?: React.ReactNode
}

export const getTransformOrigin = (
  placement: PopperProps<any>['placement'],
) => {
  if (placement === 'top') {
    return '50% 100%'
  }
  if (placement === 'bottom') {
    return '50% 0%'
  }
  if (placement === 'bottom-start') {
    return '0% 0%'
  }
  if (placement === 'bottom-end') {
    return '100% 0%'
  }
  if (placement === 'right-start') {
    return '0% 0%'
  }
  if (placement === 'right-end') {
    return '0% 100%'
  }
  if (placement === 'left') {
    return '100% 50%'
  }
  if (placement === 'left-start') {
    return '100% 0%'
  }
  return '50% 50%'
}

const Popover = ({
  visible: propVisible,
  style,
  content,
  className,
  popupClassName,
  popupContainer,
  onVisibleChange,
  placement = 'bottom',
  zIndex,
  children,
}: Props) => {
  const [visible, setVisible] = useState<boolean>(propVisible || false)
  const [refElement, setRefElement] = useState<HTMLElement | null>(null)
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null)

  const { styles, attributes } = usePopper(refElement, popperElement, {
    placement,
  })

  const toggleVisible = (v: boolean) => {
    setVisible(v)
    onVisibleChange?.(v)
  }

  const onClose = () => toggleVisible(false)

  return (
    <Fragment>
      <div
        ref={setRefElement}
        className={cx(className)}
        onClick={e => {
          e.preventDefault()
          e.stopPropagation()
          toggleVisible(true)
        }}
        style={style}
      >
        {children}
      </div>
      <AnimatePresence initial={false}>
        {visible && (
          <Portal popupContainer={popupContainer}>
            <Overlay className='!bg-transparent' />
            <div
              ref={setPopperElement}
              className={cx(popupClassName, 'p-2 z-popover')}
              style={{ ...styles.popper, zIndex }}
              {...attributes.popper}
            >
              <motion.div
                initial={{ scale: 0, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0, opacity: 0 }}
                transition={{ duration: 0.2 }}
                style={{
                  transformOrigin: getTransformOrigin(
                    attributes?.popper?.[
                      'data-popper-placement'
                    ] as typeof placement,
                  ),
                }}
              >
                {typeof content === 'function' ? (
                  content(onClose)
                ) : (
                  <PopoverContent children={content} onClose={onClose} />
                )}
              </motion.div>
            </div>
          </Portal>
        )}
      </AnimatePresence>
    </Fragment>
  )
}

Popover.Content = PopoverContent
export { Popover }
