import { SVGProps } from 'react'

export const InfoCircleFilled = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      xmlns='http://www.w3.org/2000/svg'
      strokeWidth='0'
      stroke='currentColor'
      fill='currentColor'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.87467 0C3.526 0 0 3.526 0 7.87467C0 12.2233 3.526 15.75 7.87467 15.75C12.2233 15.75 15.75 12.2233 15.75 7.87467C15.75 3.526 12.2233 0 7.87467 0ZM9.514 12.2047C9.10867 12.3647 8.786 12.486 8.544 12.57C8.30267 12.654 8.022 12.696 7.70267 12.696C7.212 12.696 6.83 12.576 6.558 12.3367C6.286 12.0973 6.15067 11.794 6.15067 11.4253C6.15067 11.282 6.16067 11.1353 6.18067 10.986C6.20133 10.8367 6.234 10.6687 6.27867 10.48L6.786 8.688C6.83067 8.516 6.86933 8.35267 6.9 8.20067C6.93067 8.04733 6.94533 7.90667 6.94533 7.77867C6.94533 7.55067 6.898 7.39067 6.804 7.30067C6.70867 7.21067 6.52933 7.16667 6.262 7.16667C6.13133 7.16667 5.99667 7.186 5.85867 7.22667C5.722 7.26867 5.60333 7.30667 5.506 7.344L5.64 6.792C5.972 6.65667 6.29 6.54067 6.59333 6.44467C6.89667 6.34733 7.18333 6.29933 7.45333 6.29933C7.94067 6.29933 8.31667 6.418 8.58133 6.65267C8.84467 6.888 8.97733 7.194 8.97733 7.57C8.97733 7.648 8.968 7.78533 8.95 7.98133C8.932 8.178 8.898 8.35733 8.84867 8.522L8.344 10.3087C8.30267 10.452 8.266 10.616 8.23267 10.7993C8.2 10.9827 8.184 11.1227 8.184 11.2167C8.184 11.454 8.23667 11.616 8.34333 11.702C8.44867 11.788 8.63333 11.8313 8.89467 11.8313C9.018 11.8313 9.156 11.8093 9.312 11.7667C9.46667 11.724 9.57867 11.686 9.64933 11.6533L9.514 12.2047ZM8.57467 5.28067C8.906 5.28067 9.18933 5.17133 9.42467 4.95267C9.66 4.734 9.778 4.46867 9.778 4.15733C9.778 3.84733 9.66 3.58067 9.42467 3.36C9.19 3.13867 8.906 3.02867 8.57467 3.02867C8.244 3.02867 7.95867 3.13867 7.72133 3.36C7.486 3.58067 7.366 3.84733 7.366 4.15733C7.366 4.468 7.48533 4.734 7.72133 4.95267C7.95867 5.17133 8.244 5.28067 8.57467 5.28067Z'
        fill='currentColor'
      />
      <mask
        id='mask0_8089_21497'
        style={{ maskType: 'alpha' }}
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='16'
        height='16'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M7.87467 0C3.526 0 0 3.526 0 7.87467C0 12.2233 3.526 15.75 7.87467 15.75C12.2233 15.75 15.75 12.2233 15.75 7.87467C15.75 3.526 12.2233 0 7.87467 0ZM9.514 12.2047C9.10867 12.3647 8.786 12.486 8.544 12.57C8.30267 12.654 8.022 12.696 7.70267 12.696C7.212 12.696 6.83 12.576 6.558 12.3367C6.286 12.0973 6.15067 11.794 6.15067 11.4253C6.15067 11.282 6.16067 11.1353 6.18067 10.986C6.20133 10.8367 6.234 10.6687 6.27867 10.48L6.786 8.688C6.83067 8.516 6.86933 8.35267 6.9 8.20067C6.93067 8.04733 6.94533 7.90667 6.94533 7.77867C6.94533 7.55067 6.898 7.39067 6.804 7.30067C6.70867 7.21067 6.52933 7.16667 6.262 7.16667C6.13133 7.16667 5.99667 7.186 5.85867 7.22667C5.722 7.26867 5.60333 7.30667 5.506 7.344L5.64 6.792C5.972 6.65667 6.29 6.54067 6.59333 6.44467C6.89667 6.34733 7.18333 6.29933 7.45333 6.29933C7.94067 6.29933 8.31667 6.418 8.58133 6.65267C8.84467 6.888 8.97733 7.194 8.97733 7.57C8.97733 7.648 8.968 7.78533 8.95 7.98133C8.932 8.178 8.898 8.35733 8.84867 8.522L8.344 10.3087C8.30267 10.452 8.266 10.616 8.23267 10.7993C8.2 10.9827 8.184 11.1227 8.184 11.2167C8.184 11.454 8.23667 11.616 8.34333 11.702C8.44867 11.788 8.63333 11.8313 8.89467 11.8313C9.018 11.8313 9.156 11.8093 9.312 11.7667C9.46667 11.724 9.57867 11.686 9.64933 11.6533L9.514 12.2047ZM8.57467 5.28067C8.906 5.28067 9.18933 5.17133 9.42467 4.95267C9.66 4.734 9.778 4.46867 9.778 4.15733C9.778 3.84733 9.66 3.58067 9.42467 3.36C9.19 3.13867 8.906 3.02867 8.57467 3.02867C8.244 3.02867 7.95867 3.13867 7.72133 3.36C7.486 3.58067 7.366 3.84733 7.366 4.15733C7.366 4.468 7.48533 4.734 7.72133 4.95267C7.95867 5.17133 8.244 5.28067 8.57467 5.28067Z'
          fill='currentColor'
        />
      </mask>
      <g mask='url(#mask0_8089_21497)'>
        <rect width='16' height='16' fill='currentColor' />
      </g>
    </svg>
  )
}
