import { useEffect, useState } from 'react'

import ImgThumbnail from '../../../../assets/images/img_thumbnail.svg'
import ImgThumbnailMusic from '../../../../assets/images/img_thumbnail_music.svg'
import { getFileType } from '../../../../utils/files'

export const UploadedThumbnail = ({ file }: { file: File }) => {
  const renderNoThumbnail = () => (
    <div className='bg-separation-200 h-full flex justify-center items-center rounded-t-lg'>
      <div className='text-center'>
        <img
          className='w-8 h-8 mx-auto'
          src={ImgThumbnail}
          alt='no thumbnail'
        />
        <span className='text-black-400 text-body'>No thumbnail</span>
      </div>
    </div>
  )
  const renderThumbnail = () => {
    const fileType = getFileType(file.type)
    switch (fileType) {
      case 'video': {
        return <VideoThumbnail file={file} />
      }
      case 'audio': {
        return <AudioThumbnail />
      }
      case 'image': {
        return (
          <img
            className='w-full block rounded-t-lg h-full object-contain'
            src={URL.createObjectURL(file)}
            alt={file.name || 'image'}
          />
        )
      }
      default: {
        return renderNoThumbnail()
      }
    }
  }
  return (
    <div className='h-44 bg-separation-200 rounded-t-lg'>
      {renderThumbnail()}
    </div>
  )
}

export const AudioThumbnail = () => {
  return (
    <div className='w-full h-full relative rounded-t-lg'>
      <img
        src={ImgThumbnailMusic}
        className='h-full w-full object-cover rounded-t-lg block'
        alt='thumbnail music'
      />
      <div className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-10 h-10'>
        <div className='bg-white rounded-full shadow-card block text-center'>
          <span className='font-icon-sort_up rotate-90 text-black text-[0.625rem] block leading-10' />
        </div>
      </div>
    </div>
  )
}

export const VideoThumbnail = ({ file }: { file: File }) => {
  const [url, setUrl] = useState<string | null>(null)
  useEffect(() => {
    setUrl(URL.createObjectURL(file))
  }, [])
  return (
    <div className='w-full h-full relative'>
      {url && (
        <video
          src={url}
          controls={false}
          className='w-full h-full object-cover rounded-t-md'
        />
      )}
      <div className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-10 h-10'>
        <div className='bg-white rounded-full shadow-card block text-center'>
          <span className='font-icon-sort_up rotate-90 text-black text-[0.625rem] block leading-10' />
        </div>
      </div>
    </div>
  )
}
