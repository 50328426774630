import { StoreApi } from 'zustand'

import { IPermisions } from '../services/permissions'
import type { Store } from './index'

export interface PermissionsSlice {
  permissions: IPermisions[]
  setPermissions: (payload: IPermisions[]) => void
}

const slice = (set: StoreApi<Store>['setState']) => ({
  permissions: [],
  setPermissions: (payload: IPermisions[]) => {
    return set(({ permissions }) => ({
      permissions: { ...permissions, permissions: payload },
    }))
  },
})

export default slice
