import orderBy from 'lodash/orderBy'
import { useEffect, useState } from 'react'
import shallow from 'zustand/shallow'

import { Select, SelectProps } from '../../../components'
import { Spinner } from '../../../components/Spinner'
import { PATHNAME } from '../../../configs/routes'
import { useAsync } from '../../../hooks'
import { usePermission } from '../../../hooks/usePermission'
import { editProject, IProject } from '../../../services/projects'
import useStore, { Store } from '../../../store'
import { OptionValue } from '../../../utils/form'
import { PERMISSIONS } from '../../../utils/permission'
import toast from '../../../utils/toast'

interface Props extends Omit<SelectProps, 'options'> {
  selected?: OptionValue | null
  projectId?: number | null
  onSelect?: (option: OptionValue | null) => void
  onUpdateComplete?: (data: IProject) => void
}

const mapState = (state: Store) => ({
  project_statuses: state.settings?.project?.project_statuses,
})

export const ProjectStatusSelect = ({
  selected = null,
  projectId = null,
  onSelect,
  onUpdateComplete,
  ...props
}: Props) => {
  const { project_statuses } = useStore(mapState, shallow)
  const [currentSelected, setSelected] = useState<OptionValue | null>(selected)
  const { isLoading, execute } = useAsync({ showNotifOnError: true })
  const { canUpdate } = usePermission({ name: PERMISSIONS.PROJECT })

  const handleChangeForm = async (value: OptionValue) => {
    setSelected(value)
    if (projectId && value) {
      const response = await execute(
        editProject(projectId, {
          status: value?.label || '',
          status_id: value?.value || 0,
        }),
      )
      onUpdateComplete?.(response.data.data)
      toast.success({
        title: `Project "${response.data.data.name}" updated`,
        path: `/${PATHNAME.projects}/${projectId}`,
      })
    }
  }

  const handleSelect = (value: OptionValue | null) => {
    setSelected(value)
    onSelect?.(value)
  }
  if (!project_statuses) {
    return null
  }

  const mapProjects = orderBy(project_statuses, ['label'], ['asc']).map(
    ({ id, label }) => ({
      value: id,
      label,
    }),
  )

  const customProps = projectId
    ? { ...props, onChange: (value: OptionValue) => handleChangeForm(value) }
    : { ...props }

  useEffect(() => {
    if (
      selected !== currentSelected ||
      selected?.label !== currentSelected?.label ||
      selected?.value !== currentSelected?.value
    ) {
      setSelected(selected)
    }
  }, [selected])
  if (!canUpdate && projectId) {
    return <div className='relative'>{selected?.label}</div>
  }
  return (
    <div className='relative project-status'>
      <Select
        {...customProps}
        value={currentSelected}
        options={mapProjects}
        labelInValue
        onSelect={
          onSelect ? (value: OptionValue) => handleSelect(value) : undefined
        }
        onDeselect={onSelect ? () => handleSelect(null) : undefined}
      />
      {isLoading && (
        <>
          <div className='absolute inset-0 bg-white opacity-60 w-full h-full z-10' />
          <div className='absolute top-1/2 right-2 z-20 -translate-y-1/2'>
            <Spinner size='xsmall' />
          </div>
        </>
      )}
    </div>
  )
}
