import { FieldData } from 'rc-field-form/es/interface'
import { useEffect, useState } from 'react'
import shallow from 'zustand/shallow'

import { Form, Modal, ModalProps } from '../../../../components'
import { Spinner } from '../../../../components/Spinner'
import { useAsync } from '../../../../hooks'
import { deleteFile } from '../../../../services/files'
import { ICompanySettings } from '../../../../services/settings'
import {
  addUserAvatar,
  detailUser,
  editUser,
  IUser,
} from '../../../../services/users'
import useStore, { Store } from '../../../../store'
import { getFileByCollectionName } from '../../../../utils/files'
import { BasicForm } from './Form/Basic'
import { ExtraForm } from './Form/Extra'
import { FormProfile, generateDataSubmit, mapDataForForm } from './FormHelper'
import { ProfileHeader } from './Header'

interface Props extends Omit<ModalProps, 'children'> {
  onSuccess?: (data: ICompanySettings) => void
  onClose: () => void
  company?: ICompanySettings | null
  userId: number
}

const mapState = (state: Store) => ({
  auth: state.auth.currentUser,
  setAuthUser: state.auth.setAuthUser,
})

export const ModalMyProfile = ({
  onClose,
  onSuccess,
  userId,
  ...props
}: Props) => {
  const { setAuthUser, auth } = useStore(mapState, shallow)
  const [currentUser, setCurrentUser] = useState<IUser | null>(null)
  const [form] = Form.useForm()
  const [formValues, setFormValues] = useState<FormProfile>({
    isEditMode: false,
  })
  const editAsync = useAsync({
    showNotifOnError: true,
  })
  const detailAsync = useAsync({ showNotifOnError: true })

  const handleSubmit = async (v: FormProfile) => {
    const data = generateDataSubmit(v)
    let result = await editAsync.execute(editUser(userId, data))
    if (formValues.avatar) {
      result = await editAsync.execute(
        addUserAvatar(userId, { avatar: formValues.avatar }),
      )
    } else {
      if (currentUser?.avatar_preview_url && !formValues.avatar_preview_url) {
        const fileAvatar = getFileByCollectionName(
          currentUser?.files || [],
          'avatars',
        )
        await editAsync.execute(deleteFile(fileAvatar[0].id))
      }
    }
    setAuthUser({
      ...auth,
      ...result.data.data,
    })
    handleChangeValues({
      isEditMode: false,
      avatar_preview_url:
        result?.data?.data?.avatar_preview_url || formValues.avatar_preview_url,
    })
  }

  const handleFieldChange = (field: FieldData[]) => {
    const name = (field[0].name as string[])[0] as keyof FormProfile
    setFormValues({ ...formValues, [name]: field[0].value })
  }

  const handleChangeValues = (value: Partial<FormProfile>) => {
    setFormValues({ ...formValues, ...value })
    form.setFieldsValue(value)
  }

  const handleChangeAddress = (field: FieldData[]) => {
    let newData = {}
    field.forEach(f => {
      const fName = f.name as string
      newData = {
        ...newData,
        [fName]: f.value,
      }
    })
    setFormValues(prev => ({
      ...prev,
      ...newData,
    }))
    form.setFieldsValue({
      ...newData,
    })
  }

  const initData = async () => {
    const { data } = await detailAsync.execute(detailUser(userId))
    const mapData = mapDataForForm(data.data)
    setFormValues(mapData)
    setCurrentUser(data.data)
    form.setFieldsValue(mapData)
  }

  useEffect(() => {
    initData()
  }, [])

  return (
    <Modal
      {...props}
      className='max-w-[36.25rem] w-full p-0 bg-transparent'
      onClose={onClose}
    >
      <div className='flex relative flex-col pt-[3.5rem]'>
        <span
          className='cursor-pointer font-icon-close text-black-400 absolute -right-2 -top-2 z-10 w-10 h-10 flex justify-center items-center'
          onClick={onClose}
        />
        <div className='bg-header-modal-profile h-[4.5rem] w-full rounded-t-lg absolute top-0 left-0 right-0 z-10' />
        <div className='flex-1 pt-0 bg-white rounded-b-lg'>
          <Form
            form={form}
            className='flex-1 w-full h-full'
            onFieldsChange={handleFieldChange}
            onFinish={() => handleSubmit(formValues)}
          >
            <div className='relative z-20'>
              {detailAsync.isLoading && (
                <div className='absolute inset-0 z-40 bg-white/50'>
                  <Spinner size='small' />
                </div>
              )}
              <div>
                <ProfileHeader
                  formValues={formValues}
                  onChangeValues={handleChangeValues}
                  isLoading={editAsync.isLoading}
                />
                <div className='max-h-[calc(100vh_-_12rem)] custom-scrollbar overflow-y-auto px-4'>
                  <BasicForm
                    formValues={formValues}
                    onChangeValues={handleChangeValues}
                  />
                  <ExtraForm
                    formValues={formValues}
                    onChangeValues={handleChangeValues}
                    onChangeAddress={handleChangeAddress}
                  />
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </Modal>
  )
}
