import { AsyncSelect } from '../../../components/Forms/AsyncSelect'
import { Props as SelectProps } from '../../../components/Forms/Select'
import { OptionValue } from '../../../utils/form'
import {
  IBasicExportItem,
  IFilterItem,
} from '../../Modals/GenericExport/ExportHelper'
import { ResourceThumbnail } from '../../Resources/ResourceThumbnail'

interface Props<T> extends Omit<SelectProps, 'options' | 'onSelect'> {
  selected?: OptionValue | null
  onSelect?: (value: OptionValue | null, valueFull?: T | null) => void
  className?: string
  excludesValues?: number[]
  filter?: IFilterItem | null
  handleGetData: (params?: {
    search: string
    page: number
  }) => Promise<{ data: T[]; totalPage: number }>
  exportFieldType: string
  infoFieldsToLoad: Array<keyof T>
  titleKey: keyof T
  getFieldValue: (item: T, key: keyof T) => string | number
  getThumbnail?: (item: T) => string
}

export const GenericExportSelect = <T extends IBasicExportItem>({
  selected = null,
  className = '',
  onSelect,
  excludesValues = [],
  filter = null,
  handleGetData,
  exportFieldType,
  infoFieldsToLoad,
  titleKey,
  getFieldValue,
  getThumbnail,
  ...props
}: Props<T>) => {
  const handleSelect = (item: any, itemFull: T) => {
    const value = !!item
      ? {
          value: +item.value,
          label: getFieldValue(item, titleKey)?.toString() || '',
        }
      : null
    onSelect?.(value, itemFull)
  }

  const renderThumbnail = (item: T): JSX.Element | undefined => {
    if (getThumbnail) {
      return <ResourceThumbnail thumbnail={getThumbnail(item)} />
    }
  }
  const renderTitle = (item: T): JSX.Element | undefined => {
    return <p className='mb-0'>{getFieldValue(item, titleKey)}</p>
  }

  return (
    <AsyncSelect
      placeholder={`All ${exportFieldType}`}
      labelInValue
      allowClear
      dropdownClassName='!w-[25rem]'
      placement='bottomRight'
      {...props}
      value={
        selected?.value
          ? { value: selected.value + '', label: selected.label }
          : null
      }
      onChange={handleSelect}
      className={className}
      fetcher={handleGetData}
      animation={undefined}
    >
      {(data: T[]) => {
        return data.map(item =>
          !excludesValues.includes(item.id) ? (
            <AsyncSelect.Option key={item.id}>
              <div className='flex-1 inline-flex gap-2 items-center'>
                {renderThumbnail(item)}
                <div className='text-body flex-1'>
                  {renderTitle(item)}
                  <div className='inline-flex gap-2 items-center text-black-400'>
                    {infoFieldsToLoad.map(
                      (fieldName, i) =>
                        item[fieldName] && (
                          <div
                            key={item.id.toString() + fieldName.toString()}
                            className='flex gap-2'
                          >
                            <span>{item[fieldName]}</span>
                            {i < infoFieldsToLoad.length - 1 && (
                              <div className='w-px h-4 bg-black-400' />
                            )}
                          </div>
                        ),
                    )}
                  </div>
                </div>
              </div>
            </AsyncSelect.Option>
          ) : null,
        )
      }}
    </AsyncSelect>
  )
}
