import {
  Props as PropsLink,
  ResourceLink,
} from '../../../Resources/ResourceLink'

export const CustomResourceLink = ({
  children,
  onClick,
  ...props
}: PropsLink) => {
  const handleClick = () => {
    const btnSearch = document.getElementById('btn-close-search')
    if (btnSearch) {
      btnSearch.click()
    }
  }
  return (
    <ResourceLink onClick={handleClick} {...props}>
      {children}
    </ResourceLink>
  )
}
