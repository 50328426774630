import { useEffect, useState } from 'react'

import { Select, SelectProps } from '../../components'
import { OptionValue } from '../../utils/form'

interface Props extends Omit<SelectProps, 'options'> {
  selected?: OptionValue | null
  onSelect?: (option: OptionValue | null) => void
  className?: string
  list: OptionValue[]
}

export const DefaultSelect = ({
  selected = null,
  className = '',
  onSelect,
  list = [],
  ...props
}: Props) => {
  const [currentSelected, setSelected] = useState<OptionValue | null>(selected)
  const handleSelect = (item: any) => {
    const value = !!item
      ? {
          value: item.value,
          label: item.label,
        }
      : null
    setSelected(value)
    onSelect?.(value)
  }

  useEffect(() => {
    if (
      selected !== currentSelected ||
      selected?.label !== currentSelected?.label ||
      selected?.value !== currentSelected?.value
    ) {
      setSelected(selected)
    }
  }, [selected])

  if (list.length === 0) {
    return null
  }
  return (
    <Select
      className='w-full capitalize cursor-pointer'
      placeholder='All values'
      labelInValue
      onChange={handleSelect}
      {...props}
      value={currentSelected}
    >
      {list.map(item => (
        <Select.Option
          value={item.value}
          className='capitalize'
          key={item.value}
        >
          {item.label}
        </Select.Option>
      ))}
    </Select>
  )
}
