import {
  createContext,
  Dispatch,
  ReactNode,
  useContext,
  useReducer,
} from 'react'

import { useAsync } from '../../../../hooks'
import { IFilter } from '../../../../services/client'
import { getFiles, IFile } from '../../../../services/files'
import { isMobile } from '../../../../utils/functions'

interface State {
  file: IFile
  listRelatedFiles: IFile[]
  currentIndex: number
  currentPage: number
  lastPage: number
  openEditTag: boolean
  openRenameFile: boolean
  openManageAccess: boolean
  openDeleteFile: boolean
  toggleInfo: boolean
  toggleNote: boolean
  filterFiles?: IFilter | null
}

type TContext = State & {
  dispatch: Dispatch<Partial<State>>
  onPrevFile: () => void
  onNextFile: () => void
  onUpdateFile: (data: Partial<IFile>) => void
}

const FileDetailContext = createContext<TContext | undefined>(undefined)

const FileDetailContextProvider = (props: {
  children?: ReactNode
  selectedFile: IFile
  listRelatedFiles: IFile[]
  filterFiles?: IFilter | null
  lastPage?: number
  currentPage?: number
  fnUpdateFile?: (data: IFile) => void
}) => {
  const isOnMobile = isMobile() || window.innerWidth < 768
  const fileIndex = props.listRelatedFiles?.findIndex(
    item => item.id === props.selectedFile.id,
  )
  const listAsync = useAsync({ showNotifOnError: true })
  const [state, dispatch] = useReducer(
    (s: State, a: Partial<State>) => ({ ...s, ...a }),
    {
      file: props.selectedFile,
      listRelatedFiles: props.listRelatedFiles,
      currentPage: props?.currentPage || 1,
      openEditTag: false,
      toggleInfo: !isOnMobile,
      toggleNote: false,
      openDeleteFile: false,
      openRenameFile: false,
      openManageAccess: false,
      currentIndex: fileIndex || 0,
      lastPage: props?.lastPage || 1,
      filterFiles: props.filterFiles || {},
    },
  )

  const onFetchFiles = async (filter: IFilter) => {
    return await listAsync.execute(
      getFiles({ ...state.filterFiles, ...filter }),
    )
  }

  const onUpdateFile = (data: Partial<IFile>) => {
    const newData = {
      ...state.file,
      ...data,
    }
    dispatch({
      file: newData,
    })
    if (props.currentPage === state.currentPage) {
      props?.fnUpdateFile?.(newData)
    }
  }

  const onPrevFile = async () => {
    const { currentIndex, listRelatedFiles, currentPage } = state
    const newIndex = currentIndex - 1
    const newFile = listRelatedFiles?.[newIndex]
    if (newIndex >= 0) {
      dispatch({
        currentIndex: newIndex,
        file: newFile,
      })
    } else {
      if (currentPage > 1) {
        const newPage = currentPage - 1
        const result = await onFetchFiles({ currentPage: newPage })
        const newList = result.data.data as IFile[]
        const newCurrentIndex = newList.length - 1
        dispatch({
          currentPage: newPage,
          currentIndex: newCurrentIndex,
          listRelatedFiles: newList,
          file: newList[newCurrentIndex],
        })
      }
    }
  }

  const onNextFile = async () => {
    const { currentIndex, listRelatedFiles, currentPage, lastPage } = state
    const newIndex = currentIndex + 1
    const newFile = listRelatedFiles?.[newIndex]
    const total = listRelatedFiles?.length || 0
    if (newIndex < total) {
      dispatch({
        currentIndex: newIndex,
        file: newFile,
      })
    } else {
      if (currentPage < lastPage) {
        const result = await onFetchFiles({ currentPage: currentPage + 1 })
        dispatch({
          currentIndex: 0,
          currentPage: currentPage + 1,
          listRelatedFiles: result.data.data,
          file: result.data.data?.[0],
        })
      }
    }
  }

  return (
    <FileDetailContext.Provider
      value={{
        ...state,
        dispatch,
        onPrevFile,
        onNextFile,
        onUpdateFile,
      }}
    >
      {props.children}
    </FileDetailContext.Provider>
  )
}

const useFileDetailCtx = () => {
  const ctx = useContext(FileDetailContext)
  if (ctx === undefined) {
    throw Error('Invalid context call')
  }
  return ctx
}

export { FileDetailContextProvider as default, useFileDetailCtx }
