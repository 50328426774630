import cx from 'classnames'

import { Select, SelectProps } from '../../../components'
import { FILE_FORMAT_ICON, FILE_FORMAT_SELECT } from '../../../utils/files'

interface Props extends Omit<SelectProps, 'options'> {
  selected?: string | null
  onSelect?: (v: string | null) => void
}
export const FileFormatsSelect = ({
  onSelect,
  selected,
  className = '',
  ...props
}: Props) => {
  return (
    <div className='relative text-body'>
      <Select
        allowClear
        placeholder='All File Formats'
        optionLabelProp='label'
        className={cx('text-body', className)}
        {...props}
        value={selected}
        onChange={onSelect}
      >
        {FILE_FORMAT_SELECT.map(s => (
          <Select.Option key={s.value} label={s.label}>
            <div className='inline-flex gap-2 items-center'>
              <span>{FILE_FORMAT_ICON[s.name]}</span>
              <span>{s.label}</span>
            </div>
          </Select.Option>
        ))}
      </Select>
    </div>
  )
}
