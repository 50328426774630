import cx from 'classnames'

import { Popover } from '../../../components'
import { listResourcesSearch } from '../../../utils/resources'

interface Props {
  onClose: () => void
  selected: string
  onSelect: (s: string) => void
}

export const PopoverGlobalSearch = ({ selected, onSelect, onClose }: Props) => {
  return (
    <Popover.Content
      onClose={onClose}
      className='w-[30rem] py-3 px-2.5 shadow-dropdown'
    >
      <div className='flex flex-wrap'>
        {listResourcesSearch.map(item => {
          const isSelected = selected === item.value
          return (
            <div className='px-0.5 w-1/2' key={item.value}>
              <div
                className={cx(
                  'px-3 py-4 flex rounded-lg group hover:bg-primary-300 hover:cursor-pointer items-center relative',
                  isSelected &&
                    'bg-primary-300 text-primary-900 font-medium pointer-events-none',
                )}
                onClick={() => {
                  onSelect(item.value)
                  onClose()
                }}
              >
                {item.icon && (
                  <span
                    className={cx(
                      `font-icon-${item.icon} w-6 group-hover:text-primary-900`,
                      isSelected && 'text-primary-900',
                      item.iconClass,
                    )}
                  />
                )}
                <span className='group-hover:text-primary-900 group-hover:font-medium text-body'>
                  {item.label}
                </span>
                {isSelected && (
                  <span className='font-icon-check text-primary-900 absolute top-1/2 right-4 -translate-y-1/2 text-[0.75rem]' />
                )}
              </div>
            </div>
          )
        })}
      </div>
    </Popover.Content>
  )
}
