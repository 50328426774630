import { DragSourceMonitor, useDrag } from 'react-dnd'

import { DRAG_TYPE_MENU, IItemDrag } from '../utils/menus'

type TDragSort<T> = {
  item?: T
  dragType?: string
  onDragEnd?: () => void
}

export const useDragMenu = <T extends IItemDrag>({
  item,
  dragType = DRAG_TYPE_MENU,
  onDragEnd,
}: TDragSort<T>) => {
  const [{ isDragging }, drag, preview] = useDrag({
    type: dragType,
    item,
    end: onDragEnd,
    collect: (monitor: DragSourceMonitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  return {
    isDragging,
    drag,
    preview,
  }
}
