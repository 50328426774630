import defaultCountries from '../../../assets/countries.json'
import { Props as SelectProps, Select } from '../../../components/Forms/Select'

interface Props extends Omit<SelectProps, 'options'> {
  selected?: string | null
  onSelect?: (value: string) => void
  className?: string
}

const countryAddress = defaultCountries.filter(item => item.code !== 'ZZ')

export const CountriesSelect = ({ value, onSelect, ...props }: Props) => (
  <Select
    className='w-full capitalize cursor-pointer'
    showSearch
    {...props}
    value={!!value ? value : undefined}
    onChange={(e, option) => {
      props?.onChange?.(e, option)
      onSelect?.(e)
    }}
  >
    {countryAddress.map(item => (
      <Select.Option value={item.name} className='capitalize' key={item.name}>
        {item.name}
      </Select.Option>
    ))}
  </Select>
)
