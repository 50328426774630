import { orderBy } from 'lodash'
import { useEffect, useState } from 'react'
import shallow from 'zustand/shallow'

import { Select, SelectProps } from '../../../components'
import { IProject } from '../../../services/projects'
import useStore, { Store } from '../../../store'
import { OptionValue } from '../../../utils/form'

interface Props extends Omit<SelectProps, 'options'> {
  selected?: OptionValue[] | null
  onSelect?: (value: OptionValue[] | null) => void
  onUpdateComplete?: (data: IProject) => void
}

const mapState = (state: Store) => ({
  quote_conditions: state.settings?.quote?.conditions,
})

export const ConditionSelect = ({
  selected = null,
  onSelect,
  onUpdateComplete,
  ...props
}: Props) => {
  const { quote_conditions } = useStore(mapState, shallow)
  const [currentSelected, setSelected] = useState<OptionValue[] | null>(
    selected,
  )

  const handleSelect = (value: OptionValue[] | null) => {
    setSelected(value)
    onSelect?.(value)
  }

  const mapConditions = orderBy(quote_conditions, ['label'], ['asc']).map(
    ({ id, label }) => ({
      value: id,
      label,
    }),
  )

  useEffect(() => {
    if (selected !== currentSelected) {
      setSelected(selected)
    }
  }, [selected])

  if (!quote_conditions) {
    return null
  }

  return (
    <div className='relative'>
      <Select
        {...props}
        value={currentSelected}
        labelInValue
        mode='multiple'
        dropdownClassName='w-full'
        onSelect={
          onSelect
            ? (value: OptionValue[] | null) => handleSelect(value)
            : undefined
        }
        onDeselect={onSelect ? () => handleSelect(null) : undefined}
        options={mapConditions}
        getRawInputElement={() => (
          <div className='select large select-multiple select-show-arrow select-show-search relative'>
            <div className='select-selector flex gap-2 w-full flex-col justify-center'>
              {!!selected &&
                selected
                  .filter(item => item.value)
                  .slice(0, 4)
                  .map(item => (
                    <div className='truncate shrink-0' key={item.value}>
                      {item.label}
                    </div>
                  ))}
            </div>
            <span className='select-arrow'>
              <span className='font-icon-arrow_down text-[1.25rem]' />
            </span>
          </div>
        )}
      />
      {!!selected && (
        <span
          className='select-clear cursor-pointer !pointer-events-auto z-50 absolute h-10 flex items-center top-0 right-8' // @ts-ignore
          onClick={() => props.onChange?.(null)}
        >
          <span className='font-icon-close' />
        </span>
      )}
    </div>
  )
}
