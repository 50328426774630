import ScrollBar from 'react-perfect-scrollbar'

import { formatDateTime } from '../../../../../utils/dateTime'
import {
  copyToClipBoard,
  formatBytes,
  pathResource,
} from '../../../../../utils/functions'
import { RESOURCE_USER } from '../../../../../utils/resources'
import toast from '../../../../../utils/toast'
import { ResourceLink } from '../../../../Resources/ResourceLink'
import { ResourceName } from '../../../../Resources/ResourceName'
import { useFileDetailCtx } from '../Context'
import { FileInfoAccess } from './Access'
import { FileInfoName } from './Name'
import { FileInfoNote } from './Note'
import { FileInfoTags } from './Tags'

export const MediaInfo = () => {
  const { file } = useFileDetailCtx()
  const fileUrl = pathResource('files', file.id)

  const handleCopyFileUrl = () => {
    copyToClipBoard(fileUrl)
    toast.success({
      title: 'File URL copied',
    })
  }
  return (
    <div className='h-full'>
      <ScrollBar options={{ wheelPropagation: false }}>
        <div className='p-6'>
          <h5 className='text-base font-medium mb-5'>Image Info</h5>
          <FileInfoName />
          <FileInfoTags />
          <div className='flex items-center mb-3 pb-3 border-b border-b-separation-900 text-body'>
            <div className='inline-flex items-center w-[7.75rem] pl-[1.875rem]'>
              <span>File Type</span>
            </div>
            <div className='uppercase flex-1 break-all'>
              {file.mime_type.split('/')[1]}
            </div>
          </div>
          <div className='flex items-center mb-3 pb-3 border-b border-b-separation-900 text-body'>
            <div className='inline-flex items-center w-[7.75rem] pl-[1.875rem]'>
              <span>File Size</span>
            </div>
            <div>{formatBytes(file.size)}</div>
          </div>
          <div className='flex items-center mb-3 pb-3 border-b border-b-separation-900 text-body'>
            <div className='inline-flex items-center w-[7.75rem] pl-[1.875rem]'>
              <span>File URL</span>
            </div>
            <div
              className='flex-1 break-all cursor-pointer'
              onClick={handleCopyFileUrl}
            >
              {fileUrl}
            </div>
          </div>
          <div className='flex items-center mb-3 pb-3 border-b border-b-separation-900 text-body'>
            <div className='inline-flex items-center w-[7.75rem]'>
              <div className='w-[1.875rem] leading-none'>
                <span className='font-icon-calendar text-black-400 text-[1rem]' />
              </div>
              <span>Upload Date</span>
            </div>
            <div>{formatDateTime(file.created_at)}</div>
          </div>
          <div className='flex items-center text-body'>
            <div className='inline-flex items-center w-[7.75rem]'>
              <div className='w-[1.875rem] leading-none'>
                <span className='font-icon-user text-black-400 text-[1rem]' />
              </div>
              <span>Uploaded By</span>
            </div>
            <div>
              {file.user ? (
                <ResourceLink
                  resourceId={file.user.id}
                  resourceType={RESOURCE_USER}
                >
                  <ResourceName
                    resource={file.user}
                    resourceType={RESOURCE_USER}
                  />
                </ResourceLink>
              ) : (
                'System'
              )}
            </div>
          </div>
          <FileInfoNote />
          <FileInfoAccess />
        </div>
      </ScrollBar>
    </div>
  )
}
