import { Popover } from '../../../components'
import { PATHNAME } from '../../../configs/routes'
import { IFile } from '../../../services/files'
import { ButtonFavorite } from '../../../shared/ButtonFavorite'
import { isFilePdf } from '../../../utils/files'
import { copyLinkResource, RESOURCE_MEDIA } from '../../../utils/resources'

interface Props {
  onClose: () => void
  onDelete?: () => void
  onFavorite?: (status: boolean) => void
  onOpenEditTag?: () => void
  onDownload?: () => void
  onManageAccess?: () => void
  onRenameFile?: () => void
  onMove?: () => void
  showCopyLink?: boolean
  file?: IFile
}

export const PopoverMenuFile = ({
  onClose,
  onDelete,
  onDownload,
  onFavorite,
  onManageAccess,
  onRenameFile,
  onMove,
  showCopyLink = true,
  file,
}: Props) => (
  <Popover.Content onClose={onClose} className='min-w-min !p-0 shadow-dropdown'>
    <div className='flex flex-col gap-3 py-4 text-body leading-none'>
      <div className='flex flex-col gap-[1.25rem]'>
        {onRenameFile && (
          <div
            className='flex gap-2 px-4 items-center cursor-pointer leading-none'
            onClick={onRenameFile}
          >
            <span className='font-icon-edit w-4' />
            <span className=''>Rename</span>
          </div>
        )}
        {onMove && (
          <div
            className='flex gap-2 px-4 items-center cursor-pointer leading-none'
            onClick={onMove}
          >
            <span className='font-icon-share w-4' />
            <span className=''>Move</span>
          </div>
        )}
        {file?.mime_type && isFilePdf(file.mime_type) && (
          <a
            href={file.url}
            className='flex gap-2 px-4 cursor-pointer items-center'
          >
            <span className='font-icon-view text-[10px] w-4' />
            <span>View File</span>
          </a>
        )}
        {file && onFavorite && (
          <div className='px-4 items-center cursor-pointer'>
            <ButtonFavorite
              currentStatus={Boolean(file.is_favorited)}
              resourceId={file.id}
              resourceType={RESOURCE_MEDIA}
              onToggleFavorite={onFavorite}
              className='flex gap-2'
            >
              {file.is_favorited ? (
                <>
                  <span className='font-icon-star_stretch w-4' />
                  <span>Remove from farvorite</span>
                </>
              ) : (
                <>
                  <span className='font-icon-star w-4' />
                  <span>Add to farvorite</span>
                </>
              )}
            </ButtonFavorite>
          </div>
        )}
        {onManageAccess && (
          <div
            className='flex gap-2 px-4 items-center cursor-pointer'
            onClick={onManageAccess}
          >
            <span className='font-icon-protected w-4' />
            <span>Manage Access</span>
          </div>
        )}
        {
          file && showCopyLink && (
            <div
              className='flex gap-3 text-black-800 cursor-pointer px-4 items-center'
              onClick={() => {
                copyLinkResource(PATHNAME.files, file.id)
              }}
            >
              <span className='font-icon-copy' />
              <div>Copy link to this resource</div>
            </div>
          )
        }
      </div>
      {onDownload && (
        <div className='flex flex-col gap-3'>
          <div className='w-full h-px bg-separation-800' />
          <div
            className='flex gap-2 px-4 cursor-pointer items-center'
            onClick={onDownload}
          >
            <span className='font-icon-download -mt-[2px] w-4' />
            <span className='text-body'>Download</span>
          </div>
        </div>
      )}
      {onDelete && (
        <div className='flex flex-col gap-3'>
          <div className='w-full h-px bg-separation-800' />
          <div
            className='flex gap-2 px-4 cursor-pointer items-center text-red-900'
            onClick={onDelete}
          >
            <span className='font-icon-trash leading-none -mt-px w-4' />
            <span className='text-body leading-none'>Delete</span>
          </div>
        </div>
      )}
    </div>
  </Popover.Content>
)
