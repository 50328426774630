import cx from 'classnames'
import { ReactNode } from 'react'

export interface PropsFieldItem {
  label: string | ReactNode
  className?: string
  labelClassName?: string
  childrenClassName?: string
  required?: boolean
  children: ReactNode
  viewType?: 'row' | 'col'
}

export const FieldItem = ({
  label,
  required,
  children,
  className = '',
  labelClassName = '',
  childrenClassName = '',
  viewType = 'col',
}: PropsFieldItem) => {
  return (
    <div
      className={cx(
        'md:flex mb-4',
        viewType === 'row' ? 'flex-col gap-1' : 'gap-4',
        className,
      )}
    >
      <label
        className={cx(
          'capitalize w-full text-body font-medium block',
          viewType === 'col' &&
            'max-w-full md:max-w-[10.5rem] md:text-right md:mb-0 md:mt-3 mb-1',
          labelClassName,
        )}
      >
        {label}
        {required && <span className='text-red-900'> *</span>}
      </label>
      <div className={cx('flex-1', childrenClassName)}>{children}</div>
    </div>
  )
}
