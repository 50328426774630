import { Link } from 'react-router-dom'

export const FooterAuth = () => {
  return (
    <div className='flex justify-between items-center text-body flex-col lg:flex-row gap-2'>
      <div className='text-black-400'>© Copyright 2022 - 2026 BSX.co </div>
      <div className='inline-flex gap-6'>
        <Link to='/tos' className='text-primary-900 font-medium'>
          Terms
        </Link>
        <Link to='/privacy' className='text-primary-900 font-medium'>
          Privacy
        </Link>
        <Link to='/' className='text-primary-900 font-medium'>
          About BSX
        </Link>
      </div>
    </div>
  )
}
