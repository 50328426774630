import { useEffect, useState } from 'react'
import shallow from 'zustand/shallow'

import { Props as SelectProps, Select } from '../../../components/Forms/Select'
import useStore, { Store } from '../../../store'
import { EXPENSE_RESOURCE } from '../../../utils/expense'
import { OptionValue } from '../../../utils/form'
import { checkMenuPermisison, PERMISSIONS } from '../../../utils/permission'

interface Props extends Omit<SelectProps, 'options'> {
  selected?: OptionValue | null
  onSelect?: (option: OptionValue | null) => void
  className?: string
}

const mapStore = (store: Store) => ({
  permissions: store.auth.currentUser?.can,
})
export const ExpenseResourceTypesSelect = ({
  selected = null,
  className = '',
  onSelect,
  ...props
}: Props) => {
  const { permissions } = useStore(mapStore, shallow)
  const [currentSelected, setSelected] = useState<OptionValue | null>(selected)
  const handleSelect = (item: any) => {
    const value = !!item
      ? {
          value: item.value,
          label: item.label,
        }
      : null
    setSelected(value)
    onSelect?.(value)
  }

  useEffect(() => {
    if (
      selected !== currentSelected ||
      selected?.label !== currentSelected?.label ||
      selected?.value !== currentSelected?.value
    ) {
      setSelected(selected)
    }
  }, [selected])

  return (
    <Select
      className='w-full capitalize cursor-pointer'
      placeholder='All Types'
      labelInValue
      onChange={handleSelect}
      {...props}
      value={currentSelected}
    >
      {Object.entries(EXPENSE_RESOURCE)
        .filter(
          (
            [key, _], // @ts-ignore
          ) => checkMenuPermisison(PERMISSIONS[key], permissions),
        )
        .map(([key, item]) => (
          <Select.Option
            value={item.value}
            className='capitalize'
            key={item.value}
          >
            {item.label}
          </Select.Option>
        ))}
    </Select>
  )
}
