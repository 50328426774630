import { SelectProps } from '../../../components'
import { AsyncSelect } from '../../../components/Forms/AsyncSelect'
import {
  getSyncLogs,
  IFilterLog,
  IQuickbookLog,
} from '../../../services/quickbook'
import { formatDateTime } from '../../../utils/dateTime'

interface Props extends Omit<SelectProps, 'options'> {
  selected?: IQuickbookLog | null
  onSelect?: (v: IQuickbookLog) => void
  filter?: IFilterLog
}
export const ListLogSelect = ({
  selected,
  onSelect,
  filter,
  ...props
}: Props) => {
  const handleGetQuotes = async ({ search = '', page = 1 } = {}) => {
    try {
      const { data } = await getSyncLogs({
        search,
        currentPage: page,
        ...filter,
      })
      return { data: data.data, totalPage: data.meta.last_page }
    } catch {
      return { data: [], totalPage: 1 }
    }
  }

  const handleSelect = (item: any, itemFull: IQuickbookLog) => {
    onSelect?.(itemFull)
  }

  return (
    <div className='relative'>
      <AsyncSelect
        {...props}
        labelInValue
        allowClear
        onChange={handleSelect}
        fetcher={handleGetQuotes}
        dropdownClassName='w-full'
        value={selected}
        fetchOnFirst={false}
      >
        {(data: IQuickbookLog[]) => {
          return data.map(item => (
            <AsyncSelect.Option key={item.id}>
              <div>{formatDateTime(item.created_at)}</div>
            </AsyncSelect.Option>
          ))
        }}
      </AsyncSelect>
    </div>
  )
}
