import cx from 'classnames'
import { format, isValid, setHours, setMinutes } from 'date-fns'
import { ChangeEventHandler, Fragment } from 'react'
import DatePicker, * as DProps from 'react-datepicker'

import MenuItem from '../../shared/MenuItem'
import {
  datePickerPresets,
  MAX_DATE_STR,
  MIN_DATE_STR,
} from '../../utils/dateTime'
import { Button, Popover } from '../index'

interface Props extends DProps.ReactDatePickerProps<any, any> {
  showPresets?: boolean
  showTime?: boolean
  onClose: () => void
}

function Picker({
  showPresets,
  showTime,
  onClose,
  selectsRange,
  selectsStart,
  selectsEnd,
  ...props
}: Props) {
  const { selected, startDate, endDate } = props
  const target = !selectsRange ? selected : selectsStart ? startDate : endDate
  const isYearPicker = Boolean(props.showMonthYearPicker)
  const customHeader = (params: DProps.ReactDatePickerCustomHeaderProps) => {
    if (!isValid(params.date)) {
      return null
    }
    return (
      <div className='flex items-center justify-between'>
        <Button
          className={cx(
            'bg-transparent px-3 py-1 h-auto text-black-800 hover:bg-transparent',
            (params.prevMonthButtonDisabled || params.prevYearButtonDisabled) &&
              '!text-black-400 pointer-events-none',
          )}
          innerClassName='!p-0 !h-auto'
          onClick={isYearPicker ? params.decreaseYear : params.decreaseMonth}
        >
          <span className='font-icon-arrow_left' />
        </Button>
        <div className='text-body text-black-800 font-medium'>
          {format(params.date, isYearPicker ? 'yyyy' : 'MMM, yyyy')}
        </div>
        <Button
          className={cx(
            'bg-transparent px-3 py-1 h-auto text-black-800 hover:bg-transparent',
            (params.nextMonthButtonDisabled || params.nextYearButtonDisabled) &&
              '!text-black-400 pointer-events-none',
          )}
          innerClassName='!p-0 !h-auto'
          onClick={isYearPicker ? params.increaseYear : params.increaseMonth}
        >
          <span className='font-icon-arrow_right' />
        </Button>
      </div>
    )
  }

  const handlePresetClick = (value: Date | [Date, Date]) => {
    props.onChange(
      selectsRange && !('length' in value) ? [value, value] : value,
      undefined,
    )
    onClose()
  }

  const renderPresets = () => {
    return datePickerPresets.map(({ label, value }, idx) => (
      <Fragment key={label}>
        <MenuItem
          onClick={() => handlePresetClick(value)}
          className={cx(
            'text-13',
            'length' in value &&
              !selectsRange &&
              'pointer-events-none text-[#c1c1c1]',
          )}
        >
          {label}
        </MenuItem>
        {(idx === 0 || idx === 3) && (
          <div key={idx} className='border-b border-[#EDEDED] my-1' />
        )}
      </Fragment>
    ))
  }

  const handleHourChange: ChangeEventHandler<HTMLInputElement> = e => {
    const value = +e.target.value
    if (!target) {
      return
    }
    const updated = setHours(target, value > 23 ? 23 : value)
    if (!selectsRange) {
      return props.onChange(updated, undefined)
    }
    props.onChange(
      [
        selectsStart ? updated : startDate || null,
        selectsEnd ? updated : endDate || null,
      ],
      undefined,
    )
  }

  const handleMinuteChange: ChangeEventHandler<HTMLInputElement> = e => {
    const value = +e.target.value
    if (!target) {
      return
    }
    const updated = setMinutes(target, value > 59 ? 59 : value)
    if (!selectsRange) {
      return props.onChange(updated, undefined)
    }
    props.onChange(
      [
        selectsStart ? updated : startDate || null,
        selectsEnd ? updated : endDate || null,
      ],
      undefined,
    )
  }

  const renderTimeInputs = () => {
    const h = target ? target.getHours() || '0' : ''
    const m = target ? target.getMinutes() || '0' : ''

    return (
      <div className='flex flex-col h-full justify-center items-center'>
        <div className='font-medium text-13 mb-2'>Choose Time</div>
        <div className='inline-flex items-center gap-1'>
          <input
            type='number'
            placeholder='hh'
            value={h}
            onChange={handleHourChange}
            className='w-12 h-12 border border-primary-200 rounded-lg outline-0 text-center p-2'
          />
          :
          <input
            type='number'
            placeholder='mm'
            value={m}
            onChange={handleMinuteChange}
            className='w-12 h-12 border border-primary-200 rounded-lg outline-0 text-center p-2'
          />
        </div>
      </div>
    )
  }

  return (
    <Popover.Content
      onClose={onClose}
      className='w-fit px-[1.125rem] pt-3 pb-4'
    >
      <div className='flex'>
        {showPresets === true && (
          <div className='w-[9.375rem] border-r border-[#EDEDED] mr-4 pr-4'>
            {renderPresets()}
          </div>
        )}
        <DatePicker
          minDate={new Date(MIN_DATE_STR)}
          maxDate={new Date(MAX_DATE_STR)}
          {...props}
          inline
          selectsRange={selectsRange}
          renderCustomHeader={customHeader}
          shouldCloseOnSelect={true}
        />
        {showTime && (
          <div className='w-32 border-l border-[#EDEDED] ml-4 pl-4'>
            {renderTimeInputs()}
          </div>
        )}
      </div>
    </Popover.Content>
  )
}

export default Picker
