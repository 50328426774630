import { StoreApi } from 'zustand'

import { AuthUser } from '../services/auth'
import type { Store } from './index'

export interface AuthSlice {
  currentUser: AuthUser | null
  setAuthUser: (user: AuthUser) => void
  updateAuthUser: (user: Partial<AuthUser>) => void
}

const slice = (set: StoreApi<Store>['setState']) => ({
  currentUser: null,
  setAuthUser: (user: AuthUser) => {
    return set(({ auth }) => ({ auth: { ...auth, currentUser: user } }))
  },
  updateAuthUser: (user: Partial<AuthUser>) => {
    return set(({ auth }) => ({
      auth: {
        ...auth,
        currentUser: { ...(auth.currentUser || {}), ...user } as AuthUser,
      },
    }))
  },
})

export default slice
