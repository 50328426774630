import { debounce, pick } from 'lodash'
import { ChangeEvent, useState } from 'react'

import { SearchInput } from '../../../components/Forms/SearchInput'
import { IFilter } from '../../../services/client'
import { ISearchResourceResponse } from '../../../services/resource'
import { SearchBestMatch } from './Component/BestMatch'
import { SearchFilterResource } from './Component/FilterResource'
import { ResultItem } from './Component/ResultItem'

interface Props {
  fetcher: (
    filter: IFilter,
  ) => Promise<{ data: ISearchResourceResponse | null }>
  onClose: () => void
}
export const SearchOnMobile = ({ fetcher, onClose }: Props) => {
  const [response, setReponse] = useState<ISearchResourceResponse | null>(null)
  const [resource, setResource] = useState('all')
  const searchDebounce = async (e: ChangeEvent<HTMLInputElement>) => {
    const result = await fetcher({ search: e.target.value })
    if (result?.data?.data) {
      setReponse(result?.data)
    }
  }
  const renderResults = (dataApi: ISearchResourceResponse | null) => {
    if (!dataApi) {
      return null
    }
    const { data, results } = dataApi
    const listData = resource !== 'all' ? pick(data, [resource]) : data
    const listResults =
      resource !== 'all'
        ? results.filter(item => item.result_type === resource)
        : results
    if (Object.keys(listData).length === 0 && listResults.length === 0) {
      return (
        <div className='text-black-400 flex items-center justify-center flex-col flex-1'>
          No Data
        </div>
      )
    }
    return (
      <div className='flex flex-col gap-10 text-body'>
        {results.length > 0 && (
          <SearchBestMatch results={listResults} keyword={resource || ''} />
        )}
        {Object.keys(listData)
          .sort((a, b) => a.localeCompare(b))
          .map(key => {
            const value = listData[key]
            return (
              <ResultItem
                key={key}
                resource={value}
                resourceName={key}
                keyword={resource || ''}
              />
            )
          })}
      </div>
    )
  }
  return (
    <div className='relative w-full flex h-screen flex-col'>
      <div className='relative flex items-center shadow-card py-1 px-3'>
        <div
          onClick={onClose}
          className='bg-button-ternary w-10 h-10 rounded-full flex items-center justify-center'
        >
          <span className='font-icon-arrow_left' />
        </div>
        <div className='flex-1'>
          <SearchInput
            onChange={debounce(searchDebounce, 500)}
            prefixIcon={<span className='font-icon-search text-black-400' />}
            prefixClassName='!bg-transparent border-none'
            className='pl-10 !pr-4 !border-none focus:!shadow-none hover:!shadow-none h-12'
            suffix={null}
            placeholder='Search anything'
            autoFocus={true}
          />
        </div>
        <div className='h-full w-px bg-separation-800' />
        <div className='pl-4'>
          <SearchFilterResource onSelected={setResource} />
        </div>
      </div>
      <div className='px-3 py-6 max-h-[calc(100vh_-_3.rem)] custom-scrollbar overflow-y-auto h-full flex-1'>
        {renderResults(response)}
      </div>
    </div>
  )
}
