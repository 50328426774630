import { OptionValue } from '../utils/form'
import { generateQueryOfEndPoint } from '../utils/functions'
import { IAccount } from './accounts'
import client, { IFilter, PAGE_SIZE, PAGE_SIZE_RECENT } from './client'
import { IFile } from './files'
import { INote } from './notes'
import { ITag } from './tags'

export interface IContact {
  id: number
  account_id: number
  account: IAccount
  first_name: string
  name?: string | null
  middle_name?: string | null
  last_name?: string | null
  job_title?: string | null
  phone_work?: string | null
  phone_mobile?: string | null
  email?: string | null
  linkedin?: string | null
  address?: string | null
  city?: string | null
  state?: string | null
  zip?: string | null
  country?: string | null
  avatar_url?: string | null
  avatar_preview_url?: string | null
  notes?: INote[] | null
  files?: IFile[] | null
  created_at: Date | string
  tags?: ITag[] | null
  inbound_email_address?: string | null
}

export interface IFilterContacts extends IFilter {
  filterAccountType?: string | null
  filterAccount?: OptionValue | null
  filterHasEmail?: boolean | null
}

export interface IContactPayload
  extends Omit<
    IContact,
    | 'id'
    | 'account'
    | 'account_id'
    | 'first_name'
    | 'created_at'
    | 'tags'
    | 'name'
  > {
  first_name?: string | null
  avatar?: File | null
  tags?: number[] | null
  existing_avatar_id?: number | null
}
export const getContacts = async ({
  search = '',
  sortBy = 'id',
  sortDirection = 'desc',
  pageSize = PAGE_SIZE,
  currentPage = 1,
  filterAccount = null,
  filterAccountType = null,
  filterHasEmail = false,
}: IFilterContacts) => {
  const sort = `${sortDirection === 'desc' ? '-' : ''}${sortBy}`
  const endPoint = generateQueryOfEndPoint('/api/contacts', {
    sort,
    limit: pageSize,
    page: currentPage,
    'filter[search]': search,
    'filter[account_type]': filterAccountType || null,
    'filter[account_id]': filterAccount?.value || null,
    'filter[has_email]': filterHasEmail ? 1 : null,
  })
  return client.get(`${endPoint}include=account`)
}
export const getRecentContacts = async (props: IFilterContacts) => {
  return getContacts({
    ...props,
    sortBy: 'recent',
    sortDirection: 'asc',
    pageSize: PAGE_SIZE_RECENT,
    currentPage: 1,
  })
}

export const createContact = async (payload: IContactPayload) => {
  return client.post('/api/contacts', payload)
}

export const addContactAvatar = async (
  id: number,
  { avatar }: { avatar: File | null },
) => {
  if (!avatar) {
    return
  }
  const formData = new FormData()
  formData.append('avatar', avatar)
  formData.append('_method', 'PUT')
  return client.post(`/api/contacts/${id}`, formData)
}

export const editContact = (contactId: number, payload: IContactPayload) => {
  return client.put(`/api/contacts/${contactId}`, payload)
}
export const deleteContact = async (id: number) => {
  return client.delete(`/api/contacts/${id}`)
}

export const detailContact = async (id: number) => {
  return client.get<{ data: IContact }>(
    `api/contacts/${id}?include=account,notes,tags,inboundEmailAddress`,
  )
}
