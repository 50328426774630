import { RefObject, useState } from 'react'
import { DropTargetMonitor, useDrop, XYCoord } from 'react-dnd'

import { IItemDrag } from '../utils/menus'

type TDropSort = {
  accept: string | string[]
  index: number
  dref: RefObject<HTMLDivElement>
  onSortEnd?: (
    dropItem: IItemDrag,
    dropIndex: number,
    overIndex: number,
  ) => void
  direction?: string // x|y
}

type TResultUseDropSort = {
  isOver: boolean
  isBottom: boolean
}
export const useDropSort = ({
  accept,
  index,
  dref,
  onSortEnd,
  direction = 'x',
}: TDropSort) => {
  const [sortBottom, setBottom] = useState(false)
  return useDrop<IItemDrag, void, TResultUseDropSort>({
    accept,
    drop: (dropItem: IItemDrag, monitor: DropTargetMonitor) => {
      let isBottom = false
      const clientOffset: XYCoord | null = monitor.getClientOffset() || null
      const hoverBoundingRect: DOMRect | null =
        dref.current?.getBoundingClientRect() || null
      if (clientOffset && hoverBoundingRect) {
        if (direction === 'y') {
          const halfHeight = hoverBoundingRect.y + hoverBoundingRect.height / 2
          isBottom = clientOffset.y > halfHeight
        } else {
          const halfWidth = hoverBoundingRect.x + hoverBoundingRect.width / 2
          isBottom = clientOffset.x > halfWidth
        }
      }
      onSortEnd &&
        onSortEnd(dropItem, dropItem.index, isBottom ? index + 1 : index)
    },
    hover: (_, monitor: DropTargetMonitor) => {
      const clientOffset: XYCoord | null = monitor.getClientOffset() || null
      const hoverBoundingRect: DOMRect | null =
        dref.current?.getBoundingClientRect() || null
      if (clientOffset && hoverBoundingRect) {
        let onBottom = false
        if (direction === 'y') {
          const halfHeight = hoverBoundingRect.y + hoverBoundingRect.height / 2
          onBottom = clientOffset.y > halfHeight
        } else {
          const halfWidth = hoverBoundingRect.x + hoverBoundingRect.width / 2
          onBottom = clientOffset.x > halfWidth
        }
        if (sortBottom !== onBottom) {
          setBottom(onBottom)
        }
      }
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
      isBottom: sortBottom,
    }),
  })
}
