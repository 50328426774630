import cx from 'classnames'

import { Tooltip } from '../../../components'
import { IProject } from '../../../services/projects'
import {
  isBlockedProject,
  isDueDateSoonProject,
  isOverDueDateProject,
} from '../../../utils/projects'

interface Props {
  className?: string
  project: IProject
}
export const ProjectIconStatus = ({ project, className }: Props) => {
  if (isBlockedProject(project)) {
    return (
      <Tooltip
        content='This project blocked'
        placement='auto'
        popupClassName='z-[9999]'
      >
        <span
          className={cx(
            'rounded min-w-[1.375rem] min-h-[1.375rem] inline-flex items-center justify-center cursor-pointer bg-separation-800',
            className,
          )}
        >
          <span className='font-icon-lock text-black-800 leading-none text-[0.875rem]' />
        </span>
      </Tooltip>
    )
  }
  if (isDueDateSoonProject(project)) {
    return (
      <Tooltip
        content='It’s 3 days left to this project’s due day'
        placement='auto'
        popupClassName='z-[9999]'
      >
        <span
          className={cx(
            'rounded min-w-[1.375rem] min-h-[1.375rem] inline-flex items-center justify-center cursor-pointer bg-orange-900',
            className,
          )}
        >
          <span className='font-icon-notification text-white leading-none text-[0.875rem]' />
        </span>
      </Tooltip>
    )
  }
  if (isOverDueDateProject(project)) {
    return (
      <Tooltip
        content='This Project passed the due date but hasn’t completed yet'
        placement='auto'
        popupClassName='z-[9999]'
      >
        <span
          className={cx(
            'rounded min-w-[1.375rem] min-h-[1.375rem] inline-flex items-center justify-center cursor-pointer bg-red-800',
            className,
          )}
        >
          <span className='font-icon-notification text-white leading-none text-[0.875rem]' />
        </span>
      </Tooltip>
    )
  }
  return null
}
