import { OptionValue } from '../utils/form'
import { generateQueryOfEndPoint } from '../utils/functions'
import { RESOURCE_PREVENTATIVE } from '../utils/resources'
import { IAccount } from './accounts'
import client, {
  IFilter,
  PAGE_SIZE,
  PAGE_SIZE_RECENT,
  ResponseMeta,
} from './client'
import { IContact } from './contacts'
import { IFile } from './files'
import { IInvoice } from './invoices'
import { INote } from './notes'
import { IProject } from './projects'
import { IQuote } from './quotes'
import { IService } from './services'
import { IQuoteSettingPayload } from './settings'
import { ITag } from './tags'
import { IUser } from './users'

export type TContractServiceType = {
  name: string
  price?: number | null
  freq?: string | null
}

export interface IContract {
  id: number
  sales_pipeline_id?: number | null
  avatar_url?: string | null
  avatar_preview_url?: string | null
  account_id: number
  account: IAccount
  project?: IProject | null
  project_id?: number | null
  quote_id?: number | null
  quote?: IQuote | null
  contact_id?: number | null
  contact?: IContact | null
  invoices?: IInvoice[] | null
  serviceTickets?: IService[] | null
  contract_number: number | null
  effective_date?: Date | string | null
  termination_date?: Date | string | null
  created_at: Date | string
  types?: TContractServiceType[] | null
  advanced_replacement?: number | null
  warranty_epuipment?: number | null
  warranty_on_labor?: number | null
  files?: IFile[] | null
  notes?: INote[] | null
  response_time_during_business_hours?: number | null
  response_time_outside_business_hours?: number | null
  preventative_maintenance?: number | null
  preventative_maintenance_frequency?: string | null
  price?: string | null
  price_frequency?: string | null
  billing_cycle?: string | null
  conditions?: IQuoteSettingPayload[] | null
  assignees?: IUser[] | null
  street_address?: string | null
  street_address_2?: string | null
  city?: string | null
  state?: string | null
  zip?: string | null
  country?: string | null
  preventativeMaintenanceLogs?: IPreventative[] | null
  inbound_email_address?: string | null
  tags?: ITag[] | null
}

export interface IContractPayload
  extends Omit<
    IContract,
    | 'id'
    | 'account'
    | 'assignees'
    | 'account_id'
    | 'contract_number'
    | 'created_at'
    | 'invoices'
    | 'tags'
  > {
  account_id?: number | null
  assignees?: number[] | null
  contract_number?: number | null
  tags?: number[] | null
}

export interface IFilterContracts extends IFilter {
  filterAccount?: OptionValue | null
}

export const getContracts = async ({
  search = '',
  sortBy = 'id',
  sortDirection = 'desc',
  pageSize = PAGE_SIZE,
  currentPage = 1,
  filterAccount = null,
}: IFilterContracts) => {
  const sort = `${sortDirection === 'desc' ? '-' : ''}${sortBy}`
  const endPoint = generateQueryOfEndPoint('/api/maintenanceContracts', {
    sort,
    'filter[search]': search,
    limit: pageSize,
    page: currentPage,
    'filter[account_id]': filterAccount?.value || null,
  })
  return client.get(
    `${endPoint}include=account,quote,invoices,inboundEmailAddress`,
  )
}

export const getRecentContracts = async (props: IFilterContracts) => {
  return getContracts({
    ...props,
    sortBy: 'recent',
    sortDirection: 'asc',
    pageSize: PAGE_SIZE_RECENT,
    currentPage: 1,
  })
}

export const createContract = async (payload: IContractPayload) => {
  return client.post('/api/maintenanceContracts', payload)
}

export const editContract = (contractId: number, payload: IContractPayload) => {
  return client.put<{ data: IContract }>(
    `/api/maintenanceContracts/${contractId}`,
    payload,
  )
}
export const deleteContract = async (contractId: number) => {
  return client.delete(`/api/maintenanceContracts/${contractId}`)
}

export const detailContractShort = async (contractId: number) => {
  return client.get<{ data: IContract }>(
    `api/maintenanceContracts/${contractId}?include=account,notes,files,preventativeMaintenanceLogs,inboundEmailAddress,tags`,
  )
}

export const detailContract = async (contractId: number) => {
  return client.get<{ data: IContract }>(
    `api/maintenanceContracts/${contractId}?include=account,files,quote,serviceTickets,preventativeMaintenanceLogs,tags,inboundEmailAddress`,
  )
}

export const getLastestContractNumber = async () => {
  return client.get('/api/maintenanceContracts?sort=-contract_number&limit=1')
}

/* PREVENTATIVE */

export type TPreventativeTaskType = 'repeated' | 'separated'

export interface IListPreventativeTask
  extends Record<TPreventativeTaskType, IPreventativeTask[]> {}
export interface IPreventative {
  id: number
  name: string
  maintenance_contract_id: number
  maintenanceContract?: IContract
  completed_on: Date | string | null
  status: string
  created_at: Date | string
  resource_type: string
  resource_id: number
  description?: string | null
  order?: null
  type?: string | null
  in_complete_tasks_count: number
  preventative_maintenance_tasks_count: number
  user_id: number | null
  due_date?: Date | string | null
  assignees?: IUser[] | null
  preventativeMaintenanceTasks: IListPreventativeTask
}

export interface IPreventativePayload {
  maintenance_contract_id?: number
  name?: string
  status?: string | null
  due_day?: Date | string | null
  assignees?: number[] | null
  preventative_maintenance_tasks_count?: number
  in_complete_tasks_count?: number
  completed_on?: Date | string | null
  preventativeMaintenanceTasks?: Partial<IPreventativeTask>[]
}

export interface IFilterPreventatives extends IFilter {
  filterContract?: OptionValue | null
  filterRecentlyUpdated?: boolean
  filterDueDate?: boolean
  filterExpired?: boolean
  filterCompleted?: boolean
}

export interface ResponsePreventativeMeta extends ResponseMeta {
  due_count: number
  expired_count: number
  completed_count: number
  recently_updated_count: number
}

export interface IResponsePreventatives {
  data: IProject[]
  meta: ResponsePreventativeMeta
}

export const getPreventative = async ({
  search = '',
  sortBy = 'id',
  sortDirection = 'desc',
  pageSize = PAGE_SIZE,
  currentPage = 1,
  filterContract = null,
  filterRecentlyUpdated = false,
  filterExpired = false,
  filterCompleted = false,
  filterDueDate = false,
}: IFilterPreventatives) => {
  const sort = `${sortDirection === 'desc' ? '-' : ''}${sortBy}`
  const endPoint = generateQueryOfEndPoint('/api/preventativeMaintenanceLogs', {
    sort,
    'filter[search]': search,
    limit: pageSize,
    page: currentPage,
    'filter[recently_updated]': filterRecentlyUpdated ? 1 : null,
    'filter[due_soon]': filterDueDate ? 1 : null,
    'filter[expired]': filterExpired ? 1 : null,
    'filter[completed]': filterCompleted ? 1 : null,
    'filter[maintenance_contract_id]': filterContract?.value || null,
  })
  return client.get<IResponsePreventatives>(
    `${endPoint}include=inCompleteTasks,totalTasks,preventativeMaintenanceTasks,assignees&kpi=1`,
  )
}

export const createPreventative = (payload: IPreventativePayload) => {
  return client.post('/api/preventativeMaintenanceLogs', payload)
}

export const editPreventative = (
  preventativeId: number,
  payload: IPreventativePayload,
) => {
  return client.put<{ data: IPreventative }>(
    `/api/preventativeMaintenanceLogs/${preventativeId}`,
    payload,
  )
}
export const deletePreventative = async (preventativeId: number) => {
  return client.delete(`/api/preventativeMaintenanceLogs/${preventativeId}`)
}

export const detailPreventative = async (preventativeId: number) => {
  return client.get<{ data: IPreventative }>(
    `api/preventativeMaintenanceLogs/${preventativeId}?include=preventativeMaintenanceTasks,assignees,inCompleteTasks,totalTasks,maintenanceContract.account`,
  )
}

/* PREVENTATIVE TASK */

export interface IPreventativeTask {
  id: number
  resource_id: number
  resource_type: string
  type: TPreventativeTaskType
  description: string
  order: number | null
  created_at: Date | string
  is_completed: number
}

export interface IPreventativeTaskPayload {
  resource_id: number
  resource_type: string
  type: string
  description: string
  order?: number
}

export const getPreventativeTask = async ({
  search = '',
  sortBy = 'id',
  sortDirection = 'desc',
  pageSize = PAGE_SIZE,
  currentPage = 1,
}: IFilterPreventatives) => {
  const sort = `${sortDirection === 'desc' ? '-' : ''}${sortBy}`
  const endPoint = generateQueryOfEndPoint(
    '/api/preventativeMaintenanceTasks',
    {
      sort,
      'filter[search]': search,
      'filter[resource]': `${RESOURCE_PREVENTATIVE},10`,
      limit: pageSize,
      page: currentPage,
    },
  )
  return client.get(`${endPoint}`)
}

export const createPreventativeTask = (payload: IPreventativeTaskPayload) => {
  return client.post('/api/preventativeMaintenanceTasks', payload)
}

export const editPreventativeTask = (
  preventativeId: number,
  payload: Partial<IPreventativeTask>,
) => {
  return client.put<{ data: IPreventativeTask }>(
    `/api/preventativeMaintenanceTasks/${preventativeId}`,
    payload,
  )
}
export const deletePreventativeTask = async (preventativeId: number) => {
  return client.delete(`/api/preventativeMaintenanceTasks/${preventativeId}`)
}

export const detailPreventativeTask = async (preventativeId: number) => {
  return client.get<{ data: IPreventative }>(
    `api/preventativeMaintenanceTasks/${preventativeId}`,
  )
}

/* PREVENTATIVE TASK TEMPLATE */
export interface IPreventativeTemplate {
  id: number
  name: string
  created_at: Date | string
  preventativeMaintenanceTasks: IListPreventativeTask
}

export interface IPreventativeTemplatePayload {
  name: string
  preventativeMaintenanceTasks?: Partial<IPreventativeTask>[]
}

export const getPreventativeTemplate = async ({
  search = '',
  sortBy = 'id',
  sortDirection = 'desc',
  pageSize = PAGE_SIZE,
  currentPage = 1,
}: IFilter) => {
  const sort = `${sortDirection === 'desc' ? '-' : ''}${sortBy}`
  const endPoint = generateQueryOfEndPoint(
    '/api/preventativeMaintenanceTaskTemplates',
    {
      sort,
      'filter[search]': search,
      limit: pageSize,
      page: currentPage,
    },
  )
  return client.get<{ data: IPreventativeTemplate }>(
    `${endPoint}include=preventativeMaintenanceTasks`,
  )
}

export const createPreventativeTemplate = (
  payload: IPreventativeTemplatePayload,
) => {
  return client.post<{ data: IPreventativeTemplate }>(
    '/api/preventativeMaintenanceTaskTemplates',
    payload,
  )
}

export const editPreventativeTemplate = (
  templateId: number,
  payload: Partial<IPreventativeTemplatePayload>,
) => {
  return client.put<{ data: IPreventativeTemplate }>(
    `/api/preventativeMaintenanceTaskTemplates/${templateId}`,
    payload,
  )
}
export const deletePreventativeTemplate = async (templateId: number) => {
  return client.delete(
    `/api/preventativeMaintenanceTaskTemplates/${templateId}`,
  )
}

export const detailPreventativeTemplate = async (templateId: number) => {
  return client.get<{ data: IPreventativeTemplate }>(
    `api/preventativeMaintenanceTaskTemplates/${templateId}`,
  )
}
