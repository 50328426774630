import { Placement } from '@popperjs/core'
import cx from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import { debounce } from 'lodash'
import { ChangeEvent, useMemo, useState } from 'react'
import useOnclickOutside from 'react-cool-onclickoutside'
import ScrollBar from 'react-perfect-scrollbar'
import { usePopper } from 'react-popper'

import { Input } from '../../components'
import { Avatar } from '../../components/Avatar'
import { getTransformOrigin } from '../../components/Popover'
import { Spinner } from '../../components/Spinner'
import { useAsync } from '../../hooks'
import { getUsers, IUser } from '../../services/users'
import MenuItem from '../MenuItem'

interface Props {
  label?: string
  placeholder?: string
  onSelect?: (user: IUser) => void
  className?: string
  placement?: Placement
  listExcludes?: number[] | null
  labeExcludes?: string | null
}

export const UserCompleteField = ({
  onSelect,
  className = '',
  placement = 'bottom',
  listExcludes = [],
  labeExcludes = 'assigned',
}: Props) => {
  const [refElement, setRefElement] = useState<HTMLElement | null>(null)
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null)
  const [keyword, setKeyword] = useState('')
  const [isOpen, setOpen] = useState(false)
  const [data, setData] = useState<IUser[] | null>(null)
  const [selected, setSelected] = useState<IUser | null>(null)
  const { execute, isLoading } = useAsync({ showNotifOnError: true })
  const { styles, attributes } = usePopper(refElement, popperElement, {
    placement,
  })

  const refOutSide = useOnclickOutside(() => {
    setData(null)
    setOpen(false)
  })

  const renderDropDown = () =>
    isOpen && (
      <>
        {isLoading && (
          <div className='absolute z-10 bg-white opacity-80 flex justify-center items-center inset-0 rounded-lg'>
            <Spinner size='xsmall' />
          </div>
        )}
        {data?.length ? (
          renderSuggestion()
        ) : (
          <div className='flex items-center text-sm px-4 py-3 gap-2'>
            <span className='font-icon-search' />
            No result match your search
          </div>
        )}
      </>
    )

  const renderSuggestion = () => {
    return (
      <ScrollBar
        options={{ wheelPropagation: false }}
        className='max-h-[15rem] px-2'
      >
        <>
          {data?.map(d => (
            <MenuItem
              key={d.id}
              onClick={() => handleSelectSuggestion(d)}
              className={cx(
                'select-none !rounded-lg',
                listExcludes?.includes(d.id) &&
                  'cursor-default pointer-events-none',
              )}
            >
              <div className='w-full mb-1'>
                <div className='flex items-center gap-2 text-13'>
                  <Avatar
                    src={d.avatar_preview_url || null}
                    size='small'
                    hashId={`user-${d.id}`}
                  />
                  <div>{d.name}</div>
                  <div className='text-black-400'>
                    {listExcludes?.includes(d.id) && `(${labeExcludes})`}
                  </div>
                </div>
              </div>
            </MenuItem>
          ))}
        </>
      </ScrollBar>
    )
  }

  const handleSelectSuggestion = async (s: IUser) => {
    if (listExcludes?.includes(s.id)) {
      return
    }
    setKeyword(s.name)
    setOpen(false)
    setData(null)
    setSelected(s)
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setKeyword(e.target.value)
    if (selected) {
      setSelected(null)
    }
    if (e.target.value.trim() === '') {
      setOpen(false)
      setData(null)
    } else {
      searchDebounce(e.target.value)
      if (!isOpen) {
        setOpen(true)
      }
    }
  }

  const searchDebounce = useMemo(() => {
    return debounce(async (k: string) => {
      const result = await execute(getUsers({ search: k }))
      if (result) {
        setData(result.data.data)
      }
    }, 300)
  }, [])

  const handleAddUser = () => {
    if (selected) {
      onSelect?.(selected)
      setSelected(null)
      setKeyword('')
    }
  }

  return (
    <div className={cx('relative z-20', className)} ref={refOutSide}>
      <Input
        onChange={handleChange}
        ref={setRefElement}
        placeholder='Add people'
        value={keyword}
      />
      {selected && (
        <span
          className='font-icon-add absolute cursor-pointer top-3 right-3'
          onClick={handleAddUser}
        />
      )}
      <AnimatePresence initial={false}>
        {isOpen && (
          <div
            ref={setPopperElement}
            className='w-full'
            style={{ ...styles.popper }}
            {...attributes.popper}
          >
            <motion.div
              initial={{ scale: 0, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0, opacity: 0 }}
              transition={{ duration: 0.2 }}
              style={{
                transformOrigin: getTransformOrigin('bottom'),
              }}
            >
              <div className='py-2 z-popover w-full bg-white text-sm text-light-primary rounded-lg border border-light-stroke shadow-dropdown relative'>
                {renderDropDown()}
              </div>
            </motion.div>
          </div>
        )}
      </AnimatePresence>
    </div>
  )
}
