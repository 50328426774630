import cx from 'classnames'
import { debounce } from 'lodash'
import { ChangeEvent, useEffect, useState } from 'react'

import { Image, Modal, ModalProps } from '../../../../components'
import { SearchInput } from '../../../../components/Forms/SearchInput'
import { useAsync } from '../../../../hooks'
import { getFiles, IFile, IFilterFile } from '../../../../services/files'
import {
  EXTENSTIONS_IMAGE_TYPE,
  getListRecentSelected,
} from '../../../../utils/files'
import { RecentSelected } from './RecentSelected'

interface Props extends Omit<ModalProps, 'children'> {
  onSelect: (file: Partial<IFile>) => void
  onClose: () => void
}

export const ModalSelectExistImage = ({
  onClose,
  onSelect,
  ...props
}: Props) => {
  const [listImages, setListImages] = useState<IFile[]>([])
  const [keyword, setKeyword] = useState('')
  const [currentPage, setCurrentPage] = useState(0)
  const { execute, data, isLoading } = useAsync({ showNotifOnError: true })

  const fetchImages = async (filter?: IFilterFile) => {
    return await execute(
      getFiles({
        filterFileFormat: EXTENSTIONS_IMAGE_TYPE,
        pageSize: 40,
        ...filter,
      }),
    )
  }

  const handleSearchImage = async (value: string) => {
    const result = await fetchImages({ search: value, currentPage: 1 })
    if (result.data.data) {
      setListImages(result.data.data)
      setCurrentPage(1)
    }
  }

  const handleSearchDebounce = (e: ChangeEvent<HTMLInputElement>) => {
    setKeyword(e.target.value)
    handleSearchImage(e.target.value)
  }

  const handleScroll = async (e: any) => {
    const { scrollHeight, offsetHeight, scrollTop } = e.target as HTMLDivElement
    const last_page = data?.data.meta?.last_page || null
    if (
      scrollHeight <= offsetHeight + scrollTop &&
      currentPage < last_page &&
      !isLoading
    ) {
      const result = await fetchImages({
        currentPage: currentPage + 1,
        search: keyword,
      })
      if (result.data.data) {
        setListImages(prev => [...prev, ...result.data.data])
        setCurrentPage(currentPage + 1)
      }
    }
  }

  const handleSelectImage = (item: IFile | Partial<IFile>) => {
    onSelect(item)
    onClose()
    const currentRecentSelected = getListRecentSelected()
    const newListRecentSelected = [
      { id: item.id, url: item.url },
      ...currentRecentSelected,
    ].slice(0, 6)
    localStorage.setItem(
      'IMAGE_RECENT_SELECTED',
      JSON.stringify(newListRecentSelected),
    )
  }

  useEffect(() => {
    handleSearchImage('')
  }, [])
  return (
    <Modal {...props} className='max-w-[55rem] w-full p-0' onClose={onClose}>
      <div className='flex gap-3 relative flex-col p-4 border-b border-b-separation-400 mb-4'>
        <span
          className='cursor-pointer font-icon-close text-black-400 absolute right-2 top-2 z-10 w-10 h-10 flex justify-center items-center'
          onClick={onClose}
        />
        <div className='text-base font-medium'>Select image</div>
      </div>
      <div>
        <div className='px-4'>
          <RecentSelected onSelectImage={handleSelectImage} />
          <div className='text-sm font-medium mb-4'>All Images</div>
          <SearchInput
            placeholder='Search image by file name, tag'
            className='shadow-card'
            wrapperClassName='mb-3'
            onChange={debounce(handleSearchDebounce, 500)}
          />
        </div>
        <div
          className='overflow-auto customs-scrollbar h-[30rem] px-4'
          onScroll={handleScroll}
        >
          {listImages.length > 0 ? (
            <div
              className={cx(
                'grid grid-cols-[repeat(auto-fill,minmax(7.5rem,1fr))] gap-3 pb-3',
                isLoading && 'opacity-50 pointer-events-none',
              )}
            >
              {listImages.map(item => (
                <div
                  key={item.id}
                  className='after:block after:pt-[100%] after:content-[""] relative cursor-pointer'
                  onClick={() => handleSelectImage(item)}
                >
                  <Image
                    src={item.preview_url || item.url}
                    className='absolute inset-0 object-cover w-full h-full rounded-lg'
                  />
                </div>
              ))}
            </div>
          ) : (
            <div className='text-body'>No image match your search</div>
          )}
        </div>
      </div>
    </Modal>
  )
}
