import { FC } from 'react'

import { Props as RadioProps, Radio as RaDio } from './Radio'

const Radio: FC<RadioProps> & { Group: any; Button: any } = props => (
  <RaDio {...props} />
)

Radio.Group = ''
Radio.Button = ''

export { Radio }
