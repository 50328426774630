import cx from 'classnames'
import { FC, Fragment, useRef } from 'react'

import { useOutsideClick } from '../../hooks'

export type Props = {
  className?: string
  onClose?: () => void
} & JSX.IntrinsicElements['div']

export const PopoverContent: FC<Props> = ({
  children,
  onClose,
  className,
  ...props
}) => {
  const popoverContentRef = useRef<HTMLDivElement>(null)
  useOutsideClick(popoverContentRef, onClose)

  return (
    <Fragment>
      <div
        ref={popoverContentRef}
        {...props}
        className={cx(
          'text-sm text-light-primary shadow-dropdown',
          'bg-white rounded-lg p-3 border border-light-stroke',
          className,
        )}
      >
        {children}
      </div>
    </Fragment>
  )
}
