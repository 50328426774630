import { SVGProps } from 'react'

export const Cad = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M0 2.28571C0 1.02335 1.02335 0 2.28571 0H13.7143C14.9767 0 16 1.02335 16 2.28571V13.7143C16 14.9767 14.9767 16 13.7143 16H2.28571C1.02335 16 0 14.9767 0 13.7143V2.28571Z'
        fill='#78082A'
      />
      <path
        d='M0 2.28571C0 1.02335 1.02335 0 2.28571 0H14.2857V11.4286C14.2857 12.6909 13.2624 13.7143 12 13.7143H0V2.28571Z'
        fill='#E51050'
      />
      <path
        d='M9.26265 10.2173H6.71793L6.31 11.4286H4.57143L7.03845 4.57143H8.96155L11.4286 11.4286H9.67058L9.26265 10.2173ZM8.83529 8.92796L7.99029 6.41758L7.155 8.92796H8.83529Z'
        fill='white'
      />
    </svg>
  )
}
