import axios from 'axios'

import { OptionValue } from '../utils/form'
import { generateQueryOfEndPoint } from '../utils/functions'
import { IAccount } from './accounts'
import client, {
  BASE_URL,
  IFilter,
  PAGE_SIZE,
  PAGE_SIZE_RECENT,
  ResponseMeta,
} from './client'
import { IContact } from './contacts'
import { IFile } from './files'
import { INote } from './notes'
import { ILineItem } from './quotes'
import { TResource } from './resource'
import { ITag } from './tags'
import { ITaxVendor } from './taxVendors'
import { ITransaction } from './transaction'
import { IUser } from './users'

export interface ResponseInvoiceMeta extends ResponseMeta {
  recently_updated_count: number
  warning_count: number
  status_count: Record<string, number>
}

export interface IResponseInvoices {
  data: IInvoice[]
  meta: ResponseInvoiceMeta
}

export interface IInvoice {
  id: number
  sales_pipeline_id?: number | null
  account_id: number | null
  account: IAccount | null
  type?: string | null
  invoice_number?: number | null
  invoice_date?: Date | string | null
  payment_terms?: number | null
  net_terms?: number | null
  purchase_order_number?: number | null
  status?: string | null
  status_id?: number | null
  tax_vendor_ids?: number[] | null
  tax_rate?: number | null
  is_credit_card_accepted?: number | null
  is_mailed_check_accepted?: number | null
  is_ach_transfer?: number | null
  shipping_street_address?: string | null
  shipping_street_address_2?: string | null
  shipping_city?: string | null
  shipping_state?: string | null
  shipping_zip?: string | null
  shipping_country?: string | null
  billing_street_address?: string | null
  billing_street_address_2?: string | null
  billing_city?: string | null
  billing_state?: string | null
  billing_zip?: string | null
  billing_country?: string | null
  is_epay?: boolean | null
  created_at: Date | string
  pdf_url?: string | null
  invoiceLineItems?: ILineItem[] | null
  discount?: number | null
  allNotes?: INote[] | null
  notes?: INote[] | null
  files?: IFile[] | null
  watchers?: IUser[] | null
  watchers_count?: number | null
  taxVendors?: ITaxVendor[] | null
  contact?: IContact | null
  contacts?: IContact[] | null
  resource_type?: string | null
  resource_id?: number | null
  resource?: TResource | null
  pdf_notes?: string | null
  public_url?: string | null
  inbound_email_address?: string | null
  lastTransaction?: ITransaction | null
  total_amount?: number | null
  sub_total?: number | null
  tax_amount?: number | null
  is_private?: boolean | null
  tags?: ITag[] | null
  has_passed_due_date?: boolean | null
  due_date?: boolean | null
  status_date?: Date | string | null
}

export interface IInvoicePayload
  extends Omit<
    IInvoice,
    | 'id'
    | 'created_at'
    | 'account'
    | 'watchers'
    | 'watchers_count'
    | 'contact'
    | 'contacts'
    | 'resource'
    | 'account'
    | 'account_id'
    | 'total_amount'
    | 'tags'
  > {
  account_id?: number | null
  watchers?: number[] | null
  contacts?: number[] | null
  contact?: number | null
  tags?: number[] | null
}
export interface ISendInvoicePayload {
  contacts?: number[] | null
  users?: number[] | null
  emails?: string[] | null
}

export interface IPreviewInvoicePayload {
  invoiceId: number
  email?: string | null
  expires: number
  signature: string
}

export interface ResponsePaymentInvoice {
  amount: number
  amount_in_cents: number
  payment_intent_client_secret: string
  stripe_public_key: string
}

export interface IFilterInvoices extends IFilter {
  filterAccount?: OptionValue | null
  filterProject?: OptionValue | null
  filterResource?: OptionValue | null
  filterStatus?: OptionValue | null
  filterStatusDirection?: 'include' | 'exclude' | null
  filterSpecialStatus?: string | null
  filterWarning?: boolean
  filterRecentUpdated?: false
  filterKpi?: boolean
  filterArchived?: string | null
}

export const getInvoices = async ({
  search = '',
  sortBy = 'id',
  sortDirection = 'desc',
  pageSize = PAGE_SIZE,
  currentPage = 1,
  filterAccount = null,
  filterProject = null,
  filterResource = null,
  filterStatus = null,
  filterStatusDirection = 'include',
  filterSpecialStatus = null,
  filterKpi = false,
  filterRecentUpdated = false,
  filterWarning = false,
  filterArchived = null,
}: IFilterInvoices) => {
  const sort = `${sortDirection === 'desc' ? '-' : ''}${sortBy}`
  const endPoint = generateQueryOfEndPoint('/api/invoices', {
    sort,
    'filter[search]': search,
    limit: pageSize,
    page: currentPage,
    'filter[account_id]': filterAccount?.value || null,
    'filter[project_id]': filterProject?.value || null,
    [`filter[${
      filterStatusDirection === 'exclude' && filterSpecialStatus ? '-' : ''
    }status]`]: filterSpecialStatus || filterStatus?.label || null,
    'filter[resource]':
      filterResource?.value && filterResource?.label
        ? `${filterResource.label},${filterResource.value}`
        : null,
    'filter[recently_updated]': filterRecentUpdated ? 1 : null,
    'filter[warning]': filterWarning ? 1 : null,
    'filter[archived]': filterArchived,
    kpi: filterKpi ? 1 : 0,
  })
  return client.get<IResponseInvoices>(
    `${endPoint}include=account,invoiceLineItems.product,resource,inboundEmailAddress,taxVendors`,
  )
}

export const getRecentInvoices = async (props: IFilterInvoices) => {
  return getInvoices({
    ...props,
    sortBy: 'recent',
    sortDirection: 'asc',
    pageSize: PAGE_SIZE_RECENT,
    currentPage: 1,
  })
}

export const createInvoice = async (payload: IInvoicePayload) => {
  return client.post('/api/invoices', payload)
}

export const editInvoice = (invoiceId: number, payload: IInvoicePayload) => {
  return client.put<{ data: IInvoice }>(`/api/invoices/${invoiceId}`, payload)
}
export const deleteInvoice = async (invoiceId: number) => {
  return client.delete(`/api/invoices/${invoiceId}`)
}

export const detailInvoice = async (invoiceId: number) => {
  return client.get<{ data: IInvoice }>(
    `api/invoices/${invoiceId}?include=invoiceLineItems.product,account,notes,files,contact,contacts,taxVendors,resource,inboundEmailAddress,lastTransaction,tags`,
  )
}

export const getLastestInvoiceNumber = async () => {
  return client.get('/api/invoices?sort=-id&limit=1')
}

export const sendInvoice = async (
  invoiceId: number,
  payload: ISendInvoicePayload,
) => {
  return client.post(`/api/invoices/${invoiceId}/send`, payload)
}

export const previewInvoice = async ({
  invoiceId,
  email,
  signature,
  expires,
}: IPreviewInvoicePayload) => {
  try {
    return await axios.get(
      `${BASE_URL}/api/preview/invoices/${invoiceId}?${
        email ? `email=${email}&` : ''
      }expires=${expires}&signature=${signature}`,
    )
  } catch (error) {
    return error
  }
}

export const paymentInvoice = async (invoiceId: number, amount?: number) => {
  return client.get<{ data: ResponsePaymentInvoice }>(
    `/api/invoices/${invoiceId}/payment${
      amount ? `?amount_in_cents=${amount}` : ''
    }`,
  )
}
