import { AnimatePresence } from 'framer-motion'
import { useEffect, useRef, useState } from 'react'

import { Button } from '../components'
import { Avatar, Props as AvatarProps } from '../components/Avatar'
import { IFile } from '../services/files'
import { ModalCropImage } from './Modals/Files/CropFile'
import { ModalSelectExistImage } from './Modals/Files/SelectExist'

interface Props extends AvatarProps {
  onSelectExist?: (file: Partial<IFile>) => void
  onRemove?: () => void
  showButtonUpload?: boolean
}
export const AvatarUpload = ({
  onChange,
  src,
  onSelectExist,
  onRemove,
  showButtonUpload = true,
  ...props
}: Props) => {
  const refUpload = useRef<HTMLInputElement | null>(null)
  const [srcFile, setSrcFile] = useState(src)
  const [fileCrop, setFileCrop] = useState<File | null>(null)
  const [openExist, setOpenExist] = useState(false)

  const handleChangeFile = (file: File) => {
    setFileCrop(file)
    onChange?.(file)
  }

  const handleCropSuccess = (file: File) => {
    const newFileUrl = URL.createObjectURL(file)
    setSrcFile(newFileUrl)
    onChange?.(file)
  }

  const handleOpenFile = () => {
    if (refUpload.current) {
      refUpload.current.click()
    }
  }

  const handleSelectExist = (file: Partial<IFile>) => {
    onSelectExist?.(file)
  }

  const handleRemove = () => {
    if (refUpload?.current?.value) {
      refUpload.current.value = ''
    }
    setSrcFile(null)
    onRemove?.()
  }

  useEffect(() => {
    if (src !== srcFile) {
      setSrcFile(src)
    }
  }, [src])

  return (
    <>
      <div className='flex gap-4 items-center'>
        <div className='relative'>
          <Avatar
            refField={refUpload}
            {...props}
            src={srcFile}
            onChange={handleChangeFile}
          />
          {(srcFile || refUpload?.current?.value) && (
            <div
              className='w-6 h-6 rounded-full bg-button-ternary absolute -top-3 -right-3 cursor-pointer flex items-center justify-center'
              onClick={handleRemove}
            >
              <span className='font-icon-close text-[0.625rem]' />
            </div>
          )}
        </div>
        {showButtonUpload && (
          <div className='flex gap-2 sm:gap-4 sm:items-center flex-col sm:flex-row'>
            <Button
              variant='secondary'
              className='border-primary-900 text-primary-900 rounded-lg items-center text-body font-medium'
              innerClassName='gap-2 px-6'
              onClick={handleOpenFile}
              asLink
            >
              <span className='font-icon-upload' />
              Upload
            </Button>
            {onSelectExist && (
              <Button
                variant='ternary'
                innerClassName='gap-2 px-4'
                asLink
                onClick={() => setOpenExist(true)}
              >
                <span className='font-icon-image' />
                Use Existing Image
              </Button>
            )}
          </div>
        )}
      </div>
      <AnimatePresence initial={false}>
        {fileCrop && (
          <ModalCropImage
            onSuccess={handleCropSuccess}
            onClose={() => setFileCrop(null)}
            file={fileCrop}
          />
        )}
      </AnimatePresence>
      <AnimatePresence initial={false}>
        {openExist && (
          <ModalSelectExistImage
            onSelect={handleSelectExist}
            onClose={() => setOpenExist(false)}
          />
        )}
      </AnimatePresence>
    </>
  )
}
