import { pick } from 'lodash'
import { useState } from 'react'

import { SearchAutoComplete } from '../../../components'
import { IFilter } from '../../../services/client'
import { ISearchResourceResponse } from '../../../services/resource'
import { SearchBestMatch } from './Component/BestMatch'
import { SearchFilterResource } from './Component/FilterResource'
import { ResultItem } from './Component/ResultItem'

interface Props {
  fetcher: (
    filter: IFilter,
  ) => Promise<{ data: ISearchResourceResponse | null }>
}

export const SearchOnDesktop = ({ fetcher }: Props) => {
  const [keyword, setKeyword] = useState('')
  const [resource, setResource] = useState('all')

  const renderResults = (
    { data, results }: ISearchResourceResponse,
    _?: () => void,
    k?: string,
  ) => {
    const listData = resource !== 'all' ? pick(data, [resource]) : data
    const listResults =
      resource !== 'all'
        ? results.filter(item => item.result_type === resource)
        : results
    if (Object.keys(listData).length === 0 && listResults.length === 0) {
      return (
        <div className='text-black-400 flex items-center justify-center flex-col flex-1'>
          No Data
        </div>
      )
    }
    return (
      <div className='px-4 flex flex-col gap-10 max-h-[calc(100vh_-_16rem)] custom-scrollbar overflow-y-auto text-body'>
        {results.length > 0 && (
          <SearchBestMatch results={listResults} keyword={k || ''} />
        )}
        {Object.keys(listData)
          .sort((a, b) => a.localeCompare(b))
          .map(key => {
            const value = listData[key]
            return (
              <ResultItem
                key={key}
                resource={value}
                resourceName={key}
                keyword={k || ''}
              />
            )
          })}
      </div>
    )
  }

  return (
    <div className='relative'>
      <SearchAutoComplete
        search={keyword}
        onSearch={setKeyword}
        fetcher={fetcher}
        renderResults={renderResults}
        prefixIcon={<span className='font-icon-search text-black-400' />}
        className='md:h-[3.375rem] pl-10 md:pl-16 pr-[13.5rem]'
        suffix={null}
        showBottomAction={false}
        placeholder='Search anything'
        inheritParentWidth={false}
        autoFocus={true}
        contentResultClassName='!max-h-[calc(100vh_-_20rem)]'
      />
      <div className='absolute top-1/2 -translate-y-1/2 right-2 min-w-[9.5rem] flex gap-2'>
        <SearchFilterResource onSelected={setResource} />
      </div>
    </div>
  )
}
