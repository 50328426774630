import { SVGProps } from 'react'

export const Video = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className='text-[#FF0000]'
      {...props}
    >
      <path
        d='M1.2 0L2.68 3.04H5.28L3.8 0H5.58L7.06 3.04H9.66L8.18 0H9.96L11.44 3.04H14.04L12.56 0H14.8C15.12 0 15.4 0.12 15.64 0.36C15.88 0.6 16 0.88 16 1.2V14.4571C16 14.7771 15.88 15.0571 15.64 15.2971C15.4 15.5371 15.12 15.6571 14.8 15.6571H1.2C0.88 15.6571 0.6 15.5371 0.36 15.2971C0.12 15.0571 0 14.7771 0 14.4571V1.2C0 0.88 0.12 0.6 0.36 0.36C0.6 0.12 0.88 0 1.2 0Z'
        fill='currentColor'
      />
      <path
        d='M10.5714 8.36227C10.9524 8.58221 10.9524 9.13207 10.5714 9.35202L6.71429 11.5789C6.33333 11.7989 5.85714 11.524 5.85714 11.0841V6.63022C5.85714 6.19033 6.33333 5.91541 6.71429 6.13535L10.5714 8.36227Z'
        fill='white'
      />
    </svg>
  )
}
