import cx from 'classnames'
import { useState } from 'react'

import { useAsync } from '../../../../../hooks'
import { editFile } from '../../../../../services/files'
import {
  PERMISSIONS,
  PERMISSIONS_UPDATE,
} from '../../../../../utils/permission'
import { ComponentPermisison } from '../../../../Permission/ComponentPermission'
import { TextAreaNote } from '../../../../TextAreaNote'
import { useFileDetailCtx } from '../Context'

export const FileInfoNote = () => {
  const { file, onUpdateFile } = useFileDetailCtx()
  const [isEditMode, setEditMode] = useState(false)
  const {execute, isLoading} = useAsync({showNotifOnError: true})

  const handleSaveNote = async (value: string) => {
    await execute(editFile(file.id, {note: value}))
    onUpdateFile({note: value})
    setEditMode(false)
  }

  const renderNoteInput = () => (
    <TextAreaNote note={file?.note || ''} rows={5} onSave={handleSaveNote} disabled={isLoading}/>
  )
  return (
    <>
      <div className='text-body mt-10'>
        <div className='flex items-center gap-2 mb-2'>
          <div className='text-base font-medium'>Note</div>
          <ComponentPermisison
            name={PERMISSIONS.FILE}
            type={PERMISSIONS_UPDATE}
          >
            <span className={cx('cursor-pointer' , isEditMode ? 'font-icon-close' : 'font-icon-edit')} onClick={() => setEditMode(prev => !prev)}/>
          </ComponentPermisison>
        </div>
        <div>
          {
            isEditMode ? (
              renderNoteInput()
            ) : (
              <div className='text-black-400'>
              {file.note}
            </div>
            )
          }
        </div>
      </div>
    </>
  )
}
