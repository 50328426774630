import cx from 'classnames'
import { sortBy } from 'lodash'
import { SelectProps } from 'rc-select'
import { CustomTagProps } from 'rc-select/lib/BaseSelect'

import { Avatar, Tooltip } from '../../../components'
import { AsyncSelect } from '../../../components/Forms/AsyncSelect'
import { ErrorCircle } from '../../../icons'
import { getContacts, IContact } from '../../../services/contacts'
import { getUsers, IUser } from '../../../services/users'
import { validateEmail } from '../../../utils/form'

interface Props extends Omit<SelectProps, 'options' | 'onSelect'> {
  onSelect?: (value: Record<string, string>[]) => void
  error?: boolean
}

export const SendQuoteSelect = ({ onSelect, error }: Props) => {
  const handleGetData = async ({ search = '' } = {}) => {
    try {
      const params = {
        search,
        currentPage: 1,
        pageSize: 10,
      }

      const [usersResult, contactsResult] = await Promise.allSettled([
        getUsers(params),
        getContacts({
          ...params,
          filterHasEmail: true,
        }),
      ])
      const users =
        usersResult.status === 'fulfilled'
          ? usersResult.value.data.data.map((item: IUser) => ({
              ...item,
              type: 'user',
            }))
          : []

      const contacts =
        contactsResult.status === 'fulfilled'
          ? contactsResult.value.data.data.map((item: IContact) => ({
              ...item,
              type: 'contact',
            }))
          : []

      const data = sortBy([...users, ...contacts], ['name'])
      return { data, totalPage: 1 }
    } catch {
      return { data: [], totalPage: 1 }
    }
  }

  const handleSelect = (item: any) => {
    const newItem = item.map((a: any) => {
      const label = a.label
        ? a.value.split('-')[0]
        : validateEmail(a.value)
        ? 'email'
        : undefined
      const value = a.label ? a.value.split('-')[1] : a.value
      return {
        value,
        label,
      }
    })
    onSelect?.(newItem)
  }

  const isValidValue = (p: CustomTagProps) => {
    if (typeof p.label === 'string') {
      return validateEmail(p.label)
    }
    return true
  }

  const tagRender = (p: CustomTagProps) => {
    const validValue = isValidValue(p)
    return (
      <div
        className={cx(
          'select-selection-item-content flex items-center gap-2 px-2 py-[0.3125rem] min-h-[2.5rem] rounded-lg border border-separation-900 bg-separation-200',
          !validValue && 'bg-red-400 border-red-700',
        )}
      >
        <div>{p.label}</div>
        {!validValue && (
          <span>
            <ErrorCircle className='text-red-900' />
          </span>
        )}
        <span
          className='font-icon-close cursor-pointer text-black-400 text-[0.75rem]'
          onClick={p.onClose}
        />
      </div>
    )
  }

  return (
    <div>
      <AsyncSelect
        showArrow={false}
        notFoundContent={null}
        mode='tags'
        labelInValue
        onChange={handleSelect}
        className='select-tags selct-tags-quote mb-2 min-h-[12.5rem] h-full'
        fetcher={handleGetData}
        paginate={false}
        tagRender={tagRender}
        error={error}
        filterOption={true}
      >
        {(data: any[]) => {
          return data.map(item => (
            <AsyncSelect.Option key={`${item.type}-${item.id}`}>
              <div className='flex items-center gap-2'>
                <Avatar
                  hashId={`${item.type}-${item.id}`}
                  src={item.avatar_preview_url}
                  size={28}
                />
                <Tooltip
                  content={`This is a ${item.type}`}
                  className='leading-none h-[0.875rem]'
                >
                  <span
                    className={cx(
                      'text-black-400 cursor-pointer',
                      item.type === 'user'
                        ? 'font-icon-user'
                        : 'font-icon-book',
                    )}
                  />
                </Tooltip>
                <div className='text-body flex flex-col flex-1 w-full text-black-800 leading-none'>
                  {item.name}
                </div>
              </div>
            </AsyncSelect.Option>
          ))
        }}
      </AsyncSelect>
    </div>
  )
}
