import { useState } from 'react'

import { Popover } from '../../../../components'
import { listResourcesSearch } from '../../../../utils/resources'
import { PopoverGlobalSearch } from '../../../Popover/Search'

interface Props {
  onSelected: (resource: string) => void
}
export const SearchFilterResource = ({ onSelected }: Props) => {
  const [resource, setResource] = useState('all')

  const detailResourceSearch = listResourcesSearch.find(
    item => item.value === resource,
  )

  const handleSelect = (s: string) => {
    setResource(s)
    onSelected(s)
  }

  return (
    <>
      <span className='w-px h-6 md:h-10 bg-separation-800' />
      <div className='flex-1 flex items-center'>
        <Popover
          placement='bottom-start'
          content={onClosePopover => (
            <PopoverGlobalSearch
              selected={resource}
              onClose={onClosePopover}
              onSelect={handleSelect}
            />
          )}
          className='w-full'
        >
          <div className='flex gap-2 items-center flex-1 text-body justify-between cursor-pointer'>
            <div className='flex gap-2 items-center'>
              {detailResourceSearch?.icon && (
                <span className={`font-icon-${detailResourceSearch.icon}`} />
              )}
              <div>
                {detailResourceSearch
                  ? detailResourceSearch.label
                  : listResourcesSearch[0]}
              </div>
            </div>
            <span className='font-icon-arrow_down' />
          </div>
        </Popover>
      </div>
    </>
  )
}
