import { StoreApi } from 'zustand'

import {
  IAccountingSettings,
  IAccountSettings,
  IAssetSettings,
  ICompanySettings,
  IExpenseSettings,
  IInvoiceSettings,
  IProductSettings,
  IProjectSettings,
  IQuoteSettings,
  ISaleProposal,
  ISettings,
  IUserSettings,
} from './../services/settings'
import type { Store } from './index'

export interface SettingsSlice {
  quote: IQuoteSettings | null
  account: IAccountSettings | null
  asset: IAssetSettings | null
  project: IProjectSettings | null
  invoice: IInvoiceSettings | null
  expense: IExpenseSettings | null
  accounting: IAccountingSettings | null
  user: IUserSettings | null
  company: ICompanySettings | null
  product: IProductSettings | null
  sales_proposal: ISaleProposal | null
  setSettings: (payload: Partial<ISettings>) => void
}

const slice = (set: StoreApi<Store>['setState']) => ({
  quote: null,
  account: null,
  project: null,
  asset: null,
  accounting: null,
  user: null,
  company: null,
  expense: null,
  invoice: null,
  product: null,
  sales_proposal: null,
  setSettings: (payload: Partial<ISettings>) => {
    return set(({ settings }) => ({
      settings: { ...settings, ...payload },
    }))
  },
})

export default slice
