import cx from 'classnames'
import { AnimatePresence } from 'framer-motion'
import { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import shallow from 'zustand/shallow'

import Logo from '../../assets/images/logo_system.png'
import { Popover } from '../../components'
import { INavigation } from '../../configs/navigations'
import { ModalEditMenus } from '../../shared/Modals/Menus/EditMenu'
import useStore, { Store } from '../../store'
import { generateMainMenu } from '../../utils/menus'
import { checkMenuPermisison, PERMISSIONS } from '../../utils/permission'
import { TopRightMenu } from './ProfileMenu'

const mapStore = (store: Store) => ({
  currentMenu: store.menus.currentMenu,
  permissions: store.auth.currentUser?.can,
  setMenu: store.menus.setMenu,
})

export const TopMenu = () => {
  const { pathname } = useLocation()
  const [isOpenEditMenu, setOpenEditMenu] = useState(false)
  const { currentMenu, permissions = [], setMenu } = useStore(mapStore, shallow)

  const renderMenuItem = (menuItem: INavigation) => {
    const { label, navLink, query = '', key } = menuItem
    const active = pathname === navLink

    return (
      <div key={`${key}-${label}`} className='relative h-full flex gap-1'>
        {!!menuItem?.children ? (
          <MenuHasSub menuItem={menuItem} active={active} />
        ) : (
          <Link
            to={navLink! + query}
            className={cx(
              'text-body group text-black-800 px-3 rounded-t-lg inline-flex relative items-center h-full border border-transparent gap-2',
            )}
          >
            {label}
            {/* {numberNoti && <Badge count={numberNoti} className='ml-1' />} */}
            <div
              className={cx(
                'absolute rounded-lg bg-primary-900 bottom-0 w-full h-1 left-0 group-hover:block hidden',
                active && '!block',
              )}
            />
          </Link>
        )}
      </div>
    )
  }

  const initMenu = () => {
    const menu = generateMainMenu()
    const filterMenus = menu.filter(item =>
      checkMenuPermisison(PERMISSIONS[item.key], permissions),
    )
    setMenu(filterMenus)
  }

  useEffect(() => {
    initMenu()
  }, [])

  return (
    <>
      <div className='flex w-full h-full px-4 sm:px-6 gap-1 items-center justify-between bg-white shadow-card'>
        <div className='flex items-center sm:pt-1'>
          <Link to='/' className='block w-7' aria-label='logo'>
            <img
              src={Logo}
              className='cursor-pointer w-full'
              width='28'
              height='28'
              alt='Logo'
            />
          </Link>
          <div className='flex-1 hidden sm:flex gap-x-1 flex-wrap overflow-hidden h-[3rem] ml-4'>
            {currentMenu.map(renderMenuItem)}
          </div>
          <div
            className='ml-4 cursor-pointer hidden sm:block'
            onClick={() => setOpenEditMenu(true)}
          >
            <span className='font-icon-edit text-black-800' />
          </div>
        </div>
        <TopRightMenu />
      </div>
      <AnimatePresence initial={false}>
        {isOpenEditMenu && (
          <ModalEditMenus onClose={() => setOpenEditMenu(false)} />
        )}
      </AnimatePresence>
    </>
  )
}

const MenuHasSub = ({
  menuItem,
  active,
}: {
  menuItem: INavigation
  active: boolean
}) => {
  const [visible, setVisible] = useState(false)
  const renderSubMenu = (onClose: () => void) => (
    <Popover.Content
      onClose={onClose}
      className='w-[13rem] !p-0 shadow-dropdown'
    >
      <div className='flex flex-col gap-4 py-3'>
        {menuItem.children?.map(item => (
          <Link
            to={`${menuItem.navLink}${item.navLink}`}
            onClick={() => {
              onClose()
            }}
            key={item.key}
            className='px-4 cursor-pointer flex gap-2 items-center'
          >
            {item.Icon}
            {item.label}
          </Link>
        ))}
      </div>
    </Popover.Content>
  )
  return (
    <div
      className={cx(
        'text-body group text-black-800 flex flex-col relative items-center h-full pr-3',
      )}
    >
      <Link
        to={menuItem.navLink}
        className='px-3 flex-1 h-full flex items-center'
      >
        <span>{menuItem.label}</span>
      </Link>
      <Popover
        placement='bottom'
        visible={visible}
        onVisibleChange={(s: boolean) => setVisible(s)}
        content={onClose => renderSubMenu(onClose)}
        className='leading-none absolute top-1/2 right-1 -translate-y-1/2 cursor-pointer'
      >
        <span className='text-black-800 font-icon-arrow_down text-[1rem] right-3' />
      </Popover>
      <div
        className={cx(
          'absolute rounded-lg bg-primary-900 bottom-0 w-full h-1 left-0 group-hover:block hidden',
          active && '!block',
        )}
      />
    </div>
  )
}
