import { Select, SelectProps } from '../../../components'
import { Spinner } from '../../../components/Spinner'
import { PATHNAME } from '../../../configs/routes'
import { useAsync } from '../../../hooks'
import { usePermission } from '../../../hooks/usePermission'
import { editOpportunity, IOpportunity } from '../../../services/opportunities'
import { OPPORTUNITY_STAGE } from '../../../utils/opportunities'
import { PERMISSIONS } from '../../../utils/permission'
import toast from '../../../utils/toast'

interface Props extends Omit<SelectProps, 'options'> {
  defaultValue?: string | null
  opportunityId?: number | null
  onUpdateComplete?: (data: IOpportunity) => void
}
export const OpportunityStageSelect = ({
  defaultValue = '',
  opportunityId = null,
  onUpdateComplete,
  ...props
}: Props) => {
  const { isLoading, execute } = useAsync({ showNotifOnError: true })
  const { canUpdate } = usePermission({ name: PERMISSIONS.OPPORTUNITY })
  const handleChange = async (value: string) => {
    if (opportunityId) {
      const response = await execute(
        editOpportunity(opportunityId, { stage: value }),
      )
      onUpdateComplete?.(response.data.data)
      toast.success({
        title: `Opportunity "${response.data.data.name}" updated`,
        path: `/${PATHNAME.opportunities}/${opportunityId}`,
      })
    }
  }

  const customProps = opportunityId
    ? { ...props, onChange: (value: string) => handleChange(value) }
    : { ...props }

  if (!canUpdate && opportunityId) {
    return <div className='relative'>{defaultValue}</div>
  }
  return (
    <div className='relative'>
      <Select {...customProps} defaultValue={defaultValue}>
        {OPPORTUNITY_STAGE.map(s => (
          <Select.Option value={s} key={s}>
            {s}
          </Select.Option>
        ))}
      </Select>
      {isLoading && (
        <>
          <div className='absolute inset-0 bg-white opacity-60 w-full h-full z-10' />
          <div className='absolute top-1/2 right-2 z-20 -translate-y-1/2'>
            <Spinner size='xsmall' />
          </div>
        </>
      )}
    </div>
  )
}
