import { StoreApi } from 'zustand'

import { IConfig } from '../services/config'
import type { Store } from './index'

export interface ConfigsSlice {
  currentConfigs: IConfig | null
  setConfigs: (config: IConfig) => void
}

const slice = (set: StoreApi<Store>['setState']) => ({
  currentConfigs: null,
  setConfigs: (payload: IConfig) => {
    return set(({ configs }) => ({
      configs: { ...configs, currentConfigs: payload },
    }))
  },
})

export default slice
