import { StoreApi } from 'zustand'

import { IUserRole } from '../services/roles'
import type { Store } from './index'

export interface RolesSlice {
  roles: IUserRole[]
  setRoles: (payload: IUserRole[]) => void
}

const slice = (set: StoreApi<Store>['setState']) => ({
  roles: [],
  setRoles: (payload: IUserRole[]) => {
    return set(({ roles }) => ({ roles: { ...roles, roles: payload } }))
  },
})

export default slice
