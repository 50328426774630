import cx from 'classnames'
import { useState } from 'react'

import { Avatar } from '../../../../components'
import { FileNameInfo } from '../../../../pages/Files/Share/FileNameInfo'
import { IAccount } from '../../../../services/accounts'
import { IAsset } from '../../../../services/assets'
import { IContact } from '../../../../services/contacts'
import { IContract } from '../../../../services/contracts'
import { IExpense } from '../../../../services/expenses'
import { IFile } from '../../../../services/files'
import { IInvoice } from '../../../../services/invoices'
import { IMonitoring } from '../../../../services/monitoring'
import { INote } from '../../../../services/notes'
import { IOpportunity } from '../../../../services/opportunities'
import { IProduct } from '../../../../services/products'
import { IProject } from '../../../../services/projects'
import { IQuote } from '../../../../services/quotes'
import { TSearchResourceResult } from '../../../../services/resource'
import { IService } from '../../../../services/services'
import { IUser } from '../../../../services/users'
import { renderAccountName } from '../../../../utils/accounts'
import { formatDateNoTz } from '../../../../utils/dateTime'
import {
  currencyFormat,
  parserHtmlToText,
  splitParagraph,
} from '../../../../utils/functions'
import {
  RESOURCE_ACCOUNT,
  RESOURCE_ASSET,
  RESOURCE_CONTACT,
  RESOURCE_CONTRACT,
  RESOURCE_EXPENSE,
  RESOURCE_INVOICE,
  RESOURCE_MEDIA,
  RESOURCE_MONITORING,
  RESOURCE_NOTE,
  RESOURCE_OPPORTUNITY,
  RESOURCE_PRODUCT,
  RESOURCE_PROJECT,
  RESOURCE_QUOTE,
  RESOURCE_SERVICE,
  RESOURCE_TYPE_LABEL,
  RESOURCE_USER,
} from '../../../../utils/resources'
import { AccountAvatar, AccountName } from '../../../AccountName'
import { ButtonFavorite } from '../../../ButtonFavorite'
import { HightLightText } from '../../../HightLightText'
import { PhoneNumberView } from '../../../PhoneNumberField'
import { ContractIconStatus } from '../../../Resources/IconStatus/Contract'
import { InvoiceIconStatus } from '../../../Resources/IconStatus/Invoice'
import { ProjectIconStatus } from '../../../Resources/IconStatus/Project'
import { QuoteIconStatus } from '../../../Resources/IconStatus/Quote'
import { ServiceIconStatus } from '../../../Resources/IconStatus/Service'
import { ResourceName } from '../../../Resources/ResourceName'
import { ResourceThumbnail } from '../../../Resources/ResourceThumbnail'
import { CustomResourceLink } from './CustomResourceLink'

const resourceTypeSupportSeach = [
  RESOURCE_ACCOUNT,
  RESOURCE_ASSET,
  RESOURCE_CONTACT,
  RESOURCE_CONTRACT,
  RESOURCE_EXPENSE,
  RESOURCE_INVOICE,
  RESOURCE_MEDIA,
  RESOURCE_MONITORING,
  RESOURCE_NOTE,
  RESOURCE_OPPORTUNITY,
  RESOURCE_PRODUCT,
  RESOURCE_PROJECT,
  RESOURCE_QUOTE,
  RESOURCE_SERVICE,
  RESOURCE_USER,
]
export const SearchBestMatch = ({
  results,
  keyword,
}: {
  results: TSearchResourceResult[]
  keyword: string
}) => {
  const [showMore, setShowMore] = useState(false)
  return (
    <div>
      <div className='text-black-500 font-medium mb-4'>
        BEST MATCH ({results.length})
      </div>
      <div className='flex flex-col gap-2'>
        {results.map((item, index) => {
          if (!resourceTypeSupportSeach.includes(item.result_type)) {
            return null
          }
          return (
            <div
              className={cx(
                'px-4 py-2.5 bg-separation-100 rounded-lg hover:bg-primary-300 text-body flex flex-col gap-1',
                !showMore && index > 5 && 'hidden',
              )}
              key={`${item.result_type}-${item.id}-${index}`}
            >
              <SearchBestMatchItem result={item} keyword={keyword} />
            </div>
          )
        })}
      </div>
      {!showMore && results.length > 5 && (
        <div
          onClick={() => setShowMore(true)}
          className='text-primary-900 font-medium cursor-pointer text-center py-1.5 hover:bg-primary-300 rounded-lg mt-4'
        >
          Show More
        </div>
      )}
    </div>
  )
}

const SearchBestMatchItem = ({
  result,
  keyword,
}: {
  result: TSearchResourceResult
  keyword: string
}) => {
  const renderHighLightText = (text?: string | number | null) => {
    return <HightLightText searchWord={keyword} text={text ? text + '' : ''} />
  }
  const { result_type } = result
  if (result_type === RESOURCE_ACCOUNT) {
    const account = result as IAccount
    return (
      <div className='flex justify-between gap-4 items-center'>
        <div>
          <CustomResourceLink
            resourceType={RESOURCE_ACCOUNT}
            resourceId={account.id}
            className='flex gap-2 items-center mb-1'
          >
            <span className='text-black-400'>Account name:</span>
            <span className='font-medium'>
              {renderHighLightText(renderAccountName(account))}
            </span>
          </CustomResourceLink>
          <div className='flex gap-2 items-center text-black-400'>
            {account.phone_no && (
              <PhoneNumberView
                phone={account.phone_no}
                showFlag={false}
                phoneClassName='no-underline'
              />
            )}
            {account.phone_no && account.email && (
              <span className='w-px h-4 bg-separation-800' />
            )}
            {account.email && (
              <a href={`mailto:${account.email}`}>
                {renderHighLightText(account.email)}
              </a>
            )}
          </div>
        </div>
        <AccountAvatar account={account} />
      </div>
    )
  }
  if (result_type === RESOURCE_USER) {
    const user = result as IUser
    return (
      <div className='flex justify-between gap-4 items-center'>
        <div>
          <CustomResourceLink
            resourceType={RESOURCE_USER}
            resourceId={user.id}
            className='flex gap-2 items-center mb-1'
          >
            <span className='text-black-400'>User name:</span>
            <span className='font-medium'>
              {renderHighLightText(user.name)}
            </span>
          </CustomResourceLink>
          <div className='flex gap-2 items-center text-black-400'>
            {user.phone && (
              <PhoneNumberView
                phone={user.phone}
                showFlag={false}
                phoneClassName='no-underline'
              />
            )}
            {user.phone && user.email && (
              <span className='w-px h-4 bg-separation-800' />
            )}
            {user.email && (
              <a href={`mailto:${user.email}`}>
                {renderHighLightText(user.email)}
              </a>
            )}
          </div>
        </div>
        <Avatar
          src={user.avatar_preview_url || user.avatar_url}
          hashId={`user-${user.id}`}
        />
      </div>
    )
  }

  if (result_type === RESOURCE_ASSET) {
    const asset = result as IAsset
    return (
      <div className='flex justify-between gap-4 items-center'>
        <CustomResourceLink
          resourceType={RESOURCE_ASSET}
          resourceId={asset.id}
          className='mb-1 flex-col gap-1'
        >
          <div className='flex gap-2'>
            <span className='text-black-400'>Asset name:</span>
            <span className='font-medium'>
              {renderHighLightText(asset.name)}
            </span>
          </div>
          <div className='flex gap-2 items-center text-black-400'>
            <span>{asset.id}</span>
            <span className='h-3.5 w-px bg-separation-800' />
            <span>{renderHighLightText(asset.type)}</span>
          </div>
        </CustomResourceLink>
        <ResourceThumbnail
          thumbnail={asset.avatar_preview_url || asset.avatar_url}
        />
      </div>
    )
  }

  if (result_type === RESOURCE_PRODUCT) {
    const product = result as IProduct
    return (
      <div className='flex justify-between gap-4 items-center'>
        <CustomResourceLink
          resourceType={RESOURCE_PRODUCT}
          resourceId={product.id}
          className='mb-1 flex-col gap-1'
        >
          <div className='flex gap-2'>
            <span className='text-black-400'>Product name:</span>
            <span className='font-medium'>
              {renderHighLightText(product.name)}
            </span>
          </div>
          <div className='flex gap-2 text-black-400 items-center'>
            {product.manufacturer && (
              <>
                <div>Accounts: </div>
                <AccountName
                  account={product.manufacturer}
                  showAvatar
                  textClassName='no-underline'
                />
              </>
            )}
            <span>{product.id}</span>
            {product.cost && (
              <>
                <span className='h-3.5 w-px bg-separation-800' />
                <div>
                  <span className='font-medium'>Cost:</span>{' '}
                  {currencyFormat(product.cost)}
                </div>
              </>
            )}
            {product.sku && (
              <>
                <span className='h-3.5 w-px bg-separation-800' />
                <div>
                  <span className='font-medium'>SKU:</span> {product.sku}
                </div>
              </>
            )}
            {product.type && (
              <>
                <span className='h-3.5 w-px bg-separation-800' />
                <span>{renderHighLightText(product.type)}</span>
              </>
            )}
          </div>
        </CustomResourceLink>
        <ResourceThumbnail
          thumbnail={product.avatar_preview_url || product.avatar_url}
        />
      </div>
    )
  }

  if (result_type === RESOURCE_CONTACT) {
    const contact = result as IContact
    return (
      <div className='flex justify-between gap-4 items-center'>
        <div>
          <CustomResourceLink
            resourceType={RESOURCE_USER}
            resourceId={contact.id}
            className='flex gap-2 items-center mb-1'
          >
            <span className='text-black-400'>Contact name:</span>
            <span className='font-medium'>
              {renderHighLightText(contact.name)}
            </span>
          </CustomResourceLink>
          <div className='flex gap-2 items-center text-black-400'>
            {contact.phone_mobile && (
              <PhoneNumberView
                phone={contact.phone_mobile}
                showFlag={false}
                phoneClassName='no-underline'
              />
            )}
            {contact.phone_mobile && contact.email && (
              <span className='w-px h-4 bg-separation-800' />
            )}
            {contact.email && (
              <a href={`mailto:${contact.email}`}>
                {renderHighLightText(contact.email)}
              </a>
            )}
          </div>
        </div>
        <Avatar
          src={contact.avatar_preview_url || contact.avatar_url}
          hashId={`contact-${contact.id}`}
        />
      </div>
    )
  }

  if (result_type === RESOURCE_INVOICE) {
    const invoice = result as IInvoice
    return (
      <>
        <CustomResourceLink
          resourceId={invoice.id}
          resourceType={RESOURCE_INVOICE}
          className='flex items-center gap-2 mb-1'
        >
          <span className='text-black-400'>Invoice Number: </span>
          <span className='font-medium'>
            {renderHighLightText(invoice.invoice_number)}
          </span>
          <InvoiceIconStatus invoice={invoice} />
        </CustomResourceLink>
        <div className='flex gap-2 items-center'>
          <CustomResourceLink
            resourceType={RESOURCE_ACCOUNT}
            resourceId={invoice.account?.id}
            className='flex gap-2 items-center text-black-400'
          >
            <div>Accounts: </div>
            <AccountName
              account={invoice.account}
              showAvatar
              textClassName='no-underline'
            />
          </CustomResourceLink>
          {invoice.resource && invoice.resource_type && (
            <>
              <span className='w-px h-4 bg-separation-800' />
              <CustomResourceLink
                resourceType={invoice.resource_type}
                resourceId={invoice.resource.id}
                className='flex gap-2 items-center text-black-400'
              >
                <div>{RESOURCE_TYPE_LABEL[invoice.resource_type]}: </div>
                <ResourceName
                  resource={invoice.resource}
                  resourceType={invoice.resource_type}
                />
              </CustomResourceLink>
            </>
          )}
        </div>
      </>
    )
  }

  if (result_type === RESOURCE_CONTRACT) {
    const contract = result as IContract
    return (
      <>
        <CustomResourceLink
          resourceId={contract.id}
          resourceType={RESOURCE_CONTRACT}
          className='relative pr-4 cursor-pointer flex items-center gap-2'
        >
          <span className='text-black-400'>Contract Number: </span>
          <span className='block leading-none font-medium'>
            {renderHighLightText(contract.contract_number)}
          </span>
          <ContractIconStatus contract={contract} />
        </CustomResourceLink>
        <CustomResourceLink
          resourceType={RESOURCE_ACCOUNT}
          resourceId={contract.account?.id}
          className='flex gap-2 items-center text-black-400'
        >
          <div>Accounts: </div>
          <AccountName
            account={contract.account}
            showAvatar
            textClassName='no-underline'
          />
        </CustomResourceLink>
      </>
    )
  }

  if (result_type === RESOURCE_MEDIA) {
    const file = result as IFile
    return (
      <>
        <CustomResourceLink
          resourceId={file.id}
          resourceType={RESOURCE_MEDIA}
          className='flex gap-2 items-center'
        >
          <FileNameInfo file={file} avatarSize={40} fileNameSize={60} />
        </CustomResourceLink>
      </>
    )
  }

  if (result_type === RESOURCE_MONITORING) {
    const monitoring = result as IMonitoring
    return (
      <>
        <CustomResourceLink
          resourceId={monitoring.id}
          resourceType={RESOURCE_MONITORING}
          className='relative pr-4 cursor-pointer flex items-center gap-2'
        >
          <span className='text-black-400'>Monitoring Name: </span>
          <span className='block leading-none font-medium'>
            {renderHighLightText(monitoring.name)}
          </span>
        </CustomResourceLink>
        <CustomResourceLink
          resourceType={RESOURCE_ACCOUNT}
          resourceId={monitoring.account?.id}
          className='flex gap-2 items-center text-black-400'
        >
          <div>Accounts: </div>
          <AccountName
            account={monitoring.account}
            showAvatar
            textClassName='no-underline'
          />
        </CustomResourceLink>
      </>
    )
  }

  if (result_type === RESOURCE_QUOTE) {
    const quote = result as IQuote
    return (
      <>
        <CustomResourceLink
          resourceId={quote.id}
          resourceType={RESOURCE_QUOTE}
          className='flex items-center gap-2'
        >
          <span>Quote: </span>
          <span className='leading-normal font-medium'>
            {renderHighLightText(
              !!quote?.regarding ? quote.regarding : quote.quote_number,
            )}
          </span>
          <QuoteIconStatus quote={quote} />
        </CustomResourceLink>
        <CustomResourceLink
          resourceType={RESOURCE_ACCOUNT}
          resourceId={quote.account?.id}
          className='flex gap-2 items-center text-black-400'
        >
          <div>Accounts: </div>
          <AccountName
            account={quote.account}
            showAvatar
            textClassName='no-underline'
          />
        </CustomResourceLink>
      </>
    )
  }

  if (result_type === RESOURCE_PROJECT) {
    const project = result as IProject
    return (
      <div className='px-4 py-2.5 bg-separation-100 rounded-lg hover:bg-primary-300 text-body'>
        <CustomResourceLink
          resourceId={project.id}
          resourceType={RESOURCE_PROJECT}
          className='flex items-center gap-2 mb-1'
        >
          <span>Project Name: </span>
          <span className='font-medium'>
            {renderHighLightText(project.name)}
          </span>
          <ProjectIconStatus project={project} />
          {project.is_favorited && <ButtonFavorite currentStatus viewOnly />}
        </CustomResourceLink>
        <CustomResourceLink
          resourceType={RESOURCE_ACCOUNT}
          resourceId={project.account?.id}
          className='flex gap-2 items-center text-black-400'
        >
          <div>Accounts: </div>
          <AccountName
            account={project.account}
            showAvatar
            textClassName='no-underline'
          />
        </CustomResourceLink>
      </div>
    )
  }

  if (result_type === RESOURCE_OPPORTUNITY) {
    const opportunity = result as IOpportunity
    return (
      <div>
        <CustomResourceLink
          resourceId={opportunity.id}
          resourceType={RESOURCE_MONITORING}
          className='relative pr-4 cursor-pointer flex items-center gap-2'
        >
          <span className='text-black-400'>Opportunity Name: </span>
          <span className='block leading-none font-medium'>
            {renderHighLightText(opportunity.name)}
          </span>
        </CustomResourceLink>
        <CustomResourceLink
          resourceType={RESOURCE_ACCOUNT}
          resourceId={opportunity.account?.id}
          className='flex gap-2 items-center text-black-400'
        >
          <div>Accounts: </div>
          <AccountName
            account={opportunity.account}
            showAvatar
            textClassName='no-underline'
          />
        </CustomResourceLink>
      </div>
    )
  }

  if (result_type === RESOURCE_SERVICE) {
    const service = result as IService
    return (
      <CustomResourceLink
        resourceId={service.id}
        resourceType={RESOURCE_SERVICE}
        className='flex items-center gap-2 mb-1'
      >
        <span>Service: </span>
        <span className='font-medium'>
          {renderHighLightText(
            parserHtmlToText(service?.reason_for_visit || ''),
          )}
        </span>
        <ServiceIconStatus service={service} />
      </CustomResourceLink>
    )
  }

  if (result_type === RESOURCE_EXPENSE) {
    const expense = result as IExpense
    return (
      <>
        <CustomResourceLink
          resourceId={expense.id}
          resourceType={RESOURCE_EXPENSE}
          className='relative pr-4 cursor-pointer flex items-center gap-2'
        >
          <span>Expense Name: </span>
          <span className='block leading-none font-medium'>
            {renderHighLightText(expense.name)}
          </span>
          {expense.resource_type && (
            <span className='text-black-400'>
              ({RESOURCE_TYPE_LABEL[expense.resource_type]})
            </span>
          )}
        </CustomResourceLink>
        <div className='flex gap-2 text-black-400 items-center'>
          <div className='flex gap-2 items-center'>
            <span className='font-icon-money' />
            <span className='font-medium text-black-800'>
              {currencyFormat(expense.amount)}
            </span>
          </div>
          {expense.expense_category && (
            <>
              <span className='h-3.5 w-px bg-separation-800' />
              <div>{expense.expense_category}</div>
            </>
          )}
          <span className='h-3.5 w-px bg-separation-800' />
          <div>{expense.status}</div>
          <span className='h-3.5 w-px bg-separation-800' />
          <div>Created at {formatDateNoTz(expense.created_at)}</div>
        </div>
      </>
    )
  }
  if (result_type === RESOURCE_NOTE) {
    const note = result as INote
    return (
      <CustomResourceLink
        resourceId={note.resource_id}
        resourceType={note.resource_type}
        pathName='/notes'
        className='flex flex-col gap-2'
      >
        <div className='relative pr-4 cursor-pointer flex items-center gap-2'>
          <span>Note: </span>
          <span className='block leading-none font-medium'>
            {renderHighLightText(
              splitParagraph(parserHtmlToText(note.body), 20),
            )}
          </span>
        </div>
        <div className='flex gap-2 text-black-400 items-center'>
          <span>Found in {RESOURCE_TYPE_LABEL[note.resource_type]}</span>
          <span className='h-3.5 w-px bg-separation-800' />
          <div>Created at {formatDateNoTz(note.created_at)}</div>
        </div>
      </CustomResourceLink>
    )
  }
  return null
}
