import { OptionValue } from '../utils/form'
import { generateQueryOfEndPoint } from '../utils/functions'
import { IAccount } from './accounts'
import { IAsset, TMetaAsset } from './assets'
import client, { IFilter, PAGE_SIZE, PAGE_SIZE_RECENT } from './client'
import { IFile } from './files'
import { INote } from './notes'
import { IDetailSettings } from './settings'
import { ITag } from './tags'

export interface IProduct {
  id: number
  name: string
  sku?: string | null
  type?: string | null
  type_id?: number | null
  manufacturer_id?: number | null
  manufacturer?: IAccount | null
  description?: string | null
  resale_price?: number | null
  web_resale_price?: number | null
  cost?: number | null
  msrp?: number | null
  model_number?: string | null
  avatar_url?: string | null
  avatar_preview_url?: string | null
  suppliers?: string[] | null
  product_images?: IFile[] | null
  product_cut_sheets?: IFile[] | null
  product_manuals?: IFile[] | null
  files?: IFile[] | null
  notes?: INote[] | null
  assets?: IAsset[] | null
  created_at: Date | string
  accounts: IAccount[]
  relatedProducts?: IProduct[] | null
  tags?: ITag[] | null
  categories?: IDetailSettings[] | null
  inbound_email_address?: string | null
  meta?: TMetaAsset | null
}

export interface IProductSupplierPayload {
  account_id?: number | null
  product_links?: string[] | null
}

export interface IProductPriceChange {
  timestamp: Date | string
  cost: string
  msrp: string
  resale_price: string
}
export interface IProductPayload {
  name?: string | null
  sku?: string | null
  manufacturer_id?: number | null
  type?: string | null
  type_id?: number | null
  description?: string | null
  web_resale_price?: number | null
  resale_price?: number | null
  msrp?: number | null
  cost?: number | null
  avatar?: File | null
  product_images?: File[] | null
  product_cut_sheets?: File[] | null
  product_manuals?: File[] | null
  model_number?: string | null
  files?: File[] | null
  assets?: number[] | null
  suppliers?: IProductSupplierPayload[] | null
  related_product_ids?: number[] | null
  category_ids?: number[] | null
  tags?: number[] | null
  existing_avatar_id?: number | null
  inbound_email_address?: string | null
  meta?: TMetaAsset | null
}

export interface IFilterProducts extends IFilter {
  filterAccount?: OptionValue | null
  fiterManufacturer?: OptionValue | null
  filterRelatedProduct?: number | null
  filterCategory?: OptionValue | null
}
export interface submitFormProductToAsset {
  asset_group_id: OptionValue | null
  quantity: number
  resource_type: OptionValue | null
  resource_id: OptionValue | null
}
export const getProducts = async ({
  search = '',
  sortBy = 'id',
  sortDirection = 'desc',
  pageSize = PAGE_SIZE,
  currentPage = 1,
  filterAccount = null,
  fiterManufacturer = null,
  filterCategory = null,
}: IFilterProducts) => {
  const sort = `${sortDirection === 'desc' ? '-' : ''}${sortBy}`
  const endPoint = generateQueryOfEndPoint('/api/products', {
    sort,
    'filter[search]': search,
    'filter[category]': filterCategory?.value || null,
    limit: pageSize,
    page: currentPage,
    'filter[account_id]': filterAccount?.value || null,
    'filter[manufacturer_id]': fiterManufacturer?.value || null,
  })
  return client.get(`${endPoint}include=accounts,manufacturer,categories`)
}

export const getProductByIds = async (ids: number[], params = '') => {
  return client.get(
    `/api/products?filter[id]=${ids.join(',')}${
      params !== '' ? `&${params}` : ''
    }&include=categories,manufacturer,accounts`,
  )
}

export const getRecentProducts = async (props: IFilterProducts) => {
  return getProducts({
    ...props,
    sortBy: 'recent',
    sortDirection: 'asc',
    pageSize: PAGE_SIZE_RECENT,
    currentPage: 1,
  })
}

export const getRelatedProducts = async ({
  search = '',
  sortBy = 'id',
  sortDirection = 'desc',
  pageSize = PAGE_SIZE,
  currentPage = 1,
  filterRelatedProduct = null,
}: IFilterProducts) => {
  const sort = `${sortDirection === 'desc' ? '-' : ''}${sortBy}`
  const endPoint = generateQueryOfEndPoint('/api/products', {
    sort,
    'filter[search]': search,
    limit: pageSize,
    page: currentPage,
    'filter[related_product_id]': filterRelatedProduct || null,
  })
  return client.get(`${endPoint}include=manufacturer`)
}

export const createProduct = async (payload: IProductPayload) => {
  return client.post('/api/products', payload)
}

export const addProductImage = async (id: number, data: IProductPayload) => {
  const formData = new FormData()
  !!data.avatar && formData.append('avatar', data.avatar)
  formData.append('_method', 'PUT')
  return client.post(`/api/products/${id}`, formData)
}

export const editProduct = (productId: number, payload: IProductPayload) => {
  return client.put<{ data: IProduct }>(`/api/products/${productId}`, payload)
}

export const productToAsset = (productId: number, payload: any) => {
  return client.post<{ data: submitFormProductToAsset }>(
    `/api/products/${productId}/assets`,
    payload,
  )
}
export const deleteProduct = async (productId: number) => {
  return client.delete(`/api/products/${productId}`)
}

export const detailProductShort = async (productId: number) => {
  return client.get<{ data: IProduct; price_changes: IProductPriceChange[] }>(
    `api/products/${productId}?include=notes,accounts,files,manufacturer,relatedProducts,tags,categories,inboundEmailAddress&price_changes=1`,
  )
}

export const detailProduct = async (productId: number) => {
  return client.get<{ data: IProduct }>(
    `api/products/${productId}?include=notes,files,assets,accounts,manufacturer,relatedProducts,tags,categories,inboundEmailAddress`,
  )
}
