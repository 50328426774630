import cx from 'classnames'
import { motion } from 'framer-motion'
import { useRef } from 'react'
import { useDrop } from 'react-dnd'
import shallow from 'zustand/shallow'

import Logo from '../../../../assets/images/logo_system.png'
import { Avatar } from '../../../../components'
import { INavigation, navigations } from '../../../../configs/navigations'
import { useDragMenu } from '../../../../hooks/useDrag'
import { useDropSort } from '../../../../hooks/useDrop'
import useStore, { Store } from '../../../../store'
import { reOrderList } from '../../../../utils/functions'
import {
  DRAG_TYPE_EDIT_MENU,
  DRAG_TYPE_MENU,
  IItemDrag,
} from '../../../../utils/menus'

interface Props {
  menus: INavigation[]
  onUpdateMenus: (values: INavigation[]) => void
}
interface PropsMenuItem {
  menuItem: INavigation
  index: number
  onSortEnd: (dropItem: IItemDrag, prevIndex: number, newIndex: number) => void
}
const mapStore = (store: Store) => ({
  user: store.auth.currentUser,
})

// for modal customize menu
export const TopMenuCustomize = ({ menus, onUpdateMenus }: Props) => {
  const { user } = useStore(mapStore, shallow)

  const handleReorder = (
    dropItem: IItemDrag,
    prev: number,
    current: number,
  ) => {
    const isHasMenu = menus.some(item => item.key === dropItem.id)
    let newList = [...menus]
    if (isHasMenu) {
      newList = reOrderList([...menus], prev, current)
    } else {
      const detailNewMenu = navigations.find(item => item.key === dropItem.id)
      if (detailNewMenu) {
        newList = reOrderList([...newList, detailNewMenu], prev, current)
      }
    }
    onUpdateMenus(newList)
  }

  const [, drop] = useDrop({
    accept: [DRAG_TYPE_EDIT_MENU, DRAG_TYPE_MENU],
    drop: (item: IItemDrag) => {
      if (item.index === menus.length) {
        // in case drop on end of list
        handleReorder(item, item.index, menus.length + 1)
      }
    },
  })

  return (
    <div className='flex w-full h-full px-4 sm:px-6 gap-1 items-center justify-between bg-white shadow-card'>
      <div className='flex items-center sm:pt-1 flex-1 max-w-[calc(100%_-_11.125rem)]'>
        <img src={Logo} className='cursor-pointer w-7 block' alt='logo' />
        <div className='hidden sm:flex gap-x-1 h-[3rem] w-full flex-wrap overflow-hidden ml-4 mr-8 relative'>
          {menus.map((item, index) => (
            <TopMenuItem
              menuItem={item}
              key={item.key}
              index={index}
              onSortEnd={handleReorder}
            />
          ))}
          <div ref={drop} className='inset-0 z-1 absolute' />
        </div>
      </div>
      <div className='item flex gap-[1.625rem] items-center text-[1.125rem] ml-4'>
        <span className='font-icon font-icon-menu text-black-800 text-base leading-none'></span>
        <span className='font-icon-search text-black-800 text-[1.125rem]'></span>
        <div className='relative flex items-center'>
          <span className='font-icon-notification text-black-800 text-[1.125rem]'></span>
        </div>
        <Avatar
          size='small'
          hashId={`user-${user?.id}`}
          className='bg-separation-200'
          src={user?.avatar_preview_url}
        />
      </div>
    </div>
  )
}

const TopMenuItem = ({ menuItem, index, onSortEnd }: PropsMenuItem) => {
  const dref = useRef<HTMLDivElement>(null)
  const { isDragging, drag } = useDragMenu({
    item: { id: menuItem.key, index },
  })
  const [{ isOver, isBottom }, drop] = useDropSort({
    accept: [DRAG_TYPE_MENU, DRAG_TYPE_EDIT_MENU],
    index,
    dref,
    onSortEnd,
  })
  drag(drop(dref))

  return (
    <motion.div
      className='relative cursor-move h-full whitespace-nowrap z-10'
      layout
    >
      {isOver && (
        <div
          className={cx(
            'h-full absolute top-0 bottom-0 w-[2px] bg-green-900',
            isBottom ? '-right-[3px]' : '-left-[3px]',
          )}
        />
      )}
      <div
        className={cx(
          'text-body group text-black-800 px-3 rounded-lg inline-flex relative items-center h-full border border-dashed border-black-700',
          isDragging && 'bg-white text-white cursor-move',
        )}
        ref={dref}
      >
        {menuItem.label}
      </div>
    </motion.div>
  )
}
