import { Avatar, Button } from '../../../../components'
import { USER_ROLE } from '../../../../services/users'
import { PERMISSIONS, PERMISSIONS_CREATE } from '../../../../utils/permission'
import { AvatarUpload } from '../../../AvatarUpload'
import { ComponentPermisison } from '../../../Permission/ComponentPermission'
import { FormProfile } from './FormHelper'

interface Props {
  onChangeValues: (value: Partial<FormProfile>) => void
  formValues: FormProfile
  isLoading: boolean
}
export const ProfileHeader = ({
  formValues,
  onChangeValues,
  isLoading,
}: Props) => {
  return (
    <div className='flex justify-between gap-2 items-center px-4'>
      <div className='flex items-center gap-4'>
        <ComponentPermisison name={PERMISSIONS.FILE} type={PERMISSIONS_CREATE}>
          <div className='mb-4 flex gap-4 items-center'>
            {formValues.isEditMode ? (
              <AvatarUpload
                onChange={file => onChangeValues({ avatar: file })}
                onRemove={() => onChangeValues({ avatar: null })}
                size='large'
                src={formValues?.avatar_preview_url || null}
                showButtonUpload={false}
              />
            ) : (
              <Avatar src={formValues?.avatar_preview_url || null} size={100} />
            )}
          </div>
        </ComponentPermisison>
        <div>
          <div className='text-base font-medium mb-1'>{formValues.name}</div>
          <div className='uppercase text-black-400 text-body'>
            {formValues?.roles?.label}
          </div>
        </div>
      </div>
      <div>
        {formValues?.roles?.label === USER_ROLE.ADMIN &&
          (formValues.isEditMode ? (
            <div className='flex items-center gap-2'>
              <Button
                variant='ternary'
                innerClassName='px-4 gap-2'
                asLink
                disabled={isLoading}
                onClick={() => {
                  onChangeValues({ isEditMode: false })
                }}
              >
                Cancel
              </Button>
              <Button
                variant='primary'
                innerClassName='!px-6 gap-2'
                disabled={isLoading}
              >
                Save
              </Button>
            </div>
          ) : (
            <Button
              innerClassName='px-4 gap-2'
              variant='ternary'
              onClick={() => {
                onChangeValues({ isEditMode: true })
              }}
              asLink
            >
              <span className='font-icon-edit' />
              Edit
            </Button>
          ))}
      </div>
    </div>
  )
}
