import { SVGProps } from 'react'

export const Pdf = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className='text-[#F01313]'
      {...props}
    >
      <path
        d='M0 2.28571C0 1.02335 1.02335 0 2.28571 0H13.7143C14.9767 0 16 1.02335 16 2.28571V13.7143C16 14.9767 14.9767 16 13.7143 16H2.28571C1.02335 16 0 14.9767 0 13.7143V2.28571Z'
        fill='currentColor'
      />
      <path
        d='M5.66828 7.186C5.66828 7.45082 5.60927 7.69394 5.49125 7.91534C5.37323 8.13241 5.19198 8.30824 4.94751 8.44282C4.70304 8.5774 4.39956 8.64469 4.03706 8.64469H3.36687V10.2857H2.28571V5.71429H4.03706C4.39113 5.71429 4.69039 5.77724 4.93487 5.90313C5.17934 6.02903 5.36269 6.20269 5.48493 6.42409C5.60717 6.6455 5.66828 6.89947 5.66828 7.186ZM3.95487 7.75906C4.16141 7.75906 4.31526 7.70913 4.41642 7.60928C4.51758 7.50943 4.56816 7.36834 4.56816 7.186C4.56816 7.00366 4.51758 6.86257 4.41642 6.76272C4.31526 6.66287 4.16141 6.61294 3.95487 6.61294H3.36687V7.75906H3.95487Z'
        fill='white'
      />
      <path
        d='M7.89397 5.71429C8.36184 5.71429 8.7707 5.8098 9.12055 6.00081C9.4704 6.19183 9.74016 6.461 9.92984 6.8083C10.1237 7.15127 10.2207 7.5485 10.2207 8C10.2207 8.44716 10.1237 8.84439 9.92984 9.1917C9.74016 9.539 9.46829 9.80817 9.11423 9.99919C8.76438 10.1902 8.35763 10.2857 7.89397 10.2857H6.23114V5.71429H7.89397ZM7.82443 9.32194C8.23328 9.32194 8.55152 9.20689 8.77913 8.9768C9.00675 8.74671 9.12055 8.42111 9.12055 8C9.12055 7.57889 9.00675 7.25112 8.77913 7.01669C8.55152 6.78226 8.23328 6.66504 7.82443 6.66504H7.3123V9.32194H7.82443Z'
        fill='white'
      />
      <path
        d='M13.7143 5.71429V6.60643H11.906V7.57021H13.2591V8.43631H11.906V10.2857H10.8249V5.71429H13.7143Z'
        fill='white'
      />
    </svg>
  )
}
