import ASSET_TYPE_BACKGROUND from '../configs/assetBackground'
import listIconTypes, { ParentType } from '../icons/Assets'
import { initFilter } from '../services/client'
import { IDetailSettings, IMetaMedia } from '../services/settings'
import useStore from '../store'
import {
  IAsset,
  IAssetResourceOption,
  IFilterAsset,
  IFilterAssetGroup,
  TKeyBelongTo,
} from './../services/assets'
import { IMetaField } from './../services/settings'
import { compareFilterPagination, isIncludeText } from './functions'
import { TLeftSideMenu } from './menus'
import {
  RESOURCE_ACCOUNT,
  RESOURCE_CONTRACT,
  RESOURCE_PROJECT,
  RESOURCE_SERVICE,
  RESOURCE_TYPE_LABEL,
} from './resources'
import { findSettingById } from './settings'


export const ASSET_TYPE_DEFAULT_UNIT = [
  {
    key: 'sensor_size',
    unit: '"'
  }
]
export const assetSideMenu: TLeftSideMenu[] = [
  { icon: 'info', name: 'Asset Detail', id: 'detail' },
  { icon: 'calendar', name: 'Important Dates', id: 'dates' },
  { icon: 'attachment', name: 'Files / Gallery', id: 'media' },
  { icon: 'spec', name: 'Specifications', id: 'specifications' },
]

export const ASSET_RESOURCE = {
  ACCOUNT: {
    value: 1,
    label: RESOURCE_TYPE_LABEL[RESOURCE_ACCOUNT],
  },
  PROJECT: {
    value: 2,
    label: RESOURCE_TYPE_LABEL[RESOURCE_PROJECT],
  },
  SERVICE_TICKET: {
    value: 3,
    label: RESOURCE_TYPE_LABEL[RESOURCE_SERVICE],
  },
  CONTRACT: {
    value: 4,
    label: RESOURCE_TYPE_LABEL[RESOURCE_CONTRACT],
  },
}

export const initFilterAsset: IFilterAsset = {
  ...initFilter,
  search: '',
  filterProject: null,
  filterAccount: null,
  filterProduct: null,
  filterType: null,
  filterLocalInventory: false,
  filterAssetGroup: null,
  filterUser: null,
}
export const initFilterAssetGroup: IFilterAssetGroup = {
  ...initFilter,
  search: '',
  filterAsset: null,
}

export const isHasFilterAssetGroup = (filter: IFilterAssetGroup) => {
  return (
    !!filter.filterAsset ||
    compareFilterPagination(filter, initFilterAssetGroup)
  )
}
export const isHasFilterAsset = (filter: IFilterAsset | null) =>
  !!filter?.filterAccount ||
  !!filter?.filterProject ||
  !!filter?.filterType ||
  !!filter?.filterLocalInventory ||
  !!filter?.filterAssetGroup || 
  compareFilterPagination(filter || {}, initFilterAsset)

export const getAssetResourceTypeByValue = (value?: number | null) => {
  if (!value) {
    return null
  }
  switch (value) {
    case 1:
      return RESOURCE_ACCOUNT
    case 2:
      return RESOURCE_PROJECT
    case 3:
      return RESOURCE_SERVICE
    case 4:
      return RESOURCE_CONTRACT
  }
}

export const isLocalInventoryAsset = (asset: IAsset) =>
  !asset?.accounts || asset?.accounts.length == 0

export const totalResourceAsset = (asset: IAsset) => {
  if (isLocalInventoryAsset(asset)) {
    return 0
  }
  const totalAccounts = asset?.accounts?.length || 0
  const totalProjects = asset?.projects?.length || 0
  const totalServiceTickets = asset?.serviceTickets
    ? asset.serviceTickets.length
    : 0
  const totalContracts = asset?.maintenanceContracts?.length || 0
  return totalAccounts + totalProjects + totalServiceTickets + totalContracts
}

export const validateResourceType = (
  resources: IAssetResourceOption[] | null,
) => {
  if (!resources || resources.length === 0) {
    return false
  }
  return resources.some(
    item =>
      item.resource_id !== null &&
      item.resource_name !== null &&
      item.resource_type !== null,
  )
}

export const getMetaFieldsOfSubType = (
  subTypeId: number | null,
  subTypes: IDetailSettings[] | null = [],
) => {
  if (!subTypes || subTypes.length === 0 || !subTypeId) {
    return null
  }
  const parentItem = subTypes.find(item =>
    item.children?.some(c => c.id === subTypeId),
  )
  if (!parentItem) {
    return null
  }
  const subTypeItem = parentItem?.children?.find(s => s.id === subTypeId)
  if (!subTypeItem) {
    return null
  }
  const metaFields = subTypeItem.meta?.fields || null
  return metaFields
}


export const getUnitOfAssetSubType = (keyName: string, subTypeId: number) => {
  const {settings} = useStore.getState()
  const defaultUnit = ASSET_TYPE_DEFAULT_UNIT.find(item => item.key === keyName)
  if(defaultUnit?.unit){
    return defaultUnit.unit
  }
  const listAssetSubTypes = settings?.asset?.asset_sub_types || null
  if(!listAssetSubTypes){
    return ''
  }
  const metaFields = getMetaFieldsOfSubType(subTypeId, listAssetSubTypes)
  const detailFieldName = metaFields?.find(item => item.name.toLowerCase().split(' ').join('_') === keyName)
  return detailFieldName?.unit || ''
}

export const getBooleanMetaFieldsOfSubType = (
  subTypes: IMetaField[] | null = [],
) => {
  if (!subTypes || subTypes.length === 0) {
    return []
  }
  const newField = subTypes.filter(item => item.type === 'boolean')
  return newField
}

export const generateMetaFieldName = (name: string) =>
  `meta-${name.toLowerCase().split(' ').join('_')}`

export const getIconDefaultSubTypeByName = (name: string) => {
  const infoIcon = [...listIconTypes, ...ParentType].find(item =>
    isIncludeText(item.keyword, name),
  )
  return infoIcon ? infoIcon.iconName : 'block'
}
export const getBgDefaultSubTypeByParentName = (name: string) => {
  const index = ParentType.findIndex(item => isIncludeText(item.keyword, name))
  const number = index >= 0 ? index + 1 : 0
  return number <= 8 ? number : number % 8
}

export const getIconDefaultAssetSubType = (
  asset: IDetailSettings,
  type: string,
): IMetaMedia => {
  if (asset.meta?.media) {
    return asset.meta?.media
  }
  const iconName = getIconDefaultSubTypeByName(asset.label)
  const assetParentName = asset?.setting_label_id
    ? findSettingById(type, asset?.setting_label_id)?.label
    : asset.label
  const background = assetParentName
    ? getBgDefaultSubTypeByParentName(assetParentName)
    : 1
  return {
    iconName,
    background,
  }
}

export const getBgIconSubTypeById = (id: number) => {
  const detail = ASSET_TYPE_BACKGROUND.find(item => item.id === id)
  return detail || ASSET_TYPE_BACKGROUND[0]
}

export const KEY_ASSET_BELONG_TO: Array<TKeyBelongTo> = [
  'projects',
  'maintenance_contracts',
  'service_tickets',
  'monitoring_services',
]

export const isCameraType = (type: string) =>
  type.toLowerCase()?.includes('camera')

export const isIpCameraType = (type: string) => type.toLowerCase() === 'analog camera' || type.toLowerCase() === 'ip camera'