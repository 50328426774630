import cx from 'classnames'
import { ChangeEventHandler, FC, InputHTMLAttributes, useState } from 'react'

export type Props = { wrapperClassName?: string } & Omit<
  InputHTMLAttributes<HTMLInputElement>,
  'type'
>

export const Radio: FC<Props> = ({ children, wrapperClassName, ...props }) => {
  const [checked, setChecked] = useState(props.checked || false)

  const handleChange: ChangeEventHandler<HTMLInputElement> = event => {
    setChecked(prev => !prev)
    props.onChange?.(event)
  }

  return (
    <div className={cx('flex items-center gap-2', wrapperClassName)}>
      <div className='w-5 h-5 relative shrink-0'>
        <input
          {...props}
          type='radio'
          checked={checked}
          onChange={handleChange}
          className={cx(
            'w-full h-full rounded-full border border-light-radio bg-white',
            'appearance-none base-transition cursor-pointer hover:border-primary-900',
            'checked:border-primary-900',
            props.disabled && 'pointer-events-none opacity-30',
            props.className,
          )}
        />
        <div
          className={cx(
            'absolute w-full h-full top-0 left-0 rounded-full pointer-events-none',
            checked ? 'flex justify-center items-center' : 'hidden',
            props.disabled && 'opacity-30',
          )}
        >
          <div className='h-2.5 w-2.5 bg-primary-900 rounded-full' />
        </div>
      </div>
      <div className='text-13'>{children}</div>
    </div>
  )
}
