import { startOfMonth, sub } from 'date-fns'
import { Link } from 'react-router-dom'

import { PATHNAME } from '../configs/routes'
import { IAccount, IFilterAccounts, TAccountType } from '../services/accounts'
import { initFilter } from '../services/client'
import { dateTimeFormat, formatDateNoTz } from './dateTime'
import { compareFilterPagination } from './functions'
import { TLeftSideMenu } from './menus'

export const accountSideMenu: TLeftSideMenu[] = [
  { icon: 'info', name: 'Account Information', id: 'account' },
  { icon: 'phone', name: 'Contact Information', id: 'contact' },
  { icon: 'money', name: 'Pay Rate', id: 'pay-rate' },
  { icon: 'location', name: 'Address', id: 'address' },
]

export const ACCOUNT_TYPES_VENDOR = 'Vendor'
export const ACCOUNT_TYPES_CUSTOMER = 'Customer'
export const ACCOUNT_TYPES_LEAD = 'Lead'
export const ACCOUNT_TYPES_SUBCONTRACTOR = 'Subcontractor'
export const ACCOUNT_TYPES_INDIRECT_CUSTOMER = 'Indirect Customer'

export const ACCOUNT_TYPES: TAccountType[] = [
  ACCOUNT_TYPES_CUSTOMER,
  ACCOUNT_TYPES_LEAD,
  ACCOUNT_TYPES_VENDOR,
  ACCOUNT_TYPES_SUBCONTRACTOR,
  ACCOUNT_TYPES_INDIRECT_CUSTOMER,
]

export const initFilterAccount: IFilterAccounts = {
  ...initFilter,
  search: '',
  filterAccountType: 'All',
  sortBy: 'full_name',
  sortDirection: 'asc',
}

export const isHasFilterAccount = (filter: IFilterAccounts) => {
  return (
    Boolean(filter.filterAccountType) ||
    Boolean(filter.filterSubType) ||
    compareFilterPagination(filter, initFilterAccount)
  )
}
export const ACCOUNT_VENDOR_CONTRACTOR = 'Contractor'
export const ACCOUNT_VENDOR_SERVICE_PROVIDER = 'Service Provider'
export const ACCOUNT_VENDOR_SUPPLIER = 'Supplier'
export const ACCOUNT_VENDOR_TAX_AGENCY = 'Tax Agency'
export const ACCOUNT_VENDOR_CENTRAL_STATION = 'Central Station'
export const ACCOUNT_VENDOR_MANUFACTURER = 'Manufacturer'
export const ACCOUNT_VENDOR_OTHER = 'Other'

export const LIST_ACCOUNT_VENDOR_TYPES = [
  ACCOUNT_VENDOR_CENTRAL_STATION,
  ACCOUNT_VENDOR_CONTRACTOR,
  ACCOUNT_VENDOR_MANUFACTURER,
  ACCOUNT_VENDOR_SERVICE_PROVIDER,
  ACCOUNT_VENDOR_SUPPLIER,
  ACCOUNT_VENDOR_TAX_AGENCY,
  ACCOUNT_VENDOR_OTHER,
]

export const ACCOUNT_CUSTOMER_COMMERCIAL = 'Commercial'
export const ACCOUNT_CUSTOMER_GOVERMENT = 'Government'
export const ACCOUNT_CUSTOMER_RESIDENTIAL = 'Residential'
export const ACCOUNT_CUSTOMER_CONDOMINIUM = 'Condominium'

export const LIST_ACCOUNT_CUSTOMER_TYPE = [
  ACCOUNT_CUSTOMER_COMMERCIAL,
  // ACCOUNT_CUSTOMER_CONDOMINIUM,
  ACCOUNT_CUSTOMER_GOVERMENT,
  ACCOUNT_CUSTOMER_RESIDENTIAL,
]

// Retail, Logistics, Law Enforcement, Manufacturing, Offices, Condominiums, Mixed, Other

export const ACCOUNT_VERTICAL_RETAIL = 'Retail'
export const ACCOUNT_VERTICAL_LOGISTICS = 'Logistics'
export const ACCOUNT_VERTICAL_LAW = 'Law Enforcement'
export const ACCOUNT_VERTICAL_MANUFACTURING = 'Manufacturing'
export const ACCOUNT_VERTICAL_OFFICES = 'Offices'
export const ACCOUNT_VERTICAL_CONDOMINIUMS = 'Condominiums'
export const ACCOUNT_VERTICAL_MIXED = 'Mixed'
export const ACCOUNT_VERTICAL_OTHER = 'Other'
export const ACCOUNT_VERTICAL_LIFE = 'Life Sciences'
export const ACCOUNT_VERTICAL_ENTERTAINMENT = 'Entertainment'

export const LIST_ACCOUNT_CUSTOMER_VERTICAL = [
  ACCOUNT_VERTICAL_CONDOMINIUMS,
  ACCOUNT_VERTICAL_ENTERTAINMENT,
  ACCOUNT_VERTICAL_LAW,
  ACCOUNT_VERTICAL_LIFE,
  ACCOUNT_VERTICAL_LOGISTICS,
  ACCOUNT_VERTICAL_MANUFACTURING,
  ACCOUNT_VERTICAL_MIXED,
  ACCOUNT_VERTICAL_OFFICES,
  ACCOUNT_VERTICAL_OTHER,
  ACCOUNT_VERTICAL_RETAIL,
]

export const isVendorAccount = (type: string) => type === ACCOUNT_TYPES_VENDOR
export const isCustomerAccount = (type: string) =>
  type === ACCOUNT_TYPES_CUSTOMER

export const renderAccountNameWithLink = (
  account?: IAccount | null,
  showLinkParent = false,
) => {
  if (!account) {
    return null
  }
  if (account?.parentAccount) {
    if (showLinkParent) {
      return (
        <>
          <Link to={`/${PATHNAME['accounts']}/${account.parentAccount.id}`}>
            {account.parentAccount.full_name}
          </Link>
          :{' '}
          <Link to={`/${PATHNAME['accounts']}/${account.id}`}>
            {account.full_name}
          </Link>
        </>
      )
    }
  }
  return renderAccountName(account)
}

export const renderAccountName = (
  account?: IAccount | null,
  showParent = true,
) => {
  if (!account) {
    return null
  }
  if (account?.parentAccount && showParent) {
    return `${account.parentAccount.full_name}: ${account.full_name}`
  }
  return account.full_name
}

export const isCustomerVertical = (type: string) =>
  type === ACCOUNT_CUSTOMER_COMMERCIAL || type === ACCOUNT_CUSTOMER_GOVERMENT

const currentDate = new Date()
const lastMonth = sub(currentDate, { months: 1 })
const last3Months = sub(currentDate, { months: 3 })
const last6Months = sub(currentDate, { months: 6 })
const last12Months = sub(currentDate, { months: 12 })

export const RANGE_DATES_KPI = {
  last_month: [
    formatDateNoTz(lastMonth, dateTimeFormat['MM-dd']),
    formatDateNoTz(currentDate),
  ],
  last_3_months: [
    formatDateNoTz(last3Months, dateTimeFormat['MM-dd']),
    formatDateNoTz(currentDate),
  ],
  last_6_months: [
    formatDateNoTz(last6Months, dateTimeFormat['MM-dd']),
    formatDateNoTz(currentDate),
  ],
  last_12_months: [formatDateNoTz(last12Months), formatDateNoTz(currentDate)],
  last_calender_month: [
    formatDateNoTz(startOfMonth(currentDate), dateTimeFormat['MM-dd']),
    formatDateNoTz(currentDate),
  ],
  last_3_calender_months: [
    formatDateNoTz(startOfMonth(last3Months), dateTimeFormat['MM-dd']),
    formatDateNoTz(currentDate),
  ],
  last_6_calender_months: [
    formatDateNoTz(startOfMonth(last6Months), dateTimeFormat['MM-dd']),
    formatDateNoTz(currentDate),
  ],
  last_12_calender_months: [
    formatDateNoTz(startOfMonth(last12Months)),
    formatDateNoTz(currentDate),
  ],
}

export const listRangeDayKpi = [
  {
    label: 'Last 30 days',
    name: 'last_month',
    value: RANGE_DATES_KPI.last_month,
  },
  {
    label: 'Last 90 days',
    name: 'last_3_months',
    value: RANGE_DATES_KPI.last_3_months,
  },
  {
    label: 'Last 120 days',
    name: 'last_6_months',
    value: RANGE_DATES_KPI.last_6_months,
  },
  {
    label: 'Last 365 days',
    name: 'last_12_months',
    value: RANGE_DATES_KPI.last_12_months,
  },
]

export const listRangeMonthKpi = [
  {
    label: 'This Month',
    name: 'last_calender_month',
    value: RANGE_DATES_KPI.last_calender_month,
  },
  {
    label: 'Last 3 months',
    name: 'last_3_calender_months',
    value: RANGE_DATES_KPI.last_3_calender_months,
  },
  {
    label: 'Last 6 months',
    name: 'last_6_calender_months',
    value: RANGE_DATES_KPI.last_6_calender_months,
  },
  {
    label: 'Last 12 months',
    name: 'last_12_calender_months',
    value: RANGE_DATES_KPI.last_12_calender_months,
  },
]
