import cx from 'classnames'
import { motion } from 'framer-motion'
import { FC, Fragment, ReactNode, useEffect, useRef, useState } from 'react'
import { PopperProps, usePopper } from 'react-popper'

import { isMobile } from '../../utils/functions'
import { Portal } from '../Portal'

export interface Props {
  children: ReactNode
  visible?: boolean
  content: ReactNode
  className?: string
  popupClassName?: string
  contentClassName?: string
  popupContainer?: () => HTMLElement
  onVisibleChange?: (visible: boolean) => void
  modifiers?: PopperProps<any>['modifiers']
  placement?: PopperProps<any>['placement']
  zIndex?: number
  delay?: number // miliseconds
}

export const getClassNameTransformArrow = (
  placement: PopperProps<any>['placement'],
) => {
  if (placement === 'top') {
    return '-bottom-2 left-1/2 -translate-x-1/2 border-l-transparent border-r-transparent border-b-0'
  }
  if (placement === 'bottom') {
    return '-top-2 left-1/2 -translate-x-1/2 border-l-transparent border-r-transparent border-t-0'
  }
  if (placement === 'left') {
    return '-right-2 top-1/2 -translate-y-1/2 border-t-transparent border-b-transparent border-r-0'
  }
  if (placement === 'right') {
    return '-left-2 top-1/2 -translate-y-1/2 border-t-transparent border-b-transparent border-l-0'
  }
  return 'hidden'
}

export const Tooltip: FC<Props> = ({
  children,
  visible: propVisible,
  className,
  popupClassName,
  popupContainer,
  content,
  onVisibleChange,
  placement = 'top',
  modifiers = [],
  zIndex,
  delay = 0,
  contentClassName = '',
}) => {
  const refTimer = useRef<NodeJS.Timeout | null>(null)
  const [visible, setVisible] = useState<boolean>(propVisible || false)
  const [refElement, setRefElement] = useState<HTMLElement | null>(null)
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null)

  const { styles, attributes } = usePopper(refElement, popperElement, {
    placement,
    modifiers: [...(modifiers || [])],
  })

  const handleMouseEnter = () => {
    if (isMobile()) {
      return
    }
    if (delay > 0) {
      refTimer.current = setTimeout(() => {
        toggleVisible(true)
      }, delay)
    } else {
      toggleVisible(true)
    }
  }

  const handleMouseLeave = () => {
    if (isMobile()) {
      return
    }
    if (delay > 0 && refTimer?.current) {
      clearTimeout(refTimer.current)
    }
    toggleVisible(false)
  }
  const toggleVisible = (v: boolean) => {
    if (propVisible === undefined) {
      setVisible(v)
    }
    onVisibleChange?.(v)
  }

  useEffect(() => {
    if (Boolean(propVisible) !== visible) {
      setVisible(Boolean(propVisible))
    }
  }, [propVisible])

  return (
    <Fragment>
      <div
        ref={setRefElement}
        className={cx(className)}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {children}
      </div>
      {visible && (
        <Portal popupContainer={popupContainer}>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            ref={setPopperElement}
            onMouseEnter={() => toggleVisible(true)}
            onMouseLeave={() => toggleVisible(false)}
            className={cx(popupClassName, 'p-2 z-tooltip')}
            style={{ ...styles.popper, zIndex }}
            {...attributes.popper}
          >
            <div
              className={cx(
                'bg-black rounded-lg text-white min-w-[2rem] min-h-[2rem] text-13 p-2.5 relative',
                contentClassName,
              )}
            >
              <div
                className={cx(
                  'h-0 w-0 absolute border-8 border-black border-solid',
                  getClassNameTransformArrow(
                    attributes?.popper?.[
                      'data-popper-placement'
                    ] as typeof placement,
                  ),
                )}
              />
              {content}
            </div>
          </motion.div>
        </Portal>
      )}
    </Fragment>
  )
}
