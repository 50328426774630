import { formatIncompletePhoneNumber } from 'libphonenumber-js/min'

export const INT_CODE = 'ZZ'
export const DEFAULT_COUNTRY_CODE = 'US'
export const DEFAULT_CALLING_CODE = '+1'

export function formatPhone(phone: string) {
  const format = formatIncompletePhoneNumber(phone)
  const parse = format.split(' ')
  if (!parse || parse.length < 3) {
    return format
  }
  if (parse.length > 3) {
    const [first, second, third, ...rest] = parse
    return `${first} (${second}) ${third}${
      rest.length > 0 ? ' ' + rest.join(' ') : ''
    }`
  }
  return `${parse[0]} (${parse[1]}) ${parse[2]}`
}
