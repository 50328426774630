import { SVGProps } from 'react'

export const QRCode = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      xmlns='http://www.w3.org/2000/svg'
      fill='currentColor'
      {...props}
    >
      <path
        d='M7.28116 0C7.28116 1.24925 7.28016 2.4985 7.27817 3.74776C7.27817 4.85643 7.27817 5.9651 7.27817 7.07378V7.2677H0V0H7.28116ZM1.47208 5.80359H5.80907V1.47009H1.47208V5.80359Z'
        fill='currentColor'
      />
      <path
        d='M16 7.28116C14.7348 7.28116 13.4693 7.28016 12.2034 7.27817C11.1103 7.27817 10.0168 7.27817 8.92273 7.27817H8.72333V7.07876C8.72333 4.78764 8.72333 2.49618 8.72333 0.204386C8.72333 0.136589 8.72333 0.0692905 8.72034 0.00149393H16V7.28116ZM10.1904 5.80508H14.5274V1.47408H10.1904V5.80508Z'
        fill='currentColor'
      />
      <path
        d='M16 13.0937H11.6326V11.6515H10.1924V15.9885H8.73131V8.7338H13.0872V10.1705H14.5329V8.71884H15.999L16 13.0937Z'
        fill='currentColor'
      />
      <path d='M16 16H14.5479V14.5314H16V16Z' fill='currentColor' />
      <path
        d='M0.00498497 15.9885V8.7338H7.26471V15.9885H0.00498497ZM5.80907 10.1949H1.47208V14.5234H5.80907V10.1949Z'
        fill='currentColor'
      />
      <path
        d='M11.6416 14.5469H13.0798V15.9885H11.6416V14.5469Z'
        fill='currentColor'
      />
      <path
        d='M5.80907 10.1949V14.5234H1.47208V10.1949H5.80907ZM2.91476 13.0793H4.36042V11.6401H2.91476V13.0793Z'
        fill='white'
      />
      <path
        d='M2.91775 2.91426H4.36042V4.35992H2.91775V2.91426Z'
        fill='currentColor'
      />
      <path
        d='M13.0872 2.91575V4.35793H11.6416V2.91575H13.0872Z'
        fill='currentColor'
      />
      <path
        d='M2.91476 13.0793V11.6401H4.36042V13.0793H2.91476Z'
        fill='currentColor'
      />
    </svg>
  )
}
