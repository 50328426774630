import client from './client'

interface IPayload {
  id: number
  resource: string
}

export const addFavorite = async (payload: IPayload) => {
  return client.post('/api/favorites', payload)
}

export const removeFavorite = async (payload: IPayload) => {
  return client.delete(
    `/api/favorites/?id=${payload.id}&resource=${payload.resource}`,
  )
}
