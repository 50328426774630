import { Tooltip } from '../../../components'
import { IContract } from '../../../services/contracts'
import { isNearlyExpiredContract } from '../../../utils/contracts'

interface Props {
  contract: IContract
}
export const ContractIconStatus = ({ contract }: Props) => {
  const isExpired = isNearlyExpiredContract(contract)
  if (!isExpired) {
    return null
  }
  return (
    <Tooltip
      content={<span>This maintenance contract nearly or expired date</span>}
      placement='right'
      popupClassName='z-[9999]'
    >
      <span className='rounded min-w-[1.375rem] min-h-[1.375rem] flex items-center justify-center cursor-pointer bg-orange-900'>
        <span className='font-icon-notification text-white leading-none text-[0.875rem]' />
      </span>
    </Tooltip>
  )
}
