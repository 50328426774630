import { ClipboardEvent, KeyboardEvent } from 'react'

import { Avatar, Button } from '../../../components'
import { isImageFile } from '../../../utils/files'
import { CustomMentionInput } from '../../Form/MentionsInput'
import { BODY_ONLY_IMG, useNotesCtx } from '../Context'
import { ComposerAttachments } from './Attachments'
import { NoteImagePicker } from './ImagePicker'

export const NoteComposer = ({ onAddedNote }: { onAddedNote: () => void }) => {
  const {
    body,
    dispatch,
    handleAddNote,
    loading,
    listAttachments,
    currentUser,
  } = useNotesCtx()

  const handleKeyDownNote = async (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      await handlePost()
    }
  }
  const handlePost = async () => {
    if (body === '' && listAttachments.length === 0) {
      return
    }
    const newBody = body === '' ? BODY_ONLY_IMG : body
    await handleAddNote(newBody)
    onAddedNote()
  }

  const handlePaste = (e: ClipboardEvent<HTMLTextAreaElement>) => {
    if (e.clipboardData.files.length > 0) {
      e.preventDefault()
      const { files } = e.clipboardData
      const listFiles = Array.from(files).filter(item => isImageFile(item.type))
      if (listFiles.length > 0) {
        dispatch({
          listAttachments: [...listAttachments, ...listFiles],
        })
      }
      return
    }
  }

  return (
    <div className='px-6 py-4 flex items-start border-t border-separation-800 gap-2 bg-white w-auto'>
      <div className='flex gap-[0.875rem] items-center flex-1 w-full'>
        <Avatar
          src={currentUser?.avatar_preview_url || null}
          hashId={`user-${currentUser?.id}`}
        />
        <div className='flex-1 flex flex-col gap-0.5'>
          <CustomMentionInput
            placeholder='Write your note...'
            value={body}
            className='border-none !p-0 hover:shadow-none focus:shadow-none resize-none'
            onPaste={handlePaste} // @ts-ignore
            onKeyDown={handleKeyDownNote}
            onChange={value => {
              if (loading) {
                return
              }
              dispatch({ body: value })
            }}
            style={{
              highlighter: {
                overflow: 'hidden',
                maxHeight: 208,
                height: 'auto',
              },
            }}
          />
          <ComposerAttachments />
        </div>
      </div>
      <div className='flex gap-4 items-start'>
        <NoteImagePicker />
        <Button
          innerClassName='flex gap-2 px-[0.875rem] py-2'
          onClick={handlePost}
          disabled={loading || (body === '' && listAttachments.length === 0)}
        >
          <span>Post</span>
          <span className='font-icon-send' />
        </Button>
      </div>
    </div>
  )
}
