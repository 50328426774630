import { ErrorBoundary } from '@rollbar/react'
import cx from 'classnames'
import RcTable from 'rc-table'
import {
  ColumnType,
  DefaultRecordType,
  FixedType,
} from 'rc-table/lib/interface'
import { TableProps } from 'rc-table/lib/Table'
import { ReactNode, useEffect, useMemo, useState } from 'react'

import NoResult from '../../assets/images/NotFound.png'
import { SortDirection } from '../../services/client'
import SkeletonLoader from '../../shared/SkeletonLoader'
import { Checkbox } from '../Checkbox'
import { ErrorFallback } from '../ErrorFallback'

interface ColumnTypeCustom extends ColumnType<DefaultRecordType> {
  sorter?: boolean
}

export interface ColumnTypeWithSort<T> extends ColumnType<T> {
  sorter?: boolean
}

interface ColumnTypeCheckbox extends Omit<ColumnTypeCustom, 'render'> {}

export interface Props
  extends Omit<TableProps<DefaultRecordType>, 'columns' | 'data'> {
  loading?: boolean
  tableClassName?: string
  keySort?: string
  directionSort?: SortDirection
  onSort?: (key: string, direction: SortDirection) => void
  fixed?: FixedType
  columns?: DefaultRecordType[]
  showColumnSelect?: boolean
  columnSelect?: ColumnTypeCheckbox
  showContentHover?: boolean
  contentColumnHover?: (row: DefaultRecordType, cb?: () => void) => ReactNode
  data?: DefaultRecordType[]
  onSelectColumn?: (selected: DefaultRecordType[]) => void
  onSelectAllColumns?: (
    selectedAll: boolean,
    listSelected: DefaultRecordType[],
  ) => void
}

function TableComponent({
  loading,
  tableClassName,
  onSort,
  className,
  keySort = '',
  directionSort = 'asc',
  showColumnSelect = false,
  onSelectColumn,
  onSelectAllColumns,
  showContentHover,
  contentColumnHover,
  columnSelect = {},
  ...props
}: Props) {
  const [rowHover, setRowHover] = useState<DefaultRecordType | null>(null)
  const [offsetRow, setOffSetRow] = useState<Record<string, number>>({
    top: 0,
    height: 0,
  })
  const [selectedColumns, setSelectedColumns] = useState<DefaultRecordType[]>(
    [],
  )
  const rowKeyTable = (props?.rowKey || 'id') as string
  const [{ key, direction }, setSort] = useState({
    key: keySort,
    direction: directionSort,
  })

  const handleSort = (k: string) => {
    setSort(prev => {
      const d =
        k !== prev.key
          ? 'asc'
          : prev.direction === ''
          ? 'asc'
          : prev.direction === 'asc'
          ? 'desc'
          : prev.direction === 'desc'
          ? 'asc'
          : 'asc'
      onSort?.(k, d)
      return { key: k, direction: d }
    })
  }

  const handleToggleAll = (checked: boolean) => {
    let listData = props?.data || []
    const total = listData.length
    const currentSelected = selectedColumns.length
    let statusCheck = checked
    if (currentSelected > 0 && currentSelected < total) {
      statusCheck = true
    } else {
      listData = checked ? listData : []
    }
    setSelectedColumns(listData)
    onSelectColumn?.(listData)
    onSelectAllColumns?.(statusCheck, listData)
  }

  const handleSelectColumn = (checked: boolean, column: DefaultRecordType) => {
    const selected = checked
      ? [...selectedColumns, column]
      : [...selectedColumns].filter(
          item => item[rowKeyTable] !== column[rowKeyTable],
        )
    setSelectedColumns(selected)
    onSelectColumn?.(selected)
  }

  const renderCheckBoxAction = () => ({
    key: 'check',
    title: (
      <Checkbox
        id='all'
        onChange={e => handleToggleAll(e.target.checked)}
        checked={
          props.data?.length === selectedColumns.length ||
          selectedColumns.length > 0
        }
        indeterminate={
          selectedColumns.length < (props.data?.length || 0) &&
          selectedColumns.length > 0
        }
      />
    ),
    className: '!pr-0',
    width: 30,
    render: (item: DefaultRecordType) => (
      <Checkbox
        onChange={e => handleSelectColumn(e.target.checked, item)}
        checked={selectedColumns.some(
          s => s[rowKeyTable] === item[rowKeyTable],
        )}
      />
    ),
    ...columnSelect,
  })

  const columns = useMemo(() => {
    let propsColumns = props.columns || []
    propsColumns = showColumnSelect
      ? [renderCheckBoxAction(), ...propsColumns]
      : propsColumns

    return propsColumns.map(
      ({ sorter = false, titleClassName = '', ...column }) => ({
        ...column,
        title: (
          <div
            className={cx(
              'select-none flex items-center justify-start text-body gap-2',
              titleClassName,
            )}
          >
            <div>{column.title}</div>
            {sorter && (
              <div className='inline-flex flex-col gap-[2px]'>
                <span
                  className={cx(
                    'base-transition font-icon-sort_up text-[0.4375rem]',
                    key === column.key && direction === 'asc'
                      ? 'text-primary-900'
                      : 'text-white-700',
                  )}
                />
                <span
                  className={cx(
                    'base-transition font-icon-sort_down text-[0.4375rem]',
                    key === column.key && direction === 'desc'
                      ? 'text-primary-900'
                      : 'text-white-700',
                  )}
                />
              </div>
            )}
          </div>
        ),
        onHeaderCell: () => ({
          className: cx(sorter && 'cursor-pointer hover:bg-[rgb(0,0,0,.04)]'),
          onClick: sorter ? () => handleSort(column.key as string) : undefined,
        }),
      }),
    )
  }, [key, direction, props.columns, selectedColumns, keySort])

  const handleUnHover = () => {
    if (!showContentHover) {
      return
    }
    setRowHover(null)
    setOffSetRow({
      top: 0,
      height: 0,
    })
  }

  useEffect(() => {
    setSelectedColumns([])
    onSelectAllColumns?.(false, [])
  }, [props.data])

  useEffect(() => {
    setSort(prev => ({
      ...prev,
      key: prev.key !== keySort ? keySort : key,
    }))
  }, [keySort])

  return (
    <div
      className={cx('relative overflow-hidden', className)}
      onMouseLeave={handleUnHover}
    >
      {loading && <SkeletonLoader />}
      <RcTable
        scroll={{ x: 1200 }}
        onHeaderRow={
          showContentHover
            ? () => ({
                onMouseEnter: () => {
                  handleUnHover()
                },
              })
            : undefined
        }
        onRow={
          showContentHover
            ? record => ({
                onMouseOver: e => {
                  setRowHover(record)
                  setOffSetRow({
                    top: e.currentTarget.offsetTop + 1,
                    height: e.currentTarget.offsetHeight - 1,
                  })
                },
              })
            : undefined
        }
        rowClassName={item => {
          const selected = selectedColumns.some(
            c => c?.[rowKeyTable] === item?.[rowKeyTable],
          )
          return selected ? 'rc-table-row-selected' : ''
        }}
        {...props}
        columns={columns}
        className={tableClassName}
        emptyText={
          <div className='select-none'>
            {loading ? (
              <div className='animate-pulse w-100 min-w-[1200px] '>
                {[...Array(5)].map((_, rowIndex) => (
                  <div
                    key={rowIndex}
                    className='flex items-center border-b border-gray-200 py-2 w-full '
                  >
                    {columns.map((__, cellIndex) => (
                      <div key={cellIndex} className='flex-grow px-10 py-2'>
                        <div className='h-3 bg-gray-200 rounded'></div>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            ) : (
              <div className='flex flex-col justify-center items-center my-8'>
                <img
                  className='w-[4rem] grayscale opacity-90'
                  src={NoResult}
                  alt='no-result'
                />
                <div className='mt-3 text-sm text-black/30'>No Data</div>
              </div>
            )}
          </div>
        }
      />
      {rowHover?.id && showContentHover && contentColumnHover && (
        <div className='absolute right-px' style={offsetRow}>
          {contentColumnHover(rowHover, handleUnHover)}
        </div>
      )}
    </div>
  )
}

export const Table = (props: Props) => {
  return (
    <ErrorBoundary fallbackUI={ErrorFallback}>
      <TableComponent {...props} />
    </ErrorBoundary>
  )
}
