import cx from 'classnames'
import { debounce } from 'lodash'
import { useEffect, useMemo, useReducer } from 'react'

import { useAsync } from '../../hooks'
import { Props as SelectProps, Select } from './Select'

type FetchResponse = {
  data: any[]
  totalPage: number
}

export interface Props extends Omit<SelectProps, 'options' | 'onChange'> {
  fetcher: (params?: { search: string; page: number }) => Promise<FetchResponse>
  search?: boolean
  paginate?: boolean
  triggerFilter?: any
  options?: (options: any[]) => any[]
  onChange: (v: any, vRaw: any) => void
  fetchOnFirst?: boolean
}

interface State {
  loading: boolean
  search: string
  isCleared: boolean
  page: number
  total: number
  data: any[]
}

const initState: State = {
  loading: true,
  isCleared: false,
  search: '',
  page: 1,
  total: 1,
  data: [],
}

function AsyncSelect({
  fetcher,
  search: propSearch,
  paginate,
  options,
  children,
  triggerFilter,
  fetchOnFirst = true,
  ...props
}: Props) {
  const [{ loading, search, page, total, data, isCleared }, setState] =
    useReducer((s: State, a: Partial<State>) => ({ ...s, ...a }), initState)
  const { execute } = useAsync<FetchResponse>({
    status: 'pending',
    showNotifOnError: true,
  })

  const allowSearch = propSearch !== false
  const allowPaginate = paginate !== false

  const initFetchData = () => {
    execute(fetcher({ search: '', page: 1 })).then(res => {
      setState({ loading: false, total: res.totalPage, data: res.data })
    })
  }
  useEffect(() => {
    if (fetchOnFirst) {
      initFetchData()
    }
  }, [])

  useEffect(() => {
    if (triggerFilter) {
      initFetchData()
    }
  }, [triggerFilter])

  const searchDebounce = useMemo(() => {
    return debounce((keyword: string) => {
      setState({ loading: true, search: keyword })
      execute(fetcher({ search: keyword, page: 1 })).then(res => {
        setState({
          loading: false,
          page: 1,
          total: res.totalPage,
          data: res.data,
          isCleared: false,
        })
      })
    }, 500)
  }, [])

  const onScroll: React.UIEventHandler<HTMLDivElement> = ({ target }) => {
    const { scrollHeight, offsetHeight, scrollTop } = target as HTMLDivElement
    if (scrollHeight <= offsetHeight + scrollTop + 30 && !loading) {
      const newPage = page + 1
      setState({ loading: true })
      execute(fetcher({ search, page: newPage })).then(res => {
        setState({
          loading: false,
          page: newPage,
          total: res.totalPage,
          data: [...data, ...res.data],
        })
      })
    }
  }

  const handleChange = (item: any) => {
    const itemRaw = !!item ? data.find(d => d.id === +item.value) : null
    props.onChange?.(item, itemRaw)
  }

  const handleDropDownChange = (v: boolean) => {
    if (v && data.length === 0 && (!fetchOnFirst || isCleared)) {
      initFetchData()
    }
  }

  const handleClear = () => {
    setState({
      search: '',
      page: 1,
      total: 1,
      isCleared: true,
      data: [],
    })
  }

  return (
    <Select
      {...props}
      onClear={handleClear}
      onChange={handleChange}
      filterOption={false}
      showSearch={allowSearch}
      onSearch={allowSearch ? searchDebounce : undefined}
      onPopupScroll={allowPaginate && total > page ? onScroll : undefined}
      options={options?.(data)}
      dropdownClassName={cx(
        loading && 'async-select-dropdown-loading',
        props.dropdownClassName,
      )}
      onDropdownVisibleChange={handleDropDownChange}
    >
      {typeof children === 'function' ? children(data) : children}
    </Select>
  )
}

AsyncSelect.Option = Select.Option
export { AsyncSelect }
