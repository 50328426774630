import cx from 'classnames'
import { FC, InputHTMLAttributes, useEffect, useRef, useState } from 'react'

export type Props = {
  wrapperClassName?: string
  contentClassName?: string
  size?: 'medium' | 'large'
  error?: boolean
  indeterminate?: boolean
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'type' | 'size'>

const sizes = {
  medium: 'w-5 h-5',
  large: 'w-6 h-6',
}

export const Checkbox: FC<Props> = ({
  children,
  size = 'medium',
  wrapperClassName,
  contentClassName,
  error,
  indeterminate = false,
  ...props
}) => {
  const refCheckbox = useRef<HTMLInputElement | null>(null)
  const [checked, setChecked] = useState(props.checked || false)

  useEffect(() => {
    if (props.checked !== undefined && props.checked !== checked) {
      setChecked(props.checked)
    }
  }, [props.checked, checked])

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    if (props.readOnly || props.disabled) {
      return
    }
    const status = indeterminate ? true : e.target.checked
    setChecked(status)
    props.onChange?.({ ...e, target: { ...e.target, checked: status } })
  }

  const handelToggle = () => {
    refCheckbox?.current?.click()
  }
  return (
    <div className={cx('flex items-center gap-2', wrapperClassName)}>
      <div className={cx(sizes[size], 'relative shrink-0')}>
        <input
          {...props}
          ref={refCheckbox}
          checked={checked}
          type='checkbox'
          onChange={props.disabled ? undefined : handleChange}
          className={cx(
            'w-full h-full rounded border border-separation-900 bg-white',
            'appearance-none checked:bg-primary-900 checked:border-primary-900',
            'base-transition cursor-pointer hover:border-primary-900',
            props.readOnly && 'pointer-events-none',
            props.disabled &&
              'pointer-events-none !bg-separation-400 !border-separation-900',
            props.className,
          )}
        />
        <span
          className={cx(
            'text-[0.625rem] absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%] hidden pointer-events-none',
            checked && 'text-white !block',
            indeterminate ? 'font-icon-minus' : 'font-icon-check',
            props.disabled && '!text-black-400',
          )}
        />
      </div>
      <div
        className={cx('text-13 cursor-pointer', contentClassName)}
        onClick={handelToggle}
      >
        {children}
      </div>
    </div>
  )
}
