import cx from 'classnames'
import React from 'react'

import { Button, ButtonProps } from '../../components'

interface Props extends ButtonProps {
  selected: boolean
}
export const ButtonCheckbox = ({ selected, children, ...props }: Props) => (
  <Button
    {...props}
    variant='single'
    size='large'
    innerClassName={cx(
      '!px-3 font-normal',
      selected && '!font-medium',
      props.innerClassName,
    )}
    className={cx(
      '!rounded-lg !border-separation-800 text-black-800',
      selected && 'bg-primary-200 border-primary-200',
      props.className,
    )}
    asLink
  >
    {children}
  </Button>
)
