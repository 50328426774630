import cx from 'classnames'
import { differenceInCalendarDays } from 'date-fns'

import { initFilter } from '../services/client'
import {
  IContract,
  IFilterContracts,
  IFilterPreventatives,
  IPreventative,
} from '../services/contracts'
import { IS_ANNUALLY, IS_MONTHLY, IS_QUARTERLY, IS_WEEKLY } from './constant'
import { compareFilterPagination } from './functions'
import { TLeftSideMenu } from './menus'

export const PREVENTATIVE_IS_DUE = 'Due'
export const PREVENTATIVE_IS_PLANNED = 'Planned'
export const PREVENTATIVE_IS_COMPLETE = 'Complete'
export const PREVENTATIVE_IS_LATE = 'Late'

export const CONTRACT_CYCLE_TYPE = [
  IS_WEEKLY,
  IS_MONTHLY,
  IS_QUARTERLY,
  IS_ANNUALLY,
]

export const CONTRACT_CYCLE_LABEL: Record<string, string> = {
  [IS_WEEKLY]: 'week',
  [IS_MONTHLY]: 'month',
  [IS_QUARTERLY]: 'quarter',
  [IS_ANNUALLY]: 'annual',
}

export const CONTRACT_SERVICE_TYPE = [
  { name: 'Access Control System', icon: 'door' },
  { name: 'Fire Alarm', icon: 'fire' },
  { name: 'Intrusion Alarm', icon: 'alarm' },
  { name: 'Video Surveillance', icon: 'cctv' },
  { name: 'Intercom System', icon: 'intercom' },
  { name: 'VOIP', icon: 'phone_call' },
  { name: 'Networking', icon: 'global' },
  { name: 'Other', icon: 'other_device' },
]

export const initFilterContracts: IFilterContracts = {
  ...initFilter,
  search: '',
  filterAccount: null,
}

export const initSpecialFilterPreventative: IFilterPreventatives = {
  filterRecentlyUpdated: false,
  filterDueDate: false,
  filterExpired: false,
  filterCompleted: false,
}

export const hasSpecialFilterPreventative = (filter: IFilterPreventatives) => {
  return Boolean(
    filter?.filterCompleted ||
      filter?.filterRecentlyUpdated ||
      filter?.filterDueDate ||
      filter?.filterExpired,
  )
}

export const isNearlyExpiredContract = (contract: IContract) => {
  const validDate = contract?.termination_date
  if (!validDate) {
    return true
  }
  const diffValidDate = differenceInCalendarDays(
    new Date(validDate),
    new Date(),
  )
  return diffValidDate <= 60
}

export const isHasFilterContract = (filter: IFilterContracts) =>
  !!filter?.filterAccount ||
  compareFilterPagination(filter, initFilterContracts)

export const contractSideMenu: TLeftSideMenu[] = [
  { icon: 'info', name: 'Contract Information', id: 'information' },
  { icon: 'calendar', name: 'Important Dates', id: 'dates' },
  { icon: 'wrench', name: 'Service Coverage', id: 'coverage' },
  { icon: 'location', name: 'Site Address', id: 'address' },
]

export const PREVENTATIVE_STATUS = [
  PREVENTATIVE_IS_PLANNED,
  PREVENTATIVE_IS_DUE,
  PREVENTATIVE_IS_COMPLETE,
  PREVENTATIVE_IS_LATE,
]

export const initFilterPreventatives: IFilterPreventatives = {
  ...initFilter,
  search: '',
  filterContract: null,
}

export const renderBgClassOfPreventativeStatus = (
  preventative: IPreventative,
  isHover?: boolean,
) => {
  const status = getStatusOfPreventative(preventative)
  switch (status) {
    case PREVENTATIVE_IS_DUE: {
      return isHover ? '!bg-orange-400' : '!bg-orange-300'
    }
    case PREVENTATIVE_IS_COMPLETE: {
      return isHover ? '!bg-green-500' : '!bg-green-400'
    }
    case PREVENTATIVE_IS_LATE: {
      return isHover ? '!bg-red-400' : '!bg-red-300'
    }
    case PREVENTATIVE_IS_PLANNED:
    default: {
      return 'bg-white'
    }
  }
}

export const getStatusOfPreventative = (item: IPreventative) => {
  if (
    item.status === PREVENTATIVE_IS_COMPLETE ||
    !!item.completed_on ||
    (item.preventative_maintenance_tasks_count > 0 &&
      item.in_complete_tasks_count === 0)
  ) {
    return PREVENTATIVE_IS_COMPLETE
  }
  if (item.due_date) {
    const diffDueDate = differenceInCalendarDays(
      new Date(item.due_date),
      new Date(),
    )
    if (diffDueDate <= 0) {
      return PREVENTATIVE_IS_LATE
    }
    if (diffDueDate < 4) {
      return PREVENTATIVE_IS_DUE
    }
  }
  return PREVENTATIVE_IS_PLANNED
}

export const renderIconPreventative = (item: IPreventative) => {
  const status = getStatusOfPreventative(item)
  if (status === PREVENTATIVE_IS_COMPLETE) {
    return <span className='font-icon-check text-green-900' />
  }
  if (status === PREVENTATIVE_IS_DUE || status === PREVENTATIVE_IS_LATE) {
    return (
      <span
        className={cx(
          'rounded min-w-[1.375rem] min-h-[1.375rem] flex items-center justify-center cursor-pointer',
          status === PREVENTATIVE_IS_LATE ? 'bg-red-900' : 'bg-orange-900',
        )}
      >
        <span className='font-icon-notification text-white leading-none text-[0.875rem]' />
      </span>
    )
  }
  return null
}
