import { usePageTitle } from '../../hooks'
import { Uncategorized } from '../../shared/Uncategorized'

export const PageUncategorized = () => {
  usePageTitle('Uncategorized | Readyflow')
  return (
    <div className='text-body max-w-2xl mx-auto my-6'>
      <div className='font-medium text-2xl mb-4'>Uncategorized notes</div>
      <div>
        <Uncategorized isSubPage={false}/>
      </div>
    </div>
  )
}