import { StoreApi } from 'zustand'

import { ICalendarSetting } from '../services/settings'
import type { Store } from './index'

export interface CalendarSlice {
  currentCalendar: ICalendarSetting[]
  setCalendar: (calendar: ICalendarSetting[]) => void
}

const slice = (set: StoreApi<Store>['setState']) => ({
  currentCalendar: [],
  setCalendar: (payload: ICalendarSetting[]) => {
    return set(({ calendars }) => ({
      calendars: {
        ...calendars,
        currentCalendar: payload,
      },
    }))
  },
})

export default slice
