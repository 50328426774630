import { AsyncSelect } from '../../../components/Forms/AsyncSelect'
import { Props as SelectProps } from '../../../components/Forms/Select'
import { PAGE_SIZE_DROPDOWN } from '../../../services/client'
import {
  getContacts,
  IContact,
  IFilterContacts,
} from '../../../services/contacts'
import { OptionValue } from '../../../utils/form'
import { Avatar } from '../../../components'

interface Props extends Omit<SelectProps, 'options'> {
  selected?: OptionValue | null
  onSelect?: (option: OptionValue | null) => void
  className?: string
  filterContact?: IFilterContacts | null
  withDetails?: boolean
}
export const ContactSelect = ({
  selected = null,
  className = '',
  filterContact,
  onSelect,
  withDetails,
  ...props
}: Props) => {
  const handleGetContacts = async ({ search = '', page = 1 } = {}) => {
    try {
      const { data } = await getContacts({
        search,
        currentPage: page,
        pageSize: PAGE_SIZE_DROPDOWN,
        ...filterContact,
      })
      return { data: data.data, totalPage: data.meta.last_page }
    } catch {
      return { data: [], totalPage: 1 }
    }
  }

  const mapContacts = (contacts: IContact[]) => {
    if (withDetails) {
      return contacts.map(contact => ({
        value: contact.id,
        label: (
          <div className="flex items-center gap-2">
            <Avatar
              hashId={`contact-${contact.id}`}
              src={contact.avatar_preview_url}
            />
            <div className="inline-flex items-center gap-2">
              <div className="text-body">
                <div className="underline text-black-800 mb-1">
                  {contact.name}
                </div>
                <div className="text-black-400 inline-flex gap-2 items-center">
                  <span>{contact.email}</span>
                  <span className="w-px h-4 bg-black-400" />
                  <span>{contact.phone_mobile}</span>
                </div>
              </div>
            </div>
          </div>
        ),
        displayLabel: contact?.name,
      }))

    }
    return contacts.map(({ id, name }) => ({
      value: id,
      label: name,
    }))
  }
  const handleSelect = (item: any) => {
    const value = !!item
      ? {
        value: item.value,
        label: item.label,
      }
      : null
    onSelect?.(value)
  }

  const triggerValue = filterContact?.filterAccount?.value

  return (
    <AsyncSelect
      labelInValue
      allowClear
      placeholder="All Contacts"
      {...props}
      value={
        selected?.value
          ? { value: selected.value + '', label: selected.label }
          : null
      }
      onChange={handleSelect}
      className={className}
      fetcher={handleGetContacts}
      optionLabelProp="displayLabel"
      options={mapContacts}
      dropdownClassName='w-full'
      triggerFilter={triggerValue}
    />
  )
}
