import { SVGProps } from 'react'

export const Csv = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width='17'
      height='16'
      viewBox='0 0 17 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g clip-path='url(#clip0_9942_34636)'>
        <path
          d='M0.5 2.28571C0.5 1.02335 1.52335 0 2.78571 0H14.2143C15.4767 0 16.5 1.02335 16.5 2.28571V13.7143C16.5 14.9767 15.4767 16 14.2143 16H2.78571C1.52335 16 0.5 14.9767 0.5 13.7143V2.28571Z'
          fill='#3C804D'
        />
        <path
          d='M9.88274 11.4286L8.41261 9.32845L7.11726 11.4286H5.12283L7.43596 7.94139L5.07143 4.57143H7.11726L8.56682 6.64225L9.84162 4.57143H11.836L9.54348 8.0293L11.9286 11.4286H9.88274Z'
          fill='white'
        />
        <path d='M3.5 3H13.5V13H3.5V3Z' fill='#3C804D' />
        <path
          d='M5.90718 6.82985H4.73568C4.72006 6.70786 4.68804 6.59775 4.63962 6.49952C4.5912 6.4013 4.52715 6.31733 4.44749 6.24762C4.36783 6.17792 4.27333 6.12484 4.16399 6.0884C4.05621 6.05038 3.93672 6.03137 3.80551 6.03137C3.57277 6.03137 3.37206 6.0892 3.20336 6.20485C3.03623 6.3205 2.90736 6.48764 2.81677 6.70627C2.72773 6.9249 2.68321 7.18948 2.68321 7.5C2.68321 7.82319 2.72851 8.09411 2.81911 8.31274C2.91127 8.52978 3.04013 8.69376 3.2057 8.80466C3.37284 8.91397 3.57043 8.96863 3.79848 8.96863C3.92657 8.96863 4.04293 8.952 4.14759 8.91873C4.2538 8.88546 4.34674 8.83714 4.4264 8.77376C4.50763 8.70881 4.57401 8.63039 4.62556 8.5385C4.67867 8.44503 4.71537 8.33967 4.73568 8.22243L5.90718 8.22956C5.88687 8.44503 5.82517 8.65732 5.72208 8.86645C5.62055 9.07557 5.48075 9.26648 5.30269 9.43916C5.12462 9.61027 4.9075 9.74651 4.65133 9.84791C4.39673 9.9493 4.10463 10 3.77505 10C3.34082 10 2.95188 9.90336 2.60824 9.71008C2.26616 9.51521 1.99593 9.23162 1.79756 8.85932C1.59919 8.48701 1.5 8.0339 1.5 7.5C1.5 6.96451 1.60075 6.51061 1.80225 6.13831C2.00374 5.766 2.27631 5.48321 2.61995 5.28992C2.96359 5.09664 3.34863 5 3.77505 5C4.06558 5 4.33425 5.04119 4.58104 5.12357C4.82784 5.20437 5.04496 5.32319 5.2324 5.48004C5.41984 5.6353 5.57213 5.8262 5.68928 6.05276C5.80643 6.27931 5.87906 6.53834 5.90718 6.82985Z'
          fill='white'
        />
        <path
          d='M9.16453 6.52567C9.14891 6.35139 9.0794 6.21594 8.95601 6.1193C8.83417 6.02107 8.66001 5.97196 8.43352 5.97196C8.28357 5.97196 8.15861 5.99176 8.05864 6.03137C7.95867 6.07098 7.88369 6.12563 7.83371 6.19534C7.78373 6.26347 7.75795 6.34189 7.75639 6.43061C7.75327 6.50349 7.76732 6.56765 7.79856 6.6231C7.83137 6.67855 7.87823 6.72766 7.93915 6.77044C8.00163 6.81163 8.0766 6.84807 8.16407 6.87975C8.25154 6.91144 8.34995 6.93916 8.45929 6.96293L8.87166 7.05799C9.10908 7.11027 9.31839 7.17997 9.49958 7.26711C9.68234 7.35425 9.83541 7.45802 9.95881 7.57842C10.0838 7.69883 10.1783 7.83745 10.2423 7.9943C10.3064 8.15114 10.3392 8.327 10.3407 8.52186C10.3392 8.82921 10.2626 9.093 10.1111 9.31321C9.95959 9.53343 9.74169 9.70216 9.45741 9.81939C9.17469 9.93663 8.83339 9.99525 8.43352 9.99525C8.03208 9.99525 7.6822 9.93425 7.38385 9.81226C7.08551 9.69027 6.85356 9.50491 6.68798 9.25618C6.52241 9.00745 6.43728 8.69297 6.4326 8.31274H7.54318C7.55255 8.46958 7.59394 8.60029 7.66736 8.70485C7.74077 8.80941 7.84152 8.88863 7.9696 8.94249C8.09925 8.99636 8.2492 9.02329 8.41946 9.02329C8.57566 9.02329 8.70843 9.0019 8.81777 8.95913C8.92867 8.91635 9.0138 8.85694 9.07316 8.78089C9.13251 8.70485 9.16297 8.61771 9.16453 8.51949C9.16297 8.4276 9.13486 8.34918 9.08019 8.28422C9.02552 8.21768 8.94117 8.16065 8.82714 8.11312C8.71468 8.06401 8.57097 8.01885 8.39603 7.97766L7.89463 7.85884C7.47914 7.7622 7.1519 7.60615 6.91291 7.39068C6.67393 7.17364 6.55521 6.88055 6.55678 6.51141C6.55521 6.21039 6.63488 5.94661 6.79576 5.72006C6.95665 5.4935 7.17923 5.31686 7.46352 5.19011C7.7478 5.06337 8.07191 5 8.43586 5C8.80762 5 9.13017 5.06416 9.40352 5.19249C9.67843 5.31923 9.89164 5.49747 10.0432 5.72719C10.1947 5.95691 10.272 6.22307 10.2751 6.52567H9.16453Z'
          fill='white'
        />
        <path
          d='M12.016 5.06654L13.0891 8.63118H13.1289L14.202 5.06654H15.5L13.881 9.93346H12.337L10.7179 5.06654H12.016Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_9942_34636'>
          <rect
            width='16'
            height='16'
            fill='white'
            transform='translate(0.5)'
          />
        </clipPath>
      </defs>
    </svg>
  )
}
