import { SVGProps } from 'react'

export const Compressed = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M0 2.28571C0 1.02335 1.02335 0 2.28571 0H13.7143C14.9767 0 16 1.02335 16 2.28571V5.14286H0V2.28571Z'
        fill='#E14774'
      />
      <path d='M0 5.14286H16V10.8571H0V5.14286Z' fill='#5A5DEA' />
      <path
        d='M0 10.8571H16V13.7143C16 14.9767 14.9767 16 13.7143 16H2.28571C1.02335 16 0 14.9767 0 13.7143V10.8571Z'
        fill='#1B9636'
      />
      <path d='M8 9.71429H9.71429V11.4286H8V9.71429Z' fill='white' />
      <path d='M6.28571 11.4286H8V13.1429H6.28571V11.4286Z' fill='white' />
      <path d='M8 13.1429H9.71429V14.8571H8V13.1429Z' fill='white' />
      <path d='M6.28571 8H8V9.71429H6.28571V8Z' fill='white' />
      <path d='M8 6.28571H9.71429V8H8V6.28571Z' fill='white' />
      <path d='M6.28571 4.57143H8V6.28571H6.28571V4.57143Z' fill='white' />
      <path d='M8 2.85714H9.71429V4.57143H8V2.85714Z' fill='white' />
      <path d='M6.28571 1.14286H8V2.85714H6.28571V1.14286Z' fill='white' />
    </svg>
  )
}
