import { Suspense } from 'react'
import { Outlet } from 'react-router-dom'

import { FooterAuth } from '../../shared/Footer/FooterAuth'

function BlankLayout() {
  return (
    <div className='w-screen min-h-screen flex overflow-x-hidden bg-white justify-center items-center'>
      <div className='px-4 py-4 flex-1 flex flex-col justify-between h-screen'>
        <div className='flex-1 flex items-center justify-center'>
          <Suspense fallback={<div />}>
            <Outlet />
          </Suspense>
        </div>
        <FooterAuth />
      </div>
    </div>
  )
}

export default BlankLayout
