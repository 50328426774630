import { FormField, Input } from '../../../../../components'
import { PhoneField } from '../../../../../components/Forms/PhoneField'
import { FieldItem } from '../../../../Form/FieldItem'
import { PhoneNumberView } from '../../../../PhoneNumberField'
import { RoleSelect, UserSelect } from '../../../../Select'
import { FormProfile } from '../FormHelper'

interface Props {
  onChangeValues: (value: Partial<FormProfile>) => void
  formValues: FormProfile
}
export const BasicForm = ({ onChangeValues, formValues }: Props) => {
  const handleChangePhone = (v: string) => {
    onChangeValues({ phone: v })
  }

  const isEditMode = Boolean(formValues.isEditMode)

  return (
    <div className='mb-4 text-body'>
      <div className='text-black-400 text-xs mb-4 font-medium'>
        BASIC INFORMATION
      </div>
      <div>
        <div className='pl-4 py-3 border-b border-b-separation-800'>
          <FieldItem
            label={
              <span className='flex gap-1 items-center'>
                <span className='font-icon-user w-4 text-left' />
                First Name
                <span className='text-red-900'>*</span>
              </span>
            }
            className='mb-0 flex gap-2 items-center justify-between'
            labelClassName='!mt-0'
            childrenClassName='w-full'
          >
            {isEditMode ? (
              <FormField
                name='first_name'
                rules={[{ required: true, message: 'Please enter First Name' }]}
                requiredMask
                className='mb-0 w-full flex-1'
              >
                <Input />
              </FormField>
            ) : (
              <div className='text-right flex-1'>{formValues.first_name}</div>
            )}
          </FieldItem>
        </div>
        <div className='pl-4 py-3 border-b border-b-separation-800'>
          <FieldItem
            label={
              <span className='pl-5'>
                Last Name
                <span className='text-red-900'>*</span>
              </span>
            }
            className='mb-0 flex gap-2 items-center justify-between'
            labelClassName='!mt-0 !text-left'
            childrenClassName='w-full'
          >
            {isEditMode ? (
              <FormField
                name='last_name'
                className='sm:basis-1/2 mb-0 w-full flex-1'
                rules={[{ required: true, message: 'Please enter Last Name' }]}
                requiredMask
              >
                <Input />
              </FormField>
            ) : (
              <div className='text-right flex-1'>{formValues.last_name}</div>
            )}
          </FieldItem>
        </div>
        <div className='pl-4 py-3 border-b border-b-separation-800'>
          <FieldItem
            label={<span className='pl-5'>Role</span>}
            className='mb-0 flex gap-2 items-center justify-between'
            labelClassName='!mt-0 !text-left'
            childrenClassName='w-full'
          >
            {isEditMode ? (
              <FormField name='roles' className='mb-0 w-full flex-1'>
                <RoleSelect selected={formValues?.roles || null} disabled />
              </FormField>
            ) : (
              <div className='text-right flex-1 capitalize'>
                {formValues.roles?.label}
              </div>
            )}
          </FieldItem>
        </div>
        <div className='pl-4 py-3 border-b border-b-separation-800'>
          <FieldItem
            label={
              <span className='flex gap-1 items-center'>
                <span className='font-icon-email w-4 text-left' />
                Email
              </span>
            }
            className='mb-0 flex gap-2 items-center justify-between'
            labelClassName='!mt-0'
            childrenClassName='w-full text-right'
          >
            {isEditMode ? (
              <FormField name='email' className='mb-0 w-full flex-1'>
                <Input disabled />
              </FormField>
            ) : (
              <a href={`mailto:${formValues.email}`}>{formValues.email}</a>
            )}
          </FieldItem>
        </div>
        <div className='pl-4 py-3 border-b border-b-separation-800'>
          <FieldItem
            label={
              <span className='flex gap-1 items-center'>
                <span className='font-icon-phone w-4 text-left' />
                Phone Number
              </span>
            }
            className='mb-0 flex gap-2 items-center justify-between'
            labelClassName='!mt-0'
            childrenClassName='w-full text-right'
          >
            {isEditMode ? (
              <PhoneField
                name='phone'
                className='sm:basis-1/2 w-full !mb-2'
                onChange={handleChangePhone}
                phoneNumber={formValues.phone || undefined}
              />
            ) : (
              <PhoneNumberView phone={formValues?.phone || ''} />
            )}
          </FieldItem>
        </div>
        <div className='pl-4 py-3 border-b border-b-separation-800'>
          <FieldItem
            label={<span className='pl-5'>Direct Manager</span>}
            className='mb-0 flex gap-2 items-center justify-between'
            labelClassName='!mt-0 !text-left'
            childrenClassName='w-full'
          >
            {isEditMode ? (
              <FormField name='roles' className='mb-0 w-full flex-1'>
                <UserSelect
                  selected={
                    formValues?.directManager
                      ? {
                          value: formValues.directManager.id,
                          label: formValues.directManager.name,
                        }
                      : null
                  }
                  disabled
                  placeholder=''
                />
              </FormField>
            ) : (
              <div className='text-right flex-1 capitalize'>
                {formValues.roles?.label}
              </div>
            )}
          </FieldItem>
        </div>
      </div>
    </div>
  )
}
