import { Elements } from '@stripe/react-stripe-js'
import {
  loadStripe,
  PaymentMethod,
  SetupIntent,
  Stripe,
} from '@stripe/stripe-js'
import { ColumnType } from 'rc-table/lib/interface'
import { useEffect, useState } from 'react'

import { Button, Switch, Table } from '../../components'
import { BillingInvoicesTableList } from '../../components/Billing/BillingInvoicesTableList'
import { SubscriptionProductsList } from '../../components/Billing/SubscriptionProductsList'
import { Spinner } from '../../components/Spinner'
import { useAsync } from '../../hooks'
import {
  addSubscription,
  cancelSubscription,
  createCustomerBilling,
  createPaymentsIntent,
  getBillingProducts,
  getCustomerBilling,
  initSubscription,
  Price,
  Subscription,
  SubscriptionItem,
  updatePaymentMethod,
  updateSubscription,
} from '../../services/billing'
import { IInvoice } from '../../services/invoices'
import { AddCardForm } from '../../shared/Payment/AddCardForm'
import { PageWrapperPermisison } from '../../shared/Permission/PageWrapperPermission'
import { ResourceCollapse } from '../../shared/Resources/ResourceColapse'
import { SubscriptionProductSelect } from '../../shared/Select/Subscription/SubscriptionProduct'
import useStore from '../../store'
import { PERMISSIONS } from '../../utils/permission'
import toast from '../../utils/toast'

interface Props {
  resourceId: number
  resourceType: string
  externalResult?: any
}

const Billing = ({ resourceId, externalResult }: Props) => {
  const roles = useStore(state =>
    state.auth.currentUser?.roles.map(role => role.name),
  )
  const [stripeKey, setStripeKey] = useState<{
    secretKey: string
    publicKey: string
  }>()
  const [showCreateStripeAccount, setShowCreateStripeAccount] = useState(false)
  const [defaultPaymentMethod, setDefaultPaymentMethod] =
    useState<PaymentMethod | null>(null)
  const [currentSubscription, setCurrentSubscription] = useState<
    Subscription[] | null
  >()
  const [pastInvoices, setPastInvoices] = useState<IInvoice[] | null>()
  const [stripe, setStripe] = useState<Stripe | null>(null)
  const [prices, setPrices] = useState<Price[]>([])
  const [selectedProducts, setSelectedProducts] = useState<string[]>([])
  const [isEditCard, setIsEditCard] = useState<boolean>(false)

  const fetchBillingAsync = useAsync({
    showNotifOnError: false,
    status: 'idle',
  })

  const customerBillingAsync = useAsync({
    showNotifOnError: true,
    status: 'idle',
  })

  const pricesAsync = useAsync({
    showNotifOnError: true,
    status: 'pending',
  })

  const subscriptionAsync = useAsync({
    showNotifOnError: true,
    status: 'idle',
  })

  const fetchData = async () => {
    let result: any
    if (externalResult && 'data' in externalResult) {
      result = externalResult
    } else {
      result = await fetchBillingAsync
        .execute(getCustomerBilling(resourceId))
        .catch(async error => {
          if (error.errorCode === 'account_not_stripe_customer') {
            setShowCreateStripeAccount(true)
          }
        })
    }
    if (result) {
      if ('data' in result) {
        if (result.data.data.default_payment_method) {
          setDefaultPaymentMethod(result.data.data.default_payment_method)
        } else {
          createPaymentIntent()
        }
      }
      if (result.data.data.subscriptions) {
        setCurrentSubscription(result.data.data.subscriptions)
      }
      if (result.data.data.invoices) {
        setPastInvoices(result.data.data.invoices)
      }
    }
  }

  const createStripeAccount = async () => {
    await customerBillingAsync.execute(createCustomerBilling(resourceId))
    setShowCreateStripeAccount(false)
    await createPaymentIntent()
  }

  const createPaymentIntent = async () => {
    const result = await customerBillingAsync.execute(
      createPaymentsIntent(resourceId),
    )
    if (result) {
      setStripeKey({
        secretKey: result.data.payment_intent.client_secret,
        publicKey: result.data.stripe_public_key,
      })
      const response = await loadStripe(result.data.stripe_public_key, {})
      setStripe(response)
    }
  }

  const fetchBillingPrices = async () => {
    const response = await pricesAsync.execute(getBillingProducts())
    if (response?.data?.data) {
      setPrices(response.data.data)
    }
  }

  useEffect(() => {
    fetchData()
    fetchBillingPrices()
  }, [])

  const onAddCardSuccess = async (payment: SetupIntent) => {
    if (payment.payment_method) {
      const response = await customerBillingAsync.execute(
        updatePaymentMethod(resourceId, payment.payment_method.toString()),
      )

      if (response) {
        setStripe(null)
        fetchData()
        if (isEditCard) {
          setIsEditCard(false)
        }
      }
    }
  }

  const onAddingSubscription = async () => {
    const response = await subscriptionAsync.execute(
      addSubscription(resourceId, selectedProducts),
    )
    if (response) {
      fetchData()
      setSelectedProducts([])
      toast.success({ title: 'Subscription updated successfully!' })
    }
  }

  const toggleEditCard = () => {
    if (!isEditCard) {
      createPaymentIntent()
    }
    setIsEditCard(!isEditCard)
  }
  const isLoading =
    fetchBillingAsync.isLoading || customerBillingAsync.isLoading
  return (
    <div className='p-4 sm:p-6'>
      <div className={'flex justify-between'}>
        <h2 className='text-2xl font-medium mb-4'>Billing</h2>
        <>
          {!isEditCard && defaultPaymentMethod && (
            <Button
              className={''}
              onClick={toggleEditCard}
              disabled={isLoading}
            >
              Edit Card
            </Button>
          )}
          {isEditCard && defaultPaymentMethod && (
            <Button onClick={toggleEditCard} disabled={isLoading}>
              cancel
            </Button>
          )}
        </>
      </div>

      {isLoading && <Spinner size='large' />}
      {showCreateStripeAccount && (
        <div>
          <h3>
            This account is not yet connected to a Stripe account. Do you want
            to connect it?
          </h3>
          <Button
            className='mt-5'
            onClick={createStripeAccount}
            disabled={customerBillingAsync.isLoading}
          >
            Setup Stripe Billing
          </Button>
        </div>
      )}
      {defaultPaymentMethod && !isEditCard && !isLoading && (
        <DefaultPaymentMethod paymentMethod={defaultPaymentMethod} />
      )}
      {stripe &&
        stripeKey &&
        ((defaultPaymentMethod && isEditCard) ||
          (!defaultPaymentMethod && !isEditCard)) &&
        !customerBillingAsync.isLoading && (
          <Elements
            stripe={stripe}
            options={{
              clientSecret: stripeKey.secretKey,
              appearance: {
                theme: 'stripe',
                variables: {
                  fontFamily: 'Poppins, system-ui, sans-serif',
                  colorText: '#2C2C2E',
                },
              },
            }}
          >
            <div className='max-w-full lg:max-w-screen-sm mb-8'>
              <AddCardForm onSuccess={onAddCardSuccess} />
            </div>
          </Elements>
        )}

      {roles && roles.includes('admin') && defaultPaymentMethod && (
        <div className='max-w-full lg:max-w-screen-sm'>
          <h3 className='text-xl mb-4'>Select Subscription products</h3>
          <SubscriptionProductSelect
            selectedProducts={selectedProducts}
            setSelectedProducts={setSelectedProducts}
          />
          <Button
            onClick={onAddingSubscription}
            className='mt-4'
            disabled={!selectedProducts.length || subscriptionAsync.isLoading}
          >
            Add Subscription
          </Button>
        </div>
      )}
      {currentSubscription && currentSubscription.length > 0 && (
        <div className='mt-8'>
          <h3 className='text-xl mb-4'>Current Subscriptions</h3>
          <TableList
            subscriptions={currentSubscription}
            prices={prices}
            loading={subscriptionAsync.isLoading}
            resourceId={resourceId}
            onFetchData={fetchData}
            isAdmin={roles && roles.includes('admin')}
          />
        </div>
      )}
      {pastInvoices && pastInvoices.length > 0 && (
        <div className='mt-8'>
          <h3 className='text-xl mb-4'>Past Invoices</h3>
          <BillingInvoicesTableList
            data={pastInvoices}
            loading={fetchBillingAsync.isLoading}
          />
        </div>
      )}
    </div>
  )
}

const DefaultPaymentMethod = ({
  paymentMethod,
}: {
  paymentMethod: PaymentMethod
}) => {
  return (
    <ResourceCollapse label='Default Payment Method' className='mb-3'>
      <div className='pb-3 mb-3 flex-col sm:flex-row flex sm:items-center border-b border-separation-400 gap-3'>
        <div className='w-60 inline-flex gap-3 items-center pl-7'>
          <span>Card Brand</span>
        </div>
        <div className='flex-1 pl-7 sm:pl-0'>
          <span>{paymentMethod.card?.brand}</span>
        </div>
      </div>
      <div className='pb-3 mb-3 flex-col sm:flex-row flex sm:items-center border-b border-separation-400 gap-3'>
        <div className='w-60 inline-flex gap-3 items-center pl-7'>
          <span>Last 4 digits</span>
        </div>
        <div className='flex-1 pl-7 sm:pl-0'>
          <span>{paymentMethod.card?.last4}</span>
        </div>
      </div>
      <div className='pb-3 mb-3 flex-col sm:flex-row flex sm:items-center border-b border-separation-400 gap-3'>
        <div className='w-60 inline-flex gap-3 items-center pl-7'>
          <span>Expiry</span>
        </div>
        <div className='flex-1 pl-7 sm:pl-0'>
          <span>
            {paymentMethod.card?.exp_month}/{paymentMethod.card?.exp_year}
          </span>
        </div>
      </div>
    </ResourceCollapse>
  )
}

interface TableListProps {
  subscriptions: Subscription[]
  prices: Price[]
  resourceId: number
  loading: boolean
  onFetchData: () => void
  isAdmin?: boolean
}

interface SubscriptionColumn extends Subscription {
  name: string
  price: number
}

const TableList = ({
  subscriptions,
  prices,
  loading,
  resourceId,
  onFetchData,
  isAdmin,
}: TableListProps) => {
  const [isEdit, setEdit] = useState(false)
  const [selectedSubscription, setSelectedSubscription] =
    useState<Subscription>(initSubscription)

  const [selectedProducts, setSelectedProducts] = useState<string[]>([])
  const [autoBillingStates, setAutoBillingStates] = useState<{
    [key: number]: boolean
  }>({})
  const handleAutoBillingToggle = (
    subscriptionId: number,
    isAutoBilling: boolean,
  ) => {
    setAutoBillingStates(prevState => ({
      ...prevState,
      [subscriptionId]: !isAutoBilling,
    }))
  }
  const subscriptionAsync = useAsync({
    showNotifOnError: true,
    status: 'idle',
  })
  const onCancelSubscription = async (id: number) => {
    const response = await subscriptionAsync.execute(
      cancelSubscription(resourceId, id),
    )
    if (response) {
      toast.success({ title: 'Subscription canceled successfully!' })
      onFetchData()
    }
  }

  const onResumeSubscription = async (id: number, record: Subscription) => {
    const response = await subscriptionAsync.execute(
      updateSubscription(resourceId, id, {
        resume: 1,
        price_ids: record.items.map(item => item.stripe_price),
      }),
    )

    if (response) {
      toast.success({ title: 'Subscription resumed successfully!' })
      onFetchData()
    }
  }

  const onEditSubscription = (subscriptionId: number, record: Subscription) => {
    setEdit(true)
    setSelectedSubscription(record)
  }
  const onSaveSubscription = async (subscriptionId: number) => {
    if (selectedSubscription?.items) {
      const response = await subscriptionAsync.execute(
        updateSubscription(resourceId, subscriptionId, {
          resume: 1,
          price_ids: [
            ...selectedSubscription.items.map(item => item.stripe_price),
            ...selectedProducts,
          ],
          is_auto_billing_enabled:
            autoBillingStates[subscriptionId] ??
            selectedSubscription.is_auto_billing_enabled,
        }),
      )
      if (response) {
        toast.success({ title: 'Subscription Edited successfully!' })
        onFetchData()
      }
    }
    resetEdit()
  }
  const resetEdit = () => {
    setEdit(!isEdit)
    setAutoBillingStates([])
    setSelectedSubscription(initSubscription)
    setSelectedProducts([])
  }

  const onRemoveProduct = (productId: number) => {
    if (selectedSubscription?.items) {
      const updatedItems = selectedSubscription.items.filter(
        item => item.id !== productId,
      )
      setSelectedSubscription(prevState => ({
        ...prevState,
        items: updatedItems,
      }))
    }
  }

  const columns: ColumnType<SubscriptionColumn>[] = [
    {
      key: 'items',
      dataIndex: 'items',
      title: 'Subscription Products',
      render: (items: SubscriptionItem[], record) => {
        if (
          selectedSubscription?.items &&
          record.id === selectedSubscription.id
        ) {
          items = selectedSubscription?.items
        }
        return (
          <SubscriptionProductsList
            items={items}
            record={record}
            selectedSubscription={selectedSubscription}
            isEdit={isEdit}
            products={prices}
            onRemoveProduct={onRemoveProduct}
            selectedProducts={selectedProducts}
            setSelectedProducts={setSelectedProducts}
          />
        )
      },
    },
    {
      key: 'stripe_status',
      title: 'Stripe Status',
      dataIndex: 'stripe_status',
    },
    {
      key: 'ends_at',
      title: 'Ends At',
      dataIndex: 'ends_at',
    },
    {
      key: 'is_auto_billing_enabled',
      title: 'Auto Billing',
      dataIndex: 'is_auto_billing_enabled',

      render: (is_auto_billing_enabled, record) => {
        const subscriptionId = record.id
        const autoBillingEnabled =
          autoBillingStates[subscriptionId] ?? is_auto_billing_enabled
        return isEdit && record.id === selectedSubscription?.id ? (
          <Switch
            checked={autoBillingEnabled}
            onChange={() =>
              handleAutoBillingToggle(subscriptionId, autoBillingEnabled)
            }
          />
        ) : autoBillingEnabled ? (
          <span className='font-icon-check text-green-900'></span>
        ) : (
          <span className='font-icon-close text-red-900'></span>
        )
      },
    },
    {
      key: 'next_payment_amount',
      title: 'next payment date',
      render: (item: Subscription) => {
        const date = new Date(item.next_payment_due_at).toLocaleDateString(
          'en-US',
          {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          },
        )
        return (
          <>
            <span className={'rounded-full bg-blue-600 text-white p-1'}>
              <span className={'mx-1 font-semibold'}>
                ${item.next_payment_amount}
              </span>
              :<span className={'mx-1'}>{date}</span>
            </span>
          </>
        )
      },
    },
  ]

  if (isAdmin) {
    columns.push({
      key: 'action',
      dataIndex: 'id',
      title: 'Actions',
      render: (val: number, record) => (
        <div className={'flex gap-2'}>
          {!record.ends_at && !isEdit && (
            <Button
              disabled={subscriptionAsync.isLoading || loading}
              onClick={() => onCancelSubscription(val)}
            >
              Cancel
            </Button>
          )}
          {record.is_on_grace_period && !isEdit && (
            <Button
              disabled={subscriptionAsync.isLoading || loading}
              onClick={() => onResumeSubscription(val, record)}
            >
              Resume
            </Button>
          )}
          {isEdit && record.id === selectedSubscription.id ? (
            <>
              <Button
                disabled={subscriptionAsync.isLoading || loading}
                onClick={() => onSaveSubscription(val)}
              >
                Save
              </Button>
              <Button
                variant='ternary'
                disabled={subscriptionAsync.isLoading || loading}
                onClick={resetEdit}
              >
                Cancel
              </Button>
            </>
          ) : (
            <Button
              variant='ternary'
              disabled={subscriptionAsync.isLoading || loading}
              innerClassName='flex items-center gap-2 !p-2'
              onClick={() => onEditSubscription(val, record)}
            >
              <span className='font-icon-edit' />
              Edit
            </Button>
          )}
        </div>
      ),
    })
  }

  return (
    <Table
      rowKey='id'
      columns={columns}
      data={subscriptions}
      className='rounded-lg rounded-b-none bg-white max-w-full'
      showContentHover={true}
      loading={loading}
    />
  )
}

export const PageBilling = (props: Props) => {
  return (
    <PageWrapperPermisison permissionName={PERMISSIONS.CUSTOMER_BILLING}>
      <Billing {...props} />
    </PageWrapperPermisison>
  )
}
