import cx from 'classnames'
import { ChangeEvent, useEffect, useState } from 'react'

import { Input, Props as PropsInput } from './Input'

interface Props extends PropsInput {
  wrapperClassName?: string | null
  search?: string
  onSearch?: (value: string) => void
  onClearSearch?: () => void
}
export const SearchInput = ({
  search,
  onSearch,
  onClearSearch,
  wrapperClassName = '',
  className,
  ...props
}: Props) => {
  const [keyword, setKeyword] = useState('')
  const handleSearch: React.KeyboardEventHandler<HTMLInputElement> = e => {
    if (e.key === 'Enter') {
      const value = (e.target as HTMLInputElement).value
      value !== search && onSearch?.(value)
    }
    props.onKeyDown?.(e)
  }
  const handleClearSearch = () => {
    setKeyword('')
    onClearSearch?.()
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setKeyword(e.target.value)
    props?.onChange?.(e)
  }
  useEffect(() => {
    if (search !== keyword) {
      setKeyword(search || '')
    }
  }, [search])

  return (
    <div className={cx('relative', wrapperClassName)}>
      <Input
        onKeyDown={handleSearch}
        suffix={
          <>
            {keyword !== '' ? (
              <span
                className='font-icon-close cursor-pointer text-13'
                onClick={handleClearSearch}
              ></span>
            ) : (
              <span
                className='font-icon-search cursor-pointer'
                onClick={() => onSearch?.(keyword)}
              />
            )}
          </>
        }
        {...props}
        value={keyword}
        onChange={handleChange}
        className={cx('pr-8 h-8 min-w-[12rem]', className)}
      />
      {/* <div className='absolute right-4 top-1/2 translate-y-[-50%] h-auto leading-none'>
        {keyword !== '' ? (
          <span
            className='font-icon-close cursor-pointer text-13'
            onClick={handleClearSearch}
          ></span>
        ) : (
          <span
            className='font-icon-search cursor-pointer'
            onClick={() => onSearch?.(keyword)}
          />
        )}
      </div> */}
    </div>
  )
}
