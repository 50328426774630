import { Props as SelectProps, Select } from '../../../components/Forms/Select'
import { OptionValue } from '../../../utils/form'

interface Props extends Omit<SelectProps, 'options'> {
  selected?: OptionValue | null
  onSelect?: (option: OptionValue | null) => void
  className?: string
}

const listStatus = [ 'Active', 'Blocked' ]

export const AccountStatusSelect = ({
  selected = null,
  onSelect,
  ...props
}: Props) => {
  return (
    <Select
      placeholder='All Status'
      allowClear
      onChange={onSelect}
      {...props}
    >
      <Select.Option value=''>All Status</Select.Option>
      {listStatus.map(item => (
        <Select.Option value={item} key={item} className='capitalize'>
          {item}
        </Select.Option>
      ))}
    </Select>
  )
}
