import { Avatar } from '../../../../../components'
import {
  PERMISSIONS,
  PERMISSIONS_UPDATE,
} from '../../../../../utils/permission'
import { RESOURCE_USER } from '../../../../../utils/resources'
import { ComponentPermisison } from '../../../../Permission/ComponentPermission'
import { ResourceLink } from '../../../../Resources/ResourceLink'
import { useFileDetailCtx } from '../Context'

export const FileInfoAccess = () => {
  const { file, dispatch } = useFileDetailCtx()
  const isPrivate = file.is_protected
  const totalUserAccess = file.accessors?.length || 0
  return (
    <>
      <div className='text-body mt-10'>
        <div className='flex justify-between items-center gap-2 mb-2'>
          <div className='text-base font-medium'>Who has access</div>
          <ComponentPermisison
            name={PERMISSIONS.FILE}
            type={PERMISSIONS_UPDATE}
          >
            <div
              className='text-primary-900 font-medium cursor-pointer'
              onClick={() => dispatch({ openManageAccess: true })}
            >
              Manage Access
            </div>
          </ComponentPermisison>
        </div>
        <div className='text-black-400 mb-2'>
          {isPrivate ? (
            <>
              <span className='font-medium text-black-800'>Private</span> Only
              added people can access
            </>
          ) : (
            <>
              <span className='font-medium text-black-800'>Public</span> All
              users in your company can access
            </>
          )}
        </div>
        {file.accessors && totalUserAccess > 0 && (
          <div className='flex flex-col'>
            {file.accessors.map((item, index) => (
              <ResourceLink
                resourceType={RESOURCE_USER}
                resourceId={item.id}
                className='flex items-center gap-2 py-3 relative'
                key={item.id}
              >
                <Avatar
                  src={item.avatar_preview_url || null}
                  hashId={`user-${item.id}`}
                  size='small'
                />
                <span className='text-body'>{item.name}</span>
                {index < totalUserAccess - 1 && (
                  <div className='absolute bottom-0 left-0 right-0 w-full h-px bg-separation-400' />
                )}
              </ResourceLink>
            ))}
          </div>
        )}
      </div>
    </>
  )
}
