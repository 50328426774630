import { useMemo, useState } from 'react'
import Cropper from 'react-cropper'

import { Button, Modal, ModalProps, Slider } from '../../../../components'

interface Props extends Omit<ModalProps, 'children'> {
  onSuccess?: (file: File) => void
  onClose: () => void
  file: File
}
const SCALE_MIN = 0
const SCALE_MAX = 10
const SCALE_STEP = 0.1
const SCALE_INIT = 5

export const ModalCropImage = ({
  onClose,
  onSuccess,
  file,
  ...props
}: Props) => {
  const [scale, setScale] = useState(SCALE_INIT)
  const [cropper, setCropper] = useState<Cropper>()

  const handleDirChange = (type: string) => {
    if (
      (type === 'decrease' && scale <= SCALE_MIN) ||
      (type === 'increase' && scale >= SCALE_MAX)
    ) {
      return null
    }
    const value = type === 'decrease' ? scale - 1 : scale + 1
    handleScale(value)
  }

  const handleSliderChange = (s: number) => {
    setScale(s)
    cropper?.zoom((s - scale) * SCALE_STEP)
  }

  const handleScale = (s: number) => {
    const zoomIn = scale < s
    setScale(s)
    cropper?.zoom(zoomIn ? SCALE_STEP : -SCALE_STEP)
    // cropper?.zoom(zoomIn ? SCALE_STEP : -SCALE_STEP)
  }

  const handleSave = () => {
    if (cropper) {
      cropper.getCroppedCanvas().toBlob((blobFile: Blob | null) => {
        if (blobFile) {
          const newFile = new File([blobFile], file.name)
          onSuccess?.(newFile)
          onClose()
        }
      })
    }
  }

  const currentFile = useMemo(() => URL.createObjectURL(file), [file])
  return (
    <Modal {...props} className='max-w-[55rem] w-full' onClose={onClose}>
      <div className='flex gap-3 relative flex-col'>
        <span
          className='cursor-pointer font-icon-close text-black-400 absolute -right-2 -top-2 z-10 w-10 h-10 flex justify-center items-center'
          onClick={onClose}
        />
        <div className='flex justify-between items-center gap-2 w-full pr-8 pb-4'>
          <div className='text-base font-medium'>Crop Image</div>
        </div>
      </div>
      <div className='h-[30rem] flex items-center relative justify-center'>
        <div className='bg-[#d9d9d9] absolute inset-0' />
        <Cropper
          src={currentFile}
          background={false}
          movable
          dragMode='move'
          responsive
          center
          toggleDragModeOnDblclick={false}
          zoomOnWheel={false}
          className='h-[30rem] w-full'
          aspectRatio={1}
          onInitialized={instance => {
            setCropper(instance)
          }}
        />
      </div>
      <div className='flex justify-between pt-3 items-center'>
        <div className='flex gap-3'>
          <div
            className='w-4 h-4 cursor-pointer'
            onClick={() => handleDirChange('decrease')}
          >
            <span className='font-icon-minus' />
          </div>
          <div className='w-[12.5rem] pt-2'>
            <Slider
              min={SCALE_MIN}
              max={SCALE_MAX}
              value={scale}
              onChange={handleSliderChange}
            />
          </div>
          <div
            className='w-4 h-4 cursor-pointer'
            onClick={() => handleDirChange('increase')}
          >
            <span className='font-icon-add' />
          </div>
        </div>
        <div className='flex gap-2'>
          <Button variant='ternary' onClick={onClose} innerClassName='!px-4'>
            Cancel
          </Button>
          <Button
            variant='primary'
            onClick={handleSave}
            innerClassName='!px-10'
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
  )
}
