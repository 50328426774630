import { Placement } from '@popperjs/core'
import cx from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import { Rule } from 'rc-field-form/lib/interface'
import { ChangeEvent, useEffect, useState } from 'react'
import useOnclickOutside from 'react-cool-onclickoutside'
import { usePopper } from 'react-popper'
import usePlacesAutocomplete from 'use-places-autocomplete'

import { FormField, Input } from '../../components'
import { getTransformOrigin } from '../../components/Popover'
import MenuItem from '../MenuItem'

export interface Props {
  country: string
  placeType: string
  name: string
  label?: string
  placeholder?: string
  required?: boolean
  requiredRule?: Rule[]
  onSelect?: (type: string, s: AutocompletePrediction) => void
  className?: string
  placement?: Placement
}

type AutocompletePrediction = google.maps.places.AutocompletePrediction

export const AutoCompleteField = ({
  country,
  placeType,
  name,
  label,
  required = false,
  requiredRule,
  placeholder = '',
  onSelect,
  className = '',
  placement = 'top-start',
}: Props) => {
  const [refElement, setRefElement] = useState<HTMLElement | null>(null)
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null)

  const { styles, attributes } = usePopper(refElement, popperElement, {
    placement,
  })

  const {
    value: autoValue,
    suggestions,
    setValue,
    clearCache,
    clearSuggestions,
  } = usePlacesAutocomplete({
    debounce: 500,
    defaultValue: '',
    requestOptions: {
      componentRestrictions: {
        country: placeType === 'country' ? '' : country,
      },
      types: [
        placeType === 'address'
          ? 'address'
          : placeType === 'country'
          ? 'country'
          : '(regions)',
      ],
    },
  })

  const { status, data } = suggestions
  const refOutSide = useOnclickOutside(() => {
    setValue('', true)
    clearSuggestions()
  })

  const renderDropDown = () =>
    autoValue !== '' &&
    status !== '' && (
      <>
        {!!data.length ? (
          data.map(renderSuggestion)
        ) : (
          <div className='h-[4.375rem] flex justify-center items-center text-light-secondary text-sm opacity-60'>
            No Data
          </div>
        )}
      </>
    )

  const renderSuggestion = (s: AutocompletePrediction) => {
    const { place_id, structured_formatting } = s
    return (
      <MenuItem
        key={place_id}
        onClick={() => handleSelectSuggestion(s)}
        className='select-none !rounded-lg'
      >
        <div className='w-full mb-1'>
          <div className='flex items-center gap-2 text-13'>
            <span className='font-icon-location' />
            <div className='font-medium'>{structured_formatting.main_text}</div>
          </div>
          <div className='ml-[1.1875rem] text-xs mt-px'>
            {structured_formatting.secondary_text}
          </div>
        </div>
      </MenuItem>
    )
  }

  const handleSelectSuggestion = async (s: AutocompletePrediction) => {
    setValue('', false)
    onSelect?.(placeType, s)
    clearSuggestions()
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setValue(value, !!value.trim().length)
  }
  useEffect(() => {
    clearCache()
  }, [country])

  return (
    <div className={cx('relative', className)} ref={refOutSide}>
      <FormField
        name={name}
        label={label}
        rules={required ? requiredRule : []}
        requiredMask={required}
        className='!mb-2'
      >
        <Input
          onChange={handleChange}
          ref={setRefElement}
          placeholder={placeholder}
        />
      </FormField>
      <AnimatePresence initial={false}>
        {autoValue !== '' && status !== '' && (
          <div
            ref={setPopperElement}
            className='w-full z-10'
            style={{ ...styles.popper }}
            {...attributes.popper}
          >
            <motion.div
              initial={{ scale: 0, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0, opacity: 0 }}
              transition={{ duration: 0.2 }}
              style={{
                transformOrigin: getTransformOrigin('right-end'),
              }}
            >
              <div className='p-2 z-popover w-full bg-white text-sm text-light-primary rounded-lg border border-light-stroke shadow-dropdown'>
                {renderDropDown()}
              </div>
            </motion.div>
          </div>
        )}
      </AnimatePresence>
    </div>
  )
}
