import cx from 'classnames'
import { ComponentProps, forwardRef } from 'react'

import { ErrorCircle } from '../../icons'

export type Props = ComponentProps<'textarea'> & {
  size?: 'small' | 'default' | 'large'
  error?: boolean
  showLength?: boolean
}

const sizes = {
  small: 'py-0 px-[0.4375rem] text-sm',
  default: 'py-1 px-2.5 text-sm',
  large: 'px-2.5 py-1.5 text-md',
}

const disabled = 'pointer-events-none bg-light-disabled !text-light-primary/50'

export const TextArea = forwardRef<HTMLTextAreaElement, Props>(
  ({ size = 'default', error, showLength, maxLength, ...props }, ref) => {
    return (
      <div className='relative'>
        <textarea
          {...props}
          ref={ref}
          rows={props.rows || 3}
          className={cx(
            'border border-separation-900 rounded-lg w-full custom-scrollbar',
            'base-transition hover:border-primary-900 focus:border-primary-900 focus:shadow-input outline-0',
            'placeholder:text-light-secondary placeholder:font-normal resize-none',
            sizes[size],
            props.disabled && disabled,
            props.className,
          )}
        />
        {error && (
          <div className='absolute inline-flex items-center gap-2 right-4 top-2'>
            <span>
              <ErrorCircle className='text-red-900' />
            </span>
          </div>
        )}
        {!!showLength && !!maxLength && (
          <div className='text-right text-body text-black-400'>
            {maxLength - (props.value?.toString().length || 0)}
          </div>
        )}
      </div>
    )
  },
)
