import cx from 'classnames'
import { ReactNode } from 'react'

import { Props as ThumbnailProps, ResourceThumbnail } from './ResourceThumbnail'

interface Props extends ThumbnailProps {
  name: string | ReactNode
  thumnailClassName?: string
  thumnailWrapperClassName?: string
  wrapperClassName?: string
  textClassName?: string
}
export const ResourceNameThumbnail = ({
  name,
  wrapperClassName = '',
  textClassName = '',
  thumnailClassName = '',
  thumnailWrapperClassName = '',
  className = '',
  ...props
}: Props) => {
  return (
    <div
      className={cx(
        'underline inline-flex items-center gap-2',
        wrapperClassName,
      )}
    >
      <div className={cx('flex-1', thumnailWrapperClassName)}>
        <ResourceThumbnail
          {...props}
          className={thumnailClassName || className}
        />
      </div>
      <span className={cx('leading-tight', textClassName)}>{name}</span>
    </div>
  )
}
