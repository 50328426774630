import cx from 'classnames'
import { useState } from 'react'

import { SelectProps } from '../../../../components'
import { useAsync } from '../../../../hooks'
import { createTag } from '../../../../services/tags'
import { OptionValueTag } from '../../../../utils/form'
import { TagsSelect } from '../../../Select'

interface Props extends Omit<SelectProps, 'options'> {
  max?: number
  selected?: OptionValueTag[] | null
  onSelect?: (v: OptionValueTag[] | null) => void
  variant?: 'inline' | 'large' | 'small'
}

export const FileTagsSelect = ({
  selected = null,
  onSelect,
  max = 5,
  variant = 'large',
  ...props
}: Props) => {
  const createTagAsync = useAsync({ showNotifOnError: false })

  const [tags, setTags] = useState<OptionValueTag[] | null>(selected)

  const handleCreateNewTags = async (
    rawTags: string[],
    currentTags: OptionValueTag[],
  ) => {
    for (let index = 0; index < rawTags.length; index++) {
      const result = await createTagAsync.execute(
        createTag({ name: rawTags[index] }),
      )
      if (result) {
        const indexRawTag = currentTags.findIndex(
          item => item.value + '' === rawTags[index],
        )
        if (indexRawTag >= 0) {
          currentTags = [
            ...currentTags.slice(0, indexRawTag),
            {
              ...currentTags[indexRawTag],
              label: result.data.data.name,
              value: result.data.data.name,
              key: result.data.data.id,
            },
            ...currentTags.slice(indexRawTag + 1),
          ]
        }
      }
    }
    return currentTags
  }
  const handleChangeTags = async (listTags: OptionValueTag[] | null) => {
    let currentListTags = listTags || []
    const listRawTags = currentListTags
      .filter(item => !item.key)
      .map(item => item.value + '')
    if (listRawTags.length > 0) {
      currentListTags = await handleCreateNewTags(listRawTags, currentListTags)
    }
    setTags(currentListTags)
    onSelect?.(currentListTags)
  }

  return (
    <TagsSelect
      className={cx('select-tags', variant)}
      onChangeTags={handleChangeTags}
      value={tags || undefined}
      {...props}
    />
  )
}
