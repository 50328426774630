import { AsyncSelect } from '../../../components/Forms/AsyncSelect'
import { Props as SelectProps } from '../../../components/Forms/Select'
import { PAGE_SIZE_DROPDOWN } from '../../../services/client'
import {
  getProjects,
  IFilterProjects,
  IProject,
} from '../../../services/projects'
import { formatDateTime } from '../../../utils/dateTime'
import { OptionValue } from '../../../utils/form'

interface Props extends Omit<SelectProps, 'options' | 'onSelect'> {
  selected?: OptionValue | null
  onSelect?: (option: OptionValue | null, optionFull?: IProject | null) => void
  className?: string
  excludesValues?: number[]
  filterProject?: IFilterProjects | null
  fetchOnFirst?: boolean
}
export const ProjectSelect = ({
  selected = null,
  className = '',
  onSelect,
  excludesValues = [],
  filterProject = null,
  fetchOnFirst = true,
  ...props
}: Props) => {
  const handleGetProjects = async ({ search = '', page = 1 } = {}) => {
    try {
      const { data } = await getProjects({
        search,
        currentPage: page,
        pageSize: PAGE_SIZE_DROPDOWN,
        ...filterProject,
      })
      return { data: data.data, totalPage: data.meta.last_page }
    } catch {
      return { data: [], totalPage: 1 }
    }
  }

  const handleSelect = (item: any, itemFull: IProject) => {
    const value = !!item
      ? {
          value: +item.value,
          label: itemFull.name,
        }
      : null
    onSelect?.(value, itemFull)
  }

  const triggerValue = filterProject?.filterAccount?.value

  return (
    <AsyncSelect
      placeholder='All Projects'
      labelInValue
      allowClear
      {...props}
      value={selected}
      onChange={handleSelect}
      className={className}
      fetcher={handleGetProjects}
      dropdownClassName='w-full'
      triggerFilter={triggerValue}
      listHeight={350}
      fetchOnFirst={fetchOnFirst}
    >
      {(data: IProject[]) => {
        return data.map(item =>
          !excludesValues.includes(item.id) ? (
            <AsyncSelect.Option key={item.id}>
              <div className='flex gap-1 flex-col'>
                <div>{item.name}</div>
                <div className='text-black-400'>
                  Created at {formatDateTime(item.created_at)}
                </div>
              </div>
            </AsyncSelect.Option>
          ) : null,
        )
      }}
    </AsyncSelect>
  )
}
