import { useEffect, useState } from 'react'

import { Select } from '../../../components'
import { Spinner } from '../../../components/Spinner'
import { useAsync } from '../../../hooks'
import {
  getBillingProducts,
  Price,
  SubscriptionItem,
} from '../../../services/billing'
import { currencyFormat } from '../../../utils/functions'

interface Props {
  selectedProducts: string[]
  setSelectedProducts: any
  except?: SubscriptionItem[]
  parentProducts?: Price[]
}

export const SubscriptionProductSelect = ({
  selectedProducts,
  setSelectedProducts,
  except = [],
  parentProducts,
}: Props) => {
  const [products, setProducts] = useState<Price[]>([])
  const productsAsync = useAsync({
    showNotifOnError: true,
    status: 'pending',
  })

  useEffect(() => {
    if (parentProducts) {
      setProducts(parentProducts)
      productsAsync.setState({ status: 'resolved' })
    } else {
      const fetchBillingProducts = async () => {
        const response = await productsAsync.execute(getBillingProducts())
        if (response?.data?.data) {
          setProducts(response.data.data)
        }
      }
      fetchBillingProducts()
    }
  }, [])

  const filteredProducts = products.filter(product =>
    except.every(item => item?.stripe_price !== product?.price_id),
  )

  return (
    <div className='relative'>
      {productsAsync.isLoading ? (
        <Spinner size='small' />
      ) : (
        <Select
          onChange={setSelectedProducts}
          placeholder={'Select Subscription Products'}
          mode={'multiple'}
          options={filteredProducts.map(val => ({
            label: `${val.product_name} (${currencyFormat(
              val.unit_amount / 100,
            )}/${val.interval})`,
            value: val.price_id,
          }))}
          value={selectedProducts}
        />
      )}
    </div>
  )
}
