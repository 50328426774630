// import { differenceInDays } from 'date-fns'

import { differenceInCalendarDays } from 'date-fns'

import { AuthUser } from '../services/auth'
import { initFilter } from '../services/client'
import { IFilterProjects, IProject } from './../services/projects'
import { compareFilterPagination } from './functions'
import { TLeftSideMenu } from './menus'

export const projectSideMenu: TLeftSideMenu[] = [
  { icon: 'money', name: 'Project Information', id: 'information' },
  { icon: 'location', name: 'Project Address', id: 'address' },
  { icon: 'calendar', name: 'Important Dates', id: 'dates' },
  { icon: 'user', name: 'Contacts', id: 'contacts' },
  { icon: 'ruler', name: 'Equipment & Tooling', id: 'equipment' },
  { icon: 'attachment', name: 'Media Gallery', id: 'media' },
  { icon: 'attachment', name: 'Blueprints', id: 'blueprints' },
]

export const isBlockedProject = (project: IProject) =>
  project.status === 'Blocked'
export const isOverDueDateProject = (project: IProject) =>
  Boolean(project.has_passed_due_date)
export const isDueDateSoonProject = (project: IProject) => {
  if (Boolean(project.has_passed_due_date)) {
    return false
  }
  const diffDueDate = project?.due_date
    ? differenceInCalendarDays(new Date(project?.due_date), new Date())
    : 7
  return diffDueDate <= 3
}

export const isFavoritedProject = (project: IProject) =>
  Boolean(project.is_favorited)

export const isCanAssign = (user: AuthUser | null) =>
  user?.can?.includes('view assigned projects')

export const initSpecialFilterProject: IFilterProjects = {
  filterStatus: null,
  filterRecentUpdated: false,
  filterDueDateSoon: false,
  filterIsFavorited: false,
  filterOverDue: false,
  filterSpecialStatus: null,
}
export const initFilterProject: IFilterProjects = {
  ...initFilter,
  search: '',
  filterAccount: null,
  ...initSpecialFilterProject,
}

export const isHasFilterProject = (filter: IFilterProjects) =>
  !!filter?.filterAccount ||
  !!filter?.filterStatus ||
  !!filter?.filterRecentUpdated ||
  !!filter?.filterDueDateSoon ||
  !!filter?.filterIsFavorited ||
  !!filter?.filterOverDue ||
  !!filter?.filterSpecialStatus ||
  compareFilterPagination(filter, initFilterProject)

export const getIconSystemTypesByName = (name: string) => {
  switch (name.trim()) {
    case 'Access Control':
      return 'access_cp'
    case 'Cabling':
      return 'cable'
    case 'Entry Gate':
      return 'door'
    case 'Fire Alarm':
      return 'fire'
    case 'Gunshot Detection':
      return 'gun'
    case 'Intercom':
      return 'intercom'
    case 'Intrusion Alarm':
      return 'alarm'
    case 'Video Surveillance':
      return 'cctv'
    default:
      return 'block'
  }
}
