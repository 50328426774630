import { AsyncSelect } from '../../../components/Forms/AsyncSelect'
import { Props as SelectProps } from '../../../components/Forms/Select'
import { PAGE_SIZE_DROPDOWN } from '../../../services/client'
import {
  getOpportunities,
  IFilterOpportunities,
  IOpportunity,
} from '../../../services/opportunities'
import { OptionValue } from '../../../utils/form'

interface Props extends Omit<SelectProps, 'options'> {
  selected?: OptionValue | null
  onSelect?: (option: OptionValue | null) => void
  className?: string
  filterOpportunities?: IFilterOpportunities | null
}
export const OpportunitySelect = ({
  selected = null,
  className = '',
  onSelect,
  filterOpportunities = null,
  ...props
}: Props) => {
  const handleGetOpportunities = async ({ search = '', page = 1 } = {}) => {
    try {
      const { data } = await getOpportunities({
        search,
        currentPage: page,
        sortBy: 'name',
        sortDirection: 'asc',
        pageSize: PAGE_SIZE_DROPDOWN,
        ...filterOpportunities,
      })
      return { data: data.data, totalPage: data.meta.last_page }
    } catch {
      return { data: [], totalPage: 1 }
    }
  }

  const mapData = (opportunites: IOpportunity[]) => {
    return opportunites.map(({ id, name }) => ({
      value: id,
      label: name,
    }))
  }
  const handleSelect = (item: any) => {
    const value = !!item
      ? {
          value: item.value,
          label:
            typeof item.label === 'string'
              ? item.label
              : item.label.props.children,
        }
      : null
    onSelect?.(value)
  }
  const triggerValue = filterOpportunities?.filterAccount?.value
  return (
    <AsyncSelect
      placeholder='All Opportunities'
      {...props}
      labelInValue
      allowClear
      value={selected}
      onChange={handleSelect}
      className={className}
      fetcher={handleGetOpportunities}
      options={mapData}
      dropdownClassName='w-full'
      triggerFilter={triggerValue}
    />
  )
}
