import {
  add,
  format as formatDefault,
  isBefore,
  isEqual,
  lastDayOfMonth,
  lastDayOfYear,
  parseISO,
  set,
  setDay,
  startOfMonth,
  startOfYear,
} from 'date-fns'
import { formatInTimeZone } from 'date-fns-tz'

import { leadZero } from './functions'

const currentTz = Intl.DateTimeFormat().resolvedOptions().timeZone

export const MIN_DATE_STR = '1900/01/01'
export const MAX_DATE_STR = '2099/12/31'

const MIN_DAY = 1
const MAX_DAY = 31
const MIN_MONTH = 1
const MAX_MONTH = 12
const MIN_YEAR = 1900
const MAX_YEAR = 2099

export const dateTimeFormat = {
  'MM-dd-yyy_h-mm_a': 'MM-dd-yyyy, h:mm a',
  'MM-dd-yyy_h-mm-ss_a': 'MM-dd-yyyy, h:mm:ss a',
  'MM-dd-yyyy': 'MM-dd-yyyy',
  'dd-MM-yyyy': 'dd-MM-yyyy',
  'MM-dd': 'MM-dd',
  'MMM dd, yyyy': 'MMM dd, yyyy',
  'yyyy-MM-dd': 'yyyy-MM-dd',
  'MM/dd/yyyy': 'MM/dd/yyyy',
  'dd/MM/yyyy': 'dd/MM/yyyy',
  'hh:mm a': 'hh:mm a',
  'h:mm a': 'h:mm a',
  'HH:mm': 'HH:mm',
  'HH:mm:ss': 'HH:mm:ss',
  'HH:mm:ss a': 'HH:mm:ss a',
}

export const daysFull = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
]

export const daysShort = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat']

export const formatInTz = (
  date: string | Date,
  f = `${dateTimeFormat['MM/dd/yyyy']} ${dateTimeFormat['HH:mm:ss']}`,
  tz = currentTz,
) => {
  return formatInTimeZone(date, tz, f, { timeZone: tz })
}

export const formatDate = (
  date: string | Date,
  dateFormat = dateTimeFormat['MM-dd-yyyy'],
) => {
  if (typeof date === 'string') {
    return formatInTz(parseISO(date.toString()), dateFormat)
  }
  return formatInTz(date, dateFormat)
}
// export const shortDay = (day: string) => {
//   return day.substring(0, 1).toUpperCase() + day.substring(1, 3).toLowerCase()
// }

export const formatDateNoTz = (
  date: string | Date,
  dateFormat = dateTimeFormat['MM-dd-yyyy'],
) => {
  let stringDate = date.toString()
  if (!stringDate.includes(':')) {
    stringDate = stringDate.replace(/-/g, '/')
  }
  return formatDefault(new Date(stringDate), dateFormat)
}

export const formatDateApi = (date: string | Date) => {
  let stringDate = date.toString()
  if (!stringDate.includes(':')) {
    stringDate = stringDate.replace(/-/g, '/')
  }
  return formatDefault(new Date(stringDate), 'MM/dd/yyyy')
}

export const formatDateApiTz = (date: string | Date) =>
  new Date(date).toISOString()

export const formatDateTime = (date: string | Date) => {
  return formatDate(date, dateTimeFormat['MM-dd-yyy_h-mm_a'])
}

export const formatDateSecond = (date: string | Date) => {
  return formatDate(date, dateTimeFormat['MM-dd-yyy_h-mm-ss_a'])
}

export const getFirstLastDayOfWeek = (
  weeks = 0,
  d = new Date(),
): [Date, Date] => {
  const date = add(d, { weeks })

  return [setDay(date, 0), setDay(date, 6)]
}

export const getFirstLastDayOfMonth = (
  months = 0,
  d = new Date(),
): [Date, Date] => {
  const date = add(d, { months })
  return [startOfMonth(date), lastDayOfMonth(date)]
}

export const getFirstLastDayOfYear = (
  years = 0,
  d = new Date(),
): [Date, Date] => {
  const date = add(d, { years })
  return [startOfYear(date), lastDayOfYear(date)]
}

export const datePickerPresets = [
  { label: 'Today', value: new Date() },
  { label: 'This Week', value: getFirstLastDayOfWeek() },
  { label: 'This Month', value: getFirstLastDayOfMonth() },
  { label: 'This Year', value: getFirstLastDayOfYear() },
  { label: 'Last Week', value: getFirstLastDayOfWeek(-1) },
  { label: 'Last Month', value: getFirstLastDayOfMonth(-1) },
  { label: 'Last Year', value: getFirstLastDayOfYear(-1) },
]

export const dateRangePickerPresets = [
  { label: 'All Time', value: [null, null] },
  { label: 'This Week', value: getFirstLastDayOfWeek() },
  { label: 'This Month', value: getFirstLastDayOfMonth() },
  { label: 'This Year', value: getFirstLastDayOfYear() },
  { label: 'Last Week', value: getFirstLastDayOfWeek(-1) },
  { label: 'Last Month', value: getFirstLastDayOfMonth(-1) },
  { label: 'Last Year', value: getFirstLastDayOfYear(-1) },
]

export const secondsToHms = (secs: number, showSecond = true) => {
  const hours = Math.floor(secs / 3600)
  const minutes = Math.floor((secs - hours * 3600) / 60)
  const seconds = Math.floor(secs - hours * 3600 - minutes * 60)
  let result = `${leadZero(hours)}:${leadZero(minutes)}`
  result = showSecond ? `${result}:${leadZero(seconds)}` : result
  return result
}

export const secondsToMs = (secs: number) => {
  const hours = Math.floor(secs / 3600)
  const minutes = Math.floor((secs - hours * 3600) / 60)
  const seconds = Math.floor(secs - hours * 3600 - minutes * 60)
  return `${leadZero(minutes)}:${leadZero(seconds)}`
}

export const hmsToSeconds = (hms: string) => {
  const [h, m, s] = hms.split(':')
  const hSecs = +h * 60 * 60
  const mSecs = +m * 60
  return hSecs + mSecs + +s
}

const checkStrDateInput = (str: string, max: number, min = 0) => {
  if (str.charAt(0) !== '0' || str == '00') {
    let num = parseInt(str)
    if (isNaN(num)) {
      num = max
    } else if (num <= min) {
      num = min
    } else if (num > max) {
      num = max
    }
    str =
      num > parseInt(max.toString().charAt(0)) && num.toString().length == 1
        ? '0' + num
        : num.toString()
  }
  return str
}

export const formatStrDateInput = (str: string) => {
  const strSplit = str.split('-')
  const newValues = strSplit.map((v, index) => {
    const leadValue = index < strSplit.length - 1 ? leadZero(v) : v
    return leadValue.replace(/\D/g, '')
  })
  if (newValues[0]) {
    newValues[0] = checkStrDateInput(newValues[0], 12)
  }
  if (newValues[1]) {
    newValues[1] = checkStrDateInput(newValues[1], 31)
  }
  if (newValues[2]) {
    newValues[2] = checkStrDateInput(
      parseInt(newValues[2]).toString(),
      MAX_YEAR,
    )
  }
  const output = newValues.map((v, i) => {
    return v.length == 2 && i < 2 ? v + '-' : v
  })
  return output.join('')
}

export const formatStrDateBlur = (str: string) => {
  const values = str.split('-').map(v => {
    return v.replace(/\D/g, '')
  })
  let output = ''
  if (values.length === 3) {
    output = values.join('-')
    const month = parseInt(values[0])
    const day = parseInt(values[1])
    const year = parseInt(values[2])
    if (
      isNaN(day) ||
      day < MIN_DAY ||
      day > MAX_DAY ||
      isNaN(month) ||
      month < MIN_MONTH ||
      month > MAX_MONTH ||
      isNaN(year) ||
      year < MIN_YEAR
    ) {
      output = ''
    } else {
      output = formatDefault(
        new Date(year, month - 1, day),
        dateTimeFormat['MM-dd-yyyy'],
      )
    }
  }
  return output
}

export const setStartDateTime = (date: Date) =>
  set(date, {
    hours: 0,
    minutes: 0,
    seconds: 0,
  })

export const setEndDateTime = (date: Date) =>
  set(date, {
    hours: 23,
    minutes: 59,
    seconds: 59,
  })

export const getListDaysByRange = (
  start: Date | string,
  end: Date | string,
) => {
  const startDate = new Date(start)
  const endDate = new Date(end)
  let flagDate = startDate
  const ranges: Date[] = []
  while (isBefore(flagDate, endDate) || isEqual(flagDate, endDate)) {
    ranges.push(flagDate), (flagDate = add(flagDate, { days: 1 }))
  }
  return ranges
}
