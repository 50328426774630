import { lazy } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import {
  PATHNAME,
  routesAccountDetail,
  routesContactDetail,
  routesContractDetail,
  routesExpenseDetail,
  routesInvoiceDetail,
  routesMonitoringDetail,
  routesOpportunityDetail,
  routesPermitDetail,
  routesPreventativeDetail,
  routesProductDetail,
  routesProjectDetail,
  routesQuoteDetail,
  routesServiceDetail,
  routesSetting,
  routesUserDetail,
} from './configs/routes'
import BlankLayout from './layouts/BlankLayout'
import CustomerLayout from './layouts/CustomerLayout'
import LoginLayout from './layouts/LoginLayout'
import MainLayout from './layouts/MainLayout'
import PublicPageLayout from './layouts/PublicLayout'
import Billing from './pages/CustomerPortal/Billing'
import { DevIcons } from './pages/Devs/Icons'
import Playground from './pages/Devs/Playground'
import { StyleGuide } from './pages/Devs/StyleGuide'
import { PageUncategorized } from './pages/Uncategorized'
import { RoleBasedRedirect } from './shared/Auth/RoleBasedRedirect'

const AuthProviderCallback = lazy(() => import('./pages/Auth/Callback'))
const PageAccounts = lazy(() => import('./pages/Accounts'))
const PageAccountCustomers = lazy(() => import('./pages/Accounts/Customers'))
const PageAccountLeads = lazy(() => import('./pages/Accounts/Leads'))
const PageAccountDetail = lazy(() => import('./pages/Accounts/Detail'))
const PageAccountVendors = lazy(() => import('./pages/Accounts/Vendors'))
const PageAssets = lazy(() => import('./pages/Assets'))
const PageAssetDetail = lazy(() => import('./pages/Assets/Detail'))
const PagePermits = lazy(() => import('./pages/Permits'))
const PagePermitDetail = lazy(() => import('./pages/Permits/Detail'))
const PageAssetGroups = lazy(() => import('./pages/AssetGroups'))
const PageCalendars = lazy(() => import('./pages/Calendars'))
const PageContacts = lazy(() => import('./pages/Contacts'))
const PageContactDetail = lazy(() => import('./pages/Contacts/Detail'))
const PageContracts = lazy(() => import('./pages/Contracts'))
const PageContractDetail = lazy(() => import('./pages/Contracts/Detail'))
const PageCustomerQuotes = lazy(() => import('./pages/CustomerPortal/Quotes'))
const PageCustomerQuoteDetail = lazy(
  () => import('./pages/CustomerPortal/Quotes/Detail'),
)
const PageCustomerInvoices = lazy(
  () => import('./pages/CustomerPortal/Invoices'),
)
const PageCustomerInvoiceDetail = lazy(
  () => import('./pages/CustomerPortal/Invoices/Detail'),
)
const PageCustomerPlansets = lazy(
  () => import('./pages/CustomerPortal/Plansets'),
)
const PageCustomerPlansetDetail = lazy(
  () => import('./pages/CustomerPortal/Plansets/Detail'),
)
const PageCustomerServices = lazy(
  () => import('./pages/CustomerPortal/Services'),
)

const PageDashboard = lazy(() => import('./pages/Dashboard'))
const PageExpenses = lazy(() => import('./pages/Expenses'))
const PageExpenseDetail = lazy(() => import('./pages/Expenses/Detail'))
const PageFileManagement = lazy(() => import('./pages/Files'))
const PageFileInternal = lazy(() => import('./pages/Files/Internal'))
const PageInvoices = lazy(() => import('./pages/Invoices'))
const PageInvoicesArchived = lazy(() => import('./pages/Invoices/Archived'))
const PageInvoiceDetail = lazy(() => import('./pages/Invoices/Detail'))
const PageMonitorings = lazy(() => import('./pages/Monitoring'))
const PageMonitoringDetail = lazy(() => import('./pages/Monitoring/Detail'))
const PageMovementTracking = lazy(() => import('./pages/MovementTracking'))
const PageOpportunities = lazy(() => import('./pages/Opportunities'))
const PageOpportunityDetail = lazy(() => import('./pages/Opportunities/Detail'))
const PageStripeConnect = lazy(() => import('./pages/Callback/Stripe'))
const PageQuickbookConnect = lazy(() => import('./pages/Callback/Quickbooks'))
const PagePlanSet = lazy(() => import('./pages/PlanSet'))
const PagePlanSetDetail = lazy(() => import('./pages/PlanSetDetail'))
const PagePreventativeDetail = lazy(
  () => import('./pages/Contracts/Preventative/Detail'),
)
const PagePreviewInvoice = lazy(() => import('./pages/Preview/Invoice'))
const PagePreviewQuote = lazy(() => import('./pages/Preview/Quote'))
const PagePrivacy = lazy(() => import('./pages/PrivacyAndTos'))
const PageProducts = lazy(() => import('./pages/Products'))
const PageProductDetail = lazy(() => import('./pages/Products/Detail'))
const PageProjects = lazy(() => import('./pages/Projects'))
const PageProjectDetail = lazy(() => import('./pages/Projects/Detail'))
const PageProjectsCalendar = lazy(() => import('./pages/ProjectsCalendar'))
const PageQuotes = lazy(() => import('./pages/Quotes'))
const PageQuoteArchived = lazy(() => import('./pages/Quotes/Archived'))
const PageQuoteDetail = lazy(() => import('./pages/Quotes/Detail'))
const PageServices = lazy(() => import('./pages/Services'))
const PageServicesTicketForm = lazy(
  () => import('./pages/Services/ServicesTasksForm'),
)
const PageServiceDetail = lazy(() => import('./pages/Services/Detail'))
const PageSettings = lazy(() => import('./pages/Settings'))
const PageUserRoles = lazy(() => import('./pages/User/Roles'))
const PageUserManagement = lazy(() => import('./pages/User/Users'))
const PageUserDetail = lazy(() => import('./pages/User/Users/Detail'))
const FleetTracking = lazy(() => import('./pages/FleetTracking'))
const PageRegister = lazy(() => import('./pages/Auth/Register'))
const PageLogin = lazy(() => import('./pages/Auth/Login'))
const PageResetPassword = lazy(() => import('./pages/Auth/ResetPassword'))
const PageInvitation = lazy(() => import('./pages/Auth/Invitation'))
const PageForgotPassword = lazy(() => import('./pages/Auth/ForgotPassword'))
const PageNotFound = lazy(() => import('./pages/404'))
const PageTimesheet = lazy(() => import('./pages/Timesheets'))
const PageNoPermission = lazy(
  () => import('./pages/Auth/Permission/NoPermission'),
)

const App = () => {
  return (
    <Routes>
      <Route element={<RoleBasedRedirect />}>
        <Route path='/' element={<MainLayout />}>
          <Route index element={<PageDashboard />} />
          <Route path={PATHNAME.assets} element={<PageAssets />} />
          <Route
            path={PATHNAME['asset-groups']}
            element={<PageAssetGroups />}
          />
          <Route path={PATHNAME.contracts} element={<PageContracts />} />
          <Route path={PATHNAME.expenses} element={<PageExpenses />} />
          <Route path={PATHNAME.files} element={<PageFileManagement />} />
          <Route
            path={`${PATHNAME.files}/internal`}
            element={<PageFileInternal />}
          />
          <Route
            path={`${PATHNAME.files}/:id`}
            element={<PageFileManagement />}
          />
          <Route
            path={PATHNAME['movement-tracking']}
            element={<PageMovementTracking />}
          />
          <Route
            path={PATHNAME['fleet-tracking']}
            element={<FleetTracking />}
          />
          <Route
            path={PATHNAME.opportunities}
            element={<PageOpportunities />}
          />
          <Route
            path={PATHNAME.preventatives}
            element={<Navigate to={PATHNAME.contracts} />}
          />
          <Route path={PATHNAME.products} element={<PageProducts />} />
          <Route path={PATHNAME.projects} element={<PageProjects />} />
          <Route
            path={PATHNAME['project-calendar']}
            element={<PageProjectsCalendar />}
          />
          <Route path={PATHNAME.services} element={<PageServices />} />
          <Route
            path={`${PATHNAME.servicesTicketForm}/:id`}
            element={<PageServicesTicketForm />}
          />
          <Route path={PATHNAME.roles} element={<PageUserRoles />} />
          <Route path={PATHNAME.users} element={<PageUserManagement />} />
          <Route path={PATHNAME.contacts} element={<PageContacts />} />
          <Route path={PATHNAME.monitorings} element={<PageMonitorings />} />
          <Route
            path='stripeConnect/callback/'
            element={<PageStripeConnect />}
          />
          <Route
            path='quickbooks/callback/'
            element={<PageQuickbookConnect />}
          />
          <Route path={PATHNAME['plan-set']} element={<PagePlanSet />} />
          <Route path={PATHNAME.dashboard} element={<PageDashboard />} />
          <Route
            path={PATHNAME.uncategorized}
            element={<PageUncategorized />}
          />
          <Route
            path={`${PATHNAME['plan-set']}/:id`}
            element={<PagePlanSetDetail permission='view-edit' />}
          />
          <Route path={PATHNAME.timesheets} element={<PageTimesheet />} />
          {/* DEV ONLY, WILL NOT INCLUDE IN PRODUCTION */}
          {process.env.NODE_ENV === 'development' && (
            <Route path='/dev'>
              <Route
                index
                element={<Navigate to='/dev/style-guide' replace />}
              />
              <Route path='style-guide' element={<StyleGuide />} />
              <Route path='icons' element={<DevIcons />} />
              <Route path='playground' element={<Playground />} />
            </Route>
          )}
        </Route>
        <Route path={`/${PATHNAME.accounts}/`} element={<MainLayout />}>
          <Route index element={<PageAccounts />} />
          <Route path='vendors' element={<PageAccountVendors />} />
          <Route path='customers' element={<PageAccountCustomers />} />
          <Route path='leads' element={<PageAccountLeads />} />
        </Route>
        <Route path={`/${PATHNAME.accounts}/:id`} element={<MainLayout />}>
          <Route index element={<PageAccountDetail />} />
          {routesAccountDetail.map(r => (
            <Route path={r} element={<PageAccountDetail />} key={r} />
          ))}
        </Route>
        <Route path={`/${PATHNAME.contacts}/:id`} element={<MainLayout />}>
          <Route index element={<PageContactDetail />} />
          {routesContactDetail.map(r => (
            <Route path={r} element={<PageContactDetail />} key={r} />
          ))}
        </Route>
        <Route path={`/${PATHNAME.projects}/:id`} element={<MainLayout />}>
          <Route index element={<PageProjectDetail />} />
          {routesProjectDetail.map(r => (
            <Route path={r} element={<PageProjectDetail />} key={r} />
          ))}
        </Route>
        <Route path={`/${PATHNAME.quotes}/`} element={<MainLayout />}>
          <Route index element={<PageQuotes />} />
          <Route path='archived' element={<PageQuoteArchived />} />
        </Route>
        <Route path={`/${PATHNAME.quotes}/:id`} element={<MainLayout />}>
          <Route index element={<PageQuoteDetail />} />
          {routesQuoteDetail.map(r => (
            <Route path={r} element={<PageQuoteDetail />} key={r} />
          ))}
        </Route>
        <Route path={`/${PATHNAME.permits}/`} element={<MainLayout />}>
          <Route index element={<PagePermits />} />
        </Route>
        <Route path={`/${PATHNAME.permits}/:permitId`} element={<MainLayout />}>
          <Route index element={<PagePermitDetail />} />
          {routesPermitDetail.map(r => (
            <Route path={r} element={<PagePermitDetail />} key={r} />
          ))}
        </Route>
        <Route path={`/${PATHNAME.invoices}/`} element={<MainLayout />}>
          <Route index element={<PageInvoices />} />
          <Route path='archived' element={<PageInvoicesArchived />} />
        </Route>
        <Route path={`/${PATHNAME.invoices}/:id`} element={<MainLayout />}>
          <Route index element={<PageInvoiceDetail />} />
          {routesInvoiceDetail.map(r => (
            <Route path={r} element={<PageInvoiceDetail />} key={r} />
          ))}
        </Route>
        <Route path={`/${PATHNAME.assets}/:id`} element={<MainLayout />}>
          <Route index element={<PageAssetDetail />} />
          {routesQuoteDetail.map(r => (
            <Route path={r} element={<PageAssetDetail />} key={r} />
          ))}
        </Route>
        <Route path={`/${PATHNAME.services}/:id`} element={<MainLayout />}>
          <Route index element={<PageServiceDetail />} />
          {routesServiceDetail.map(r => (
            <Route path={r} element={<PageServiceDetail />} key={r} />
          ))}
        </Route>
        <Route path={`/${PATHNAME.expenses}/:id`} element={<MainLayout />}>
          <Route index element={<PageExpenseDetail />} />
          {routesExpenseDetail.map(r => (
            <Route path={r} element={<PageExpenseDetail />} key={r} />
          ))}
        </Route>
        <Route path={`/${PATHNAME.opportunities}/:id`} element={<MainLayout />}>
          <Route index element={<PageOpportunityDetail />} />
          {routesOpportunityDetail.map(r => (
            <Route path={r} element={<PageOpportunityDetail />} key={r} />
          ))}
        </Route>
        <Route path={`/${PATHNAME.users}/:id`} element={<MainLayout />}>
          <Route index element={<PageUserDetail />} />
          {routesUserDetail.map(r => (
            <Route path={r} element={<PageUserDetail />} key={r} />
          ))}
        </Route>
        <Route path={`/${PATHNAME.products}/:id`} element={<MainLayout />}>
          <Route index element={<PageProductDetail />} />
          {routesProductDetail.map(r => (
            <Route path={r} element={<PageProductDetail />} key={r} />
          ))}
        </Route>
        <Route path={`/${PATHNAME.contracts}/:id`} element={<MainLayout />}>
          <Route index element={<PageContractDetail />} />
          {routesContractDetail.map(r => (
            <Route path={r} element={<PageContractDetail />} key={r} />
          ))}
        </Route>
        <Route path={`/${PATHNAME.preventatives}/:id`} element={<MainLayout />}>
          <Route index element={<PagePreventativeDetail />} />
          {routesPreventativeDetail.map(r => (
            <Route path={r} element={<PagePreventativeDetail />} key={r} />
          ))}
        </Route>
        <Route
          path={`/${PATHNAME.monitorings}/:monitoringId`}
          element={<MainLayout />}
        >
          <Route index element={<PageMonitoringDetail />} />
          {routesMonitoringDetail.map(r => (
            <Route path={r} element={<PageMonitoringDetail />} key={r} />
          ))}
        </Route>
        <Route path={`/${PATHNAME.calendars}/`} element={<MainLayout />}>
          <Route index element={<Navigate to='/' replace />} />
          <Route
            path={`/${PATHNAME.calendars}/:id`}
            element={<PageCalendars />}
          />
        </Route>
        <Route path={`/${PATHNAME.customer}/`} element={<CustomerLayout />}>
          <Route index element={<Navigate to='quotes' replace />} />
          <Route path={PATHNAME.quotes} element={<PageCustomerQuotes />} />
          <Route
            path={`${PATHNAME.quotes}/:id`}
            element={<PageCustomerQuoteDetail />}
          />
          <Route path={PATHNAME.invoices} element={<PageCustomerInvoices />} />
          <Route
            path={`${PATHNAME.invoices}/:id`}
            element={<PageCustomerInvoiceDetail />}
          />
          <Route
            path={PATHNAME['plan-set']}
            element={<PageCustomerPlansets />}
          />
          <Route
            path={`${PATHNAME['plan-set']}/:id`}
            element={<PageCustomerPlansetDetail />}
          />
          <Route path={PATHNAME.billing} element={<Billing />} />
          <Route path={PATHNAME.services} element={<PageCustomerServices />} />
        </Route>
      </Route>
      <Route path={`/${PATHNAME.settings}/`} element={<MainLayout />}>
        <Route index element={<Navigate to={routesSetting[0]} replace />} />
        {routesSetting.map(r => (
          <Route path={r} element={<PageSettings />} key={r} />
        ))}
      </Route>
      <Route path='/' element={<PublicPageLayout />}>
        <Route path='preview/quotes/:id' element={<PagePreviewQuote />} />
        <Route path='preview/invoices/:id' element={<PagePreviewInvoice />} />
        <Route path='privacy' element={<PagePrivacy />} />
        <Route path='tos' element={<PagePrivacy />} />
        <Route
          path={PATHNAME['no-permission']}
          element={<PageNoPermission />}
        />
      </Route>
      <Route path='/' element={<LoginLayout />}>
        <Route path='login' element={<PageLogin />} />
        <Route path='register' element={<PageRegister />} />
        <Route path='password-reset/:token' element={<PageResetPassword />} />
        <Route path='invitations/:id/:token' element={<PageInvitation />} />
      </Route>
      <Route path='/' element={<BlankLayout />}>
        <Route
          path='auth/social/:provider/callback'
          element={<AuthProviderCallback />}
        />
        <Route path='forgot-password' element={<PageForgotPassword />} />
        <Route path='404' element={<PageNotFound />} />
      </Route>
      <Route path='*' element={<Navigate to='/404' replace />} />
    </Routes>
  )
}

export default App
