import { OptionValue } from '../utils/form'
import { generateQueryOfEndPoint } from '../utils/functions'
import { IAccount } from './accounts'
import client, { IFilter, PAGE_SIZE, PAGE_SIZE_RECENT } from './client'
import { IContract } from './contracts'
import { IFile } from './files'
import { IMonitoring } from './monitoring'
import { INote } from './notes'
import { IProduct } from './products'
import { IProject } from './projects'
import { TResource } from './resource'
import { IService } from './services'
import { ITag } from './tags'
import { IUser } from './users'

export interface IApplicationPort {
  app_port: string
}

export type TMetaAsset = Record<
  string,
  string[] | OptionValue | string | number | boolean
>

export interface IAssetResource {
  resource_id: number | null
  resource_type: string | null
}

export interface IAssetResourceOption extends IAssetResource {
  resource_name: string | null
}

export type TKeyBelongTo =
  | 'all'
  | 'projects'
  | 'monitoring_services'
  | 'service_tickets'
  | 'maintenance_contracts'

export type TAssetBelong = {
  [key in TKeyBelongTo]: TResource[]
}

export interface IAsset {
  id: number
  name: string
  type: string
  type_id: number
  avatar?: File | null
  avatar_url?: string | null
  avatar_preview_url?: string | null
  install_date?: Date | string | null
  warranty_until?: Date | string | null
  licensed_until?: Date | string | null
  created_at: Date | string
  accounts?: IAccount[] | null
  projects?: IProject[] | null
  products?: IProduct[] | null
  serviceTickets?: IService[] | null
  maintenanceContracts?: IContract[] | null
  notes?: INote[] | null
  files?: IFile[] | null
  manufacturer?: IAccount | null
  model_number?: string | null
  sku?: string | null
  meta?: TMetaAsset | null
  location?: string | null
  monitoringServices?: IMonitoring[] | null
  manufacturer_id?: number | null
  original_product_id?: number | null
  recipient?: IUser | null
  recipient_id?: number | null
  tags?: ITag[] | null
  assetGroup?: IAssetGroup | null
  inbound_email_address?: string | null
}

export interface IAssetGroup {
  id: number
  name: string
  created_at: Date | string
  assets_count: number
  assets?: IAsset[] | null
  tags?: ITag[] | null
}

export interface IFilterAsset extends IFilter {
  filterAccount?: OptionValue | null
  filterProject?: OptionValue | null
  filterType?: OptionValue | null
  filterProduct?: OptionValue | null
  filterLocalInventory?: boolean
  fiterManufacturer?: OptionValue | null
  filterAssetGroup?: OptionValue | null
  filterUser?: OptionValue | null
}

export interface IFilterAssetGroup extends IFilter {
  filterAsset?: OptionValue | null
}

export interface IAssetPayload
  extends Omit<
    IAsset,
    | 'id'
    | 'accounts'
    | 'projects'
    | 'serviceTickets'
    | 'maintenanceContracts'
    | 'created_at'
    | 'name'
    | 'type'
    | 'type_id'
    | 'manufacturer'
    | 'tags'
    | 'assetGroups'
  > {
  name?: string | null
  type?: string | null
  type_id?: number | null
  resources?: IAssetResource[] | null
  tags?: number[] | null
  existing_avatar_id?: number | null
  qty?: number | null
  asset_group_id?: number | null
}

export interface IPropsFormResource {
  resourceType: OptionValue | null
  resourceName: OptionValue | null
}

export const getAssets = async ({
  search = '',
  sortBy = 'id',
  sortDirection = 'desc',
  pageSize = PAGE_SIZE,
  currentPage = 1,
  filterAccount = null,
  filterProject = null,
  filterType = null,
  filterProduct = null,
  filterLocalInventory = false,
  fiterManufacturer = null,
  filterAssetGroup = null,
  filterUser = null,
}: IFilterAsset) => {
  const sort = `${sortDirection === 'desc' ? '-' : ''}${sortBy}`
  const endPoint = generateQueryOfEndPoint('/api/assets', {
    sort,
    'filter[search]': search,
    limit: pageSize,
    page: currentPage,
    'filter[account_id]': filterAccount?.value || null,
    'filter[project_id]': filterProject?.value || null,
    'filter[product_id]': filterProduct?.value || null,
    'filter[recipient_id]': filterUser?.value || null,
    'filter[type_id]': filterType?.value || null,
    'filter[asset_group_id]': filterAssetGroup?.value || null,
    'filter[is_local_inventory]': filterLocalInventory ? 1 : null,
    'filter[manufacturer_id]': fiterManufacturer?.value || null,
  })
  return client.get(
    `${endPoint}include=accounts,projects,serviceTickets,products,maintenanceContracts,monitoringServices,manufacturer,recipient,assetGroup`,
  )
}

export const getAssetByIds = (ids: number[], params = '') => {
  return client.get(
    `/api/assets?filter[id]=${ids.join(',')}${
      params !== '' ? `&${params}` : ''
    }`,
  )
}

export const getRecentAssets = async (props: IFilterAsset) => {
  return getAssets({
    ...props,
    sortBy: 'recent',
    sortDirection: 'asc',
    pageSize: PAGE_SIZE_RECENT,
    currentPage: 1,
  })
}

export const addAssetThumbnail = async (
  id: number,
  { avatar }: { avatar: File | null },
) => {
  if (!avatar) {
    return
  }
  const formData = new FormData()
  formData.append('avatar', avatar)
  formData.append('_method', 'PUT')
  return client.post(`/api/assets/${id}`, formData)
}
export const createAsset = async (payload: IAssetPayload) => {
  return client.post('/api/assets', payload)
}
export const editAsset = (id: number, payload: IAssetPayload) => {
  return client.put(`/api/assets/${id}`, payload)
}
export const deleteAsset = async (id: number) => {
  return client.delete(`/api/assets/${id}`)
}
export const detailAsset = async (id: number) => {
  return client.get<{ data: IAsset }>(
    `api/assets/${id}?include=files,accounts,projects,notes,serviceTickets,products,maintenanceContracts,monitoringServices,manufacturer,recipient,tags,assetGroup,inboundEmailAddress`,
  )
}
export const checkoutAsset = async ({
  asset_ids,
  resources,
  recipient_id,
}: {
  asset_ids: number[]
  resources: IAssetResource[]
  recipient_id?: number | null
}) => {
  return client.post('/api/assets/checkout', {
    asset_ids,
    resources,
    recipient_id,
  })
}

export const exportAssets = async ({
  ids,
  fields,
  format,
}: {
  ids: string
  fields: string
  format: string
}) => {
  return client.get(
    `/api/assets/export?filter[id]=${ids}&format=${format}&fields=${fields}`,
    {
      responseType: 'blob',
    },
  )
}

export const searchResources = ({ search }: { search: string }) => {
  return client.get<{ data: TAssetBelong }>(
    `/api/search?resource=project,maintenance_contract,service_ticket,monitoring_service&s=${search}`,
  )
}

export const copyAsset = async ({
  assetId,
  qty,
}: {
  assetId: number
  qty: number
}) => {
  return client.post(`/api/assets/${assetId}/copy`, { qty })
}

export const getAssetGroups = ({
  search = '',
  sortBy = 'id',
  sortDirection = 'desc',
  pageSize = PAGE_SIZE,
  currentPage = 1,
  filterAsset = null,
}: IFilterAssetGroup) => {
  const sort = `${sortDirection === 'desc' ? '-' : ''}${sortBy}`
  const endPoint = generateQueryOfEndPoint('/api/assetGroups', {
    sort,
    'filter[search]': search,
    'filter[asset_id]': filterAsset?.value || null,
    limit: pageSize,
    page: currentPage,
  })
  return client.get(`${endPoint}include=assetsCount,assets`)
}

export const createAssetGroup = async (payload: { name: string }) => {
  return client.post('/api/assetGroups', payload)
}

export const editAssetGroup = async (id: number, payload: IAssetPayload) => {
  return client.put(`/api/assetGroups/${id}`, payload)
}

export const deleteAssetGroup = async (id: number) => {
  return client.delete(`/api/assetGroups/${id}`)
}
