import { Link } from 'react-router-dom'
import shallow from 'zustand/shallow'

import Logo from '../../assets/images/logo_system.png'
import { ProfileMenu } from '../../layouts/MainLayout/ProfileMenu'
import useStore, { Store } from '../../store'

const mapState = (state: Store) => ({
  auth: state.auth.currentUser,
})
export const HeaderPublicPage = () => {
  const { auth } = useStore(mapState, shallow)

  const handleStoreRedirect = () => {
    localStorage.setItem('APP/LAST_PATH', window.location.href)
  }

  const renderMenuNotAuth = () => (
    <div className='flex gap-6 items-center text-body'>
      <div className='text-white-400'>Have an account?</div>
      <Link
        to='/login'
        className='text-primary-dark-900 font-medium'
        onClick={handleStoreRedirect}
      >
        Login
      </Link>
      <Link
        to='/register'
        className='text-primary-dark-900 font-medium'
        onClick={handleStoreRedirect}
      >
        Sign Up
      </Link>
    </div>
  )

  const renderMenuUser = () => <ProfileMenu user={auth} />

  return (
    <div className='bg-white shadow-card h-[3.25rem] fixed top-0 left-0 right-0 z-[2999] px-4'>
      <div className='flex h-full items-center justify-between'>
        <Link to='/' className='block w-7'>
          <img src={Logo} className='cursor-pointer w-full' alt='logo' />
        </Link>
        <div>{auth ? renderMenuUser() : renderMenuNotAuth()}</div>
      </div>
    </div>
  )
}
