import cx from 'classnames'

interface Props {
  data: string[]
  currentSelected: string
  onSelect?: (selected: string) => void
  isActiveTab?: (tab: string) => boolean
}

export const TabsViewType = ({
  data,
  currentSelected,
  onSelect,
  isActiveTab,
}: Props) => {
  return (
    <div className='bg-button-ternary rounded-[2rem] flex items-center text-body'>
      {data.map(item => (
        <div
          key={item}
          onClick={() => onSelect?.(item)}
          className={cx(
            'px-4 py-2.5 border border-transparent rounded-[2rem] capitalize',
            Boolean(isActiveTab?.(item)) || currentSelected === item
              ? 'bg-white fonnt-medium border-black-900 pointer-events-none'
              : 'cursor-pointer',
          )}
        >
          {item}
        </div>
      ))}
    </div>
  )
}
