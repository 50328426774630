import client from './client'

const baseUrl = '/api/billing/customerAccounts'

export interface Price {
  price_id: string
  currency: string
  product_name: string
  product_type: string
  billing_scheme: string
  interval: string
  interval_count: number
  unit_amount: number
}

export const initSubscription = {
  id: -1,
  account_id: -1,
  name: '',
  items: [],
  stripe_id: '',
  stripe_status: '',
  stripe_price: '',
  quantity: 0,
  is_on_grace_period: false,
  is_auto_billing_enabled: true,
  trial_ends_at: null,
  ends_at: null,
  created_at: new Date().toISOString(),
  updated_at: new Date().toISOString(),
  next_payment_due_at: new Date().toISOString(),
  next_payment_amount: 0,
}

export interface Subscription {
  id: number
  account_id: number
  name: string
  items: SubscriptionItem[]
  stripe_id: string
  stripe_status: string
  stripe_price: string
  quantity: number
  is_on_grace_period: boolean
  is_auto_billing_enabled: boolean
  trial_ends_at: null
  ends_at: null
  created_at: string
  updated_at: string
  next_payment_due_at: string
  next_payment_amount: number
}

export interface SubscriptionItem {
  id: number
  account_subscription_id: number
  stripe_id: string
  stripe_product: string
  stripe_price: string
  quantity: number
  created_at: string
  updated_at: string
}
export interface IBillingInvoice {
  amount: number
  date: string
  invoice_hosted_url: string
  is_paid: boolean
}
export const getCustomerBilling = async (id: number) => {
  return client.get(`${baseUrl}/${id}`)
}

export const createCustomerBilling = async (id: number) => {
  return client.post(`${baseUrl}/${id}`)
}

export const createPaymentsIntent = async (id: number) => {
  return client.get(`${baseUrl}/${id}/createPaymentsIntent`)
}

export const updatePaymentMethod = async (
  id: number,
  paymentMethodId: string,
) => {
  return client.post(`${baseUrl}/${id}/updatePaymentMethod`, {
    payment_method_id: paymentMethodId,
  })
}

export const getBillingProducts = async () => {
  return client.get<Price[]>('/api/billing/prices')
}

export const addSubscription = async (id: number, priceIds: string[]) => {
  return client.post(`${baseUrl}/${id}/subscriptions`, { price_ids: priceIds })
}

interface UpdateSubscriptionPayload {
  resume: number
  price_ids: string[]
  is_auto_billing_enabled?: boolean
}

export const updateSubscription = async (
  accountId: number,
  subscriptionId: number,
  payload: UpdateSubscriptionPayload,
) => {
  return client.put(`${baseUrl}/29/subscriptions/${subscriptionId}`, payload)
}

export const cancelSubscription = async (
  accountId: number,
  subscriptionId: number,
) => {
  return client.delete(
    `${baseUrl}/${accountId}/subscriptions/${subscriptionId}`,
  )
}
