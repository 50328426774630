import client from './client'

export interface IConfigPusher {
  key: string
  cluster: string
  wsHost: string
  wsPort: number
}
export interface IConfig {
  pusher: IConfigPusher
  inbound_email_domain: string
}

export const getConfigs = () => {
  return client.get<{ data: IConfig }>('api/config')
}
