import { Select, SelectProps } from '../../../components'
import { Spinner } from '../../../components/Spinner'
import { PATHNAME } from '../../../configs/routes'
import { useAsync } from '../../../hooks'
import { editExpense, IExpense } from '../../../services/expenses'
import { EXPENSE_STATUS } from '../../../utils/expense'
import toast from '../../../utils/toast'

interface Props extends Omit<SelectProps, 'options'> {
  selected?: string | null
  expenseId?: number | null
  onUpdateComplete?: (data: IExpense) => void
}
export const ExpenseStatusSelect = ({
  selected = '',
  expenseId = null,
  onUpdateComplete,
  ...props
}: Props) => {
  const { isLoading, execute } = useAsync({ showNotifOnError: true })

  const handleChange = async (value: string) => {
    if (expenseId) {
      const response = await execute(editExpense(expenseId, { status: value }))
      onUpdateComplete?.(response.data.data)
      toast.success({
        title: 'Expense updated',
        path: `/${PATHNAME.expenses}/${expenseId}`,
      })
    }
  }

  const customProps = expenseId
    ? { ...props, onChange: (value: string) => handleChange(value) }
    : { ...props }

  return (
    <div className='relative'>
      <Select {...customProps} value={selected}>
        {EXPENSE_STATUS.map(s => (
          <Select.Option value={s} key={s}>
            {s}
          </Select.Option>
        ))}
      </Select>
      {isLoading && (
        <>
          <div className='absolute inset-0 bg-white opacity-60 w-full h-full z-10' />
          <div className='absolute top-1/2 right-2 z-20 -translate-y-1/2'>
            <Spinner size='xsmall' />
          </div>
        </>
      )}
    </div>
  )
}
