import { Select, SelectProps } from '../../../components'
import { Spinner } from '../../../components/Spinner'
import { PATHNAME } from '../../../configs/routes'
import { useAsync } from '../../../hooks'
import { usePermission } from '../../../hooks/usePermission'
import {
  editService,
  IService,
  IServicePayload,
} from '../../../services/services'
import { PERMISSIONS } from '../../../utils/permission'
import { SERVICE_IS_COMPLETED, SERVICE_STATUS } from '../../../utils/services'
import toast from '../../../utils/toast'

interface Props extends Omit<SelectProps, 'options'> {
  selected?: string | null
  serviceId?: number | null
  onUpdateComplete?: (data: IService) => void
}
export const ServiceStatusSelect = ({
  selected = 'Created',
  serviceId = null,
  onUpdateComplete,
  ...props
}: Props) => {
  const { isLoading, execute } = useAsync({ showNotifOnError: true })
  const { canUpdate } = usePermission({ name: PERMISSIONS.SERVICE })
  const handleChange = async (value: string) => {
    if (serviceId) {
      let data: IServicePayload = {
        status: value,
      }
      if (value === SERVICE_IS_COMPLETED) {
        data = {
          ...data,
          datetime_ended: new Date(),
        }
      }
      const response = await execute(editService(serviceId, data))
      onUpdateComplete?.(response.data.data)
      toast.success({
        title: 'Service ticket updated',
        path: `/${PATHNAME.services}/${serviceId}`,
      })
    }
  }

  const customProps = serviceId
    ? { ...props, onChange: (value: string) => handleChange(value) }
    : { ...props }

  if (!canUpdate && serviceId) {
    return <div className='relative'>{selected}</div>
  }
  return (
    <div className='relative'>
      <Select {...customProps} value={selected}>
        {SERVICE_STATUS.map(s => (
          <Select.Option value={s} key={s}>
            {s}
          </Select.Option>
        ))}
      </Select>
      {isLoading && (
        <>
          <div className='absolute inset-0 bg-white opacity-60 w-full h-full z-10' />
          <div className='absolute top-1/2 right-2 z-20 -translate-y-1/2'>
            <Spinner size='xsmall' />
          </div>
        </>
      )}
    </div>
  )
}
