import { AnimatePresence } from 'framer-motion'
import { useState } from 'react'
import { Link } from 'react-router-dom'

import { Overlay, Popover, Portal, Avatar } from '../../components'
import { Spinner } from '../../components/Spinner'
import { PATHNAME } from '../../configs/routes'
import { useAsync } from '../../hooks'
import { AuthUser, logout } from '../../services/auth'
import { ModalMyProfile } from '../../shared/Modals/Auth/Profile'
import useStore from '../../store'
import cx from 'classnames'

export const ProfileMenu = ({ user }: { user: AuthUser | null }) => {
  const [isOpenProfile, setOpenProfile] = useState(false)
  const { execute, isLoading, isSuccess } = useAsync({ showNotifOnError: true })
  const handleLogout = () => {
    execute(logout())
      .then(() => (window.location.href = window.location.origin + '/login'))
      .catch(console.error)
  }
  const roles = useStore(state => state.auth.currentUser?.roles)?.map(
    role => role.name,
  )
  const renderPopoverProfile = (onClose: () => void) => (
    <Popover.Content
      onClose={onClose}
      className='w-[12rem] !p-0 shadow-dropdown'
    >
      <div className='p-2'>
        {roles && !roles.includes('customer') && (
          <>
            <div
              onClick={() => {
                setOpenProfile(true)
                onClose()
              }}
              className='px-4 py-2 flex gap-2 items-center hover:bg-white-900 rounded-lg cursor-pointer'
            >
              <span className='font-icon-user' />
              <span>My Profile</span>
            </div>
            <Link
              to={`/${PATHNAME.settings}`}
              onClick={onClose}
              className='px-4 py-2 flex gap-2 items-center hover:bg-white-900 rounded-lg cursor-pointer'
            >
              <span className='font-icon-edit' />
              <span>Settings</span>
            </Link>
          </>
        )}
        <div
          className={cx(
            'cursor-pointer',
            roles &&
              !roles.includes('customer') &&
              'border-t border-t-sepearation-400 pt-2 mt-2',
          )}
        >
          <div
            className='text-red-900  hover:bg-white-900 rounded-lg px-4 py-2 font-medium'
            onClick={handleLogout}
          >
            Logout
          </div>
        </div>
      </div>
    </Popover.Content>
  )

  return (
    <>
      <Popover content={renderPopoverProfile}>
        <Avatar
          size='small'
          hashId={`user-${user?.id}`}
          className='bg-separation-200 cursor-pointer'
          src={user?.avatar_preview_url}
        />
      </Popover>
      {(isLoading || isSuccess) && (
        <Portal>
          <Overlay zIndex={9999} lockScroll className='bg-white/60'>
            <Spinner.Fullscreen />
          </Overlay>
        </Portal>
      )}
      <AnimatePresence initial={false}>
        {isOpenProfile && user?.id && (
          <ModalMyProfile
            onClose={() => setOpenProfile(false)}
            userId={user.id}
          />
        )}
      </AnimatePresence>
    </>
  )
}
