import orderBy from 'lodash/orderBy'
import { useEffect, useState } from 'react'
import shallow from 'zustand/shallow'

import { Select, SelectProps } from '../../../components'
import { Spinner } from '../../../components/Spinner'
import { PATHNAME } from '../../../configs/routes'
import { useAsync } from '../../../hooks'
import { usePermission } from '../../../hooks/usePermission'
import { editQuote, IQuote } from '../../../services/quotes'
import useStore, { Store } from '../../../store'
import { OptionValue } from '../../../utils/form'
import { PERMISSIONS } from '../../../utils/permission'
import {
  QUOTE_STATUS_PAID,
  QUOTE_STATUS_PARTIAL_PAID,
} from '../../../utils/quotes'
import toast from '../../../utils/toast'

interface Props extends Omit<SelectProps, 'options'> {
  selected?: OptionValue | null
  quoteId?: number | null
  onSelect?: (v: OptionValue) => void
  onUpdateComplete?: (data: IQuote) => void
}

const mapState = (state: Store) => ({
  quote_statuses: state.settings?.quote?.quote_statuses,
})

export const QuoteStatusSelect = ({
  selected = null,
  quoteId = null,
  onSelect,
  onUpdateComplete,
  ...props
}: Props) => {
  const { quote_statuses } = useStore(mapState, shallow)
  const { isLoading, execute } = useAsync({ showNotifOnError: true })
  const { canUpdate } = usePermission({ name: PERMISSIONS.QUOTE })
  const [currentSelected, setSelected] = useState<OptionValue | null>(selected)
  const handleChange = async (value: OptionValue) => {
    setSelected(value)
    if (quoteId) {
      // handle change with select on form
      const response = await execute(
        editQuote(quoteId, {
          status: value.label,
          status_id: value.value,
        }),
      )
      onUpdateComplete?.(response.data.data)
      toast.success({
        title: 'Quote updated',
        path: `/${PATHNAME.quotes}/${quoteId}`,
      })
    }
  }

  const handleSelect = (value: OptionValue) => {
    //handle change with select only
    setSelected(value)
    onSelect?.(value)
  }

  if (!quote_statuses) {
    return null
  }

  if (!canUpdate && quoteId) {
    return <div className='relative'>{currentSelected?.label}</div>
  }

  const customProps = quoteId
    ? { ...props, onChange: (value: OptionValue) => handleChange(value) }
    : { ...props }

  useEffect(() => {
    if (
      selected !== currentSelected ||
      selected?.label !== currentSelected?.label ||
      selected?.value !== currentSelected?.value
    ) {
      setSelected(selected)
    }
  }, [selected])
  return (
    <div className='relative'>
      <Select
        {...customProps}
        value={currentSelected}
        labelInValue
        onSelect={
          onSelect ? (value: OptionValue) => handleSelect(value) : undefined
        }
      >
        {orderBy(quote_statuses, ['label'], ['asc']).map(item => (
          <Select.Option
            key={item.id}
            disabled={
              item.label === QUOTE_STATUS_PAID ||
              item.label === QUOTE_STATUS_PARTIAL_PAID
            }
          >
            {item.label}
          </Select.Option>
        ))}
      </Select>
      {isLoading && (
        <>
          <div className='absolute inset-0 bg-white opacity-60 w-full h-full z-10' />
          <div className='absolute top-1/2 right-2 z-20 -translate-y-1/2'>
            <Spinner size='xsmall' />
          </div>
        </>
      )}
    </div>
  )
}
