import { Collapse } from '../../components'
import { Props as PropsCollapse } from '../../components/Collapse'

interface Props extends Omit<PropsCollapse, 'panel'> {
  label: string | React.ReactNode
}
export const ResourceCollapse = ({ label, children, ...props }: Props) => {
  return (
    <Collapse
      active={true}
      {...props}
      panel={<span className='font-medium'>{label}</span>}
      panelClassName='bg-button-ternary gap-4'
      contentClassName='!p-0 text-body'
    >
      <div className='pt-3'>{children}</div>
    </Collapse>
  )
}
