import cx from 'classnames'
import { FC } from 'react'
import { Link, LinkProps } from 'react-router-dom'

import { PATHNAME } from '../../configs/routes'
import {
  RESOURCE_ACCOUNT,
  RESOURCE_ASSET,
  RESOURCE_COMPANY_SETTING,
  RESOURCE_CONTACT,
  RESOURCE_CONTRACT,
  RESOURCE_EXPENSE,
  RESOURCE_INVOICE,
  RESOURCE_MEDIA,
  RESOURCE_MONITORING,
  RESOURCE_NOTE,
  RESOURCE_OPPORTUNITY,
  RESOURCE_PERMIT,
  RESOURCE_PLANSET,
  RESOURCE_PREVENTATIVE,
  RESOURCE_PRODUCT,
  RESOURCE_PROJECT,
  RESOURCE_QUOTE,
  RESOURCE_SERVICE,
  RESOURCE_USER,
} from '../../utils/resources'

export interface Props extends Omit<LinkProps, 'to'> {
  resourceId?: number | null
  resourceType: string
  pathName?: string | null
}

export const resourceLinks: Record<string, string> = {
  [RESOURCE_ACCOUNT]: PATHNAME.accounts,
  [RESOURCE_ASSET]: PATHNAME.assets,
  [RESOURCE_EXPENSE]: PATHNAME.expenses,
  [RESOURCE_OPPORTUNITY]: PATHNAME.opportunities,
  [RESOURCE_PROJECT]: PATHNAME.projects,
  [RESOURCE_QUOTE]: PATHNAME.quotes,
  [RESOURCE_INVOICE]: PATHNAME.invoices,
  [RESOURCE_SERVICE]: PATHNAME.services,
  [RESOURCE_USER]: PATHNAME.users,
  [RESOURCE_PRODUCT]: PATHNAME.products,
  [RESOURCE_CONTRACT]: PATHNAME.contracts,
  [RESOURCE_MONITORING]: PATHNAME.monitorings,
  [RESOURCE_CONTACT]: PATHNAME.contacts,
  [RESOURCE_NOTE]: PATHNAME.notes,
  [RESOURCE_PREVENTATIVE]: PATHNAME.preventatives,
  [RESOURCE_COMPANY_SETTING]: `${PATHNAME.settings}/company`,
  [RESOURCE_PLANSET]: PATHNAME['plan-set'],
  [RESOURCE_MEDIA]: PATHNAME.files,
  [RESOURCE_PERMIT]: PATHNAME.permits,
}

export const ResourceLink: FC<Props> = ({
  resourceId,
  resourceType,
  children,
  className,
  pathName = null,
  ...props
}) => {
  const link = resourceLinks[resourceType]
    ? `/${resourceLinks[resourceType]}${
        resourceId ? `/${resourceId}${pathName || ''}` : ''
      }`
    : '#'
  return (
    <Link
      draggable={false}
      {...props}
      to={link}
      className={cx('leading-normal flex', className)}
    >
      {children}
    </Link>
  )
}
