import { AsyncSelect } from '../../../components/Forms/AsyncSelect'
import { Props as SelectProps } from '../../../components/Forms/Select'
import { getAssets, IAsset, IFilterAsset } from '../../../services/assets'
import { PAGE_SIZE_DROPDOWN } from '../../../services/client'
import { OptionValue } from '../../../utils/form'
import { ResourceThumbnail } from '../../Resources/ResourceThumbnail'

interface Props extends Omit<SelectProps, 'options' | 'onSelect'> {
  selected?: OptionValue | null
  onSelect?: (value: OptionValue | null, valueFull?: IAsset | null) => void
  className?: string
  excludesValues?: number[]
  filter?: IFilterAsset | null
}

export const AssetSelect = ({
  selected = null,
  className = '',
  onSelect,
  excludesValues = [],
  filter = null,
  ...props
}: Props) => {
  const handleGetAssets = async ({ search = '', page = 1 } = {}) => {
    try {
      const { data } = await getAssets({
        search,
        currentPage: page,
        pageSize: PAGE_SIZE_DROPDOWN,
        ...filter,
      })
      return { data: data.data, totalPage: data.meta.last_page }
    } catch {
      return { data: [], totalPage: 1 }
    }
  }

  const handleSelect = (item: any, itemFull: IAsset) => {
    const value = !!item
      ? {
          value: +item.value,
          label: itemFull.name,
        }
      : null
    onSelect?.(value, itemFull)
  }

  return (
    <AsyncSelect
      placeholder='All Assets'
      labelInValue
      allowClear
      dropdownClassName='!w-[25rem]'
      placement='bottomRight'
      {...props}
      value={
        selected?.value
          ? { value: selected.value + '', label: selected.label }
          : null
      }
      onChange={handleSelect}
      className={className}
      fetcher={handleGetAssets}
      animation={undefined}
    >
      {(data: IAsset[]) => {
        return data.map(item =>
          !excludesValues.includes(item.id) ? (
            <AsyncSelect.Option key={item.id}>
              <div className='inline-flex items-center gap-2'>
                <div className='flex-1'>
                  <ResourceThumbnail thumbnail={item.avatar_preview_url} />
                </div>
                <div className='text-body'>
                  <div className='underline text-black-800 mb-1'>
                    {item.name}
                  </div>
                  <div className='text-black-400 inline-flex gap-2 items-center'>
                    <span>{item.id}</span>
                    <span className='w-px h-4 bg-black-400' />
                    <span>{item.type}</span>
                    {!!item.accounts?.length && (
                      <>
                        <span className='w-px h-4 bg-black-400' />
                        <span>{item.accounts[0].full_name}</span>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </AsyncSelect.Option>
          ) : null,
        )
      }}
    </AsyncSelect>
  )
}
