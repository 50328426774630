import cx from 'classnames'
import { FC } from 'react'

interface Props {
  className?: string
  count: number
}

export const Badge: FC<Props> = ({ className, count }) => {
  return (
    <div
      className={cx(
        'inline-block pointer-events-none m-0 px-[0.375rem] py-[0.125rem] box-border bg-red-900 text-white-900 font-semibold text-xs leading-[1.125rem] rounded-[1.75rem] min-w-[1.75rem] text-center',
        className,
      )}
    >
      <span>{count > 99 ? '99+' : count}</span>
    </div>
  )
}
