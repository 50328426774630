import {
  IDetailSettings,
  IMenuSetting,
  IParentDetailSettings,
  IReponseItemSetting,
} from '../services/settings'
import useStore from '../store'
import { filterCategoryHierarchy,findCategoryHierarchyById, flatHierarchyOfCategoryId } from './functions'

export const mapSettingsToForm = (
  listSettings?: IDetailSettings[] | null,
  defautlData?: IReponseItemSetting[] | null,
): IReponseItemSetting[] => {
  if (!listSettings || listSettings.length === 0) {
    return []
  }
  return listSettings.map(item => {
    const detailValue = defautlData?.find(d => d.setting_label_id === item.id)
    return {
      id: detailValue?.id || null,
      created_at: detailValue?.created_at || null,
      user_id: detailValue?.user_id || null,
      setting_label_id: item.id,
      label_name: item.label,
      value: detailValue?.value || '',
    }
  })
}

export const filterValidSettingForm = (
  listSettings: IReponseItemSetting[],
): IReponseItemSetting[] => {
  return listSettings.filter(item => item.value && item.value !== '')
}

export const SETTING_MENU_GENERAL: IMenuSetting[] = [
  {
    path: 'account',
    name: 'Account',
    Icon: <span className='font-icon-card text-black-400 text-[0.75rem]' />,
    tags: 'rates',
    blocks: ['Account Rates'],
  },
  {
    path: 'accounting',
    name: 'Accounting',
    Icon: <span className='font-icon-money text-black-400 text-[1rem]' />,
    blocks: ['Tax Vendors'],
  },
  {
    path: 'company',
    name: 'Company',
    Icon: <span className='font-icon-building text-black-400 text-[1rem]' />,
    tags: 'info,logo,email,mail,address,phone,fax,number,website,name',
    blocks: ['Company Info'],
    keyPermission: 'COMPANY',
  },
  {
    path: 'invoice',
    name: 'Invoice',
    Icon: <span className='font-icon-money text-black-400 text-[1rem]' />,
    blocks: ['Sharing Mode'],
  },
  {
    path: 'project',
    name: 'Project',
    Icon: <span className='font-icon-rocket text-black-400 text-[1rem]' />,
    blocks: ['Project Statuses', 'Project System Types'],
  },
  {
    path: 'quote',
    name: 'Quote',
    Icon: <span className='font-icon-document text-black-400 text-[1rem]' />,
    blocks: [
      'Sharing Mode',
      'Line Item Types',
      'Quote Statuses',
      'Warranty Terms',
      'Conditions',
    ],
  },
]
export const SETTING_MENU_ASSET: IMenuSetting[] = [
  {
    path: 'asset',
    name: 'Asset',
    Icon: <span className='font-icon-device text-black-400 text-[1rem]' />,
    tags: 'asset',
  },
  {
    path: 'product',
    name: 'Product',
    Icon: <span className='font-icon-box text-black-400 text-[1rem]' />,
    blocks: ['Product Categories'],
  },
]
export const SETTING_MENU_OTHER: IMenuSetting[] = [
  {
    path: 'cms',
    name: 'Central Monitoring Stations',
    Icon: <span className='font-icon-view text-black-400 text-[0.75rem]' />,
    tags: 'cms',
  },
  {
    path: 'expense',
    name: 'Expense',
    Icon: <span className='font-icon-moneybag text-[1rem] text-black-400' />,
    blocks: ['Expense Categories'],
  },
  {
    path: 'integrations',
    name: 'Integrations Settings',
    Icon: <span className='font-icon-moneybag text-[1rem] text-black-400' />,
    blocks: ['Integrations Settings'],
    tags: 'integrations,quickbook',
  },
  {
    path: 'notification',
    name: 'Notification',
    Icon: (
      <span className='font-icon-notification text-black-400 text-[1rem]' />
    ),
    tags: 'watch,assigned,payment,event',
    blocks: ['Accept Payment'],
  },
  {
    path: 'payment',
    name: 'Payment',
    Icon: <span className='font-icon-moneybag text-black-400 text-[1rem]' />,
    tags: 'stripe, disconnect, connect',
    blocks: ['Accept Payment'],
  },
  {
    path: 'user',
    name: 'User',
    Icon: <span className='font-icon-user text-[1rem] text-black-400' />,
    blocks: ['User Meta'],
  },
  {
    path: 'templates',
    name: 'Templates',
    Icon: <span className='font-icon-layout text-[1rem] text-black-400' />,
    blocks: ['Template, sale, proposal'],
  },
  {
    path: 'calendars',
    name: 'Calendars',
    Icon: <span className='font-icon-calendar text-[1rem] text-black-400' />,
    blocks: ['Calendars Settings'],
    tags: 'calendar,project,admin',
  },
]

export const SETTING_MENU: IMenuSetting[] = [
  ...SETTING_MENU_GENERAL,
  ...SETTING_MENU_ASSET,
  ...SETTING_MENU_OTHER,
]

export const addDataSetting = (
  list: IDetailSettings[],
  data: IDetailSettings,
  parentId?: number,
) => {
  const result: IDetailSettings[] = []
  if (!parentId) {
    return [...list, data]
  }
  list.forEach(item => {
    if (item.id === parentId) {
      const child =
        !!item.children && item.children.length > 0 ? item.children : []
      result.push({
        ...item,
        children: sortItemSettings([...child, data]),
      })
    } else {
      result.push({
        ...item,
        children: sortItemSettings(
          addDataSetting(item.children || [], data, parentId),
        ),
      })
    }
  })
  return result
}
export const updateDataSetting = (
  list: IDetailSettings[],
  data: Partial<IDetailSettings>,
  id: number,
) => {
  const result: IDetailSettings[] = []
  list.forEach(item => {
    if (item.id === id) {
      result.push({
        ...item,
        ...data,
      })
    } else {
      if (!!item.children && item.children.length > 0) {
        result.push({
          ...item,
          children: updateDataSetting(item.children, data, id),
        })
      } else {
        result.push(item)
      }
    }
  })
  return result
}
export const deleteDataSetting = (list: IDetailSettings[], id: number) => {
  const result: IDetailSettings[] = []
  list.forEach(item => {
    if (item.id !== id) {
      if (!!item.children && item.children.length > 0) {
        result.push({
          ...item,
          children: deleteDataSetting(item.children, id),
        })
      } else {
        result.push(item)
      }
    }
  })
  return result
}

export const sortItemSettings = (list: IDetailSettings[], direction = 'asc') =>
  list.sort((a, b) =>
    direction === 'asc'
      ? a.label.localeCompare(b.label)
      : b.label.localeCompare(a.label),
  )

export const findSettingById = (
  type: string,
  id: number,
): IDetailSettings | null => {
  const { settings } = useStore.getState()
  const list =
    type === 'asset'
      ? settings.asset?.asset_sub_types
      : settings.product?.product_types
  if (list) {
    return findCategoryHierarchyById(list, id)
  }
  return null
}

export const getListParentSettings = (
  listData: IDetailSettings[],
  result: IParentDetailSettings[],
  level = 1,
  path = '',
) => {
  if (level < 5) {
    listData.forEach(item => {
      const newPath = level === 1 ? item.label : path + ' / ' + item.label
      if (item.children && item.children?.length > 0) {
        result.push({
          ...item,
          level,
          fullPathStr: newPath,
        })
        getListParentSettings(item.children, result, level + 1, newPath)
      } else {
        result.push({
          ...item,
          level,
          fullPathStr: newPath,
        })
      }
    })
  }
  return result
}

export const getListSettingHierarchy = (
  type: string,
  id: number,
  label: string,
) => {
  const { settings } = useStore.getState()
  const listData =
    type === 'product'
      ? settings.product?.product_types
      : settings.asset?.asset_sub_types
  const result = listData && listData?.length > 0 ? filterCategoryHierarchy(listData, label, 'label', id) : []
  return flatHierarchyOfCategoryId(result, id, [])
}

export const templateAcceptance = () => {
  const { settings } = useStore.getState()
  return `
  <h3>ACCEPTANCE</h3>
  <p>${settings.company?.name || ''}</p>
  <br />
  <div style="display: flex;">
    <div style="flex:1 1 50%;padding-right:10px">
      <hr style="margin-bottom:0"/>  
      <p style="color: #8E8E93;margin-top:5px">SIGNED</p>
    </div>
    <div style="flex:1 1 50%;padding-left:10px">
      <hr style="margin-bottom:0"/>  
      <p style="color: #8E8E93;margin-top:5px">SIGNED</p>
    </div>    
  </div>
  <p style="margin: 0;"></p>
  <div style="display: flex;">
    <div style="flex:1 1 50%;padding-right:10px">
      <hr style="margin-bottom:0"/>  
      <p style="color: #8E8E93;margin-top:5px">PRINT NAME</p>
    </div>
    <div style="flex:1 1 50%;padding-left:10px">
      <hr style="margin-bottom:0"/>  
      <p style="color: #8E8E93;margin-top:5px">TITLE</p>
    </div>    
  </div>
  <br />
  `
}
