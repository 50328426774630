import { Button } from '../../../../../components'
import { InfoCircleFilled } from '../../../../../icons'
import { getExtFile } from '../../../../../utils/files'
import { downloadFile } from '../../../../../utils/functions'
import { PERMISSIONS, PERMISSIONS_VIEW } from '../../../../../utils/permission'
import { RESOURCE_MEDIA } from '../../../../../utils/resources'
import { ButtonFavorite } from '../../../../ButtonFavorite'
import { ComponentPermisison } from '../../../../Permission/ComponentPermission'
import { useFileDetailCtx } from '../Context'
import { HeaderPopoverMenu } from './PopoverMenu'

interface Props {
  onClose: () => void
}

export const GalleryHeader = ({ onClose }: Props) => {
  const { file, onUpdateFile, dispatch, toggleInfo, toggleNote } =
    useFileDetailCtx()
  const extFile = getExtFile(file.file_name)
  const totalUnReadNote = file?.unread_notes_count || 0
  const totalNote = file?.notes?.length || 0

  const handleToggleSidebar = (type: string) => {
    if (type === 'info') {
      const isOpenNote = Boolean(toggleNote)
      if (isOpenNote) {
        dispatch({ toggleNote: false })
      }
      setTimeout(
        () => {
          dispatch({ toggleInfo: !toggleInfo })
        },
        isOpenNote ? 300 : 0,
      )
    }
    if (type === 'note') {
      const isOpenInfo = Boolean(toggleInfo)
      if (isOpenInfo) {
        dispatch({ toggleInfo: false })
      }
      setTimeout(
        () => {
          dispatch({ toggleNote: !toggleNote })
        },
        isOpenInfo ? 300 : 0,
      )
    }
  }

  return (
    <div className='flex justify-between gap-2 flex-wrap px-4 md:px-6 z-10'>
      <div className='inline-flex gap-3 items-center'>
        <span
          className='font-icon-arrow_left cursor-pointer text-white-900'
          onClick={onClose}
        />
        <span className='text-white-900 text-base'>{file?.name}</span>
        <ButtonFavorite
          currentStatus={file.is_favorited}
          resourceId={file.id}
          resourceType={RESOURCE_MEDIA}
          className='text-white'
          onToggleFavorite={(status: boolean) =>
            onUpdateFile({ is_favorited: status })
          }
        />
      </div>
      <div className='items-center gap-2 inline-flex'>
        <Button
          variant='primary'
          className='bg-[#45484F]'
          innerClassName='font-normal inline-flex gap-2 !px-2'
          onClick={() => downloadFile(file.url, `${file.name}.${extFile}`)}
        >
          <span className='font-icon-download' />
          Download
        </Button>
        <HeaderPopoverMenu />
        <ComponentPermisison name={PERMISSIONS.NOTE} type={PERMISSIONS_VIEW}>
          <Button
            variant='primary'
            className='bg-[#45484F] !rounded-lg relative'
            innerClassName='!px-2'
            onClick={() => handleToggleSidebar('note')}
          >
            {totalUnReadNote > 0 && (
              <span className='w-2.5 h-2.5 bg-red-900 rounded-full absolute top-0 right-0' />
            )}
            <div className='flex gap-2 items-center'>
              <span className='font-icon-chat text-white' />
              {totalNote > 0 && (
                <span className='text-body font-normal'>{totalNote}</span>
              )}
            </div>
          </Button>
        </ComponentPermisison>
        <Button
          variant='primary'
          className='bg-[#45484F] !rounded-lg'
          innerClassName='!px-2'
          onClick={() => handleToggleSidebar('info')}
        >
          <InfoCircleFilled />
        </Button>
        <Button
          variant='primary'
          className='bg-red-800 !rounded-lg'
          innerClassName='!px-2'
          onClick={onClose}
        >
          <span className='font-icon-close' />
        </Button>
      </div>
    </div>
  )
}
