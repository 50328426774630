import { AxiosRequestConfig } from 'axios'

import { OptionValue } from '../utils/form'
import { generateQueryOfEndPoint } from '../utils/functions'
import client, { IFilter, PAGE_SIZE, PAGE_SIZE_RECENT } from './client'
import { INote } from './notes'
import { TResource } from './resource'
import { ITag } from './tags'
import { IUser } from './users'

export type TGroupFileDetail = {
  id: number
  file: {
    uploaded?: IFile
    raw?: File
  }
}
export type TGroupFiles = Record<'medias' | 'files', TGroupFileDetail[]>

export interface IFile {
  collection_name: string
  created_at: Date | string
  custom_properties: string[]
  file_name: string
  id: number
  is_favorited: boolean
  is_protected: boolean
  mime_type: string
  resource_id: number
  resource_type: string
  resource: TResource
  name: string
  preview_url: string
  size: number
  url: string
  tags?: ITag[] | null
  user?: IUser | null
  accessors?: IUser[] | null
  unread_notes_count: number
  notes?: INote[] | null
  note?: string | null
  folder_id?: number | null
}

export interface IFilePayload {
  name?: string | null
  resource_id?: number | null
  resource_type?: string | null
  file?: File
  is_protected?: boolean
  tags?: number[]
  collection_name?: string
  existing_media_ids?: number[] | null
  accessors?: number[] | null
  note?: string | null
  folder_id?: number | null
  resource_folder_id?: number | null
}

export interface IFilterFile extends IFilter {
  filterFileFormat?: string | null
  filterAccount?: OptionValue | null
  filterResourceId?: number | null
  filterResourceType?: string | null
  filterFileRelate?: string | null // all : relate resource
  filterGlobal?: boolean | null // 1/0
  filterFavorited?: boolean | null
  filterCollection?: string | null
  filterAllFileByAccountId?: number | null
  filterFolderId?: number | null
  filterResourceFolderId?: number | null
}

export const getFiles = async ({
  search = '',
  sortBy = 'id',
  sortDirection = 'desc',
  pageSize = PAGE_SIZE,
  currentPage = 1,
  filterResourceId = null,
  filterResourceType = null,
  filterFileRelate = null,
  filterGlobal = false,
  filterFileFormat = null,
  filterFavorited = null,
  filterCollection = null,
  filterAllFileByAccountId = null,
  filterFolderId = null,
  filterResourceFolderId = null,
}: IFilterFile) => {
  const isFilterAllSalePipeline = Boolean(
    filterFileRelate && filterFileRelate === 'all',
  )
  const isFilterAllAccountFile = Boolean(filterAllFileByAccountId)

  const sort = `${sortDirection === 'desc' ? '-' : ''}${sortBy}`
  const endPoint = generateQueryOfEndPoint('/api/files', {
    sort,
    'filter[search]': search,
    limit: pageSize,
    page: currentPage,
    'filter[global]': filterGlobal ? 1 : 0,
    'filter[extension]': filterFileFormat || null,
    'filter[collection_name]': filterCollection || null,
    'filter[is_favorited]': filterFavorited || null,
    'filter[resource_type]':
      isFilterAllSalePipeline || isFilterAllAccountFile
        ? null
        : filterResourceType || null,
    'filter[resource_id]':
      isFilterAllSalePipeline || isFilterAllAccountFile
        ? null
        : filterResourceId || null,
    'filter[sales_pipeline_for]':
      isFilterAllSalePipeline &&
      filterResourceId &&
      filterResourceType &&
      !isFilterAllAccountFile
        ? `${filterResourceType},${filterResourceId}`
        : null,
    'filter[account_id]': filterAllFileByAccountId,
    'filter[folder_id]': filterFolderId,
    'filter[resource_folder_id]': filterResourceFolderId,
  })
  return client.get(
    `${endPoint}include=user,tags,resource,accessors,favorites,unreadNotesCount`,
  )
}

export const getFileCollection = () => client.get('api/files/collections')

export const getRecentFiles = async (props: IFilterFile) => {
  return getFiles({
    ...props,
    sortBy: 'recent',
    sortDirection: 'asc',
    pageSize: PAGE_SIZE_RECENT,
    currentPage: 1,
  })
}

export const createFile = async (
  props: IFilePayload,
  config?: AxiosRequestConfig<unknown>,
) => {
  if (props.existing_media_ids) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { file, ...newProps } = props
    return client.post('/api/files', newProps)
  }
  if (!props.file) {
    return
  }
  const {
    resource_id,
    resource_type,
    file,
    collection_name = '',
    folder_id,
    resource_folder_id,
    tags,
  } = props
  const fileName = props?.name || file.name
  const formData = new FormData()
  resource_id && formData.append('resource_id', resource_id + '')
  resource_type && formData.append('resource_type', resource_type)
  folder_id && formData.append('folder_id', folder_id + '')
  resource_folder_id &&
    formData.append('resource_folder_id', resource_folder_id + '')
  tags && formData.append('tags', JSON.stringify(tags))
  formData.append('file', file, fileName)
  collection_name !== '' && formData.append('collection_name', collection_name)
  return client.post('/api/files', formData, config)
}

export const editFile = (fileId: number, payload: Partial<IFilePayload>) => {
  return client.put<{ data: IFile }>(`/api/files/${fileId}`, payload)
}
export const deleteFile = async (fileId: number) => {
  return client.delete(`/api/files/${fileId}`)
}

export const detailFile = async (fileId: number) => {
  return client.get<{ data: IFile }>(
    `api/files/${fileId}?include=user,tags,resource,favorites,accessors,notes`,
  )
}

/* -------------- FOLDER --------------*/

export interface IFolder {
  id: number
  name: string
  created_at: Date | string
  parent_folder_id?: number | null
  children: IFolder[]
}

export interface IFilterFolder {
  filterResourceId?: number | null
  filterResourceType?: string | null
}

export interface IFolderPayload {
  name: string
  parent_folder_id?: number | null
  resource_id?: number | null
  resource_type?: string | null
}
export const getFolders = async (payload?: IFilterFolder | null) => {
  const endPoint = generateQueryOfEndPoint('api/folders', {
    'filter[resource_type]': payload?.filterResourceType,
    'filter[resource_id]': payload?.filterResourceId,
  })
  return client.get(endPoint)
}

export const createFolder = async (payload: IFolderPayload) => {
  return client.post('api/folders', payload)
}

export const editFolder = async (id: number, payload: IFolderPayload) => {
  return client.put(`api/folders/${id}`, payload)
}

export const deleteFolder = async (id: number) => {
  return client.delete(`api/folders/${id}`)
}
