import { getExpenseResourceTypeByValue } from '../utils/expense'
import { OptionValue } from '../utils/form'
import { generateQueryOfEndPoint } from '../utils/functions'
import { IAccount } from './accounts'
import client, { IFilter, PAGE_SIZE, PAGE_SIZE_RECENT } from './client'
import { IFile } from './files'
import { INote } from './notes'
import { TResource } from './resource'
import { ITag } from './tags'

export interface IExpense {
  id: number
  name?: string | null
  account_id?: number | null
  account?: IAccount | null
  vendor_id?: number | null
  vendor?: IAccount | null
  resource_id?: number | null
  resource_type?: string | null
  resource?: TResource | null
  invoice_number: number | null
  description?: string | null
  amount: number | null
  status: string
  due_date?: Date | string | null
  created_at: Date | string
  expense_category_id?: number | null
  expense_category?: string | null
  notes?: INote[] | null
  files?: IFile[] | null
  tags?: ITag[] | null
  inbound_email_address?: string | null
}

export interface IExpensePayload
  extends Omit<IExpense, 'id' | 'created_at' | 'resource' | 'tags'> {
  tags?: number[] | null
}

export interface IFilterExpense extends IFilter {
  filterAccount?: OptionValue | null
  filterVendor?: OptionValue | null
  filterResource?: OptionValue | null // Project , Maintenance Contract, Opportunity, Service
  filterResourceType?: OptionValue | null
}

export const getExpenses = async ({
  search = '',
  sortBy = 'id',
  sortDirection = 'desc',
  pageSize = PAGE_SIZE,
  currentPage = 1,
  filterAccount = null,
  filterResource = null,
  filterResourceType = null,
  filterVendor = null,
}: IFilterExpense) => {
  const sort = `${sortDirection === 'desc' ? '-' : ''}${sortBy}`
  const resourceType = filterResourceType?.value
    ? getExpenseResourceTypeByValue(filterResourceType.value)
    : null
  const endPoint = generateQueryOfEndPoint('/api/expenses', {
    sort,
    'filter[search]': search,
    limit: pageSize,
    page: currentPage,
    'filter[account_id]': filterAccount?.value || null,
    'filter[vendor_id]': filterVendor?.value || null,
    'filter[resource]':
      filterResource?.value && resourceType
        ? `${resourceType},${filterResource.value}`
        : null,
  })
  return client.get(`${endPoint}include=account,resource`)
}

export const getRecentExpenses = async (props: IFilterExpense) => {
  return getExpenses({
    ...props,
    sortBy: 'recent',
    sortDirection: 'asc',
    pageSize: PAGE_SIZE_RECENT,
    currentPage: 1,
  })
}

export const createExpense = async (payload: IExpensePayload) => {
  return client.post('/api/expenses', payload)
}

export const editExpense = (
  expenseId: number,
  payload: Partial<IExpensePayload>,
) => {
  return client.put<{ data: IExpense }>(`/api/expenses/${expenseId}`, payload)
}
export const deleteExpense = async (expenseId: number) => {
  return client.delete(`/api/expenses/${expenseId}`)
}

export const detailExpense = async (expenseId: number) => {
  return client.get<{ data: IExpense }>(
    `api/expenses/${expenseId}?include=notes,account,resource,vendor,tags,inboundEmailAddress`,
  )
}
