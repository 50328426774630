import { generateQueryOfEndPoint } from '../utils/functions'
import client, { IFilter, PAGE_SIZE } from './client'
import { IFile } from './files'
import { IUser } from './users'

export interface IMetaNote {
  pinned?: boolean
}
export interface INote {
  resource_id: number
  resource_type: string
  body: string
  created_at: Date | string
  id: number
  updated_at: Date | string
  read_at: Date | string
  user_id: number
  user: IUser
  is_system: boolean
  meta?: IMetaNote[] | null
  files?: IFile[] | null
}

export interface INotePayload {
  resource_id: number
  resource_type: string
  body: string
  meta?: IMetaNote[]
}
export interface IFilterNote extends IFilter {
  resource?: string | null
  mark_as_read?: number | null
  account_id?: number | null
}
export const getNotes = async ({
  search = '',
  sortBy = 'created_at',
  sortDirection = 'asc',
  pageSize = PAGE_SIZE,
  currentPage = 1,
  resource = null,
  mark_as_read = null,
  account_id = null,
}: IFilterNote) => {
  const sort = `${sortDirection === 'desc' ? '-' : ''}${sortBy}`
  const endPoint = generateQueryOfEndPoint('/api/notes', {
    sort,
    'filter[search]': search,
    'filter[resource]': !account_id ? resource : null,
    'filter[account_id]': account_id,
    limit: pageSize,
    page: currentPage,
    mark_as_read,
  })
  return client.get(`${endPoint}include=files`)
}

export const detailNote = async (noteId: number) => {
  return client.get<{ data: INote }>(`api/notes/${noteId}`)
}

export const createNote = async (payload: INotePayload) => {
  return client.post('/api/notes', payload)
}

export const editNote = (noteId: number, payload: { body: string; meta?: IMetaNote[] }) => {
  return client.put(`/api/notes/${noteId}`, payload)
}
export const deleteNote = async (noteId: number) => {
  return client.delete(`/api/notes/${noteId}`)
}
