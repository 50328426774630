import cx from 'classnames'
import { ChangeEvent, KeyboardEventHandler, memo, useEffect, useState } from 'react'

import { Image, Input } from '../../../components'
import { FILE_FORMAT_ICON, getFileType } from '../../../utils/files'
import { updateOrAddNewIndex } from '../../../utils/functions'
import { useNotesCtx } from '../Context'

export const ComposerAttachments = () => {
  const { listAttachments, listNameAttachments, dispatch, loading } = useNotesCtx()

  const renderItem = (file: File) => {
    const url = URL.createObjectURL(file)
    const fileType = getFileType(file.type)
    switch (fileType) {
      case 'compressed':
      case 'pdf':
      case 'audio':
      case 'video': {
        return (
          <div className='relative w-full h-20 bg-separation-200 rounded-lg flex items-center justify-center border border-separation-400'>
            <span className='text-[1.5rem]'>{FILE_FORMAT_ICON[fileType]}</span>
          </div>
        )
      }
      default: {
        return (
          <Image src={url} className='w-full h-20 rounded-lg object-cover' />
        )
      }
    }
  }
  const handleRemoveFile = (index: number) => {
    if (loading) {
      return
    }
    const newAttachments = listAttachments.filter((_, idx) => idx !== index)
    const newListName = listNameAttachments.filter(n => n.id !== index)
    dispatch({
      listAttachments: newAttachments,
      listNameAttachments: newListName
    })
  }

  const handleUpdateName = (name: string, index: number) => {
    const newListName = updateOrAddNewIndex(listNameAttachments, index, {name})
    console.log('newListName', newListName)
    dispatch({
      listNameAttachments: newListName
    })
  }
  if (listAttachments.length === 0) {
    return null
  }
  return (
    <div className='grid grid-cols-[repeat(auto-fill,minmax(10rem,1fr))] gap-4 mt-2'>
      {listAttachments.map((item, index) => {
        const currentName = listNameAttachments.find(n => n.id === index)
        return (
          <div className='relative' key={index}>
            {renderItem(item)}
            <div className='mt-1'>
              <AttachmentName name={currentName?.name || item.name} onUpdateName={(value) => handleUpdateName(value, index)}/> 
            </div>
            <div
              className={cx(
                'absolute w-5 h-5 rounded-full bg-white shadow-card flex items-center justify-center cursor-pointer -top-1.5 -right-1.5',
                loading && 'cursor-default pointer-events-none',
              )}
              onClick={() => handleRemoveFile(index)}
            >
              {loading ? (
                <span className='font-icon-loading text-[0.75rem] animate-spin-slow' />
              ) : (
                <span className='font-icon-close text-[0.5rem]' />
              )}
            </div>            
          </div>
        )
      })}
    </div>
  )
}

const AttachmentName = memo(({name, onUpdateName}: {name: string; onUpdateName: (name: string) => void}) => {
  const [isEdit, setEdit] = useState(false)
  const [fileName, setFileName] = useState(name)

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFileName(e.target.value)
  }
  const handleBlur = () => {
    setEdit(false)
    setFileName(name)
  }

  const handleSave = () => {
    setEdit(false)
    if(fileName !== name){
      onUpdateName(fileName)
    }
  }

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = e => {    
    e.key === 'Escape' && handleBlur()
    e.key === 'Enter' && !e.shiftKey && handleSave()
  }

  useEffect(() => {
    setFileName(name)
  }, [name])
  if(isEdit){
    return (
      <Input
        value={fileName}
        onBlur={handleBlur}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        autoFocus
      />
    )
  }
  return (
    <div className='line-clamp-1' onDoubleClick={() => setEdit(true)}>{fileName}</div>
  )
})