import { differenceInCalendarDays } from 'date-fns'

import { initFilter } from '../services/client'
import { IFilterInvoices } from '../services/invoices'
import { IInvoice } from './../services/invoices'
import { compareFilterPagination } from './functions'
import { TLeftSideMenu } from './menus'

export const INVOICE_STATUS_CREATED = 'Created'
export const INVOICE_STATUS_SENT = 'Sent'
export const INVOICE_STATUS_DISCARDED = 'Discarded'
export const INVOICE_STATUS_PAID = 'Paid'
export const INVOICE_STATUS_PARTIAL_PAID = 'Partially Paid'
export const INVOICE_STATUS_PAST_DUE = 'Past Due'
export const INVOICE_STATUS_WARNING = 'Warning'

export const INVOICE_STATUS = [
  INVOICE_STATUS_CREATED,
  INVOICE_STATUS_DISCARDED,
  INVOICE_STATUS_PAID,
  INVOICE_STATUS_PARTIAL_PAID,
  INVOICE_STATUS_PAST_DUE,
  INVOICE_STATUS_SENT,
]

export const INVOICE_STATUS_NEED_CONFIRM = [
  INVOICE_STATUS_PAID,
  INVOICE_STATUS_PARTIAL_PAID,
]

export const initSpecialFilterInvoice: IFilterInvoices = {
  filterWarning: false,
  filterRecentUpdated: false,
  filterSpecialStatus: null,
}

export const initFilterInvoice: IFilterInvoices = {
  ...initFilter,
  search: '',
  filterAccount: null,
  filterProject: null,
  filterStatus: null,
  filterStatusDirection: null,
  filterSpecialStatus: null,
  ...initSpecialFilterInvoice,
}

export const isHasFilterInvoice = (filter: IFilterInvoices) =>
  !!filter?.filterAccount ||
  !!filter?.filterStatus ||
  compareFilterPagination(filter, initFilterInvoice) 

export const invoiceSideMenu: TLeftSideMenu[] = [
  { icon: 'info', name: 'Invoice detail', id: 'detail' },
  { icon: 'attachment', name: 'Purchase Order', id: 'po' },
  { icon: 'money', name: 'Line Items', id: 'line-items' },
  { icon: 'user', name: 'Contacts', id: 'contact' },
  { icon: 'link', name: 'Address Information', id: 'localtion' },
  { icon: 'location', name: 'Site Address', id: 'address' },
]

export const isWarningInvoice = (invoice: IInvoice) => {
  if (invoice.status === INVOICE_STATUS_PAID) {
    return false
  }
  return invoice.has_passed_due_date
  // const invoiceDate = invoice?.invoice_date
  // if (!invoiceDate) {
  //   return true
  // }
  // const netTermNumberDate = invoice?.net_terms || 0
  // const diffWarningDate = differenceInDays(
  //   new Date(),
  //   add(new Date(invoiceDate), { days: netTermNumberDate }),
  // )
  // return diffWarningDate > 1
}

export const isPaidInvoice = (status: string) => status === INVOICE_STATUS_PAID

export const isRejectedInvoice = (status: string) =>
  status === INVOICE_STATUS_DISCARDED

export const isNewUpdateInvoice = (invoice: IInvoice) => {
  const updateDate = false //invoice?.updated_at
  if (!updateDate) {
    return true
  }
  const diffNewUpdateDate = differenceInCalendarDays(
    new Date(),
    new Date(updateDate),
  )
  return diffNewUpdateDate <= 1
}
