import cx from 'classnames'

import { Modal, ModalProps } from '../../../components'
import { IFilter } from '../../../services/client'
import { searchResources } from '../../../services/resource'
import { isMobile as fnCheckMobile } from '../../../utils/functions'
import { SearchOnMobile } from './SearchMobile'
import { SearchOnDesktop } from './SearchOnDesktop'

interface Props extends Omit<ModalProps, 'children'> {
  onSuccess?: () => void
  onClose: () => void
}

export const ModalGlobalSearch = ({ onClose, onSuccess, ...props }: Props) => {
  const fetcher = async ({ search }: IFilter) => {
    try {
      const { data } = await searchResources({
        search: search || '',
      })
      return { data }
    } catch {
      return { data: null }
    }
  }

  const isMobile = fnCheckMobile()
  return (
    <Modal
      {...props}
      className={cx(
        'w-full bg-transparent',
        isMobile ? 'max-w-full p-0 h-full' : 'max-w-[55rem]',
      )}
      wrapperClassName={cx(
        '!items-start',
        isMobile ? 'p-0 !bg-white' : ' py-24',
      )}
      onClose={onClose}
    >
      <div id='btn-close-search' onClick={onClose} />
      <div id='container-modal'>
        {isMobile ? (
          <SearchOnMobile fetcher={fetcher} onClose={onClose} />
        ) : (
          <SearchOnDesktop fetcher={fetcher} />
        )}
      </div>
    </Modal>
  )
}
