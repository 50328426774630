import Background1 from '../assets/images/assetBackground/AssetTypeBG_1.svg'
import Background2 from '../assets/images/assetBackground/AssetTypeBG_2.svg'
import Background3 from '../assets/images/assetBackground/AssetTypeBG_3.svg'
import Background4 from '../assets/images/assetBackground/AssetTypeBG_4.svg'
import Background5 from '../assets/images/assetBackground/AssetTypeBG_5.svg'
import Background6 from '../assets/images/assetBackground/AssetTypeBG_6.svg'
import Background7 from '../assets/images/assetBackground/AssetTypeBG_7.svg'
import Background8 from '../assets/images/assetBackground/AssetTypeBG_8.svg'

const ASSET_TYPE_BACKGROUND = [
  { id: 1, url: Background1, rangeBg: '#FF474752', rangeStroke: '#FA0101' },
  { id: 2, url: Background2, rangeBg: '#DEA20652', rangeStroke: '#FF9500' },
  { id: 3, url: Background3, rangeBg: '#4BA96252', rangeStroke: '#009325' },
  { id: 4, url: Background4, rangeBg: '#20ABAB52', rangeStroke: '#20ABAB' },
  { id: 5, url: Background5, rangeBg: '#38B0E852', rangeStroke: '#32ADE6' },
  { id: 6, url: Background6, rangeBg: '#0054D152', rangeStroke: '#0054D1' },
  { id: 7, url: Background7, rangeBg: '#825FFF52', rangeStroke: '#632CFF' },
  { id: 8, url: Background8, rangeBg: '#FF46B852', rangeStroke: '#FF00C7' },
]

export default ASSET_TYPE_BACKGROUND
