import { has } from 'lodash'

import { createTag, ITag } from '../services/tags'
import { OptionValue, OptionValueTag } from './form'

export const TAG_BLUEPRINT = 'blueprint'
export const TAG_SALEPROPOSAL = 'sales-proposal'

export const mapAndCreateTags = async (
  tags?: (OptionValueTag | OptionValue)[] | null,
): Promise<number[]> => {
  if (!tags || tags.length === 0) {
    return []
  }
  const currentTags = tags || []
  const filterNewTags: any[] = []
  const listTagIds: number[] = []
  currentTags.forEach(item => {
    if (has(item, ['key'])) {
      const tagItem = item as OptionValueTag
      if (tagItem?.key) {
        listTagIds.push(+tagItem?.key)
      } else {
        filterNewTags.push(item?.value)
      }
    } else {
      filterNewTags.push(item?.value)
    }
  })
  for (let i = 0; i < filterNewTags.length; i++) {
    const resultAdd = await createTag({ name: filterNewTags[i] + '' || '' })
    if (resultAdd?.data?.data?.id) {
      listTagIds.push(resultAdd.data.data.id)
    }
  }
  return listTagIds
}

export const mapFullTagsToOptionValue = (tags?: ITag[] | null) =>
  tags?.map(item => ({
    value: item.name,
    label: item.name,
    key: item.id + '',
  })) || null
