import { initFilter } from '../services/client'
import { IDetailSettings, IMetaField } from '../services/settings'
import { IFilterProducts, IProduct } from './../services/products'
import { ASSET_TYPE_DEFAULT_UNIT } from './assets'
import { compareFilterPagination } from './functions'
import { TLeftSideMenu } from './menus'

export const productSideMenu: TLeftSideMenu[] = [
  { icon: 'info', name: 'Product Information', id: 'information' },
  { icon: 'image', name: 'Product Images', id: 'images' },
  { icon: 'spec', name: 'Cut Sheet', id: 'cut-sheet' },
  { icon: 'document', name: 'Manual', id: 'manual' },
  { icon: 'attachment', name: 'Attachment', id: 'attachment' },
]

export const initFilterProduct: IFilterProducts = {
  ...initFilter,
  sortBy: 'id',
  search: '',
  filterAccount: null,
  filterCategory: null,
  fiterManufacturer: null,
  filterRelatedProduct: null,
}

export const isHasFilterProduct = (filter: IFilterProducts) =>
  !!filter?.filterAccount ||
  !!filter?.filterCategory ||
  !!filter?.fiterManufacturer ||
  compareFilterPagination(filter, initFilterProduct)

export const PRODUCT_TYPES = ['Equipment', 'Licenses', 'Other']

export const renderLabelOptionProduct = (product: IProduct) => {
  const manufacturer = product.manufacturer?.full_name || ''
  return manufacturer !== '' ? `${manufacturer} ${product.name}` : product.name
}


export const getUnitOfProductTypes = (keyName: string, productCateories: IDetailSettings[] | null) => {
  const defaultUnit = ASSET_TYPE_DEFAULT_UNIT.find(item => item.key === keyName)
  if(defaultUnit?.unit){
    return defaultUnit.unit
  }
  const listMeta = productCateories?.reduce((result: IMetaField[], item) => {
    let data = [...result]
    if(item?.meta?.fields){
      data = [...data, ...item.meta.fields]
    }
    return data
  }, [])
  const detailFieldName = listMeta?.find(item => item.name.toLowerCase().split(' ').join('_') === keyName)
  return detailFieldName?.unit || ''
}