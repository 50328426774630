import { filter } from 'lodash'

import { AsyncSelect } from '../../../components/Forms/AsyncSelect'
import { Props as SelectProps } from '../../../components/Forms/Select'
import { PAGE_SIZE_DROPDOWN } from '../../../services/client'
import {
  getServices,
  IFilterServices,
  IService,
} from '../../../services/services'
import { OptionValue } from '../../../utils/form'
import { renderTextForServiceSelect } from '../../../utils/services'

interface Props extends Omit<SelectProps, 'options'> {
  selected?: OptionValue | null
  onSelect?: (option: OptionValue | null) => void
  className?: string
  excludesValues?: number[]
  filterService?: IFilterServices | null
  fetchOnFirst?: boolean
}
export const ServicesSelect = ({
  selected = null,
  className = '',
  onSelect,
  excludesValues = [],
  filterService = null,
  fetchOnFirst = true,
  ...props
}: Props) => {
  const handleGetServices = async ({ search = '', page = 1 } = {}) => {
    try {
      const { data } = await getServices({
        search,
        currentPage: page,
        pageSize: PAGE_SIZE_DROPDOWN,
        ...filterService,
      })
      return { data: data.data, totalPage: data.meta.last_page }
    } catch {
      return { data: [], totalPage: 1 }
    }
  }

  const mapServices = (services: IService[]) => {
    return filter(services, item => !excludesValues.includes(item.id)).map(
      ({ id, reason_for_visit }) => ({
        value: id,
        label: reason_for_visit
          ? renderTextForServiceSelect(reason_for_visit)
          : id + '',
      }),
    )
  }
  const handleSelect = (item: any) => {
    const value = !!item
      ? {
          value: item.value,
          label:
            typeof item.label === 'string'
              ? item.label
              : item.label.props.children,
        }
      : null
    onSelect?.(value)
  }

  const triggerValue = filterService?.filterAccount?.value

  return (
    <AsyncSelect
      placeholder='All Service Tickets'
      {...props}
      labelInValue
      allowClear
      value={selected}
      onChange={handleSelect}
      className={className}
      fetcher={handleGetServices}
      options={mapServices}
      dropdownClassName='w-full'
      triggerFilter={triggerValue}
      fetchOnFirst={fetchOnFirst}
    />
  )
}
