import { StoreApi } from 'zustand'

import { defaultNavigation, INavigation } from '../configs/navigations'
import { setMenusToLocalStorage } from '../utils/menus'
import type { Store } from './index'

export interface MenuSlice {
  currentMenu: INavigation[]
  setMenu: (menu: INavigation[]) => void
}

const slice = (set: StoreApi<Store>['setState']) => ({
  currentMenu: defaultNavigation,
  setMenu: (payload: INavigation[]) => {
    setMenusToLocalStorage(payload)
    return set(({ menus }) => ({
      menus: { ...menus, currentMenu: payload },
    }))
  },
})

export default slice
