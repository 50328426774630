import cx from 'classnames'
import { Field } from 'rc-field-form'
import type { FieldProps } from 'rc-field-form/lib/Field'
import { cloneElement, FC, ReactElement, ReactNode } from 'react'

import { AsyncSelect } from './AsyncSelect'
import { Select } from './Select'

export type Props = FieldProps & {
  label?: string | ReactNode
  requiredMask?: boolean
  className?: string
  showError?: boolean
}

const errorClasses = 'border-danger focus:!border-danger hover:!border-danger'

export const FormField: FC<Props> = ({
  children,
  label,
  name,
  requiredMask,
  className,
  showError = true,
  ...props
}) => {
  const renderErrors = (errors: string[]) => (
    <div className='min-h-[1.5rem] mt-2'>
      {errors.map(err => (
        <div key={err} className='text-red-900 text-body'>
          {err}
        </div>
      ))}
    </div>
  )

  const renderChildren = (child: any, controls: any, meta: any) => {
    const c = child as ReactElement
    const isSelectEl = c.type === Select || c.type === AsyncSelect
    return cloneElement(c, {
      name,
      ...controls,
      error: !!meta.errors.length,
      value: controls.value || (isSelectEl ? undefined : ''),
      className: cx(c.props.className, meta.errors.length && errorClasses),
      onChange: (...args: any[]) => {
        controls.onChange(...args)
        c.props.onChange?.(...args)
      },
    })
  }

  return (
    <Field name={name} {...props}>
      {(controls, meta, form) => (
        <div className={cx('mb-6', className)} id={`form-field-${name}`}>
          {!!label && (
            <label className='block font-medium text-body mb-1'>
              {label} {requiredMask && <span className='text-red-900'>*</span>}
            </label>
          )}
          {typeof children === 'function'
            ? children(controls, meta, form)
            : renderChildren(children, controls, meta)}
          {!!meta.errors.length && showError && renderErrors(meta.errors)}
        </div>
      )}
    </Field>
  )
}
