import { has } from 'lodash'
import { useState } from 'react'

import { Button, Input, Modal, ModalProps } from '../../../../components'
import { useAsync } from '../../../../hooks'
import { editFile, editFolder, IFile, IFolder } from '../../../../services/files'
import toast from '../../../../utils/toast'

interface Props extends Omit<ModalProps, 'children'> {
  onSuccess: (data: Partial<IFile>) => void
  onClose: () => void
  data: IFile | IFolder
}

export const ModalRenameFile = ({
  onClose,
  onSuccess,
  data,
  ...props
}: Props) => {
  const isFile = has(data, 'file_name')
  const [name, setName] = useState(data.name)
  const { execute, isLoading } = useAsync({
    showNotifOnError: true,
  })

  const handleSubmit = async () => {
    if (name === '') {
      return
    }
    const result = await execute(
      isFile ? editFile(data.id, {
        name,
      }) : editFolder(data.id, {
        name
      })
    )
    if (result) {
      onSuccess({ name })
      onClose()
      toast.success({
        title: `${isFile ? 'File' : 'Folder'} renamed`,
      })
    }
  }

  return (
    <Modal {...props} className='max-w-[27.5rem] w-full p-0' onClose={onClose}>
      <div className='relative'>
        <span
          className='cursor-pointer font-icon-close text-black-400 absolute right-2 top-2 z-10 w-10 h-10 flex justify-center items-center'
          onClick={onClose}
        />
        <div className='flex-1 p-4 pb-6'>
          <div className='text-base font-medium text-black-800 mb-4'>
            Rename {isFile ? 'file' : 'folder'}
          </div>
          <Input
            placeholder={`Name your ${isFile ? 'file' : 'folder'}`}
            value={name}
            onChange={e => setName(e.target.value)}
            autoFocus
          />
        </div>
        <div className='flex justify-between gap-2 py-3 px-4 border-t border-t-separation-800'>
          <Button
            variant='ternary'
            onClick={onClose}
            disabled={isLoading}
            asLink
            innerClassName='!px-3'
          >
            Cancel
          </Button>
          <Button
            variant='primary'
            disabled={isLoading || name === ''}
            innerClassName='px-8'
            onClick={handleSubmit}
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
  )
}
