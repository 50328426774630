import { Props as SelectProps, Select } from '../../../components/Forms/Select'
import { ASSET_RESOURCE } from '../../../utils/assets'
import { OptionValue } from '../../../utils/form'

interface Props extends Omit<SelectProps, 'options'> {
  selected?: OptionValue | null
  onSelect?: (option: OptionValue | null) => void
  className?: string
  excludeTypes?: OptionValue[]
}

export const ResourceTypesSelect = ({
  selected = null,
  className = '',
  onSelect,
  excludeTypes = [],
  ...props
}: Props) => {
  const handleSelect = (item: any) => {
    const value = !!item
      ? {
          value: item.value,
          label:
            typeof item.label === 'string'
              ? item.label
              : item.label.props.children,
        }
      : null
    onSelect?.(value)
  }

  const newOption =
    excludeTypes.length > 0
      ? Object.values(ASSET_RESOURCE).filter(item => {
          return !excludeTypes.some(e => e.value === item.value)
        })
      : Object.values(ASSET_RESOURCE)

  return (
    <Select
      className='w-full capitalize cursor-pointer'
      placeholder='All Types'
      labelInValue
      allowClear
      onChange={handleSelect}
      value={selected}
      options={newOption}
      {...props}
    />
  )
}
