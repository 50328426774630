import { filter } from 'lodash'

import { AsyncSelect } from '../../../components/Forms/AsyncSelect'
import { Props as SelectProps } from '../../../components/Forms/Select'
import { getCMSs, ICms, IFilterCms } from '../../../services/monitoring'
import { OptionValue } from '../../../utils/form'

interface Props extends Omit<SelectProps, 'options'> {
  selected?: OptionValue | null
  onSelect?: (option: OptionValue | null) => void
  className?: string
  excludesValues?: number[]
  filterCms?: IFilterCms | null
}
export const CMSSelect = ({
  selected = null,
  className = '',
  onSelect,
  excludesValues = [],
  filterCms = null,
  ...props
}: Props) => {
  const handleGetCMSs = async ({ search = '', page = 1 } = {}) => {
    try {
      const { data } = await getCMSs({
        search,
        currentPage: page,
        ...filterCms,
      })
      return { data: data.data, totalPage: data.meta.last_page }
    } catch {
      return { data: [], totalPage: 1 }
    }
  }

  const mapUsers = (cms: ICms[]) => {
    return filter(cms, item => !excludesValues.includes(item.id)).map(
      ({ id, name }) => ({
        value: id,
        label: name,
      }),
    )
  }
  const handleSelect = (item: any) => {
    const value = !!item
      ? {
          value: item.value,
          label:
            typeof item.label === 'string'
              ? item.label
              : item.label.props.children,
        }
      : null
    onSelect?.(value)
  }

  const filterValue = filterCms?.filterAccount?.value

  return (
    <AsyncSelect
      placeholder='All Central Monitoring Services'
      labelInValue
      allowClear
      {...props}
      value={selected}
      onChange={handleSelect}
      className={className}
      fetcher={handleGetCMSs}
      options={mapUsers}
      dropdownClassName='w-full'
      triggerFilter={filterValue}
    />
  )
}
