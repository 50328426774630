import cx from 'classnames'

import { Tooltip } from '../../components'
import { ITag } from '../../services/tags'
import { truncatedText } from '../../utils/functions'

interface Props {
  tags?: ITag[] | null
  onSelectTag?: (tag: ITag) => void
  tagClassName?: string
}

export const FileTags = ({ tags, onSelectTag, tagClassName = '' }: Props) => {
  if (!tags || tags.length === 0) {
    return null
  }
  return (
    <div className='flex flex-wrap gap-1'>
      {tags.map(i => (
        <Tooltip
          content={i.name}
          placement='bottom'
          className='leading-none'
          key={i.id}
          zIndex={4000}
        >
          <div
            className={cx(
              'bg-separation-200 rounded-[2rem] border border-separation-900 text-xs px-2 py-1 cursor-pointer',
              // filterTag === i.id && 'bg-primary-900 text-white border-primary-900',
              tagClassName,
            )}
            onClick={() => onSelectTag?.(i)}
          >
            {truncatedText(i.name, 20)}
          </div>
        </Tooltip>
      ))}
    </div>
  )
}
