import cx from 'classnames'
import { useRef } from 'react'

import { useNotesCtx } from '../Context'

export const NoteImagePicker = () => {
  const { dispatch, listAttachments, loading } = useNotesCtx()
  const pickerRef = useRef<HTMLInputElement | null>(null)

  const handlePickerChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    if (loading) {
      return
    }
    const { files } = e.target
    let listFiles: File[] = []
    if (files && files.length > 0) {
      listFiles = Array.from(files)
    }
    dispatch({
      listAttachments: [...listAttachments, ...listFiles],
    })
  }

  const handleOpenPicker = () => {
    if (pickerRef.current) {
      pickerRef.current.click()
    }
  }

  return (
    <div className='pt-2'>
      <input
        type='file'
        accept='image/*'
        className='absolute hidden pointer-events-none'
        ref={pickerRef}
        onChange={handlePickerChange}
        multiple
      />
      <span
        className={cx(
          'font-icon-image text-black-400 text-[1.125rem] cursor-pointer',
          loading && 'cursor-default pointer-events-none',
        )}
        onClick={handleOpenPicker}
      />
    </div>
  )
}
