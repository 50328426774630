import { CountryCode, isValidNumber } from 'libphonenumber-js'
import { useState } from 'react'

import { PhoneNumberField } from '../../shared/PhoneNumberField'
import { FormField, Props as FormFieldProps } from './FormField'

interface Props extends FormFieldProps {
  phoneNumber?: string
  isRequired?: boolean
  onChange: (value: string) => void
}

export const PhoneField = ({
  phoneNumber,
  onChange,
  isRequired,
  ...props
}: Props) => {
  const [countryPhone, setCountry] = useState('')
  const handleChange = (value: string, country: string) => {
    if (country !== countryPhone) {
      setCountry(country)
    }
    onChange(value)
  }
  return (
    <FormField
      {...props}
      rules={[
        {
          validator: (_, v) => {
            const countryCode = countryPhone !== 'ZZ' ? countryPhone : undefined
            if (!v) {
              if (isRequired) {
                return Promise.reject(new Error('Invalid Phone'))
              }
              return Promise.resolve()
            }
            if (v === countryCode && !isRequired) {
              return Promise.resolve()
            }
            if (!isValidNumber(v, countryCode as CountryCode)) {
              return Promise.reject(new Error('Invalid Phone'))
            }
            return Promise.resolve()
          },
        },
      ]}
    >
      <PhoneNumberField
        phoneNumber={phoneNumber}
        onChange={(v: string, c: string) => handleChange(v, c)}
      />
    </FormField>
  )
}
