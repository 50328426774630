import cx from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import { omit } from 'lodash'
import { ChangeEvent, useEffect, useReducer, useState } from 'react'
import useOnclickOutside from 'react-cool-onclickoutside'
import { usePopper } from 'react-popper'

import { Input } from '../../../components'
import { Spinner } from '../../../components/Spinner'
import { usePermission } from '../../../hooks/usePermission'
import {
  IAssetResourceOption,
  TAssetBelong,
  TKeyBelongTo,
} from '../../../services/assets'
import { IFilter } from '../../../services/client'
import { getContracts, IContract } from '../../../services/contracts'
import { getMonitorings, IMonitoring } from '../../../services/monitoring'
import { getProjects, IProject } from '../../../services/projects'
import { TResource } from '../../../services/resource'
import { getServices, IService } from '../../../services/services'
import { OptionValue } from '../../../utils/form'
import { isIncludeText, parserHtmlToText } from '../../../utils/functions'
import { PERMISSIONS } from '../../../utils/permission'
import {
  RESOURCE_CONTRACT,
  RESOURCE_MONITORING,
  RESOURCE_PROJECT,
  RESOURCE_SERVICE,
  RESOURCE_TYPE_LABEL,
} from '../../../utils/resources'
import { renderTextForServiceSelect } from '../../../utils/services'

interface State {
  openDropdown: boolean
  isFetched: boolean
  loading: boolean
  search: string
  selectedId: string
  all: TResource[]
  projects: TResource[]
  monitoring_services: TResource[]
  service_tickets: TResource[]
  maintenance_contracts: TResource[]
}

const initDataApi = {
  all: [],
  projects: [],
  maintenance_contracts: [],
  monitoring_services: [],
  service_tickets: [],
}

interface Props {
  placeholder?: string
  selected?: IAssetResourceOption | null
  onSelect?: (value: IAssetResourceOption | null) => void
  filterAccount?: OptionValue | null
  excludeResource?: Record<TKeyBelongTo, number[]> | null
}

interface PropContent {
  search: string
  data: TAssetBelong
  selectedId: string
  onSelect: (value: IAssetResourceOption) => void
}

const mapData = (arr: TAssetBelong | null) => {
  if (!arr) {
    return []
  }
  return Object.values(arr).reduce((total, item) => [...total, ...item], [])
}

const filterData = (arr: TAssetBelong | null, search = '') => {
  if (!arr) {
    return null
  }
  if (search === '') {
    return arr
  }
  let res = {}
  let resultAll = [] as TResource[]
  Object.entries(omit(arr, ['all'])).forEach(([key, value]) => {
    let result = value
    if (key === 'projects') {
      const values = value as IProject[]
      result = values.filter(item => isIncludeText(item.name, search))
    }
    if (key === 'service_tickets') {
      const values = value as IService[]
      result = values.filter(item =>
        isIncludeText(parserHtmlToText(item.reason_for_visit || ''), search),
      )
    }
    if (key === 'monitoring_services') {
      const values = value as IMonitoring[]
      result = values.filter(item => isIncludeText(item.name, search))
    }
    if (key === 'maintenance_contracts') {
      const values = value as IContract[]
      result = values.filter(item =>
        isIncludeText(item.contract_number + '', search),
      )
    }
    resultAll = [...resultAll, ...result]
    res = {
      ...res,
      [key]: result,
    }
  })
  return {
    ...res,
    all: resultAll,
  } as TAssetBelong
}
const renderSelectedId = (id: number, type: string) => `${type}_${id}`

export const AssetBelongToSelect = ({
  placeholder = '',
  selected,
  onSelect,
  filterAccount = null,
}: Props) => {
  const [refElement, setRefElement] = useState<HTMLElement | null>(null)
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null)
  const { styles, attributes } = usePopper(refElement, popperElement, {
    placement: 'bottom-start',
  })

  const { canView: canViewProject } = usePermission({
    name: PERMISSIONS.PROJECT,
  })
  const { canView: canViewMonitoring } = usePermission({
    name: PERMISSIONS.MONITORING,
  })
  const { canView: canViewService } = usePermission({
    name: PERMISSIONS.SERVICE,
  })
  const { canView: canViewContract } = usePermission({
    name: PERMISSIONS.CONTRACT,
  })
  const [
    {
      openDropdown,
      isFetched,
      loading,
      search,
      all,
      projects,
      monitoring_services,
      service_tickets,
      maintenance_contracts,
      selectedId,
    },
    setState,
  ] = useReducer((s: State, a: Partial<State>) => ({ ...s, ...a }), {
    loading: false,
    isFetched: false,
    openDropdown: false,
    all: [],
    projects: [],
    monitoring_services: [],
    service_tickets: [],
    maintenance_contracts: [],
    search: selected?.resource_name || '',
    selectedId:
      selected?.resource_type && selected?.resource_id
        ? renderSelectedId(selected.resource_id, selected.resource_type)
        : '',
  })

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setState({ search: e.target.value })
  }

  const handleOpenDropdown = async () => {
    setState({
      openDropdown: true,
    })
    if (!isFetched) {
      await handleFetch()
      setState({
        isFetched: true,
      })
    }
  }

  const getResourceApiByType = (
    payload: IFilter & { filterAccount: OptionValue | null },
    type: TKeyBelongTo,
  ) => {
    switch (type) {
      case 'maintenance_contracts': {
        return getContracts(payload)
      }
      case 'projects': {
        return getProjects(payload)
      }
      case 'service_tickets': {
        return getServices(payload)
      }
      case 'monitoring_services': {
        return getMonitorings(payload)
      }
      default: {
        return null
      }
    }
  }

  const fetcher = async (
    defaultData: TResource[],
    page = 1,
    type: TKeyBelongTo,
  ) => {
    const payload = {
      currentPage: page,
      filterAccount,
    }
    const result = await getResourceApiByType(payload, type)
    if (result?.data) {
      const currentData = result.data.data
      // const exResource = excludeResource?.[type] || []
      // const notApplyExclude = selected?.resource_id && exResource.includes(selected?.resource_id)
      // const filteredData = currentData.filter((item: TResource) => !exResource.includes(item.id) && !notApplyExclude)
      const resultDataOfType = [...defaultData, ...currentData]
      setState({
        [type]: resultDataOfType,
      })
      const last_page = result.data.meta.last_page
      if (page < last_page) {
        await fetcher(resultDataOfType, page + 1, type)
      }
    }
  }

  const handleFetch = async () => {
    const apiProject = canViewProject && fetcher(projects, 1, 'projects')
    const apiService =
      canViewService && fetcher(service_tickets, 1, 'service_tickets')
    const apiContract =
      canViewContract &&
      fetcher(maintenance_contracts, 1, 'maintenance_contracts')
    const apiMonitoring =
      canViewMonitoring &&
      fetcher(monitoring_services, 1, 'monitoring_services')
    await Promise.allSettled([
      apiProject,
      apiService,
      apiContract,
      apiMonitoring,
    ])
  }

  const handleSelect = (value: IAssetResourceOption) => {
    setState({
      search: value?.resource_name || '',
      selectedId: renderSelectedId(
        value?.resource_id || 0,
        value?.resource_type || '',
      ),
      openDropdown: false,
    })
    onSelect?.(value)
  }

  const handleBlur = () => {
    if (search !== selected?.resource_name) {
      setState({
        search:
          selectedId !== '' && !!selected?.resource_name
            ? selected?.resource_name
            : '',
      })
    }
    setState({
      openDropdown: false,
    })
  }
  const handleClear = () => {
    if (selectedId !== '') {
      onSelect?.(null)
    }
    setState({
      search: '',
      selectedId: '',
    })
  }

  const refOutSide = useOnclickOutside(handleBlur)

  useEffect(() => {
    const newAllData = [
      ...projects,
      ...service_tickets,
      ...maintenance_contracts,
      ...monitoring_services,
    ]
    setState({
      all: newAllData,
    })
  }, [projects, service_tickets, maintenance_contracts, monitoring_services])

  useEffect(() => {
    setState({
      ...initDataApi,
      isFetched: false,
    })
  }, [filterAccount])

  return (
    <div className='relative' ref={refOutSide}>
      <div>
        <Input
          value={search || ''}
          className={search !== '' ? '!pr-16' : 'pr-8'}
          suffix={
            <div className='flex gap-2 items-center'>
              {search !== '' && (
                <span
                  className='font-icon-close cursor-pointer'
                  onClick={handleClear}
                />
              )}
              <span className='font-icon-arrow_down text-[1.25rem]' />
            </div>
          }
          onChange={handleChange}
          ref={setRefElement}
          placeholder={placeholder}
          onFocus={handleOpenDropdown}
        />
      </div>
      <AnimatePresence initial={false}>
        {openDropdown && (
          <div
            ref={setPopperElement}
            className={cx('z-[9999]', all.length > 0 ? 'w-[44rem]' : 'w-full')}
            style={{ ...styles.popper }}
            {...attributes.popper}
          >
            <motion.div
              initial={{ scale: 0, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0, opacity: 0 }}
              transition={{ duration: 0.2 }}
              style={{
                transformOrigin: '0 0',
              }}
            >
              <div className='z-popover w-full bg-white text-sm text-light-primary rounded-lg border border-light-stroke shadow-dropdown relative min-h-[4.375rem]'>
                {loading && (
                  <div className='absolute inset-0 bg-white/70 z-10 rounded-lg'>
                    <Spinner size='xsmall' />
                  </div>
                )}
                {all.length > 0 ? (
                  <DropdownContent
                    search={search}
                    data={{
                      all,
                      projects,
                      monitoring_services,
                      maintenance_contracts,
                      service_tickets,
                    }}
                    selectedId={selectedId}
                    onSelect={handleSelect}
                  />
                ) : (
                  isFetched && (
                    <div className='h-[4.375rem] flex justify-center items-center text-sm opacity-60'>
                      No Data
                    </div>
                  )
                )}
              </div>
            </motion.div>
          </div>
        )}
      </AnimatePresence>
    </div>
  )
}

const DropdownContent = ({
  data,
  onSelect,
  selectedId,
  search,
}: PropContent) => {
  const [selectedTab, setSelectedTab] = useState<TKeyBelongTo>('all')
  const filter = filterData(data, search)
  if (!filter) {
    return null
  }

  const listResults = mapData(filter)

  const renderItemTab = (key: TKeyBelongTo, name: string, total: number) => {
    const isActive = selectedTab === key
    return (
      <div
        className={cx(
          'flex gap-1 px-2 pb-3 relative',
          isActive ? 'font-medium' : 'cursor-pointer',
        )}
        onClick={!isActive ? () => setSelectedTab(key) : undefined}
      >
        <span className='capitalize'>{name}</span>
        <span>({total})</span>
        {isActive && (
          <span className='bg-primary-700 h-1 absolute left-0 right-0 bottom-0 rounded' />
        )}
      </div>
    )
  }
  const renderTab = () => (
    <div className='flex gap-3 w-full border-b border-separation-900 px-4 pt-4'>
      {renderItemTab('all', 'All', filter.all.length)}
      {filter?.projects?.length > 0 &&
        renderItemTab('projects', 'Projects', filter.projects.length)}
      {filter?.service_tickets?.length > 0 &&
        renderItemTab(
          'service_tickets',
          'Service Tickets',
          filter.service_tickets.length,
        )}
      {filter?.maintenance_contracts?.length > 0 &&
        renderItemTab(
          'maintenance_contracts',
          'Maintenance',
          filter.maintenance_contracts.length,
        )}
      {filter?.monitoring_services?.length > 0 &&
        renderItemTab(
          'monitoring_services',
          'Monitoring',
          filter.monitoring_services.length,
        )}
    </div>
  )
  const renderItem = (id: number, name: string, type: string) => (
    <div
      className={cx(
        'cursor-pointer px-4 py-1',
        selectedId === `${type}_${id}` && 'bg-separation-400',
      )}
      key={`${type}_${id}`}
      onClick={() =>
        onSelect({
          resource_id: id,
          resource_type: type,
          resource_name: name,
        })
      }
    >
      <div>{name}</div>
      <div className='text-black-400 flex gap-2'>
        <span>{RESOURCE_TYPE_LABEL[type]}</span>
        <span>|</span>
        <span>{id}</span>
      </div>
    </div>
  )
  const renderItemContent = (item: TResource, key: TKeyBelongTo) => {
    if (key === 'projects') {
      const value = item as IProject
      return renderItem(value.id, value.name, RESOURCE_PROJECT)
    }
    if (key === 'service_tickets') {
      const value = item as IService
      return renderItem(
        value.id,
        renderTextForServiceSelect(value.reason_for_visit, 40),
        RESOURCE_SERVICE,
      )
    }
    if (key === 'maintenance_contracts') {
      const value = item as IContract
      return renderItem(value.id, value.contract_number + '', RESOURCE_CONTRACT)
    }
    if (key === 'monitoring_services') {
      const value = item as IMonitoring
      return renderItem(value.id, value.name, RESOURCE_MONITORING)
    }
    return null
  }
  const renderContent = () => {
    const list = selectedTab === 'all' ? listResults : filter?.[selectedTab]
    if (selectedTab === 'all') {
      return (
        <div className='py-6 max-h-[18rem] custom-scrollbar h-full overflow-y-auto flex flex-col gap-1'>
          {Object.entries(filter).map(([key, value]: [string, TResource[]]) =>
            value.map(item => renderItemContent(item, key as TKeyBelongTo)),
          )}
        </div>
      )
    }
    return (
      <div className='py-6 max-h-[18rem] custom-scrollbar h-full overflow-y-auto flex flex-col gap-1'>
        {list.map(item => renderItemContent(item, selectedTab))}
      </div>
    )
  }
  return (
    <div>
      {renderTab()}
      {renderContent()}
    </div>
  )
}
