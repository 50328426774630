import { SVGProps } from 'react'

export const Excel = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className='text-[#3C804D]'
      {...props}
    >
      <path
        d='M0 2.28571C0 1.02335 1.02335 0 2.28571 0H13.7143C14.9767 0 16 1.02335 16 2.28571V13.7143C16 14.9767 14.9767 16 13.7143 16H2.28571C1.02335 16 0 14.9767 0 13.7143V2.28571Z'
        fill='currentColor'
      />
      <path
        d='M9.38274 11.4286L7.91261 9.32845L6.61726 11.4286H4.62283L6.93596 7.94139L4.57143 4.57143H6.61726L8.06682 6.64225L9.34162 4.57143H11.336L9.04348 8.0293L11.4286 11.4286H9.38274Z'
        fill='white'
      />
    </svg>
  )
}
