import listStates from '../assets/stateUs.json'
import { IAddress } from '../services/address'

export enum COUNTRY {
  USA = 'usa',
  CANADA = 'canada',
}

export const findTypeOfPlace = (
  place: google.maps.places.PlaceResult,
  placeType: string,
) => {
  if (place?.address_components) {
    const detail = place?.address_components.find(
      (item: google.maps.GeocoderAddressComponent) =>
        item.types[0] === placeType,
    )
    if (detail) {
      return detail
    }
  }
  return null
}

export const parseInfoAddresFromPlaceResult = (
  place: google.maps.places.PlaceResult,
) => {
  const streetNumber = findTypeOfPlace(place, 'street_number')
  const streetName = findTypeOfPlace(place, 'route')
  const city = findTypeOfPlace(place, 'locality')
  const postalCode = findTypeOfPlace(place, 'postal_code')
  const stateCountry = findTypeOfPlace(place, 'administrative_area_level_1')
  const countryName = findTypeOfPlace(place, 'country')
  const streetAddress = `${streetNumber?.long_name || ''} ${
    streetName?.long_name || ''
  }`.trim()
  return {
    streetAddress,
    city: city?.long_name || '',
    zipCode: postalCode?.long_name || '',
    state: stateCountry?.short_name || '', // use short_name , ticket SEC-36
    country: countryName?.long_name || '',
  }
}

export const defaultMapCenter = {
  lat: 40.73061,
  lng: -73.935242,
}

export const defaultMapOptions = {
  streetViewControl: false,
  fullscreenControl: false,
  mapTypeControl: true,
  mapTypeControlOptions: {
    position: window?.google?.maps?.ControlPosition.TOP_RIGHT || 11.0,
  },
  styles: [
    {
      featureType: 'poi',
      elementType: 'labels.text.fill',
      stylers: [{ visibility: 'off' }],
    },
  ],
}

export const getShortStateUsa = (state: string) => {
  const detailState = listStates.find(item => item.name === state)
  if (!detailState) {
    return state
  }
  return detailState.code
}

export const getFullStateUsa = (code: string) => {
  const detailState = listStates.find(item => item.code === code)
  if (!detailState) {
    return code
  }
  return detailState.name
}

export const mapAddress = ({
  street_address,
  street_address_2,
  city,
  state,
  zip,
  country,
}: IAddress) => {
  const str =
    (!!street_address ? street_address + ',' : '') +
    (!!street_address_2 ? street_address_2 + ',' : '') +
    (!!city ? city + ',' : '') +
    (!!state ? state + ',' : '') +
    (!!zip ? zip + ',' : '') +
    (!!country ? country : '')
  return str.endsWith(',') ? str.slice(0, -1) : str
}
