import { AnimatePresence } from 'framer-motion'
import { orderBy } from 'lodash'
import { useEffect, useState } from 'react'
import shallow from 'zustand/shallow'

import { Select, SelectProps } from '../../../components'
import { Spinner } from '../../../components/Spinner'
import { PATHNAME } from '../../../configs/routes'
import { useAsync } from '../../../hooks'
import { usePermission } from '../../../hooks/usePermission'
import { editInvoice, IInvoice } from '../../../services/invoices'
import useStore, { Store } from '../../../store'
import { OptionValue } from '../../../utils/form'
import { INVOICE_STATUS_NEED_CONFIRM } from '../../../utils/invoices'
import { PERMISSIONS } from '../../../utils/permission'
import toast from '../../../utils/toast'
import { ModalMarkInvoiceAsPaid } from '../../Modals/Invoices/MarkAsPaid'

interface Props extends Omit<SelectProps, 'options'> {
  selected?: OptionValue | null
  invoice?: IInvoice | null
  onSelect?: (v: OptionValue) => void
  onUpdateComplete?: (data: IInvoice) => void
}

const mapState = (state: Store) => ({
  invoice_statuses: state.settings?.invoice?.invoice_statuses,
})

export const InvoiceStatusSelect = ({
  selected = null,
  invoice = null,
  onUpdateComplete,
  onSelect,
  ...props
}: Props) => {
  const { invoice_statuses } = useStore(mapState, shallow)
  const [currentSelected, setCurrentSelected] = useState(selected)
  const [openMarkPaid, setOpenMarkPaid] = useState<OptionValue | null>(null)
  const { isLoading, execute } = useAsync({ showNotifOnError: true })
  const { canUpdate } = usePermission({ name: PERMISSIONS.INVOICE })
  const invoiceId = invoice?.id
  const handleChangeSuccess = (value: IInvoice) => {
    setCurrentSelected(openMarkPaid)
    onUpdateComplete?.(value)
  }
  const handleChange = async (v: OptionValue) => {
    if (currentSelected === v) {
      return
    }
    if (INVOICE_STATUS_NEED_CONFIRM.includes(v.label || '')) {
      setOpenMarkPaid(v)
    } else {
      setCurrentSelected(v)
      if (invoiceId) {
        const response = await execute(
          editInvoice(invoiceId, {
            status: v.label,
            status_id: v.value,
          }),
        )
        toast.success({
          title: `Invoice "${response.data.data.invoice_number}" updated`,
          path: `/${PATHNAME.invoices}/${invoiceId}`,
        })
        onUpdateComplete?.(response.data.data)
      }
    }
  }

  const handleSelect = (value: OptionValue) => {
    //handle change with select only
    setCurrentSelected(value)
    onSelect?.(value)
  }

  const customProps = invoiceId
    ? { ...props, onChange: (value: OptionValue) => handleChange(value) }
    : { ...props }

  useEffect(() => {
    if (
      selected !== currentSelected ||
      selected?.label !== currentSelected?.label ||
      selected?.value !== currentSelected?.value
    ) {
      setCurrentSelected(selected)
    }
  }, [selected])

  if (!canUpdate && invoiceId) {
    return <div className='relative'>{currentSelected}</div>
  }

  return (
    <>
      <div className='relative'>
        <Select
          {...customProps}
          value={currentSelected}
          labelInValue
          onSelect={
            onSelect ? (value: OptionValue) => handleSelect(value) : undefined
          }
        >
          {orderBy(invoice_statuses, ['label'], ['asc']).map(item => (
            <Select.Option key={item.id}>{item.label}</Select.Option>
          ))}
        </Select>
        {isLoading && (
          <>
            <div className='absolute inset-0 bg-white opacity-60 w-full h-full z-10' />
            <div className='absolute top-1/2 right-2 z-20 -translate-y-1/2'>
              <Spinner size='xsmall' />
            </div>
          </>
        )}
      </div>
      <AnimatePresence initial={false}>
        {openMarkPaid && openMarkPaid.label && invoice && (
          <ModalMarkInvoiceAsPaid
            onClose={() => setOpenMarkPaid(null)}
            onSuccess={handleChangeSuccess}
            invoice={invoice}
            invoiceType={openMarkPaid.label}
          />
        )}
      </AnimatePresence>
    </>
  )
}
