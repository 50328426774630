import { Props as SelectProps, Select } from '../../../components/Forms/Select'
import { ACCOUNT_TYPES } from '../../../utils/accounts'

interface Props extends Omit<SelectProps, 'options'> {
  selected?: string | null
  className?: string
}

export const AccountTypesSelect = ({
  selected = null,
  onSelect,
  ...props
}: Props) => {
  return (
    <Select
      size='default'
      className='w-full capitalize cursor-pointer'
      placeholder='All Type'
      allowClear
      value={selected}
      {...props}
    >
      <Select.Option value=''>All</Select.Option>
      {ACCOUNT_TYPES.map(item => (
        <Select.Option value={item} key={item}>
          {item}
        </Select.Option>
      ))}
    </Select>
  )
}
