import cx from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'

import { Button, Checkbox } from '../../components'
import { useUncategorizedCtx } from './Context'

export const UncategorizedFooter = () => {
  const {selectedIds, listUncategorized, dispatch, isSubPage} = useUncategorizedCtx()

  const handleToggleAll = (checked: boolean) => {
    let listIds: number[] = []
    if(checked){
      listIds = listUncategorized.map(item => item.id)      
    }
    dispatch({selectedIds: listIds})
  }

  const handleDeselect = () => {
    dispatch({selectedIds: []})
  }
  const isShowFooter = selectedIds.length > 0
  return (    
    <AnimatePresence initial={false}>
      {
        isShowFooter && (
          <motion.div
            className={cx(
              'fixed bottom-0 right-0 py-4 border-t border-t-separation-800 flex justify-center bg-white',
              isSubPage ? 'left-0 sm:left-[11.5rem]' : 'left-0'
            )}
            initial={{ bottom: -65 }}
            animate={{
              bottom: 0,
              transition: { duration: 0.3 },
            }}    
            exit={{ bottom: -65 }}
          >
            <div className="max-w-2xl flex w-full justify-between px-6">
              <div className="flex items-center gap-6 font-medium">
                <Checkbox
                  onChange={e => handleToggleAll(e.target.checked)}
                  checked={selectedIds.length > 0}
                  indeterminate={selectedIds.length < listUncategorized.length}
                >
                  Select all
                </Checkbox>
                <div className="cursor-pointer" onClick={handleDeselect}>Deselect ({selectedIds.length})</div>
              </div>
              <div className="flex items-center gap-2">
                <Button variant="ternary" innerClassName="gap-2" onClick={() => dispatch({deleteIds: selectedIds})}>
                  <span className="font-icon-trash" />
                  Delete
                </Button>
                <Button variant="ternary" innerClassName="gap-2" onClick={() => dispatch({moveIds: selectedIds})}>
                  <span className="font-icon-share"/>
                  Move
                </Button>
              </div>
            </div>
          </motion.div>
        )
      }
    </AnimatePresence>    
  )
}