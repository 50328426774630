import { PERMISSIONS } from './../utils/permission'
import client from './client'
import { ITaxVendor } from './taxVendors'

export interface IMenuSetting {
  path: string
  name: string
  Icon: JSX.Element
  tags?: string
  blocks?: string[]
  keyPermission?: keyof typeof PERMISSIONS
}
export interface IFormSetting {
  label: string
  info?: string | null
  default?: boolean
}

export interface IMetaField {
  name: string
  type: string
  is_required: boolean
  options?: string[] | null
  unit?: string | null
  rules?: {
    max?: number | null
    min?: number | null
  } | null
  sub_field?: IMetaField | null
}

export interface IIconSetting {
  iconName: string
  keyword: string
}
export interface IMetaMedia {
  iconName: string
  background: number
}
export interface IMetaSetting {
  fields?: IMetaField[] | null
  media?: IMetaMedia | null
}
export interface IDetailSettings {
  created_at: Date | string
  default: string | number | boolean | null
  group: string
  id: number
  info?: string | null
  is_editable: boolean
  label: string
  section: string
  children?: IDetailSettings[] | null
  meta?: IMetaSetting | null
  name: string
  setting_label_id?: number | null
}

export interface IParentDetailSettings extends IDetailSettings {
  level?: number | null
  fullPathStr?: string | null
}
export interface IReponseItemSetting {
  setting_label_id: number
  label_name: string
  value: string
  id: number | null
  created_at: Date | string | null
  user_id: number | null
}

export interface IAccountSettings {
  account_rates?: IDetailSettings[] | null
}

export interface IAccountingSettings {
  taxVendor: ITaxVendor[] | null
}

export interface IUserSettings {
  user_meta?: IDetailSettings[] | null
}

export interface IAssetSettings {
  asset_sub_types?: IDetailSettings[] | null
}

export interface IQuoteSettings {
  line_item_types?: IDetailSettings[] | null
  quote_statuses?: IDetailSettings[] | null
  warranty_terms?: IDetailSettings[] | null
  conditions?: IDetailSettings[] | null
  quote_setting?: IDetailSettings[] | null
}

export interface IQuoteSettingPayload {
  setting_id: number
  label: string
}

export interface IInvoiceSettings {
  invoice_setting: IDetailSettings[] | null
  invoice_statuses?: IDetailSettings[] | null
}

export interface IProjectSettings {
  project_statuses?: IDetailSettings[] | null
  project_system_types?: IDetailSettings[] | null
}

export interface IProductSettings {
  product_types?: IDetailSettings[] | null
}

export interface IExpenseSettings {
  expense_categories?: IDetailSettings[] | null
}

export interface ISaleProposal {
  sales_proposal_templates?: IDetailSettings[] | null
}

export interface ICompanySettings {
  id: number
  avatar: File | null
  avatar_url: string | null
  avatar_preview_url: string | null
  name: string
  street_address: string
  street_address_2: string
  city: string
  state: string
  zip: string
  country: string
  mailing_street_address: string | null
  mailing_street_address_2: string | null
  mailing_city: string | null
  mailing_state: string | null
  mailing_zip: string | null
  mailing_country: string | null
  email: string | null
  website: string | null
  work_week: string
  business_hrs: string
  phone_no: string | null
  fax_no: string | null
  industry_types: string | null
  created_at: string
  lat: string
  lng: string
  is_stripe_authorized: boolean
  is_quickbooks_connected: boolean
  quickbooks_sync_enabled: boolean
}

export interface ISettings {
  account: IAccountSettings
  accounting: IAccountingSettings
  asset: IAssetSettings
  invoice: IInvoiceSettings
  project: IProjectSettings
  expense: IExpenseSettings
  quote: IQuoteSettings
  user: IUserSettings
  company: ICompanySettings
  product: IProductSettings
  sales_proposal: ISaleProposal
}

export interface ISettingPayload {
  section?: string
  group?: string
  label?: string | null
  default?: string | number | boolean | null
  info?: string | null
  is_editable?: boolean
  setting_label_id?: number
  name?: string | null
  meta?: IMetaSetting | null
}

export type ICompanySettingsPayload = Omit<
  ICompanySettings,
  | 'is_stripe_authorized'
  | 'id'
  | 'is_quickbooks_connected'
  | 'quickbooks_sync_enabled'
>

export const getSettings = async () => {
  return client.get('/api/settingLabels')
}

export const createSetting = async (payload: Partial<IDetailSettings>) => {
  return client.post('/api/settingLabels', payload)
}

export const editSetting = (
  settingId: number,
  payload: Partial<IDetailSettings>,
) => {
  return client.put<{ data: ISettings }>(
    `/api/settingLabels/${settingId}`,
    payload,
  )
}

export const deleteSetting = (id: number) => {
  return client.delete(`api/settingLabels/${id}`)
}

export const getCompanySettings = () => {
  return client.get<{ data: ICompanySettings }>('/api/companySettings')
}

export const editCompanySettings = (
  payload: Partial<ICompanySettingsPayload>,
) => {
  if (!payload.avatar) {
    return client.put<{ data: ICompanySettings }>(
      '/api/companySettings',
      payload,
    )
  }
  const formData = new FormData()
  Object.entries(payload).forEach(([key, value]) => {
    if (!!value) {
      const newValue = typeof value === 'number' ? value + '' : value
      formData.append(key, newValue)
    }
  })
  formData.append('_method', 'PUT')
  return client.post<{ data: ICompanySettings }>(
    '/api/companySettings',
    formData,
  )
}

// EmbeddedCalendars

export interface ICalendarSetting {
  id: number
  provider: string
  name: string
  iframe: string | null
  created_at: Date | string
}

export const getCalendarsSetting = async () => {
  return client.get('/api/embeddedCalendars')
}

export const editCalendarSetting = (
  id: number,
  payload: Partial<ICalendarSetting>,
) => {
  return client.put<{ data: ICalendarSetting }>(
    `/api/embeddedCalendars/${id}`,
    payload,
  )
}
