import cx from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import { debounce } from 'lodash'
import { ChangeEvent, useEffect, useReducer, useState } from 'react'
import { usePopper } from 'react-popper'

import { useAsync } from '../../hooks'
import { Overlay } from '../Overlay'
import { Popover } from '../Popover'
import { Portal } from '../Portal'
import { Props as PropsInput } from './Input'
import { SearchInput } from './SearchInput'

type FetchResponse = {
  data: any
}

export interface Props extends PropsInput {
  search?: string
  onSearch?: (s: string) => void
  onCreateFromKeyword?: (s?: string) => void
  onClearSearch?: () => void
  pageSize?: number
  getData?: (search: string) => FetchResponse
  fetcher?: (params: {
    search: string
    pageSize?: number
  }) => Promise<FetchResponse>
  renderResults: (
    results: any,
    onClose?: () => void,
    keyword?: string,
  ) => React.ReactNode
  showBottomAction?: boolean | null
  debounceTime?: number
  showNotFoundContent?: boolean
  notFoundContent?: React.ReactNode
  popoverClassName?: string
  contentResultClassName?: string
  inheritParentWidth?: boolean
}

interface State {
  loading: boolean
  keyword: string
  result: any
  open: boolean
}

const initState: State = {
  loading: false,
  keyword: '',
  result: null,
  open: false,
}

export const SearchAutoComplete = ({
  search = '',
  pageSize = 5,
  onSearch,
  onCreateFromKeyword,
  onClearSearch,
  fetcher,
  getData,
  renderResults,
  showBottomAction = true,
  debounceTime = 500,
  showNotFoundContent = false,
  notFoundContent,
  popoverClassName = 'w-[calc(100%_-_1rem)] sm:w-[calc(100%_-_2rem)]',
  contentResultClassName = '',
  inheritParentWidth = true,
  ...props
}: Props) => {
  const [refElement, setRefElement] = useState<HTMLElement | null>(null)
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null)
  const { styles, attributes } = usePopper(
    inheritParentWidth ? refElement?.parentElement : refElement,
    popperElement,
    {
      placement: 'bottom',
    },
  )

  const [{ loading, keyword, result, open }, setState] = useReducer(
    (s: State, a: Partial<State>) => ({ ...s, ...a }),
    initState,
  )
  const noData = !result || result?.length === 0

  const { execute } = useAsync({
    status: 'pending',
    showNotifOnError: true,
  })

  const handleClearSearch = () => {
    setState({
      result: [],
      keyword: '',
    })
    search !== '' && onClearSearch?.()
  }

  const handleSearch = (s?: string) => {
    const newKeyword = s || keyword
    setState({
      open: false,
      keyword: newKeyword,
    })
    onSearch?.(newKeyword)
  }

  const handleGetResults = async (k: string) => {
    if (fetcher) {
      try {
        const { data } = await fetcher({
          search: k,
          pageSize,
        })
        return { data }
      } catch {
        return { data: [] }
      }
    }
    return []
  }

  const searchDebounce = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    if (getData) {
      const response = getData(value)
      setState({
        result: response.data,
        open: true,
        keyword: value,
      })
    } else {
      setState({ loading: true, keyword: value })
      if (value !== '') {
        execute(handleGetResults(value)).then(res => {
          setState({
            loading: false,
            result: res.data,
            open: true,
          })
        })
      } else {
        setState({
          loading: false,
          result: null,
        })
      }
    }
  }

  const handleClose = () => {
    setState({ open: false })
  }

  const handleCreateFromKeyword = () => {
    setState({
      open: false,
    })
    onCreateFromKeyword?.(keyword)
  }

  const renderContent = () => {
    if (keyword === '' && noData && !showNotFoundContent) {
      return null
    }
    if (keyword === '') {
      return null
    }
    return (
      <div className='flex flex-col gap-3 py-3 relative'>
        {!noData ? (
          <div
            className={cx(
              'overflow-auto custom-scrollbar max-h-[calc(100vh_-_32rem)] flex flex-col gap-5',
              contentResultClassName,
            )}
          >
            {renderResults(result, handleClose, keyword)}
          </div>
        ) : (
          notFoundContent || (
            <div className='flex flex-col flex-1 py-2 px-4'>
              No result match your search
            </div>
          )
        )}
        {loading && (
          <div className='absolute inset-0 flex justify-center items-center bg-white/70' />
        )}
        {showBottomAction && (!noData || Boolean(onCreateFromKeyword)) && (
          <div className='flex gap-3 flex-col border-t border-t-separation-800 text-primary-900 px-4 pt-3'>
            {!noData && (
              <div
                className={cx(
                  'flex gap-2 cursor-pointer items-center',
                  loading && 'opacity-0',
                )}
                onClick={() => handleSearch()}
              >
                <span className='font-icon-search' />
                <span className='font-medium'>Search for "{keyword}"</span>
              </div>
            )}
            {Boolean(onCreateFromKeyword) && (
              <div
                className={cx(
                  'flex gap-2 cursor-pointer items-center',
                  loading && 'opacity-0',
                )}
                onClick={handleCreateFromKeyword}
              >
                <span className='font-icon-add' />
                <span className='font-medium'>Create "{keyword}"</span>
              </div>
            )}
          </div>
        )}
      </div>
    )
  }

  const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = e => {
    if (e.key === 'Enter') {
      const value = (e.target as HTMLInputElement).value
      value !== search && handleSearch(value)
    }
  }

  useEffect(() => {
    if (search !== keyword) {
      setState({
        keyword: !!search ? search : '',
      })
    }
  }, [search])

  const widthPopover = refElement?.offsetWidth
  const isNotOpenSearch = (!result || result?.length === 0) && keyword === ''
  return (
    <>
      <div ref={setRefElement} className='w-full flex-1 border-solid'>
        <SearchInput
          onClearSearch={handleClearSearch}
          onChange={debounce(searchDebounce, debounceTime)}
          onSearch={handleSearch}
          onKeyDown={handleKeyDown}
          onBlur={e => props.onBlur?.(e)}
          prefixClassName='!left-[1px] !top-[1px] md:w-[3.25rem] border-none md:border-solid  md:!bg-separation-100 md:border-separation-800 !bg-transparent'
          {...props}
          className={cx(
            'border border-[transparent] focus:border-primary-900 focus:shadow-none hover:shadow-none hover:border-[transparent]',
            props?.className || '',
          )}
          search={keyword}
        />
      </div>
      <AnimatePresence initial={false}>
        {open && keyword !== '' && (
          <Portal>
            <Overlay className='!bg-transparent' />
            <div
              ref={setPopperElement}
              className={cx(
                'p-2 z-popover overflow-hidden',
                inheritParentWidth ? popoverClassName : '',
              )}
              style={{ ...styles.popper }}
              {...attributes.popper}
            >
              <motion.div
                initial={{ y: '-100%', opacity: 1 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: '-100%', opacity: 0 }}
                transition={{ duration: 0.3 }}
              >
                <Popover.Content
                  onClose={() => setState({ open: false })}
                  className={cx(
                    'min-w-full !p-0 shadow-dropdown',
                    isNotOpenSearch && 'bg-transparent border-none',
                  )}
                  style={{
                    width: widthPopover,
                  }}
                >
                  {renderContent()}
                </Popover.Content>
              </motion.div>
            </div>
          </Portal>
        )}
      </AnimatePresence>
    </>
  )
}
