import cx from 'classnames'
import { debounce } from 'lodash'
import { ChangeEvent, useEffect, useRef, useState } from 'react'

import { Button } from '../../components'
import { SearchInput } from '../../components/Forms/SearchInput'
import { parserHtmlToText } from '../../utils/functions'
import { PERMISSIONS, PERMISSIONS_VIEW } from '../../utils/permission'
import { ComponentPermisison } from '../Permission/ComponentPermission'
import { AccountSelect } from '../Select'
import { useUncategorizedCtx } from './Context'
import { FileFormatsSelect } from '../Select/Files/FileFormats'
import { useFileManagementCtx } from '../../pages/FilesOld/Context'

export const NoteHeader = () => {
  const refSearch = useRef<HTMLDivElement | null>(null)
  const [isSticky, setSticky] = useState(false)

  const { resultUncategorized, isSubPage } = useUncategorizedCtx()
  const total = resultUncategorized.length

  const handleScroll = () => {
    if (isSubPage) {
      return
    }
    const currentOffset = refSearch?.current?.getBoundingClientRect().top || 0
    if (currentOffset <= 0) {
      if (!isSticky) {
        setSticky(true)
      }
    } else {
      if (isSticky) {
        setSticky(false)
      }
    }
  }

  useEffect(() => {
    if (!isSubPage) {
      window.addEventListener('scroll', handleScroll, true)
    }
    return () =>
      !isSubPage
        ? window.removeEventListener('scroll', handleScroll)
        : undefined
  }, [isSticky])

  return (
    <>
      <div
        className={cx('h-[3.375rem]', !isSticky && '!h-0')}
        ref={refSearch}
      />
      <div
        className={cx(
          'flex items-center w-full shadow-card py-2',
          isSubPage
            ? 'py-2 border-b border-b-separation-800'
            : 'bg-white py-0 rounded-lg border border-separation-800',
          isSticky && 'fixed top-14 z-10 w-full max-w-2xl',
        )}
      >
        <NoteSearch />
        {total > 0 && <NoteNavigateResult />}
      </div>
    </>
  )
}

const NoteNavigateResult = () => {
  const { resultUncategorized, dispatch } = useUncategorizedCtx()
  const [currentItem, setCurrentItem] = useState(0)
  const total = resultUncategorized.length

  const handleClearSearch = () => {
    dispatch({
      resultUncategorized: [],
      keyword: '',
      scrollId: null,
    })
  }

  const handleNavigate = (newIndex: number) => {
    setCurrentItem(newIndex)
    dispatch({
      scrollId: resultUncategorized[newIndex].id,
    })
  }
  return (
    <div className='flex gap-2 items-center pr-2'>
      <div
        className='text-primary-900 font-medium cursor-pointer'
        onClick={handleClearSearch}
      >
        Clear
      </div>
      <div className='h-8 w-px bg-separation-800' />
      <div className='text-black-400'>{`${currentItem + 1}/${total}`}</div>
      {total > 1 && (
        <>
          <Button
            variant='ternary'
            className='w-6 !h-6 rounded-md'
            innerClassName='!p-0'
            disabled={currentItem === total - 1}
            onClick={() => handleNavigate(currentItem + 1)}
          >
            <span className='font-icon-arrow_down' />
          </Button>
          <Button
            variant='ternary'
            className='w-6 !h-6 rounded-md'
            innerClassName='!p-0'
            disabled={currentItem === 0}
            onClick={() => handleNavigate(currentItem - 1)}
          >
            <span className='font-icon-arrow_up text-[0.375rem]' />
          </Button>
        </>
      )}
    </div>
  )
}

const NoteSearch = () => {
  const {
    dispatch,
    listUncategorized,
    keyword,
    isSubPage,
    filterAccount,
    filterFileFormat,
  } = useUncategorizedCtx()

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = (e.target as HTMLInputElement).value
    const resultUncategorized =
      value !== ''
        ? listUncategorized
            .filter(item =>
              parserHtmlToText(item.message)
                .toLowerCase()
                .includes(value.toLowerCase()),
            )
            .filter(item =>
              filterAccount?.value
                ? item.account_id === filterAccount?.value
                : item.account_id,
            )
        : []
    dispatch({
      keyword: value,
      resultUncategorized,
      scrollId: resultUncategorized[0].id,
    })
  }

  return (
    <>
      <div
        className={cx('flex-1', !isSubPage ? 'flex gap-2 items-center' : '')}
      >
        <div className='flex-1'>
          <SearchInput
            className={cx('!border-none !shadow-none', !isSubPage && 'pl-16')}
            prefixIcon={<span className='font-icon-search' />}
            prefixClassName={cx(
              isSubPage
                ? '!bg-transparent !border-none'
                : 'bg-separation-100 -top-[7px] -bottom-[7px] -left-px text-white-400 px-3 w-14',
            )}
            suffix={null}
            placeholder='Search message'
            onChange={debounce(handleChange, 200)}
            search={keyword}
          />
        </div>
        {!isSubPage && (
          <ComponentPermisison
            name={PERMISSIONS.ACCOUNT}
            type={PERMISSIONS_VIEW}
          >
            <>
              <div className='md:w-px md:h-10 w-full h-px bg-separation-800' />
              <AccountSelect
                selected={filterAccount}
                className='select-search-inline w-52'
                size='default'
                onSelect={v => {
                  dispatch({
                    filterAccount: v,
                    resultUncategorized: [],
                    keyword: '',
                    scrollId: null,
                  })
                }}
                placeholder='All Account'
              />
              <FileFormatsSelect
                className='md:w-48'
                size='large'
                selected={filterFileFormat}
                onSelect={(v: string | null) => {
                  dispatch({
                    filterFileFormat: v,
                  })
                }}
              />
            </>
          </ComponentPermisison>
        )}
      </div>
    </>
  )
}
