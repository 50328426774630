import { useLoadScript } from '@react-google-maps/api'
import { AxiosResponse } from 'axios'
import { Suspense, useEffect, useState } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import shallow from 'zustand/shallow'

import { Spinner } from '../../components/Spinner'
import { useAsync } from '../../hooks'
import { AuthUser } from '../../services/auth'
import { instance } from '../../services/client'
import { getConfigs } from '../../services/config'
import {
  getCalendarsSetting,
  getCompanySettings,
  getSettings,
} from '../../services/settings'
import useStore, { Store } from '../../store'
import {
  isHasPermission,
  PERMISSIONS,
  PERMISSIONS_VIEW,
} from '../../utils/permission'
import { TopMenu } from './TopMenu'

const mapState = (state: Store) => ({
  auth: state.auth.currentUser,
  accounting: state.settings.accounting,
  setAuthUser: state.auth.setAuthUser,
  setSettings: state.settings.setSettings,
  setConfigs: state.configs.setConfigs,
  setCalendars: state.calendars.setCalendar,
})

const libraries = ['places']

export const MainLayout = () => {
  useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_PLACE_KEY!, // @ts-ignore
    libraries,
  })
  // const { pathname } = useLocation()
  const { setAuthUser, auth, setSettings, setConfigs, setCalendars } = useStore(
    mapState,
    shallow,
  )
  const [isLoading, setLoading] = useState(true)
  const instanceAsync = useAsync<{ data: AxiosResponse<AuthUser> }>({
    status: 'pending',
  })

  const getMoreData = async (dataAuth: AuthUser | null) => {
    const canViewSetting = isHasPermission(
      `${PERMISSIONS_VIEW} ${PERMISSIONS.COMPANY}`,
      dataAuth?.can || [],
    )
    const canViewCompanySetting = isHasPermission(
      `${PERMISSIONS_VIEW} ${PERMISSIONS.COMPANY}`,
      dataAuth?.can || [],
    )
    const canViewCalendar = isHasPermission(
      `${PERMISSIONS_VIEW} ${PERMISSIONS.CALENDAR}`,
      dataAuth?.can || [],
    )
    const [settingsPromise, companyPromise, configsPromise, calendarsPromise] =
      await Promise.allSettled([
        canViewSetting ? getSettings() : null,
        canViewCompanySetting ? getCompanySettings() : null,
        getConfigs(),
        canViewCalendar ? getCalendarsSetting() : null,
      ])

    const settings =
      settingsPromise.status === 'fulfilled' ? settingsPromise.value : null
    const company =
      companyPromise.status === 'fulfilled' ? companyPromise.value : null
    const configs =
      configsPromise.status === 'fulfilled' ? configsPromise.value : null
    const calendars =
      calendarsPromise.status === 'fulfilled' ? calendarsPromise.value : null

    const {
      conditions = [],
      warranty_terms = [],
      expense_categories = [],
      ...project
    } = settings?.data?.data?.project || {}

    const quote = {
      ...(settings?.data?.data?.quote || {}),
      conditions,
      warranty_terms,
    }

    const expense = {
      expense_categories,
    }

    setSettings({
      ...(settings?.data?.data || {}),
      quote,
      project,
      expense,
      company: company ? company.data.data : null,
    })

    if (configs && configs.data && configs.data.data) {
      setConfigs(configs.data.data)
    }

    if (calendars && calendars.data && calendars.data.data) {
      setCalendars(calendars.data.data)
    }

    setLoading(false)
  }
  const init = async () => {
    if (!auth) {
      instanceAsync
        .execute(instance.get('/api/user'))
        .then(async data => {
          setAuthUser(data.data.data)
          await getMoreData(data.data.data)
        })
        .catch(() => {
          localStorage.setItem('APP/LAST_PATH', window.location.href)
          console.error('Unauthenticated')
          setLoading(false)
        })
    } else {
      setLoading(false)
    }
  }

  useEffect(() => {
    init()
  }, [])

  if (isLoading) {
    return <Spinner className='w-screen h-screen overflow-hidden' />
  }

  if (!auth) {
    return <Navigate to='/login' />
  }

  return (
    <div className='w-full h-screen flex flex-col'>
      <div className='app__top-menu flex-0 h-[3.25rem] fixed top-0 left-0 right-0 z-[2999]'>
        <TopMenu />
      </div>
      <div className='flex-1 pt-[3.25rem]'>
        <Suspense fallback={<div />}>
          <Outlet />
        </Suspense>
      </div>
    </div>
  )
}

export default MainLayout
