import { useEffect, useState } from 'react'

import { Avatar } from '../../../components/Avatar'
import { AsyncSelect } from '../../../components/Forms/AsyncSelect'
import { Props as SelectProps } from '../../../components/Forms/Select'
import { PAGE_SIZE_DROPDOWN } from '../../../services/client'
import {
  getPlanSets,
  IPlanSet,
  IPlanSetFilters,
} from '../../../services/planSets'
import { OptionValue } from '../../../utils/form'

interface Props extends Omit<SelectProps, 'options' | 'onSelect'> {
  selected?: OptionValue | null
  onSelect?: (value: OptionValue | null, valueFull: IPlanSet | null) => void
  className?: string
  filter?: IPlanSetFilters | null
  fetchOnFirst?: boolean
}
export const PlanSetsSelect = ({
  selected = null,
  className = '',
  onSelect,
  filter = null,
  ...props
}: Props) => {
  const [currentSelected, setSelected] = useState<OptionValue | null>(selected)
  const handleGetPlanSets = async ({ search = '', page = 1 } = {}) => {
    try {
      const { data } = await getPlanSets({
        search,
        currentPage: page,
        pageSize: PAGE_SIZE_DROPDOWN,
        ...filter,
      })
      return { data: data.data, totalPage: data.meta.last_page }
    } catch {
      return { data: [], totalPage: 1 }
    }
  }

  const handleSelect = (item: any, itemFull: IPlanSet) => {
    const value = !!item
      ? {
          value: +item.value,
          label: item.label,
        }
      : null
    setSelected(value)
    onSelect?.(value, itemFull)
  }

  const mapOptions = (data: IPlanSet[]) => {
    return data.map(item => ({
      value: item.id,
      label: (
        <div className='flex items-center gap-2'>
          <Avatar type='thumbnail' src={item.files?.[0]?.preview_url} />
          <div className='text-body flex flex-col flex-1 w-full'>
            {item.name}
          </div>
        </div>
      ),
      displayLabel: item.name,
    }))
  }

  useEffect(() => {
    if (
      selected !== currentSelected ||
      selected?.label !== currentSelected?.label ||
      selected?.value !== currentSelected?.value
    ) {
      setSelected(selected)
    }
  }, [selected])

  return (
    <AsyncSelect
      placeholder='All Plan Sets'
      labelInValue
      allowClear
      dropdownMatchSelectWidth={300}
      {...props}
      value={
        currentSelected?.value
          ? { value: currentSelected.value + '', label: currentSelected.label }
          : null
      }
      onChange={handleSelect}
      className={className}
      fetcher={handleGetPlanSets}
      dropdownClassName='w-full'
      optionLabelProp='displayLabel'
      options={mapOptions}
    />
  )
}
