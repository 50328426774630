import { ModalProps } from '../../../../components'
import { ModalDelete } from '../../../../components/Modal/Delete'
import { useAsync } from '../../../../hooks'
import { deleteNote } from '../../../../services/notes'

interface Props extends Omit<ModalProps, 'children'> {
  onSuccess?: () => void
  onClose: () => void
  noteId: number
}

export const ModalDeleteNote = ({
  onClose,
  onSuccess,
  noteId,
  ...props
}: Props) => {
  const { execute, isLoading } = useAsync({
    showNotifOnError: true,
  })

  const handleDelete = async () => {
    await execute(deleteNote(noteId))
    onClose()
    onSuccess?.()
  }

  return (
    <ModalDelete
      title='Delete this note?'
      onClose={onClose}
      onCancel={onClose}
      onSubmit={handleDelete}
      loading={isLoading}
      {...props}
    />
  )
}
