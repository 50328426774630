import { OptionValue } from '../utils/form'
import { generateQueryOfEndPoint } from '../utils/functions'
import { IAccount } from './accounts'
import client, { IFilter, PAGE_SIZE, PAGE_SIZE_RECENT } from './client'
import { IContact } from './contacts'
import { IFile } from './files'
import { INote } from './notes'
import { IQuote } from './quotes'
import { ITag } from './tags'

export interface IOpportunity {
  id: number
  account_id: number
  account: IAccount
  due_date?: Date | string | null
  name: string | null
  stage?: string | null
  source?: string | null
  scope?: string | null
  probability?: number | null
  priority?: number | null
  est_man_hours?: number | null
  est_value?: number | null
  created_at: string
  allNotes?: INote[] | null
  notes?: INote[] | null
  allFiles?: IFile[] | null
  quotes?: IQuote[] | null
  contact?: IContact | null
  // contact_id?: number | null
  street_address?: string | null
  street_address_2?: string | null
  city?: string | null
  state?: string | null
  zip?: string | null
  country?: string | null
  tags?: ITag[] | null
  inbound_email_address?: string | null
}
export interface IOpportunityPayload
  extends Omit<
    IOpportunity,
    'id' | 'created_at' | 'account' | 'account_id' | 'contact' | 'tags'
  > {
  account_id?: number | null
  lead_id?: number | null
  contact?: number | null
  tags?: number[] | null
}

export interface IFilterOpportunities extends IFilter {
  filterAccount?: OptionValue | null
}
export const getOpportunities = async ({
  search = '',
  sortBy = 'created_at',
  sortDirection = 'desc',
  pageSize = PAGE_SIZE,
  currentPage = 1,
  filterAccount = null,
}: IFilterOpportunities) => {
  const sort = `${sortDirection === 'desc' ? '-' : ''}${sortBy}`
  const endPoint = generateQueryOfEndPoint('/api/opportunities', {
    sort,
    'filter[search]': search,
    limit: pageSize,
    page: currentPage,
    'filter[account_id]': filterAccount?.value || null,
  })
  return client.get(`${endPoint}include=account,quotes,contact,tags`)
}

export const getRecentOpportunities = async (props: IFilterOpportunities) => {
  return getOpportunities({
    ...props,
    sortBy: 'recent',
    sortDirection: 'asc',
    pageSize: PAGE_SIZE_RECENT,
    currentPage: 1,
  })
}

export const createOpportunity = async (payload: IOpportunityPayload) => {
  return client.post('/api/opportunities', payload)
}

export const editOpportunity = async (
  id: number,
  payload: Partial<IOpportunityPayload>,
) => {
  return client.put(`api/opportunities/${id}`, payload)
}

export const deleteOpportunity = async (id: number) => {
  return client.delete(`/api/opportunities/${id}`)
}

export const detailOpportunity = async (id: number) => {
  return client.get(
    `api/opportunities/${id}?include=account,notes,contact,tags,inboundEmailAddress`,
  )
}
