import cx from 'classnames'

import { AsyncSelect } from '../../../components/Forms/AsyncSelect'
import { Props as SelectProps } from '../../../components/Forms/Select'
import { getRoles, IUserRole } from '../../../services/roles'
import { OptionValue } from '../../../utils/form'

interface Props extends Omit<SelectProps, 'options'> {
  selected?: OptionValue | null
  onSelect?: (option: OptionValue | null) => void
  className?: string
}
export const RoleSelect = ({
  selected = null,
  className = '',
  onSelect,
  ...props
}: Props) => {
  const handleGetRoles = async ({ search = '', page = 1 } = {}) => {
    try {
      const { data } = await getRoles({
        search,
        currentPage: page,
        sortBy: 'name',
        sortDirection: 'asc',
      })
      return { data: data.data, totalPage: data?.meta?.last_page || 1 }
    } catch {
      return { data: [], totalPage: 1 }
    }
  }

  const mapRoles = (roles: IUserRole[]) => {
    return roles.map(({ id, name }) => ({
      value: id,
      label: name,
    }))
  }
  const handleSelect = (item: any) => {
    const value = !!item
      ? {
          value: item.value,
          label:
            typeof item.label === 'string'
              ? item.label
              : item.label.props.children,
        }
      : null
    onSelect?.(value)
  }
  return (
    <AsyncSelect
      {...props}
      labelInValue
      allowClear
      value={selected}
      onChange={handleSelect}
      className={cx('capitalize', className)}
      fetcher={handleGetRoles}
      options={mapRoles}
      dropdownClassName='w-full'
    />
  )
}
