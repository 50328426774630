import { ReactNode } from 'react'

import { WarningCircle } from '../../icons'
import { Button } from '../Button'
import { Modal, ModalProps } from '.'

interface Props extends Omit<ModalProps, 'children'> {
  onClose: () => void
  onCancel?: () => void
  onSubmit?: () => void
  loading?: boolean
  title?: ReactNode
  confirmText?: ReactNode
  cancelText?: ReactNode
  submitText?: ReactNode
  modalIcon?: ReactNode
}

export const ModalConfirm = ({
  onClose,
  onCancel,
  onSubmit,
  loading,
  title,
  confirmText,
  submitText,
  cancelText,
  modalIcon,
  ...props
}: Props) => {
  return (
    <Modal {...props} className='w-[27rem]' onClose={onClose}>
      <div className='flex gap-3 relative'>
        <span
          className='font-icon-close absolute text-black-400 right-0 cursor-pointer'
          onClick={onClose}
        />
        {modalIcon || (
          <div className='w-10 h-10 bg-orange-300 rounded-full flex items-center justify-center'>
            <WarningCircle className='text-orange-900' />
          </div>
        )}
        <div className='flex-1'>
          {title && (
            <div className='text-base font-medium text-black-800 mb-3 whitespace-pre-wrap pr-5'>
              {title}
            </div>
          )}
          {confirmText && (
            <div className='text-body text-black-400 mb-6'>{confirmText}</div>
          )}
          <div className='flex justify-end gap-2'>
            {onCancel && (
              <Button variant='ternary' onClick={onCancel} disabled={loading}>
                {cancelText || 'Cancel'}
              </Button>
            )}
            {onSubmit && (
              <Button
                variant='primary'
                onClick={onSubmit}
                className='bg-red-900'
                disabled={loading}
              >
                {submitText || 'Save'}
              </Button>
            )}
          </div>
        </div>
      </div>
    </Modal>
  )
}
