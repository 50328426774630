import { isArray } from 'lodash'

import {
  Audio,
  Cad,
  Compressed,
  Excel,
  Image,
  Other,
  Pdf,
  Powerpoint,
  Video,
  Word,
} from '../icons'
import { initFilter } from '../services/client'
import { IFile, IFilterFile, IFolder, TGroupFiles } from '../services/files'
import { compareFilterPagination, leadZero } from './functions'

export const MAX_FILE_SIZE =
  (process.env.REACT_APP_MAXIMIUM_SIZE
    ? +process.env.REACT_APP_MAXIMIUM_SIZE
    : 100) *
  1024 *
  1024

export const MINETYPE_IMAGE_JPEG = 'image/jpeg'
export const MINETYPE_IMAGE_JPG = 'image/jpg'
export const MINETYPE_IMAGE_SVG = 'image/svg+xml'
export const MINETYPE_IMAGE_GIF = 'image/gif'
export const MINETYPE_IMAGE_PNG = 'image/png'
export const MINETYPE_IMAGE_PSD = 'image/vnd.adobe.photoshop'
export const MINETYPE_IMAGE_WEBP = 'image/webp'
export const MINETYPE_VIDEO_MP4 = 'video/mp4'
export const MINETYPE_VIDEO_MPEG = 'video/mpeg'
export const MINETYPE_VIDEO_WEBM = 'video/webm'
export const MINETYPE_AUDIO_MP3 = 'audio/mpeg'
export const MINETYPE_AUDIO_OGG = 'audio/ogg'
export const MINETYPE_AUDIO_WAV = 'audio/wav'
export const MINETYPE_AUDIO_XWAV = 'audio/x-wav'
export const MINETYPE_WORD_DOC = 'application/msword'
export const MINETYPE_WORD_DOCX =
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
export const MINETYPE_EXCEL_XLS = 'application/vnd.ms-excel'
export const MINETYPE_EXCEL_XLSX =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
export const MINETYPE_PDF = 'application/pdf'
export const MINETYPE_CAD_DWG = 'image/x-dwg'
export const MINETYPE_CAD_DXF = 'image/x-dxf'
export const MINETYPE_CAD_DWF = 'drawing/x-dwf'
export const MINETYPE_POWERPOINT_PPTX =
  'application/vnd.openxmlformats-officedocument.presentationml.presentation'
export const MINETYPE_POWERPOINT_PPT = 'application/vnd.ms-powerpoint'
export const MINETYPE_COMPRESSED_ZIP = 'application/zip'
export const MINETYPE_COMPRESSED_RAR = 'application/x-rar'
export const MINETYPE_COMPRESSED_RAR_VN = 'application/vnd.rar'
export const MINETYPE_COMPRESSED_7Z = 'application/x-7z-compressed'
export const byteToSize = (byte: number, decimals = 2) => {
  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  if (!byte) {
    return `0 ${sizes[0]}`
  }

  const i = Math.floor(Math.log(byte) / Math.log(k))
  return `${parseFloat((byte / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export const getExtFile = (filename: string) => filename.split('.').pop()
export const isFilesMediaType = (type: string) => {
  if (type.includes('image') || type.includes('video')) {
    return true
  }
  return false
}

export const isFilePdf = (mineType: string) => mineType === MINETYPE_PDF

export const groupFileByType = (files: (File | IFile)[]) => {
  let result: TGroupFiles = {
    medias: [],
    files: [],
  }
  files.forEach((item: File | IFile, index: number) => {
    const isRawFile = 'type' in item
    const type = isRawFile ? item.type : item.mime_type
    const key = isFilesMediaType(type) ? 'medias' : 'files'
    result = {
      ...result,
      [key]: result[key].concat({
        id: index,
        file: {
          [isRawFile ? 'raw' : 'uploaded']: item,
        },
      }),
    }
  })
  return result
}

export const covertDurationToMinutes = (duration: number) => {
  const ceilDuration = Math.ceil(duration)
  if (ceilDuration < 60) {
    return `00:${leadZero(ceilDuration)}`
  }
  const minutes = Math.floor(ceilDuration / 60)
  const seconds = ceilDuration - 60 * minutes
  return `${leadZero(minutes)}:${leadZero(seconds)}`
}

export const ACCEPTED_FILE_IMAGE = {
  [MINETYPE_IMAGE_JPG]: ['.jpg'],
  [MINETYPE_IMAGE_SVG]: ['.svg'],
  [MINETYPE_IMAGE_GIF]: ['.gif'],
  [MINETYPE_IMAGE_JPEG]: ['.jpeg'],
  [MINETYPE_IMAGE_WEBP]: ['.webp'],
  [MINETYPE_IMAGE_PNG]: ['.png'],
  [MINETYPE_IMAGE_PSD]: ['.psd'],
}
export const ACCEPTED_FILE_VIDEO = {
  [MINETYPE_VIDEO_MP4]: ['.mp4'],
  [MINETYPE_VIDEO_MPEG]: ['.mpeg'],
  [MINETYPE_VIDEO_WEBM]: ['.webm'],
}
export const ACCEPTED_FILE_AUDIO = {
  [MINETYPE_AUDIO_MP3]: ['.mp3'],
  [MINETYPE_AUDIO_OGG]: ['.ogg'],
  [MINETYPE_AUDIO_WAV]: ['.wav'],
  [MINETYPE_AUDIO_XWAV]: ['.wav'],
}
export const ACCEPTED_FILE_MEDIA = {
  ...ACCEPTED_FILE_IMAGE,
  ...ACCEPTED_FILE_VIDEO,
  ...ACCEPTED_FILE_AUDIO,
}
export const ACCEPTED_FILE_DOC = {
  [MINETYPE_PDF]: ['.pdf'],
  [MINETYPE_CAD_DWG]: ['.dwg'],
  [MINETYPE_CAD_DXF]: ['.dxf'],
  [MINETYPE_CAD_DWF]: ['.dwf'],
  [MINETYPE_WORD_DOC]: ['.doc'],
  [MINETYPE_WORD_DOCX]: ['.docx'],
  [MINETYPE_EXCEL_XLS]: ['.xls'],
  [MINETYPE_EXCEL_XLSX]: ['.xlsx'],
  [MINETYPE_POWERPOINT_PPTX]: ['.pptx'],
  [MINETYPE_POWERPOINT_PPT]: ['.ppt'],
}
export const ACCEPTED_FILE_COMPRESSED = {
  [MINETYPE_COMPRESSED_ZIP]: ['.zip'],
  [MINETYPE_COMPRESSED_RAR]: ['.rar'],
  [MINETYPE_COMPRESSED_RAR_VN]: ['.rar'],
  [MINETYPE_COMPRESSED_7Z]: ['.7z'],
}

export const ACCEPTED_FILE_ALL = {
  ...ACCEPTED_FILE_DOC,
  ...ACCEPTED_FILE_MEDIA,
  ...ACCEPTED_FILE_COMPRESSED,
}

export const ACCEPTED_FILE_ALL_STRING = Object.keys(ACCEPTED_FILE_ALL).join(',')

export const EXTENSTIONS_IMAGE_TYPE = Object.values(ACCEPTED_FILE_IMAGE)
  .map(item => item[0].slice(1))
  .join(',')
export const EXTENSTIONS_VIDEO_TYPE = Object.values(ACCEPTED_FILE_VIDEO)
  .map(item => item[0].slice(1))
  .join(',')
export const EXTENSTIONS_AUDIO_TYPE = Object.values(ACCEPTED_FILE_AUDIO)
  .map(item => item[0].slice(1))
  .join(',')
export const EXTENSTION_PDF_TYPE = 'pdf'
export const EXTENSTION_DOC_TYPE = 'doc,docx'
export const EXTENSTION_EXCEL_TYPE = 'xls,xlsx'
export const EXTENSTION_POWERPOINT_TYPE = 'ppt,pptx'
export const EXTENSTION_CAD_TYPE = 'dwg,dxf,dwf'
export const EXTENSTION_COMPRESSED_TYPE = 'zip,rar,7z'

export const FILE_FORMAT_SELECT = [
  { name: 'cad', label: 'Cads', value: EXTENSTION_CAD_TYPE },
  {
    name: 'compressed',
    label: 'Compressed',
    value: EXTENSTION_COMPRESSED_TYPE,
  },
  { name: 'excel', label: 'Excels', value: EXTENSTION_EXCEL_TYPE },
  { name: 'image', label: 'Images', value: `${EXTENSTIONS_IMAGE_TYPE}` },
  { name: 'audio', label: 'Audio', value: EXTENSTIONS_AUDIO_TYPE },
  { name: 'pdf', label: 'Pdfs', value: EXTENSTION_PDF_TYPE },
  {
    name: 'powerpoint',
    label: 'Powerpoints',
    value: EXTENSTION_POWERPOINT_TYPE,
  },
  { name: 'video', label: 'Videos', value: EXTENSTIONS_VIDEO_TYPE },
  { name: 'word', label: 'Words', value: EXTENSTION_DOC_TYPE },
  // { name: 'other', label: 'Others' },
]

export const FILE_FORMAT_ICON: Record<string, JSX.Element> = {
  cad: <Cad />,
  compressed: <Compressed />,
  excel: <Excel />,
  image: <Image />,
  audio: <Audio />,
  pdf: <Pdf />,
  powerpoint: <Powerpoint />,
  video: <Video />,
  word: <Word />,
  other: <Other />,
}

export const isAudioFile = (mime_type: string) => mime_type.includes('audio/')
export const isVideoFile = (mime_type: string) => mime_type.includes('video/')
export const isImageFile = (mime_type: string) => mime_type.includes('image/')
export const getFileType = (mime_type?: string) => {  
  if (!mime_type) {
    return 'other'
  }
  if (isAudioFile(mime_type)) {
    return 'audio'
  }
  if (isVideoFile(mime_type)) {
    return 'video'
  }
  if (isImageFile(mime_type)) {
    return 'image'
  }
  switch (mime_type) {
    case MINETYPE_IMAGE_JPEG:
    case MINETYPE_IMAGE_JPG:
    case MINETYPE_IMAGE_SVG:
    case MINETYPE_IMAGE_GIF:
    case MINETYPE_IMAGE_WEBP:
    case MINETYPE_IMAGE_PNG:
    case MINETYPE_IMAGE_PSD: {
      return 'image'
    }
    case MINETYPE_VIDEO_MP4:
    case MINETYPE_VIDEO_MPEG:
    case MINETYPE_VIDEO_WEBM: {
      return 'video'
    }
    case MINETYPE_AUDIO_MP3:
    case MINETYPE_AUDIO_OGG:
    case MINETYPE_AUDIO_WAV:
    case MINETYPE_AUDIO_XWAV: {
      return 'audio'
    }
    case MINETYPE_WORD_DOC:
    case MINETYPE_WORD_DOCX: {
      return 'word'
    }
    case MINETYPE_EXCEL_XLS:
    case MINETYPE_EXCEL_XLSX: {
      return 'excel'
    }
    case MINETYPE_PDF: {
      return 'pdf'
    }
    case MINETYPE_CAD_DWF:
    case MINETYPE_CAD_DWG:
    case MINETYPE_CAD_DXF: {
      return 'cad'
    }
    case MINETYPE_COMPRESSED_ZIP:
    case MINETYPE_COMPRESSED_RAR:
    case MINETYPE_COMPRESSED_RAR_VN:
    case MINETYPE_COMPRESSED_7Z: {
      return 'compressed'
    }
    default: {
      return 'other'
    }
  }
}

export const FOLDER_ROOT: IFolder = {
  id: 0,
  name: 'Root',
  parent_folder_id: null,
  children: [],
  created_at: new Date()
}

export const initFilterFile: IFilterFile = {
  ...initFilter,  
  search: '',  
  filterAccount: null,
  filterResourceId: null,
  filterResourceType: null,
  filterFileFormat: null,
  filterFileRelate: null,
  filterFavorited: null,
  filterCollection: null,  
}

export const hasFiltersFile = (filter: IFilterFile) =>
  Boolean(filter.search) ||
  Boolean(filter.filterFileRelate) ||
  Boolean(filter.filterAccount?.value) ||
  Boolean(filter.filterFileFormat) ||
  Boolean(filter.filterFavorited) ||
  Boolean(filter.filterAllFileByAccountId) ||
  compareFilterPagination(filter, initFilterFile)


export const getFileByCollectionName = (
  files: IFile[],
  collectionName = 'default',
) => {
  if (files.length === 0) {
    return []
  }
  return files.filter(item => item.collection_name === collectionName)
}

export const getListRecentSelected = () => {
  try {
    const prevRecent = localStorage.getItem('IMAGE_RECENT_SELECTED')
    if (prevRecent) {
      const parse = JSON.parse(prevRecent)
      if (isArray(parse)) {
        return parse
      }
    }
    return []
  } catch (error) {
    return []
  }
}
