import cx from 'classnames'
import { motion } from 'framer-motion'
import { useState } from 'react'
import { DropTargetMonitor, useDrop } from 'react-dnd'
import shallow from 'zustand/shallow'

import { Button, Modal, ModalProps } from '../../../../components'
import {
  bussinessNavigation,
  INavigation,
  managementNavigation,
  otherNavigation,
  salePipeLineNavigation,
} from '../../../../configs/navigations'
import { useDragMenu } from '../../../../hooks/useDrag'
import useStore, { Store } from '../../../../store'
import {
  DRAG_TYPE_EDIT_MENU,
  DRAG_TYPE_MENU,
  IItemDrag,
} from '../../../../utils/menus'
import { checkMenuPermisison, PERMISSIONS } from '../../../../utils/permission'
import { TopMenuCustomize } from './TopMenu'

interface Props extends Omit<ModalProps, 'children'> {
  onSuccess?: () => void
  onClose: () => void
}

const mapStore = (store: Store) => ({
  currentMenu: store.menus.currentMenu,
  permissions: store.auth.currentUser?.can,
  setMenu: store.menus.setMenu,
})

export const ModalEditMenus = ({ onClose, ...props }: Props) => {
  const { currentMenu, permissions, setMenu } = useStore(mapStore, shallow)
  const [listMenus, setListMenus] = useState(currentMenu || [])
  const handelDropEnd = (item: IItemDrag) => {
    const newListItem = listMenus.filter(c => c.key !== item.id)
    setListMenus(newListItem)
  }
  const [{ isOver }, drop] = useDrop({
    accept: DRAG_TYPE_MENU,
    drop: handelDropEnd,
    collect: (monitor: DropTargetMonitor) => ({
      isOver: monitor.isOver(),
    }),
  })

  const handleSave = () => {
    setMenu(listMenus)
    onClose()
  }

  const renderMenuItem = (item: INavigation) => {
    const hasOnCurrentMenu = listMenus.some(c => c.label === item.label)
    return (
      <MenuItem
        item={item}
        key={`${item.key}-${item.label}`}
        disabled={hasOnCurrentMenu}
        lastIndex={listMenus.length}
      />
    )
  }

  const salePipelineFilter = salePipeLineNavigation.filter(item =>
    checkMenuPermisison(PERMISSIONS[item.key], permissions),
  )
  const businessFilter = bussinessNavigation.filter(item =>
    checkMenuPermisison(PERMISSIONS[item.key], permissions),
  )
  const managementFilter = managementNavigation.filter(item =>
    checkMenuPermisison(PERMISSIONS[item.key], permissions),
  )
  const otherFilter = otherNavigation.filter(item =>
    checkMenuPermisison(PERMISSIONS[item.key], permissions),
  )

  return (
    <Modal {...props} className='max-w-[40rem] w-full p-0' onClose={onClose}>
      <div className='fixed top-0 left-0 right-0'>
        <TopMenuCustomize menus={listMenus} onUpdateMenus={setListMenus} />
      </div>
      <div className='flex gap-3 relative flex-col mt-4'>
        <span
          className='cursor-pointer font-icon-close text-black-400 absolute right-2 -top-3 z-10 w-10 h-10 flex justify-center items-center'
          onClick={onClose}
        />
        <div className='flex-1'>
          <div className='mb-4 text-xl font-medium px-5'>Customize Menu</div>
          <div className='text-base mb-4 font-medium px-5'>
            Drag & drop your favorite items into the menu bar
          </div>
          <div
            className={cx(
              'grid grid-cols-1 sm:grid-cols-2 gap-6 sm:gap-x-6 text-body px-5',
              'h-auto max-h-[calc(100vh_-_20rem)] overflow-y-scroll custom-scrollbar',
              isOver && 'opacity-50',
            )}
            ref={drop}
          >
            <div>
              {salePipelineFilter.length > 0 && (
                <div className='mb-6'>
                  <div className='font-semibold uppercase text-black-400 mb-2'>
                    SALE PIPELINE & DASHBOARD
                  </div>
                  <div className='flex flex-col gap-2 bg-separation-200 p-3 rounded-lg'>
                    {salePipelineFilter.map(renderMenuItem)}
                  </div>
                </div>
              )}
              {businessFilter.length > 0 && (
                <div>
                  <div className='font-semibold uppercase text-black-400 mb-2'>
                    BUSINESS & ACCOUNTING
                  </div>
                  <div className='flex flex-col gap-2 bg-separation-200 p-3 rounded-lg'>
                    {businessFilter.map(renderMenuItem)}
                  </div>
                </div>
              )}
            </div>
            <div>
              {managementFilter.length > 0 && (
                <div className='mb-6'>
                  <div className='font-semibold uppercase text-black-400 mb-2'>
                    WORK MANAGEMENT
                  </div>
                  <div className='flex flex-col gap-2 bg-separation-200 p-3 rounded-lg'>
                    {managementFilter.map(renderMenuItem)}
                  </div>
                </div>
              )}
              {otherFilter.length > 0 && (
                <div>
                  <div className='font-semibold uppercase text-black-400 mb-2'>
                    OTHERS
                  </div>
                  <div className='flex flex-col gap-2 bg-separation-200 p-3 rounded-lg'>
                    {otherFilter.map(renderMenuItem)}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className='border-t border-t-separation-800 h-[4.5rem] md:h-[3.5rem] px-5 justify-between flex items-center z-20'>
          <Button
            variant='ternary'
            onClick={onClose}
            asLink
            innerClassName='!px-3'
          >
            Cancel
          </Button>
          <Button variant='primary' innerClassName='px-8' onClick={handleSave}>
            Save
          </Button>
        </div>
      </div>
    </Modal>
  )
}

const MenuItem = ({
  item,
  disabled,
  lastIndex,
}: {
  item: INavigation
  disabled: boolean
  lastIndex: number
}) => {
  const { isDragging, drag } = useDragMenu({
    dragType: DRAG_TYPE_EDIT_MENU,
    item: { id: item.key, index: lastIndex },
  })
  return (
    <motion.div
      className={cx(
        'flex gap-1 items-center rounded-lg bg-white px-3 py-[0.875rem] text-black-400 cursor-pointer ',
        disabled
          ? 'bg-separation-200 cursor-default opacity-50 pointer-events-none'
          : 'hover:bg-primary-900 hover:text-white group cursor-move',
        isDragging && 'bg-white',
      )}
      key={item.key}
      ref={drag}
      draggable={!disabled}
    >
      <span className='w-5 flex items-center text-[1rem]'>{item.Icon}</span>
      <span className='text-black-800 leading-none group-hover:text-white'>
        {item.label}
      </span>
    </motion.div>
  )
}
