import { filter } from 'lodash'

import { AsyncSelect } from '../../../components/Forms/AsyncSelect'
import { Props as SelectProps } from '../../../components/Forms/Select'
import { PAGE_SIZE_DROPDOWN } from '../../../services/client'
import { getUsers, IUser } from '../../../services/users'
import { OptionValue } from '../../../utils/form'

interface Props extends Omit<SelectProps, 'options' | 'onSelect'> {
  selected?: OptionValue | null
  onSelect?: (option: OptionValue | null, itemFull?: IUser | null) => void
  className?: string
  excludesValues?: number[]
}
export const UserSelect = ({
  selected = null,
  className = '',
  onSelect,
  excludesValues = [],
  ...props
}: Props) => {
  const handleGetUsers = async ({ search = '', page = 1 } = {}) => {
    try {
      const { data } = await getUsers({
        search,
        currentPage: page,
        sortBy: 'first_name',
        sortDirection: 'asc',
        pageSize: PAGE_SIZE_DROPDOWN,
      })
      return { data: data.data, totalPage: data.meta.last_page }
    } catch {
      return { data: [], totalPage: 1 }
    }
  }

  const mapUsers = (users: IUser[]) => {
    return filter(users, item => !excludesValues.includes(item.id)).map(
      ({ id, name }) => ({
        value: id,
        label: name,
      }),
    )
  }
  const handleSelect = (item: any, itemFull: IUser) => {
    const value = !!item
      ? {
          value: item.value,
          label:
            typeof item.label === 'string'
              ? item.label
              : item.label.props.children,
        }
      : null
    onSelect?.(value, itemFull)
  }
  return (
    <AsyncSelect
      placeholder='All Users'
      {...props}
      labelInValue
      allowClear
      onChange={handleSelect}
      fetcher={handleGetUsers}
      options={mapUsers}
      dropdownClassName='w-full'
      value={selected}
      className={className}
    />
  )
}
