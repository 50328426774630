import cx from 'classnames'
import { useState } from 'react'

import { Button, Input, TextArea, Tooltip } from '../../../../components'
import { ErrorCircle, SuccesCircle } from '../../../../icons'
import { FILE_FORMAT_ICON, getFileType } from '../../../../utils/files'
import { OptionValueTag } from '../../../../utils/form'
import {
  FILE_STATUS_FAIL,
  FILE_STATUS_INPROGRESS,
  FILE_STATUS_SUCCESS,
  TDataEditFile,
  TLocalFile,
} from '.'
import { FileTagsSelect } from './Tags'
import { UploadedThumbnail } from './Thumbnail'

interface Props {
  file: TLocalFile
  canUseTag?: boolean
  onRetry: () => void
  onDelete: () => void
  onEditFile?: (data: TDataEditFile) => void
  percentProgress: number | null
}
export const FileItem = ({
  file,
  canUseTag = true,
  onDelete,
  onRetry,
  onEditFile,
  percentProgress,
}: Props) => {
  const { rawFile, status } = file
  const [fileName, setFileName] = useState(rawFile.name)
  const [tags, setTags] = useState<OptionValueTag[] | null>(null)
  const fileType = getFileType(rawFile.type)

  const isFailed = status === FILE_STATUS_FAIL
  const isSuccess = status === FILE_STATUS_SUCCESS
  const isInprogress = status === FILE_STATUS_INPROGRESS

  const handleChangeTags = (listTags: OptionValueTag[] | null) => {
    setTags(listTags)
    onEditFile?.({tags: listTags})
  }

  const handlChangeName = (name: string) => {  
    setFileName(name)  
    onEditFile?.({name})
  }

  const handleAddNote = (note: string) => {
    onEditFile?.({note})
  }

  const renderPopoverFailed = () => {
    return (
      <Tooltip
        content={
          <div className='px-3 py-2 bg-red-500 text-body text-black-800 w-60 rounded-lg'>
            {file.statusMsg ||
              'Upload failed, the server hasn’t responded, please try again'}
          </div>
        }
        placement='right'
        popupClassName='!p-0'
        contentClassName='!bg-transparent'
      >
        <ErrorCircle className='text-red-900 cursor-pointer' />
      </Tooltip>
    )
  }

  const renderIconStatus = () => {
    return (
      <div
        className={cx(
          'absolute p-2.5 bg-white rounded-br-lg rounded-tl-[0.3125rem] top-0 left-0 z-10',
          isFailed && 'bg-red-500',
          isSuccess && 'bg-green-500',
        )}
      >
        {isFailed ? (
          renderPopoverFailed()
        ) : isSuccess ? (
          <SuccesCircle className='text-green-900' />
        ) : (
          <span className='text-body w-8 h-5 leading-[20px] block text-center'>
            {percentProgress || 0}%
          </span>
        )}
      </div>
    )
  }

  return (
    <div
      className={cx(
        'rounded-lg shadow-card relative group',
        isFailed && ' border-2 border-red-900',
        isSuccess && ' border-2 border-green-900',
      )}
    >
      {renderIconStatus()}
      <div className='absolute top-3 right-3 hidden gap-1 group-hover:flex z-10'>
        {isFailed && (
          <Button
            variant='ternary'
            innerClassName='!px-3 gap-2'
            onClick={onRetry}
          >
            <span className='font-icon-refresh' />
            Retry
          </Button>
        )}
        {!isInprogress && (
          <div
            className='w-8 h-8 rounded-full bg-white text-center cursor-pointer shadow-card'
            onClick={onDelete}
          >
            <span className='font-icon-trash leading-8' />
          </div>
        )}
      </div>
      <UploadedThumbnail file={rawFile} />
      <div className='p-4 bg-white rounded-b-lg'>
        <div className='flex mb-4'>
          <div className='w-10'>
            <span>{FILE_FORMAT_ICON[fileType]}</span>
          </div>
          <div className='text-body w-[calc(100%_-_2.5rem)]'>
            <Input value={fileName} onChange={e => handlChangeName(e.target.value)}/>
          </div>
        </div>
        {canUseTag && (
          <div className='flex'>
            <div className='text-body text-black-400 w-10'>
              {tags?.length || 0}/5
            </div>
            <div className='w-[calc(100%_-_2rem)]'>
              <FileTagsSelect
                placeholder='# Add tags (optional)'
                disabled={!isSuccess}
                onSelect={handleChangeTags}
                animation={undefined}
              />
            </div>
          </div>
        )}
        <div className='flex mt-4 text-body'>
          <div className='w-10 text-black-400'>Note</div>
          <div className='w-[calc(100%_-_2.5rem)]'>
            <TextArea
              className='w-full !text-xs !p-2 rounded-md border border-separation-900 focus:border-primary-900'
              onChange={(e) => handleAddNote(e.target.value)}
            />
          </div>          
        </div>
      </div>
    </div>
  )
}
