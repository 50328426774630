import { usePermission } from '../../hooks/usePermission'

interface Props {
  children: React.ReactNode
  permissionName: string
}
export const PageWrapperPermisison = ({ children, permissionName }: Props) => {
  const { canView, redirectNoPermision } = usePermission({
    name: permissionName,
  })
  if (!canView) {
    return redirectNoPermision()
  }
  return <>{children}</>
}
