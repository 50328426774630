import { Props as SelectProps } from '../../../components/Forms/Select'
import { IAccount } from '../../../services/accounts'
import { ACCOUNT_VENDOR_MANUFACTURER } from '../../../utils/accounts'
import { OptionValue } from '../../../utils/form'
import { AccountSelect } from './Accounts'

interface Props extends Omit<SelectProps, 'options' | 'onSelect'> {
  selected?: OptionValue | null
  onSelect?: (value: OptionValue | null, valueRaw?: IAccount | null) => void
  className?: string
  excludesValues?: number[]
}
export const AccountManufacturerSelect = (props: Props) => {
  return (
    <AccountSelect
      {...props}
      filter={{
        filterSubType: ACCOUNT_VENDOR_MANUFACTURER,
      }}
    />
  )
}
