import useStore from '../../../store'
import { RESOURCE_ACCOUNT } from '../../../utils/resources'
import { PageBilling } from '../../Billing'
import { useCustomerPortalCtx } from '../Context'

const Index = () => {
  const user = useStore(state => state.auth.currentUser)
  const { billingResult } = useCustomerPortalCtx()
  return (
    user && (
      <PageBilling
        resourceId={user.account_id || 0}
        resourceType={RESOURCE_ACCOUNT}
        externalResult={billingResult}
      />
    )
  )
}

export default Index
