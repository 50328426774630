import cx from 'classnames'
import { ReactNode, useState } from 'react'

import { Avatar } from '../../../../components'
import { PATHNAME } from '../../../../configs/routes'
import { FileNameInfo } from '../../../../pages/Files/Share/FileNameInfo'
import { IAccount } from '../../../../services/accounts'
import { IAsset } from '../../../../services/assets'
import { IContact } from '../../../../services/contacts'
import { IContract } from '../../../../services/contracts'
import { IExpense } from '../../../../services/expenses'
import { IFile } from '../../../../services/files'
import { IInvoice } from '../../../../services/invoices'
import { IMonitoring } from '../../../../services/monitoring'
import { INote } from '../../../../services/notes'
import { IOpportunity } from '../../../../services/opportunities'
import { IProduct } from '../../../../services/products'
import { IProject } from '../../../../services/projects'
import { IQuote } from '../../../../services/quotes'
import { TResource } from '../../../../services/resource'
import { IService } from '../../../../services/services'
import { IUser } from '../../../../services/users'
import { renderAccountName } from '../../../../utils/accounts'
import { formatDateNoTz } from '../../../../utils/dateTime'
import {
  currencyFormat,
  parserHtmlToText,
  splitParagraph,
} from '../../../../utils/functions'
import {
  RESOURCE_ACCOUNT,
  RESOURCE_ASSET,
  RESOURCE_CONTACT,
  RESOURCE_CONTRACT,
  RESOURCE_EXPENSE,
  RESOURCE_INVOICE,
  RESOURCE_MEDIA,
  RESOURCE_MONITORING,
  RESOURCE_NOTE,
  RESOURCE_OPPORTUNITY,
  RESOURCE_PRODUCT,
  RESOURCE_PROJECT,
  RESOURCE_QUOTE,
  RESOURCE_SERVICE,
  RESOURCE_TYPE_LABEL,
  RESOURCE_USER,
} from '../../../../utils/resources'
import { AccountAvatar, AccountName } from '../../../AccountName'
import { ButtonFavorite } from '../../../ButtonFavorite'
import { HightLightText } from '../../../HightLightText'
import { PhoneNumberView } from '../../../PhoneNumberField'
import { ContractIconStatus } from '../../../Resources/IconStatus/Contract'
import { InvoiceIconStatus } from '../../../Resources/IconStatus/Invoice'
import { ProjectIconStatus } from '../../../Resources/IconStatus/Project'
import { QuoteIconStatus } from '../../../Resources/IconStatus/Quote'
import { ServiceIconStatus } from '../../../Resources/IconStatus/Service'
import { ResourceName } from '../../../Resources/ResourceName'
import { ResourceThumbnail } from '../../../Resources/ResourceThumbnail'
import { CustomResourceLink } from './CustomResourceLink'

interface Props {
  resource: TResource[]
  resourceName: string
  keyword: string
}
export const ResultItem = ({ resource, resourceName, keyword }: Props) => {
  const renderHighLightText = (text?: string | number | null) => {
    return <HightLightText searchWord={keyword} text={text ? text + '' : ''} />
  }
  if (resourceName === RESOURCE_ACCOUNT) {
    return (
      <div>
        <div className='text-black-500 font-medium mb-4'>
          ACCOUNT ({resource.length})
        </div>
        <SearchListItems
          items={resource}
          renderItem={item => {
            const account = item as IAccount
            return (
              <div className='flex gap-2 items-center text-body'>
                <AccountAvatar account={account} />
                <div className='flex gap-1 flex-col'>
                  <CustomResourceLink
                    resourceType={RESOURCE_ACCOUNT}
                    resourceId={account.id}
                  >
                    {renderHighLightText(renderAccountName(account))}
                  </CustomResourceLink>
                  <div className='flex gap-2 items-center text-black-400'>
                    {account.phone_no && (
                      <PhoneNumberView
                        phone={account.phone_no}
                        showFlag={false}
                        phoneClassName='no-underline'
                      />
                    )}
                    {account.phone_no && account.email && (
                      <span className='w-px h-4 bg-separation-800' />
                    )}
                    {account.email && (
                      <a href={`mailto:${account.email}`}>
                        {renderHighLightText(account.email)}
                      </a>
                    )}
                  </div>
                </div>
              </div>
            )
          }}
        />
      </div>
    )
  }
  if (resourceName === RESOURCE_USER) {
    return (
      <div>
        <div className='text-black-500 font-medium mb-4'>
          USERS ({resource.length})
        </div>
        <SearchListItems
          items={resource}
          renderItem={item => {
            const user = item as IUser
            return (
              <div className='flex gap-2 items-center text-body'>
                <Avatar
                  src={user.avatar_preview_url || user.avatar_url}
                  hashId={`user-${user.id}`}
                />
                <div className='flex gap-1 flex-col'>
                  <CustomResourceLink
                    resourceType={RESOURCE_USER}
                    resourceId={user.id}
                  >
                    {renderHighLightText(user.name)}
                  </CustomResourceLink>
                  <div className='flex gap-2 items-center text-black-400'>
                    {user.phone && (
                      <PhoneNumberView
                        phone={user.phone}
                        showFlag={false}
                        phoneClassName='no-underline'
                      />
                    )}
                    {user.phone && user.email && (
                      <span className='w-px h-4 bg-separation-800' />
                    )}
                    {user.email && (
                      <a href={`mailto:${user.email}`}>
                        {renderHighLightText(user.email)}
                      </a>
                    )}
                  </div>
                </div>
              </div>
            )
          }}
        />
      </div>
    )
  }
  if (resourceName === RESOURCE_ASSET) {
    return (
      <div>
        <div className='text-black-500 font-medium mb-4'>
          ASSETS ({resource.length})
        </div>
        <SearchListItems
          items={resource}
          renderItem={item => {
            const asset = item as IAsset
            return (
              <CustomResourceLink
                resourceId={asset.id}
                resourceType={RESOURCE_ASSET}
                className='flex gap-2 items-center'
              >
                <ResourceThumbnail
                  thumbnail={asset.avatar_preview_url || asset.avatar_url}
                />
                <div className='flex flex-col gap-0.5 text-body'>
                  <div>{renderHighLightText(asset.name)}</div>
                  <div className='flex gap-2 text-black-400 items-center'>
                    <div>
                      <span className='font-medium'>ID:</span>{' '}
                      <span>{asset.id}</span>
                    </div>
                    {asset.sku && (
                      <>
                        <span className='h-3.5 w-px bg-separation-800' />
                        <div>
                          <span className='font-medium'>SKU:</span>{' '}
                          <span>{asset.sku}</span>
                        </div>
                      </>
                    )}
                    {asset.model_number && (
                      <>
                        <span className='h-3.5 w-px bg-separation-800' />
                        <div>
                          <span className='font-medium'>Model No.</span>{' '}
                          <span>{asset.model_number}</span>
                        </div>
                      </>
                    )}
                    <span className='h-3.5 w-px bg-separation-800' />
                    <span>{renderHighLightText(asset.type)}</span>
                  </div>
                </div>
              </CustomResourceLink>
            )
          }}
        />
      </div>
    )
  }
  if (resourceName === RESOURCE_PRODUCT) {
    return (
      <div>
        <div className='text-black-500 font-medium mb-4'>
          PRODUCTS ({resource.length})
        </div>
        <SearchListItems
          items={resource}
          renderItem={item => {
            const product = item as IProduct
            return (
              <CustomResourceLink
                resourceId={product.id}
                resourceType={RESOURCE_PRODUCT}
                className='flex gap-2 items-center'
              >
                <ResourceThumbnail
                  thumbnail={product.avatar_preview_url || product.avatar_url}
                />
                <div className='flex flex-col gap-0.5 text-body'>
                  <div>{renderHighLightText(product.name)}</div>
                  <div className='flex gap-2 text-black-400 items-center'>
                    {product.manufacturer && (
                      <>
                        <div>Accounts: </div>
                        <AccountName
                          account={product.manufacturer}
                          showAvatar
                          textClassName='no-underline'
                        />
                      </>
                    )}
                    <span>{product.id}</span>
                    {product.cost && (
                      <>
                        <span className='h-3.5 w-px bg-separation-800' />
                        <div>
                          <span className='font-medium'>Cost:</span>{' '}
                          {currencyFormat(product.cost)}
                        </div>
                      </>
                    )}
                    {product.sku && (
                      <>
                        <span className='h-3.5 w-px bg-separation-800' />
                        <div>
                          <span className='font-medium'>SKU:</span>{' '}
                          {product.sku}
                        </div>
                      </>
                    )}
                    {product.model_number && (
                      <>
                        <span className='h-3.5 w-px bg-separation-800' />
                        <div>
                          <span className='font-medium'>Model No.</span>{' '}
                          {product.sku}
                        </div>
                      </>
                    )}
                    {product.type && (
                      <>
                        <span className='h-3.5 w-px bg-separation-800' />
                        <span>{renderHighLightText(product.type)}</span>
                      </>
                    )}
                  </div>
                </div>
              </CustomResourceLink>
            )
          }}
        />
      </div>
    )
  }
  if (resourceName === RESOURCE_CONTACT) {
    return (
      <div>
        <div className='text-black-500 font-medium mb-4'>
          CONTACTS ({resource.length})
        </div>
        <SearchListItems
          items={resource}
          renderItem={item => {
            const contact = item as IContact
            return (
              <div className='flex gap-2 items-center text-body'>
                <Avatar
                  src={contact.avatar_preview_url || contact.avatar_url}
                  hashId={`contact-${contact.id}`}
                />
                <div className='flex gap-1 flex-col'>
                  <CustomResourceLink
                    resourceType={RESOURCE_CONTACT}
                    resourceId={contact.id}
                  >
                    {renderHighLightText(contact.name)}
                  </CustomResourceLink>
                  <div className='flex gap-2 items-center text-black-400'>
                    {contact.phone_mobile && (
                      <PhoneNumberView
                        phone={contact.phone_mobile}
                        showFlag={false}
                        phoneClassName='no-underline'
                      />
                    )}
                    {contact.phone_mobile && contact.email && (
                      <span className='w-px h-4 bg-separation-800' />
                    )}
                    {contact.email && (
                      <a href={`mailto:${contact.email}`}>
                        {renderHighLightText(contact.email)}
                      </a>
                    )}
                  </div>
                </div>
              </div>
            )
          }}
        />
      </div>
    )
  }
  if (resourceName === RESOURCE_INVOICE) {
    return (
      <div>
        <div className='text-black-500 font-medium mb-4'>
          INVOICES ({resource.length})
        </div>
        <SearchListItems
          items={resource}
          renderItem={item => {
            const invoice = item as IInvoice
            return (
              <div className='flex flex-col gap-2'>
                <CustomResourceLink
                  resourceId={invoice.id}
                  resourceType={RESOURCE_INVOICE}
                  className='flex items-center gap-2 mb-1'
                >
                  <span className='text-black-400'>Invoice Number: </span>
                  <span className='font-medium leading-none'>
                    {renderHighLightText(invoice.invoice_number)}
                  </span>
                  <InvoiceIconStatus invoice={invoice} />
                </CustomResourceLink>
                <div className='flex gap-2 items-center'>
                  <CustomResourceLink
                    resourceType={RESOURCE_ACCOUNT}
                    resourceId={invoice.account?.id}
                    className='flex gap-2 items-center text-black-400'
                  >
                    <div>Accounts: </div>
                    <AccountName
                      account={invoice.account}
                      showAvatar
                      textClassName='no-underline'
                    />
                  </CustomResourceLink>
                  {invoice.resource && invoice.resource_type && (
                    <>
                      <span className='w-px h-4 bg-separation-800' />
                      <CustomResourceLink
                        resourceType={invoice.resource_type}
                        resourceId={invoice.resource.id}
                        className='flex gap-2 items-center text-black-400'
                      >
                        <div className='font-medium'>
                          {RESOURCE_TYPE_LABEL[invoice.resource_type]}:{' '}
                        </div>
                        <ResourceName
                          resource={invoice.resource}
                          resourceType={invoice.resource_type}
                        />
                      </CustomResourceLink>
                    </>
                  )}
                </div>
              </div>
            )
          }}
        />
      </div>
    )
  }
  if (resourceName === RESOURCE_CONTRACT) {
    return (
      <div>
        <div className='text-black-500 font-medium mb-4'>
          MAINTENANCE CONTRACTS ({resource.length})
        </div>
        <div className='flex flex-col gap-4'>
          <SearchListItems
            items={resource}
            renderItem={item => {
              const contract = item as IContract
              return (
                <div className='flex flex-col gap-2'>
                  <CustomResourceLink
                    resourceId={contract.id}
                    resourceType={RESOURCE_CONTRACT}
                    className='relative pr-4 cursor-pointer flex items-center gap-2'
                  >
                    <span className='block leading-none font-medium'>
                      {renderHighLightText(contract.contract_number)}
                    </span>
                    <ContractIconStatus contract={contract} />
                  </CustomResourceLink>
                  <CustomResourceLink
                    resourceType={RESOURCE_ACCOUNT}
                    resourceId={contract.account?.id}
                    className='flex gap-2 items-center text-black-400'
                  >
                    <div>Accounts: </div>
                    <AccountName
                      account={contract.account}
                      showAvatar
                      textClassName='no-underline'
                    />
                  </CustomResourceLink>
                </div>
              )
            }}
          />
        </div>
      </div>
    )
  }
  if (resourceName === RESOURCE_MEDIA) {
    return (
      <div>
        <div className='text-black-500 font-medium mb-4'>
          FILES ({resource.length})
        </div>
        <SearchListItems
          items={resource}
          renderItem={item => {
            const file = item as IFile
            return (
              <CustomResourceLink
                resourceId={file.id}
                resourceType={RESOURCE_MEDIA}
                className='flex gap-2 items-center'
              >
                <FileNameInfo file={file} avatarSize={40} fileNameSize={60} />
              </CustomResourceLink>
            )
          }}
        />
      </div>
    )
  }
  if (resourceName === RESOURCE_MONITORING) {
    return (
      <div>
        <div className='text-black-500 font-medium mb-4'>
          MONITORING SERVICES ({resource.length})
        </div>
        <SearchListItems
          items={resource}
          renderItem={item => {
            const monitoring = item as IMonitoring
            return (
              <div className='flex flex-col gap-2'>
                <CustomResourceLink
                  resourceId={monitoring.id}
                  resourceType={RESOURCE_MONITORING}
                  className='relative pr-4 cursor-pointer flex items-center gap-2'
                >
                  <span className='block leading-none font-medium'>
                    {renderHighLightText(monitoring.name)}
                  </span>
                </CustomResourceLink>
                <CustomResourceLink
                  resourceType={RESOURCE_ACCOUNT}
                  resourceId={monitoring.account?.id}
                  className='flex gap-2 items-center text-black-400'
                >
                  <div>Accounts: </div>
                  <AccountName
                    account={monitoring.account}
                    showAvatar
                    textClassName='no-underline'
                  />
                </CustomResourceLink>
              </div>
            )
          }}
        />
      </div>
    )
  }
  if (resourceName === RESOURCE_QUOTE) {
    return (
      <div>
        <div className='text-black-500 font-medium mb-4'>
          QUOTES ({resource.length})
        </div>
        <SearchListItems
          items={resource}
          renderItem={item => {
            const quote = item as IQuote
            return (
              <div className='flex flex-col gap-2'>
                <CustomResourceLink
                  resourceId={quote.id}
                  resourceType={RESOURCE_QUOTE}
                  className='flex items-center gap-2'
                >
                  <span className='leading-normal font-medium'>
                    {renderHighLightText(
                      !!quote?.regarding ? quote.regarding : quote.quote_number,
                    )}
                  </span>
                  <QuoteIconStatus quote={quote} />
                </CustomResourceLink>
                <CustomResourceLink
                  resourceType={RESOURCE_ACCOUNT}
                  resourceId={quote.account?.id}
                  className='flex gap-2 items-center text-black-400'
                >
                  <div>Accounts: </div>
                  <AccountName
                    account={quote.account}
                    showAvatar
                    textClassName='no-underline'
                  />
                </CustomResourceLink>
              </div>
            )
          }}
        />
      </div>
    )
  }
  if (resourceName === RESOURCE_PROJECT) {
    return (
      <div>
        <div className='text-black-500 font-medium mb-4'>
          PROJECTS ({resource.length})
        </div>
        <SearchListItems
          items={resource}
          renderItem={item => {
            const project = item as IProject
            return (
              <div className='flex flex-col gap-2'>
                <CustomResourceLink
                  resourceId={project.id}
                  resourceType={RESOURCE_PROJECT}
                  className='flex items-center gap-2'
                >
                  <span className='font-medium'>
                    {renderHighLightText(project.name)}
                  </span>
                  {project.status === 'Blocked' ? (
                    <span className='bg-separation-900 rounded w-[1.375rem] h-[1.375rem] inline-flex items-center justify-center'>
                      <span className='font-icon-lock text-black-800 leading-none' />
                    </span>
                  ) : (
                    project.is_watched && (
                      <ProjectIconStatus project={project} />
                    )
                  )}
                  {project.is_favorited && (
                    <ButtonFavorite currentStatus viewOnly />
                  )}
                </CustomResourceLink>
                <CustomResourceLink
                  resourceType={RESOURCE_ACCOUNT}
                  resourceId={project.account?.id}
                  className='flex gap-2 items-center text-black-400'
                >
                  <div>Accounts: </div>
                  <AccountName
                    account={project.account}
                    showAvatar
                    textClassName='no-underline'
                  />
                </CustomResourceLink>
              </div>
            )
          }}
        />
      </div>
    )
  }
  if (resourceName === RESOURCE_OPPORTUNITY) {
    return (
      <div>
        <div className='text-black-500 font-medium mb-4'>
          OPPORTUNITIES ({resource.length})
        </div>
        <SearchListItems
          items={resource}
          renderItem={item => {
            const opportunity = item as IOpportunity
            return (
              <div className='flex flex-col gap-2'>
                <CustomResourceLink
                  resourceId={opportunity.id}
                  resourceType={RESOURCE_OPPORTUNITY}
                  className='relative pr-4 cursor-pointer flex items-center gap-2'
                >
                  <span className='block leading-none font-medium'>
                    {renderHighLightText(opportunity.name)}
                  </span>
                </CustomResourceLink>
                <CustomResourceLink
                  resourceType={RESOURCE_ACCOUNT}
                  resourceId={opportunity.account?.id}
                  className='flex gap-2 items-center text-black-400'
                >
                  <div>Accounts: </div>
                  <AccountName
                    account={opportunity.account}
                    showAvatar
                    textClassName='no-underline'
                  />
                </CustomResourceLink>
              </div>
            )
          }}
        />
      </div>
    )
  }
  if (resourceName === RESOURCE_SERVICE) {
    return (
      <div>
        <div className='text-black-500 font-medium mb-4'>
          SERVICE TICKETS ({resource.length})
        </div>
        <SearchListItems
          items={resource}
          renderItem={item => {
            const service = item as IService
            return (
              <CustomResourceLink
                resourceId={service.id}
                resourceType={RESOURCE_SERVICE}
                className='flex gap-2 flex-col text-body'
              >
                <div className='flex items-center gap-2 mb-1 text-black-800'>
                  <span className='font-medium'>
                    {renderHighLightText(
                      parserHtmlToText(service?.reason_for_visit || ''),
                    )}
                  </span>
                  <ServiceIconStatus service={service} />
                </div>
                <div className='text-black-400'>{service.id}</div>
              </CustomResourceLink>
            )
          }}
        />
      </div>
    )
  }
  if (resourceName === RESOURCE_EXPENSE) {
    return (
      <div>
        <div className='text-black-500 font-medium mb-4'>
          EXPENSES ({resource.length})
        </div>
        <SearchListItems
          items={resource}
          renderItem={item => {
            const expense = item as IExpense
            return (
              <div className='flex flex-col gap-2'>
                <CustomResourceLink
                  resourceId={expense.id}
                  resourceType={RESOURCE_EXPENSE}
                  className='relative pr-4 cursor-pointer flex items-center gap-2'
                >
                  <span className='block leading-none font-medium'>
                    {renderHighLightText(expense.name)}
                  </span>
                  {expense.resource_type && (
                    <span className='text-black-400'>
                      ({RESOURCE_TYPE_LABEL[expense.resource_type]})
                    </span>
                  )}
                </CustomResourceLink>
                <div className='flex gap-2 text-black-400 items-center'>
                  <div className='flex gap-2 items-center'>
                    <span className='font-icon-money' />
                    <span className='font-medium text-black-800'>
                      {currencyFormat(expense.amount)}
                    </span>
                  </div>
                  {expense.expense_category && (
                    <>
                      <span className='h-3.5 w-px bg-separation-800' />
                      <div>{expense.expense_category}</div>
                    </>
                  )}
                  <span className='h-3.5 w-px bg-separation-800' />
                  <div>{expense.status}</div>
                  <span className='h-3.5 w-px bg-separation-800' />
                  <div>Created at {formatDateNoTz(expense.created_at)}</div>
                </div>
              </div>
            )
          }}
        />
      </div>
    )
  }
  if (resourceName === RESOURCE_NOTE) {
    return (
      <div>
        <div className='text-black-500 font-medium mb-4'>
          NOTES ({resource.length})
        </div>
        <SearchListItems
          items={resource}
          renderItem={item => {
            const note = item as INote
            return (
              <CustomResourceLink
                resourceId={note.resource_id}
                resourceType={note.resource_type}
                pathName={`/${PATHNAME.notes}`}
                className='flex flex-col gap-2'
              >
                <div className='relative pr-4 cursor-pointer flex items-center gap-2'>
                  <span className='font-icon-text' />
                  <span className='block leading-none font-medium'>
                    {renderHighLightText(
                      splitParagraph(parserHtmlToText(note.body), 20),
                    )}
                  </span>
                </div>
                <div className='flex gap-2 text-black-400 items-center'>
                  <span>
                    Found in {RESOURCE_TYPE_LABEL[note.resource_type]}
                  </span>
                  <span className='h-3.5 w-px bg-separation-800' />
                  <div>Created at {formatDateNoTz(note.created_at)}</div>
                </div>
              </CustomResourceLink>
            )
          }}
        />
      </div>
    )
  }
  return null
}

const SearchListItems = ({
  renderItem,
  items,
}: {
  renderItem: (item: TResource) => ReactNode
  items: TResource[]
}) => {
  const [showMore, setShowMore] = useState(false)
  return (
    <div className='flex flex-col gap-4'>
      {items.map((item, index) => (
        <div
          className={cx(
            'px-4 py-2.5 bg-separation-100 rounded-lg hover:bg-primary-300',
            !showMore && index > 3 && 'hidden',
          )}
          key={`${item.id}-${index}`}
        >
          {renderItem(item)}
        </div>
      ))}
      {!showMore && items.length > 3 && (
        <div
          onClick={() => setShowMore(true)}
          className='text-primary-900 font-medium cursor-pointer text-center py-1.5 hover:bg-primary-300 rounded-lg'
        >
          Show More
        </div>
      )}
    </div>
  )
}
