import { useState } from 'react'

import { Button, Modal, ModalProps } from '../../../../components'
import { useAsync } from '../../../../hooks'
import { editFile, IFile } from '../../../../services/files'
import { OptionValueTag } from '../../../../utils/form'
import { mapAndCreateTags } from '../../../../utils/tag'
import { TagsSelect } from '../../../Select'

interface Props extends Omit<ModalProps, 'children'> {
  onSuccess?: (file: IFile) => void
  onClose: () => void
  file: IFile
}

export const ModalEditTag = ({ onClose, onSuccess, file, ...props }: Props) => {
  const initTags = !!file.tags
    ? file.tags.map(item => ({
        value: item.name,
        label: item.name,
        key: item.id + '',
      }))
    : null
  const [tags, setTags] = useState<OptionValueTag[] | null>(initTags)
  const [loading, setLoading] = useState(false)
  const { execute } = useAsync({
    showNotifOnError: true,
  })

  const handleSave = async () => {
    try {
      setLoading(true)
      const listTagIds = await mapAndCreateTags(tags)
      const result = await execute(editFile(file.id, { tags: listTagIds }))
      onClose()
      onSuccess?.(result.data.data)
    } catch (error) {
      return
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal {...props} className='w-[27rem]' onClose={onClose}>
      <div className='flex gap-3 relative'>
        <span
          className='cursor-pointer font-icon-close text-black-400 absolute -right-2 -top-2 z-10 w-10 h-10 flex justify-center items-center'
          onClick={onClose}
        />
        <div className='w-10 h-10 bg-primary-200 rounded-full flex items-center justify-center'>
          <span className='font-icon-label text-[1.1875rem]' />
        </div>
        <div className='flex-1'>
          <div className='text-base font-medium text-black-800 mb-3'>
            Edit Label
          </div>
          <div className='text-body mb-6'>
            <TagsSelect onChangeTags={setTags} value={tags || undefined} />
            <div className='text-black-400 text-right mb-6'>{`${
              tags ? tags.length : 0
            }/5`}</div>
          </div>
          <div className='flex justify-end gap-2'>
            <Button
              variant='ternary'
              onClick={onClose}
              disabled={loading}
              innerClassName='!px-4'
            >
              Cancel
            </Button>
            <Button
              variant='primary'
              onClick={handleSave}
              className='bg-primary-900'
              innerClassName='!px-10'
              disabled={loading}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}
