import cx from 'classnames'
import { update } from 'jdenticon'
import { useEffect, useRef, useState } from 'react'

import ImgThumbnail from '../../assets/images/img_thumbnail.svg'
import ImgThumbnailCircle from '../../assets/images/img_thumbnail_rounded.svg'
import { Image } from '../Image'
import { Spinner } from '../Spinner'

export interface Props {
  refField?: React.RefObject<HTMLInputElement> | null
  size?: 'large' | 'medium' | 'small' | number
  type?: 'avatar' | 'thumbnail'
  hashId?: string
  src?: string | null
  className?: string
  isRemove?: boolean
  loading?: boolean
  onChange?: (image: File) => void
}

const sizes = {
  small: 'w-7 h-7',
  medium: 'w-8 h-8',
  large: 'w-[4.5rem] h-[4.5rem]',
}

export function Avatar({
  size = 'medium',
  type = 'avatar',
  hashId,
  src: propSrc,
  className,
  onChange,
  refField = null,
  isRemove = false,
  loading = false,
}: Props) {
  const [src, setSrc] = useState(propSrc)
  const iconRef = useRef<SVGSVGElement>(null)
  const pickerRef = refField || useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    if (!src && !!iconRef.current) {
      update(iconRef.current, hashId, { padding: 0 })
    }
  }, [hashId, src])

  useEffect(() => {
    if (src?.startsWith('blob:')) {
      setTimeout(() => {
        URL.revokeObjectURL(src)
      }, 1000)
    }
  }, [src])

  useEffect(() => {
    if (propSrc !== src) {
      setSrc(propSrc)
    }
  }, [propSrc])

  const handleOpenFileDialog = () => {
    if (pickerRef.current) {
      pickerRef.current.click()
    }
  }

  const handlePickerChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    const { files } = e.target
    if (!files || !files.length) {
      return
    }
    const newSrc = URL.createObjectURL(files[0])
    setSrc(newSrc)
    onChange && onChange(files[0])
  }

  useEffect(() => {
    if (isRemove && src) {
      setSrc(null)
    }
  }, [isRemove])
  const defaultThumbnail = type === 'avatar' ? ImgThumbnailCircle : ImgThumbnail
  return (
    <div
      style={typeof size === 'number' ? { width: size, height: size } : {}}
      className={cx(
        typeof size === 'string' && sizes[size],
        'box-border m-0 p-0 relative block overflow-hidden',
        type === 'avatar' ? 'rounded-full' : '',
        className,
      )}
    >
      {src ? (
        <Image src={src} className='w-full h-full object-cover' alt='avatar' />
      ) : hashId ? (
        <svg ref={iconRef} className='p-0.5' data-jdenticon-value={hashId} />
      ) : (
        <img
          src={defaultThumbnail}
          className='w-full h-full object-cover'
          alt='avatar'
        />
      )}
      {onChange && (
        <div
          onClick={handleOpenFileDialog}
          className={cx(
            'w-full h-full text-center flex justify-center cursor-pointer items-center absolute inset-0',
            (src || hashId) && '!bg-transparent',
          )}
        >
          <input
            type='file'
            accept='image/*'
            className='absolute hidden pointer-events-none'
            ref={pickerRef}
            onChange={handlePickerChange}
          />
          {!src && !hashId && type === 'avatar' && (
            <img
              src={defaultThumbnail}
              className='w-full h-full object-cover'
              alt='avatar'
            />
          )}
        </div>
      )}
      {loading && (
        <>
          <div className='absolute inset-0 bg-black-800 opacity-50' />
          <div className='absolute inset-0'>
            <Spinner size='xsmall' />
          </div>
        </>
      )}
    </div>
  )
}
