import { useEffect } from 'react'

import { getNotes } from '../../../../../services/notes'
import { RESOURCE_MEDIA } from '../../../../../utils/resources'
import { Notes } from '../../../../Notes'
import { useFileDetailCtx } from '../Context'

export const MediaNote = () => {
  const { file, toggleInfo, toggleNote, dispatch } = useFileDetailCtx()

  const handleToggleNote = () => {
    const isOpenInfo = Boolean(toggleInfo)
    if (isOpenInfo) {
      dispatch({
        toggleInfo: false,
      })
    }
    setTimeout(
      () => {
        dispatch({
          toggleNote: !toggleNote,
        })
      },
      isOpenInfo ? 300 : 0,
    )
  }
  const handleMarkReadNotes = async () => {
    await getNotes({
      mark_as_read: 1,
      resource: `${RESOURCE_MEDIA},${file.id}`,
    })
  }
  useEffect(() => {
    if (file.unread_notes_count > 0) {
      handleMarkReadNotes()
    }
  }, [])
  return (
    <div className='h-full flex flex-col'>
      <div className='flex items-center justify-between p-6 border-b border-separation-800'>
        <h5 className='text-base font-medium'>Note</h5>
        <div className='cursor-pointer' onClick={handleToggleNote}>
          <span className='font-icon-close text-black-600' />
        </div>
      </div>
      <div className='flex-1 text-body overflow-auto custom-scrollbar'>
        <Notes
          resourceId={file.id}
          resourceType={RESOURCE_MEDIA}
          contentClassName='max-h-[calc(100vh_-_20rem)] md:max-h-full'
        />
      </div>
    </div>
  )
}
