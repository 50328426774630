import cx from 'classnames'
import { useState } from 'react'

import { Button, Collapse, Popover } from '../../../components'
import { IFolder } from '../../../services/files'
import { useFileManagementCtx } from '../Context'
import { PopoverMenuFile } from '../Main/PopoverMenuFile'

interface PropsFolder {
  folder: IFolder
  selectedId: number | null
  onSelect: (folder: IFolder, listFolders: IFolder[]) => void
  level: number
  activeCollapse?: boolean
  showMenu?: boolean
  onRenameFolder?: (folder: IFolder) => void
  onDeleteFolder?: (folder: IFolder) => void
  onMoveFolder?: (folder: IFolder) => void
}
export const ListFolders = () => {
  const { listFolders, onOpenFolder, currentOpenFolder, dispatch } =
    useFileManagementCtx()

  const handleSelectFolder = (folder: IFolder | null) => {
    onOpenFolder(folder)
  }

  const handleMoveFolder = (f: IFolder) => {
    dispatch({
      selectedFolders: [f],
      openMoveFile: true,
    })
  }

  const handleRenameFolder = (f: IFolder) => {
    dispatch({
      renameData: f,
    })
  }

  const handleDeleteFolder = (f: IFolder) => {
    dispatch({
      deleteData: [f],
    })
  }
  const renderRootLabel = () => (
    <div
      className='flex items-center gap-2 flex-1 py-1.5'
      onClick={() => {
        handleSelectFolder(null)
      }}
    >
      <span
        className={cx(
          'font-icon-folder group-hover:text-primary-900 cursor-pointer',
          Boolean(currentOpenFolder) ? 'text-black-300' : 'text-primary-900',
        )}
      />
      <div className='text-body line-clamp-1'>Root</div>
    </div>
  )
  const folderProps = {
    onSelect: handleSelectFolder,
    onRenameFolder: handleRenameFolder,
    onMoveFolder: handleMoveFolder,
    onDeleteFolder: handleDeleteFolder,
    selectedId: currentOpenFolder?.id || null,
    showMenu: true,
  }
  return (
    <div className='flex flex-col gap-2 text-body  custom-scrollbar px-8 max-h-[calc(100vh_-_11rem)] overflow-y-auto'>
      <Collapse
        active={true}
        panel={renderRootLabel()}
        panelClassName='items-center group rounded-3xl !p-0 hover:bg-primary-400 cursor-pointer flex-1 w-full gap-0'
        contentClassName='!p-0'
        iconWrapperClassName='w-6 h-6'
        iconClassName='text-[0.6125rem] !text-black-400'
        onlyClickIcon={true}
      >
        <div className='pl-4'>
          {listFolders
            .sort((a, b) => a.name.localeCompare(b.name))
            .map(item => (
              <FolderItem
                folder={item}
                level={1}
                key={item.id}
                {...folderProps}
              />
            ))}
        </div>
      </Collapse>
    </div>
  )
}

export const FolderItem = ({
  folder,
  onSelect,
  level,
  showMenu = false,
  ...props
}: PropsFolder) => {
  const { currentLevelFolders } = useFileManagementCtx()
  const subFolders = folder?.children || []
  const hasChildren = subFolders.length > 0
  const isSelected = props.selectedId === folder.id
  const [visibleMenu, setVisibleMenu] = useState(false)
  const [isHover, setHover] = useState(false)
  const handleSelectMenu = (f: IFolder, action: string) => {
    setVisibleMenu(false)
    setHover(false)
    switch (action) {
      case 'rename': {
        props.onRenameFolder?.(f)
        break
      }
      case 'delete': {
        props.onDeleteFolder?.(f)
        break
      }
      case 'move': {
        props.onMoveFolder?.(f)
        break
      }
    }
  }
  const renderPopover = () => (
    <Popover
      placement='right-end'
      visible={visibleMenu}
      onVisibleChange={s => {
        setVisibleMenu(s)
        if (!s) {
          setHover(false)
        }
      }}
      content={onClose => (
        <PopoverMenuFile
          onClose={onClose}
          onRenameFile={
            props.onRenameFolder
              ? () => handleSelectMenu(folder, 'rename')
              : undefined
          }
          onDelete={
            props.onDeleteFolder
              ? () => handleSelectMenu(folder, 'delete')
              : undefined
          }
          onMove={
            props.onMoveFolder
              ? () => handleSelectMenu(folder, 'move')
              : undefined
          }
        />
      )}
    >
      <Button variant='ternary' className='w-8' innerClassName='px-0'>
        <span className='font-icon-more' />
      </Button>
    </Popover>
  )
  const renderLabelPanel = (data: IFolder) => (
    <div
      className='flex flex-1 py-1.5 items-center relative'
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
    >
      <div
        className='flex flex-1 items-center gap-2'
        onClick={() => {
          onSelect(data, [data])
        }}
      >
        <span
          className={cx(
            'font-icon-folder group-hover:text-primary-900 cursor-pointer',
            isSelected ? 'text-primary-900' : 'text-black-300',
          )}
        />
        <div className='text-body line-clamp-1'>{data.name}</div>
      </div>
      {showMenu && isHover && (
        <div className='absolute right-0 top-0'>{renderPopover()}</div>
      )}
    </div>
  )

  if (!hasChildren) {
    return (
      <div
        className={cx(
          'flex  group rounded-3xl hover:bg-primary-400 cursor-pointer flex-1 w-full',
          isSelected && 'bg-primary-400',
        )}
      >
        <div className='w-6 h-6' />
        {renderLabelPanel(folder)}
      </div>
    )
  }

  return (
    <Collapse
      active={currentLevelFolders.map(f => f.id).includes(folder?.id)}
      panel={renderLabelPanel(folder)}
      panelClassName='items-center group rounded-3xl !p-0 hover:bg-primary-400 cursor-pointer flex-1 w-full gap-0'
      contentClassName='!p-0'
      iconWrapperClassName='w-6 h-6'
      iconClassName='text-[0.6125rem] !text-black-400'
      onlyClickIcon={true}
    >
      <div className='pl-4'>
        {subFolders
          .sort((a, b) => a.name.localeCompare(b.name))
          .map(subFolder => (
            <FolderItem
              key={subFolder.id}
              folder={subFolder}
              onSelect={(v, f) => {
                onSelect(v, [folder, ...f])
              }}
              level={level + 1}
              showMenu={showMenu}
              {...props}
            />
          ))}
      </div>
    </Collapse>
  )
}
