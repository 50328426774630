import { useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

import useStore from '../../store'

export const RoleBasedRedirect = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const userRoles = useStore(state => state.auth.currentUser?.roles)?.map(
    role => role.name,
  )

  useEffect(() => {
    if (userRoles && userRoles.includes('customer')) {
      if (!location.pathname.startsWith('/customer')) {
        navigate('/customer')
      }
    }
  }, [userRoles, location, navigate])

  return <Outlet />
}
