import { useState } from 'react'
import ScrollBar from 'react-perfect-scrollbar'

import { Avatar, Button, Modal, ModalProps } from '../../../../components'
import { Spinner } from '../../../../components/Spinner'
import { useAsync } from '../../../../hooks'
import { editFile, IFile, IFilePayload } from '../../../../services/files'
import { IUser } from '../../../../services/users'
import { UserCompleteField } from '../../../Form/UserCompleteField'

interface Props extends Omit<ModalProps, 'children'> {
  file: IFile
  onSuccess?: (file: Partial<IFile>) => void
}

export const ModalFileUserAccess = ({
  onClose,
  file,
  onSuccess,
  ...props
}: Props) => {
  const [listUsers, setListUsers] = useState(file?.accessors || [])
  const [isPrivate, setPrivate] = useState(file.is_protected)
  const [removingId, setRemovingId] = useState<number | null>(null)

  const updateAsync = useAsync({ showNotifOnError: true })

  const handleRemove = async (id: number) => {
    setRemovingId(id)
    const newUsers = listUsers.filter(item => item.id !== id)
    setListUsers(newUsers)
    setRemovingId(null)
  }

  const handleAddUser = async (user: IUser) => {
    const newUsers = [...listUsers, user]
    setListUsers(newUsers)
  }

  const handleFetch = async (data: IFilePayload) => {
    await updateAsync.execute(editFile(file.id, data))
  }

  const toggleStatuAccess = async () => {
    let newPayload: IFilePayload = {
      is_protected: !isPrivate,
    }
    if (isPrivate) {
      newPayload = {
        ...newPayload,
        accessors: [],
      }
    }
    await handleFetch(newPayload)
    setListUsers(newPayload.accessors?.length === 0 ? [] : listUsers)
    setPrivate(Boolean(newPayload.is_protected))
  }

  const handleCancel = () => {
    onClose()
    const isChangedData =
      isPrivate !== file.is_protected ||
      listUsers.length !== file.accessors?.length
    onSuccess &&
      isChangedData &&
      onSuccess({
        is_protected: isPrivate,
      })
  }

  const handleDone = async () => {
    const newIds = listUsers.map(item => item.id)
    const isChangedData =
      isPrivate !== file.is_protected ||
      listUsers.length !== file.accessors?.length
    if (isChangedData) {
      await handleFetch({ accessors: newIds, is_protected: isPrivate })
      onSuccess?.({
        is_protected: isPrivate,
        accessors: listUsers,
      })
    }
    onClose()
  }

  const renderItem = (item: IUser) => (
    <div
      className='flex items-center justify-between py-2 border-b border-b-separation-400'
      key={item.id}
    >
      <div className='inline-flex items-center gap-2'>
        <Avatar
          src={item.avatar_preview_url || null}
          hashId={`user-${item.id}`}
          size='small'
        />
        <span className='text-body'>{item.name}</span>
      </div>
      <Button
        variant='ternary'
        innerClassName='inline-flex justify-between gap-2 !px-3'
        onClick={() => handleRemove(item.id)}
        className='relative'
      >
        <span className='font-icon-remove' />
        Remove
        {updateAsync.isLoading && removingId === item.id && (
          <>
            <div className='absolute inset-0 bg-white w-full h-full z-10 rounded-lg' />
            <div className='absolute top-1/2 left-1/2 z-20 mt-[-1.125rem] ml-[-1rem]'>
              <Spinner size='xsmall' />
            </div>
          </>
        )}
      </Button>
    </div>
  )

  return (
    <Modal {...props} className='w-[27rem]' onClose={handleCancel}>
      <>
        <div className='flex justify-between items-center mb-4 relative'>
          <div className='text-base font-medium'>Manage Access</div>
          <span
            className='cursor-pointer font-icon-close text-black-400 absolute -right-2 -top-2 z-10 w-10 h-10 flex justify-center items-center'
            onClick={handleCancel}
          />
        </div>
        <div className='text-black-400 text-body mb-2'>
          {isPrivate ? (
            <>
              <span className='font-medium text-black-800'>Private</span> Only
              added people can access
            </>
          ) : (
            <>
              <span className='font-medium text-black-800'>Public</span> All
              users in your company can access
            </>
          )}
        </div>
        <div
          className='text-primary-900 cursor-pointer text-body mb-4 font-medium'
          onClick={toggleStatuAccess}
        >
          {isPrivate
            ? 'Allow everyone in the company access this file'
            : 'Restrict people to access this file'}
        </div>
        {isPrivate && (
          <div className='mb-4'>
            <UserCompleteField
              onSelect={handleAddUser}
              listExcludes={listUsers.map(item => item.id)}
            />
          </div>
        )}
        {listUsers.length > 0 && isPrivate && (
          <ScrollBar
            options={{ wheelPropagation: false }}
            className='bg-separation-100 p-3 rounded-lg max-h-[20rem]'
          >
            {listUsers
              .sort((a, b) => a.name.localeCompare(b.name))
              .map(item => renderItem(item))}
          </ScrollBar>
        )}
        <div className='flex justify-end gap-2 mt-6'>
          <Button
            variant='ternary'
            onClick={handleCancel}
            disabled={updateAsync.isLoading}
            asLink
          >
            Cancel
          </Button>
          <Button
            className='w-[7rem]'
            disabled={updateAsync.isLoading}
            onClick={handleDone}
          >
            Done
          </Button>
        </div>
      </>
    </Modal>
  )
}
