import { Tooltip } from '../../../components'
import { IInvoice } from '../../../services/invoices'
import { isPaidInvoice, isWarningInvoice } from '../../../utils/invoices'

interface Props {
  invoice: IInvoice
  showPaid?: boolean
}
export const InvoiceIconStatus = ({ invoice, showPaid = true }: Props) => {
  const isWarning = isWarningInvoice(invoice)
  const isPaid = isPaidInvoice(invoice?.status || '')
  if (isWarning) {
    return (
      <Tooltip
        content='This invoice has not been paid by the net terms date'
        placement='auto'
        popupClassName='z-[9999]'
      >
        <span className='rounded min-w-[1.375rem] min-h-[1.375rem] inline-flex items-center justify-center cursor-pointer bg-orange-900'>
          <span className='font-icon-notification text-white leading-none text-[0.875rem]' />
        </span>
      </Tooltip>
    )
  }
  if (isPaid && showPaid) {
    return <span className='font-icon-check text-green-900 text-[0.75rem]' />
  }
  return null
}
