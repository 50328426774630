import cx from 'classnames'
import RcTabs, { TabPane, TabPaneProps, TabsProps } from 'rc-tabs'
import { FC } from 'react'

const Tabs: FC<Omit<TabsProps, 'prefixCls'>> & {
  TabPane: FC<TabPaneProps>
} = ({ children, ...props }) => {
  return (
    <RcTabs
      {...props}
      className={cx('custom-tabs', props.className)}
      children={children}
    />
  )
}

Tabs.TabPane = TabPane
export { Tabs }
