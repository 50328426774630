import { useEffect, useState } from 'react'

import { Image } from '../../../../components'
import { IFile } from '../../../../services/files'
import { getListRecentSelected } from '../../../../utils/files'

interface Props {
  onSelectImage: (image: Partial<IFile>) => void
}
export const RecentSelected = ({ onSelectImage }: Props) => {
  const [listRecent, setListRecent] = useState<Array<Partial<IFile>>>([])
  const initData = () => {
    const result = getListRecentSelected()
    setListRecent(result)
  }

  useEffect(() => {
    initData()
  }, [])

  if (listRecent.length === 0) {
    return null
  }
  return (
    <div className='mb-6'>
      <div className='text-sm font-medium mb-3'>Recent Selected</div>
      <div className='grid grid-cols-[repeat(auto-fill,minmax(7.5rem,1fr))] gap-3 pb-3'>
        {listRecent.map((item, index) => (
          <div
            key={`${item.id}-${index}`}
            className='after:block after:pt-[100%] after:content-[""] relative cursor-pointer'
            onClick={() => onSelectImage(item)}
          >
            <Image
              src={item.preview_url || item.url}
              className='absolute inset-0 object-cover w-full h-full rounded-lg'
            />
          </div>
        ))}
      </div>
    </div>
  )
}
