import axios from 'axios'

import client, { BASE_URL } from './client'
import { IUserRole } from './roles'
import { IUserNotification } from './users'

export interface AuthUser {
  id: number
  account_id?: number
  first_name: string
  last_name: string
  name: string
  email: string
  email_verified_at: string
  password: string
  avatar?: string | null
  avatar_url?: string | null
  avatar_preview_url?: string | null
  phone: string
  can?: string[] | null
  notification_settings: IUserNotification
  roles: IUserRole[]
}

export const getAuthUser = () => {
  return client.get<{ data: AuthUser }>('api/user')
}
export const editAuthUser = (payload: Partial<AuthUser>) => {
  return client.put<{ data: AuthUser }>('api/user', payload)
}
export const resendVerify = () => {
  return client.post('/auth/email/verification-notification')
}

export const verifyEmail = (payload: { id: string; hash: string }) => {
  return client.get(`/auth/email/verify/${payload.id}/${payload.hash}`)
}

export const acceptInvitation = async (payload: {
  user: string
  hash: string
  password: string
}) => {
  try {
    await axios.post(
      `${BASE_URL}/api/invitations/${payload.user}/${payload.hash}`,
      {
        password: payload.password,
      },
    )
  } catch (error) {
    return error
  }
}
export const login = async (payload: {
  email: string
  password: string
  remember: boolean
}) => {
  await client.get('/auth/csrf-cookie')
  return await client.post<{ two_factor: boolean }>('/auth/login', payload)
}

export const loginGoole = async (params: string) => {
  try {
    await client.get('/auth/csrf-cookie')
    await client.get(`/auth/social/google/callback${params}`)
  } catch (error) {
    return error
  }
}

export const logout = async () => {
  await client.get('/auth/csrf-cookie')
  await client.post('/auth/logout')
}

export const register = async (payload: {
  first_name: string
  last_name: string
  email: string
  password: string
  avatar: File | null
}) => {
  await client.get('/auth/csrf-cookie')
  return client.post('/auth/register', payload)
}

export const forgotPassword = async (payload: { email: string }) => {
  await client.get('/auth/csrf-cookie')
  return client.post('/auth/forgot-password', payload)
}

export const resetPassword = (payload: {
  email: string
  password: string
  password_confirmation: string
  token: string
}) => {
  return client.post('/auth/reset-password', payload)
}

export const confirmPassword = (payload: { password: string }) => {
  return client.post('/auth/confirm-password', payload)
}

export const changePassword = (payload: { password: string }) => {
  return client.put('/auth/users/auth', payload)
}

export const uploadAvatar = (img: File) => {
  const formData = new FormData()
  formData.append('avatar', img, img.name)
  formData.append('_method', 'PUT')
  return client.post<{ data: AuthUser }>('/auth/users/auth', formData)
}

export const updateUsername = (payload: { name: string }) => {
  return client.put('/auth/users/auth', payload)
}

export const csrfCookie = async () => {
  return await client.get('/auth/csrf-cookie')
}
export const getUserAccessToken = (payload: {
  email: string
  password: string
  device_name: string
}) => {
  return client.post('/auth/token', payload)
}

export const authorizeNotification = (channelName: string) => {
  return {
    authorize: (socketId: string, callback: any) => {
      client
        .post('/api/broadcasting/auth', {
          socket_id: socketId,
          channel_name: channelName,
        })
        .then(response => {
          return callback(false, response.data)
        })
        .catch(error => callback(true, error))
    },
  }
}
