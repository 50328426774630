import { isArray } from 'lodash'

import {
  defaultNavigation,
  INavigation,
  navigations,
} from '../configs/navigations'

export type TLeftSideMenu = {
  icon: string
  name: string
  id: string
}

export interface IItemDrag {
  id: string
  index: number
}

export const DRAG_TYPE_MENU = 'DRAG_TYPE_MENU'
export const DRAG_TYPE_EDIT_MENU = 'DRAG_TYPE_EDIT_MENU'

const pickMenuFromValueStorage = (values: string[]) => {
  const menus: INavigation[] = []
  values.forEach(item => {
    const detail = navigations.find(n => n.key === item)
    if (detail) {
      menus.push(detail)
    }
  })
  return menus
}

export const setMenusToLocalStorage = (menus: INavigation[]) => {
  const currentMenus = menus.map(item => item.key)
  localStorage.setItem('CURRENT_MENUS', JSON.stringify(currentMenus))
}

export const generateMainMenu = () => {
  try {
    const prevMenu = localStorage.getItem('CURRENT_MENUS')
    if (prevMenu) {
      const parse = JSON.parse(prevMenu)
      if (isArray(parse)) {
        const menus = pickMenuFromValueStorage(parse)
        setMenusToLocalStorage(menus)
        return menus
      }
    }
    setMenusToLocalStorage(defaultNavigation)
    return defaultNavigation
  } catch (error) {
    setMenusToLocalStorage(defaultNavigation)
    return defaultNavigation
  }
}
