import cx from 'classnames'
import { memo } from 'react'

import ImgThumbnail from '../../../assets/images/img_thumbnail.svg'
import ImgThumbnailMusic from '../../../assets/images/img_thumbnail_music.svg'
import { Image } from '../../../components'
import { IFile } from '../../../services/files'
import { ITag } from '../../../services/tags'
import { ButtonFavorite } from '../../../shared/ButtonFavorite'
import { FileTags } from '../../../shared/Files/FileTags'
import { FILE_FORMAT_ICON, getFileType } from '../../../utils/files'

interface Props {
  file: IFile
  avatarSize?: number
  fileNameSize?: number
  onSelectFile?: (file: IFile) => void
  onSelectTag?: (tag: ITag) => void
}
export const FileNameInfo = memo(
  ({ file, avatarSize = 72, onSelectFile, onSelectTag }: Props) => {
    const tags = file.tags
    const fileType = getFileType(file.mime_type)

    const typeHasThumbnail =
      fileType === 'image' || fileType === 'pdf' || fileType === 'video'
    const isAudio = fileType === 'audio'
    return (
      <div className='flex gap-4 items-center flex-1'>
        <div onClick={() => onSelectFile?.(file)} className='cursor-pointer'>
          <div
            className={cx(
              'bg-separation-100 rounded-lg',
              !typeHasThumbnail && !isAudio && 'p-5',
            )}
            style={{
              width: avatarSize,
              height: avatarSize,
            }}
          >
            <Image
              src={
                typeHasThumbnail
                  ? file.preview_url || file.url
                  : isAudio
                  ? ImgThumbnailMusic
                  : ImgThumbnail
              }
              className={cx(
                'w-full h-full',
                typeHasThumbnail && 'rounded-lg',
                isAudio && 'rounded-lg object-cover',
              )}
            />
          </div>
        </div>
        <div className='flex flex-col flex-1'>
          <div className='flex gap-2 items-center leading-none relative'>
            <span
              onClick={() => onSelectFile?.(file)}
              className='cursor-pointer'
            >
              {FILE_FORMAT_ICON[fileType]}
            </span>
            <div className='flex-1 relative flex gap-2 items-center w-full'>
              <span
                onClick={() => onSelectFile?.(file)}
                className='cursor-pointer truncate leading-normal max-w-[calc(100%_-_4.5rem)]'
                title={file.file_name}
              >
                {file.file_name}
              </span>
              {file.is_favorited && <ButtonFavorite currentStatus viewOnly />}
            </div>
          </div>
          {tags && tags.length > 0 ? (
            <div className='mt-2'>
              <FileTags tags={tags || []} onSelectTag={onSelectTag} />
            </div>
          ) : null}
        </div>
      </div>
    )
  },
)
