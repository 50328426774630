export const PATHNAME = {
  assets: 'assets',
  'asset-groups': 'asset-groups',
  accounts: 'accounts',
  contracts: 'contracts',
  credentialsManager: 'credentials-manager',
  expenses: 'expenses',
  files: 'files',
  invoices: 'invoices',
  leads: 'leads',
  quotes: 'quotes',
  'movement-tracking': 'movement-tracking',
  'fleet-tracking': 'fleet-tracking',
  opportunities: 'opportunities',
  preventatives: 'preventatives',
  products: 'products',
  projects: 'projects',
  'project-calendar': 'project-calendar',
  services: 'services',
  servicesTicketForm: 'services-ticket-form',
  roles: 'roles',
  users: 'users',
  contacts: 'contacts',
  monitorings: 'monitorings',
  'plan-set': 'plan-sets',
  timesheets: 'timesheets',
  settings: 'settings',
  'sub-accounts': 'sub-accounts',
  bills: 'bills',
  bids: 'bids',
  permits: 'permits',
  notes: 'notes',
  'change-logs': 'change-logs',
  estimates: 'estimates',
  tasks: 'tasks',
  'no-permission': 'no-permission',
  dashboard: 'dashboard',
  calendars: 'calendars',
  customer: 'customer',
  uncategorized: 'uncategorized',
  billing: 'billing',
}

export const routesAccountDetail = [
  PATHNAME['sub-accounts'],
  PATHNAME.users,
  PATHNAME.contacts,
  PATHNAME.assets,
  PATHNAME['asset-groups'],
  PATHNAME.projects,
  PATHNAME.quotes,
  PATHNAME.invoices,
  PATHNAME.expenses,
  PATHNAME.bills,
  PATHNAME.opportunities,
  PATHNAME.bids,
  PATHNAME.permits,
  `${PATHNAME.permits}/:permitId`,
  PATHNAME.notes,
  `${PATHNAME.notes}/:noteId`,
  PATHNAME.files,
  PATHNAME.contracts,
  PATHNAME.services,
  PATHNAME.monitorings,
  PATHNAME.products,
  PATHNAME['plan-set'],
  PATHNAME['change-logs'],
  PATHNAME.uncategorized,
  PATHNAME.credentialsManager,
]
export const routesAssetDetail = [
  PATHNAME.notes,
  `${PATHNAME.notes}/:noteId`,
  PATHNAME.files,
  PATHNAME['change-logs'],
]
export const routesPermitDetail = [
  PATHNAME.notes,
  `${PATHNAME.notes}/:noteId`,
  PATHNAME.files,
  PATHNAME['change-logs'],
]

export const routesContactDetail = [
  PATHNAME.files,
  PATHNAME.notes,
  `${PATHNAME.notes}/:noteId`,
  PATHNAME['change-logs'],
]
export const routesContractDetail = [
  PATHNAME.quotes,
  PATHNAME.estimates,
  PATHNAME.invoices,
  PATHNAME.services,
  PATHNAME.expenses,
  PATHNAME.preventatives,
  PATHNAME.timesheets,
  PATHNAME['plan-set'],
  PATHNAME.notes,
  `${PATHNAME.notes}/:noteId`,
  PATHNAME.files,
  PATHNAME['change-logs'],
]
export const routesExpenseDetail = [
  PATHNAME.files,
  PATHNAME.notes,
  `${PATHNAME.notes}/:noteId`,
  PATHNAME['change-logs'],
]
export const routesInvoiceDetail = [
  PATHNAME.notes,
  `${PATHNAME.notes}/:noteId`,
  PATHNAME.files,
  PATHNAME.contracts,
  PATHNAME.monitorings,
  PATHNAME['change-logs'],
]
export const routesMonitoringDetail = [
  PATHNAME.invoices,
  PATHNAME.files,
  PATHNAME.notes,
  `${PATHNAME.notes}/:noteId`,
  PATHNAME['change-logs'],
  PATHNAME.billing,
]
export const routesOpportunityDetail = [
  PATHNAME.expenses,
  PATHNAME.files,
  PATHNAME.notes,
  `${PATHNAME.notes}/:noteId`,
  PATHNAME['change-logs'],
]
export const routesPreventativeDetail = [
  PATHNAME.tasks,
  PATHNAME.timesheets,
  PATHNAME.files,
  PATHNAME.notes,
  `${PATHNAME.notes}/:noteId`,
  PATHNAME['change-logs'],
]
export const routesProductDetail = [
  PATHNAME.assets,
  PATHNAME.files,
  PATHNAME.notes,
  `${PATHNAME.notes}/:noteId`,
  PATHNAME['change-logs'],
]

export const routesProjectDetail = [
  PATHNAME.quotes,
  PATHNAME.contacts,
  PATHNAME.assets,
  PATHNAME.expenses,
  PATHNAME.notes,
  `${PATHNAME.notes}/:noteId`,
  PATHNAME.files,
  PATHNAME.timesheets,
  PATHNAME.permits,
  `${PATHNAME.permits}/:permitId`,
  PATHNAME.invoices,
  PATHNAME['plan-set'],
  PATHNAME['change-logs'],
]
export const routesQuoteDetail = [
  PATHNAME.invoices,
  PATHNAME.notes,
  `${PATHNAME.notes}/:noteId`,
  PATHNAME.files,
  PATHNAME['change-logs'],
]
export const routesServiceDetail = [
  PATHNAME.expenses,
  PATHNAME.invoices,
  PATHNAME['plan-set'],
  PATHNAME.files,
  PATHNAME.notes,
  `${PATHNAME.notes}/:noteId`,
  PATHNAME.timesheets,
  PATHNAME['change-logs'],
  PATHNAME.tasks,
]
export const routesSetting = [
  'account',
  'company',
  'quote',
  'project',
  'accounting',
  'asset',
  'invoice',
  'user',
  'expense',
  'cms',
  'payment',
  'product',
  'notification',
  'integrations',
  'templates',
  'calendars',
]
export const routesUserDetail = [
  PATHNAME.files,
  PATHNAME.notes,
  `${PATHNAME.notes}/:noteId`,
  PATHNAME.assets,
  PATHNAME.timesheets,
  PATHNAME['change-logs'],
]
