import { generateQueryOfEndPoint } from '../utils/functions'
import { IAccount } from './accounts'
import client, { IFilter, PAGE_SIZE } from './client'
import { IFile } from './files'
import { IUser } from './users'

export interface IWhatsappMessage {
  id: number
  user_id: number
  user: IUser
  account_id: number
  account: IAccount
  message: string
  created_at: Date | string
  whatsapp_chat_session_id: number | null
  files: IFile[]
}

export interface IFilterWhatsappMessage extends IFilter {
  filterAccountId?: number | null
  filterFileType?: string | null
}

export interface IWhatsappMessagePayload {
  message: string
  whatsapp_chat_message_ids: number[]
  resource_id: number
  resource_type: string
}

export const getWhatsappMessages = async ({
  search = '',
  sortBy = 'id',
  sortDirection = 'desc',
  pageSize = PAGE_SIZE,
  currentPage = 1,
  filterFileType = null,
  filterAccountId = null,
}: IFilterWhatsappMessage) => {
  const sort = `${sortDirection === 'desc' ? '-' : ''}${sortBy}`
  const endPoint = generateQueryOfEndPoint('/api/whatsappChatMessages', {
    sort,
    'filter[search]': search,
    'filter[account_id]': filterAccountId || null,
    'filter[file_extension]': filterFileType || null,
    limit: pageSize,
    page: currentPage,
  })
  return client.get(`${endPoint}include=account,user,files`)
}

export const editWhatsappMessage = (
  id: number,
  payload: Partial<IWhatsappMessagePayload>,
) => {
  return client.put(`/api/whatsappChatMessages/${id}`, payload)
}

export const moveWhatsappMessage = async (
  payload: Partial<IWhatsappMessagePayload>,
) => {
  return client.post('/api/whatsappChatMessages/move', payload)
}

export const deleteWhatsappMessage = async (id: number) => {
  return client.delete(`/api/whatsappChatMessages/${id}`)
}
