import { IReponseItemSetting } from '../../../../services/settings'
import { IUser, IUserPayload } from '../../../../services/users'
import { formatDateApi } from '../../../../utils/dateTime'
import { OptionValue } from '../../../../utils/form'
import { filterValidSettingForm } from '../../../../utils/settings'

export interface FormProfile {
  name?: string
  first_name?: string
  last_name?: string
  roles?: OptionValue | null
  email?: string
  direct_manager_id?: number
  directManager?: IUser
  dob?: string | null
  driver_license_number?: string | null
  driver_license_state?: string | null
  phone?: string | null
  fax?: string | null
  country?: string | null
  city?: string | null
  zip?: string | null
  state?: string | null
  address?: string | null
  address_2?: string | null
  avatar?: File | null
  avatar_preview_url?: string | null
  userMetas?: IReponseItemSetting[] | null
  isEditMode: boolean
}

export const generateDataSubmit = (value: FormProfile) => {
  const { first_name, last_name, phone, userMetas } = value
  const userDetail = {
    dob: value.dob ? formatDateApi(value.dob) : null,
    driver_license_number: value.driver_license_number || null,
    driver_license_state: value.driver_license_state || null,
    address: value.address || null,
    city: value.city || null,
    state: value.state || null,
    zip: value.zip || null,
    country: value.country || null,
  }

  const filterUserMetas = filterValidSettingForm(userMetas || [])
  const mapUserMetas = filterUserMetas.map(item => ({
    setting_label_id: item.setting_label_id,
    label_name: item.label_name,
    value: item.value || '',
  }))
  const newValues: IUserPayload = {
    first_name,
    last_name,
    phone: phone || null,
    userDetail,
    userMetas: mapUserMetas,
  }
  return newValues
}

export const mapDataForForm = (data: IUser) => {
  const {
    first_name,
    last_name,
    roles,
    phone,
    email,
    userDetail,
    avatar_preview_url,
    userMetas,
    name,
  } = data
  const mapRole =
    roles.length > 0
      ? roles.map(item => ({ label: item.name, value: item.id }))
      : null

  return {
    name,
    avatar_preview_url,
    first_name,
    last_name,
    email,
    phone,
    roles: !!mapRole ? mapRole[0] : null,
    dob: userDetail?.dob || null,
    driver_license_number: userDetail?.driver_license_number || null,
    driver_license_state: userDetail?.driver_license_state || null,
    country: userDetail?.country || null,
    city: userDetail?.city || null,
    zip: userDetail?.zip || null,
    state: userDetail?.state || null,
    address: userDetail?.address || null,
    userMetas,
    isEditMode: false,
  }
}
