import { SVGProps } from 'react'

export const Powerpoint = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className='text-[#E93D28]'
      {...props}
    >
      <path
        d='M0 2.28571C0 1.02335 1.02335 0 2.28571 0H13.7143C14.9767 0 16 1.02335 16 2.28571V13.7143C16 14.9767 14.9767 16 13.7143 16H2.28571C1.02335 16 0 14.9767 0 13.7143V2.28571Z'
        fill='currentColor'
      />
      <path
        d='M10.8571 6.779C10.8571 7.17623 10.7674 7.5409 10.588 7.87302C10.4085 8.19862 10.133 8.46235 9.76128 8.66422C9.38959 8.8661 8.92817 8.96703 8.37704 8.96703H7.35808V11.4286H5.71429V4.57143H8.37704C8.91535 4.57143 9.37036 4.66585 9.74206 4.8547C10.1138 5.04355 10.3925 5.30403 10.5784 5.63614C10.7642 5.96825 10.8571 6.34921 10.8571 6.779ZM8.25207 7.63858C8.56609 7.63858 8.8 7.5637 8.95381 7.41392C9.10761 7.26414 9.18451 7.0525 9.18451 6.779C9.18451 6.5055 9.10761 6.29385 8.95381 6.14408C8.8 5.9943 8.56609 5.91941 8.25207 5.91941H7.35808V7.63858H8.25207Z'
        fill='white'
      />
    </svg>
  )
}
