import listUSStates from '../../../assets/stateUs.json'
import { Props as SelectProps, Select } from '../../../components/Forms/Select'

interface Props extends Omit<SelectProps, 'options'> {
  selected?: string | null
  onSelect?: (value: string) => void
  className?: string
  labelIsName?: boolean | null
}

export const UsStatesSelect = ({
  value,
  onSelect,
  labelIsName = false,
  ...props
}: Props) => {
  return (
    <Select
      className='w-full capitalize cursor-pointer'
      placeholder=''
      onChange={onSelect}
      showSearch
      {...props}
      value={!!value ? value : undefined}
    >
      {listUSStates.map(item => {
        const v = labelIsName ? item.name : item.code
        return (
          <Select.Option value={v} className='capitalize' key={item.code}>
            {v}
          </Select.Option>
        )
      })}
    </Select>
  )
}
