import { usePermission } from '../../hooks/usePermission'

interface Props {
  children: React.ReactNode
  name?: string
  type?: string
  fullPermisison?: string
}
export const ComponentPermisison = ({
  children,
  name,
  type,
  fullPermisison,
}: Props) => {
  const { hasPermission, hasPermissionDetail } = usePermission({
    name,
    type,
    permission: fullPermisison,
  })
  if (hasPermission || hasPermissionDetail) {
    return <>{children}</>
  }
  return null
}
