import { useEffect, useState } from 'react'
import shallow from 'zustand/shallow'

import { Props as SelectProps, Select } from '../../../components/Forms/Select'
import useStore, { Store } from '../../../store'
import { OptionValue } from '../../../utils/form'

interface Props extends Omit<SelectProps, 'options'> {
  selected?: OptionValue | null
  onSelect?: (option: OptionValue | null) => void
  className?: string
}

const mapState = (state: Store) => ({
  line_item_types: state.settings?.quote?.line_item_types,
})

export const LineItemTypesSelect = ({
  selected = null,
  className = '',
  onSelect,
  ...props
}: Props) => {
  const { line_item_types } = useStore(mapState, shallow)
  const [currentSelected, setSelected] = useState<OptionValue | null>(selected)
  // const handleSelect = (id: number) => {
  //   const detailItem = line_item_types?.find(item => item.id === id)
  //   if (detailItem) {
  //     onSelect?.({
  //       value: id,
  //       label: detailItem.label,
  //     })
  //   }
  // }

  const handleSelect = (item: OptionValue) => {
    setSelected(item)
    onSelect?.(item)
  }
  const handleDeselect = () => {
    setSelected(null)
    onSelect?.(null)
  }

  if (!line_item_types) {
    return null
  }

  const mapData = line_item_types.map(item => ({
    value: item.id,
    label: item.label,
  }))

  useEffect(() => {
    if (
      selected !== currentSelected ||
      selected?.label !== currentSelected?.label ||
      selected?.value !== currentSelected?.value
    ) {
      setSelected(selected)
    }
  }, [selected])

  return (
    <Select
      className='w-full capitalize cursor-pointer'
      {...props}
      labelInValue
      allowClear
      onSelect={handleSelect}
      onDeselect={handleDeselect}
      options={mapData}
      value={currentSelected}
    />
  )
}
