import { SVGProps } from 'react'

export const ErrorCircle = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width='14'
      height='14'
      viewBox='0 0 14 14'
      xmlns='http://www.w3.org/2000/svg'
      fill='currentColor'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7 0C3.13425 0 0 3.13425 0 7C0 10.8658 3.13425 14 7 14C10.8658 14 14 10.8658 14 7C14 3.13425 10.8658 0 7 0ZM7 11.4683C6.45283 11.4683 6.07833 11.0466 6.07833 10.5C6.07833 9.93767 6.46858 9.53167 7 9.53167C7.56175 9.53167 7.92167 9.93767 7.92167 10.5C7.92167 11.0472 7.56175 11.4683 7 11.4683ZM6.63775 7.77058C6.78417 8.25417 7.22342 8.246 7.36283 7.77058C7.50808 7.27592 8.13925 5.10417 8.13983 3.72108C8.13983 1.89817 5.87008 1.90692 5.87008 3.72108C5.87008 5.096 6.46917 7.21292 6.63775 7.77058Z'
        fill='currentColor'
      />
    </svg>
  )
}
