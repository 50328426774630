import {
  Mention,
  MentionProps,
  MentionsInput,
  MentionsInputProps,
  SuggestionDataItem,
} from 'react-mentions'

import { useAsync } from '../../hooks'
import { getUsers,IUser } from '../../services/users'
import { ResourceNameThumbnail } from '../Resources/ResourceNameThumbnail'

interface CustomSuggestionItem extends SuggestionDataItem {
  avatar: string
}

interface Props extends Omit<MentionsInputProps, 'onChange' | 'children'> {
  value: string
  onChange: (value: string) => void
  maxUsers?: number
  mentionProps?: Partial<MentionProps>
}

export const CustomMentionInput = ({ onChange, style, mentionProps, maxUsers = 5, ...props }: Props) => {
  const { execute } = useAsync({ showNotifOnError: true })
  const handleFetchUsers = (
    query: string,
    callback: (data: CustomSuggestionItem[]) => void,
  ) => {
    execute(getUsers({ search: query, pageSize: maxUsers }))
      .then(res =>
        res.data.data.map((item: IUser) => ({
          id: item.id,
          display: item.name,
          avatar: item.avatar_preview_url || item.avatar_url,
        })),
      )
      .then(callback)
  }

  const renderSuggestion = (s: SuggestionDataItem) => {
    const suggestion = s as CustomSuggestionItem
    return (
      <div key={suggestion.id} className='px-4 py-1.5'>
        <ResourceNameThumbnail
          name={suggestion.display}
          thumbnail={suggestion.avatar}
          type='avatar'
          hashId={`user-${suggestion.id}`}
          thumnailClassName='!w-6 !h-6'
          wrapperClassName='no-underline !flex w-full'
          thumnailWrapperClassName='flex-none'
          textClassName='flex-1'
        />
      </div>
    )
  }

  return (
    <MentionsInput
      placeholder='Write your note...'
      maxLength={2500}
      forceSuggestionsAboveCursor={true}
      customSuggestionsContainer={children => {
        return (
          <div className='border border-separation-900 shadow-dropdown rounded-lg w-60 bg-white'>
            {children}
          </div>
        )
      }}
      {...props}      
      onChange={(_, newValue) => onChange(newValue)}
      style={{
        ...style,
        input: {
          overflow: 'auto',
          resize: 'none',
          ...(style?.input || {})
        },
        suggestions: {
          background: 'transparent',
          marginTop: '0 !important',
          ...(style?.suggestions || {})
        },        
      }}      
    >
      <Mention
        trigger='@'
        data={handleFetchUsers}
        renderSuggestion={renderSuggestion}
        className='bg-primary-400 rounded-[2rem] text-primary-900 !font-medium relative z-[1] pointer-events-none' 
        displayTransform={(_, display) => `   ${display}   `}
        appendSpaceOnAdd={true}
        {...mentionProps}
      />
    </MentionsInput>
  )
}
