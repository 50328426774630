import cx from 'classnames'
import { forwardRef, InputHTMLAttributes, ReactNode } from 'react'

import { ErrorCircle } from '../../icons'

export type Props = {
  size?: 'small' | 'default' | 'large'
  block?: boolean
  prefixClassName?: string
  prefixIcon?: ReactNode
  suffix?: ReactNode
  extra?: ReactNode
  error?: boolean
  success?: boolean
  showIconError?: boolean
  extraClass?: string
  innerClass?: string
  disableDecimal?: boolean
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'size'>

const sizes = {
  small: 'h-6 py-0 px-[0.4375rem]',
  default: 'h-10 py-1 px-3',
  large: 'h-12 px-4 py-1.5',
}
const normalField =
  'border-separation-900 hover:border-primary-900 hover:shadow-input focus:shadow-input focus:border-primary-900'
const successField =
  'border-green-900 hover:shadow-input focus:shadow-input focus:border-green-900'
const disabledField =
  'pointer-events-none border-separation-900 bg-separation-400 text-black-400'
const errorField = 'border-red-900 hover:border-red-900 focus:border-red-900'

export const Input = forwardRef<HTMLInputElement, Props>(
  (
    {
      size = 'default',
      block,
      suffix,
      extra,
      prefixIcon,
      error,
      success,
      extraClass,
      innerClass = '',
      showIconError = true,
      disableDecimal = false,
      prefixClassName = '',
      ...props
    },
    ref,
  ) => {
    return (
      <div className={cx('relative', innerClass, block && 'block w-full')}>
        <div className='flex items-center w-full'>
          <input
            {...props}
            ref={ref}
            className={cx(
              'border rounded-lg w-full text-body outline-0 placeholder:text-black-400 placeholder:font-normal',
              sizes[size],
              suffix && '!pr-8',
              props.disabled && disabledField,
              error ? errorField : normalField,
              success && successField,
              prefixIcon && 'pl-12',
              props.className,
            )}
            onKeyDown={e => {
              if (e.key === '.' && disableDecimal && props.type === 'number') {
                e.preventDefault()
                props.onKeyDown?.(e)
                return
              }
              props.onKeyDown?.(e)
            }}
          />
          {prefixIcon && (
            <div
              className={cx(
                'absolute left-px top-px bottom-px w-[2.625rem] flex items-center justify-center',
                'bg-separation-400 border-r border-separation-900 rounded-l-lg',
                prefixClassName,
              )}
            >
              {prefixIcon}
            </div>
          )}
          {(error || suffix) && (
            <div className='inline-flex gap-2 absolute right-4 items-center'>
              {error && showIconError && (
                <span>
                  <ErrorCircle className='text-red-900' />
                </span>
              )}
              {suffix && (
                <div className={cx('flex justify-center items-center')}>
                  {suffix}
                </div>
              )}
            </div>
          )}
        </div>
        {extra && <div className={cx('mt-1 text-sm', extraClass)}>{extra}</div>}
      </div>
    )
  },
)
